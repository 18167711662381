class Visita {
    constructor(visitaData, user) {
        this.locacion = visitaData.PuntoBodega.Nombre;
        this.pesoCarga = visitaData.PesoCarga;
        this.informe = visitaData.Informe || [];
        this.valoracionPersonal = visitaData.ValoracionPersonal;
        this.user = user;
    }

    convertirAKilos(peso, unidad) {
        switch (unidad.toLowerCase()) {
            case 'gramo':
                return peso / 1000;
            case 'tonelada':
                return peso * 1000;
            default:
                return peso;
        }
    }

    materialPermitido(material) {
        if (this.user.typeUser === "EMPRESA") {
            return this.user.materiales.some(e => e.Material.Material === material);
        }
        return true; // Si no es una empresa, todos los materiales son permitidos
    }

    getPesoTotal() {
        if (this.pesoCarga !== undefined && this.pesoCarga !== null) {
            return this.pesoCarga;
        }

        return this.informe.reduce((acumulado, item) => {
            // Maneja la posibilidad de diferentes estructuras de informe
            const material = typeof item === 'string' ? item : item.material;
            const unidad = item.Unidad_de_Peso || 'kilogramo';

            if (!this.materialPermitido(material) || unidad.toLowerCase() === "unidad") {
                return acumulado;
            }

            const pesoEnKilos = this.convertirAKilos(parseFloat(item.value || 0), unidad);
            return acumulado + (isNaN(pesoEnKilos) ? 0 : pesoEnKilos);
        }, 0);
    }

    getValoracion() {
        return this.valoracionPersonal;
    }
}


export const procesarVisitas = (datos, user) => {
    const resumenPorLocacion = {};

    datos.forEach(visitaData => {
        const visita = new Visita(visitaData, user);
        const locacion = visitaData.PuntoGenerador ? visitaData.PuntoGenerador.Nombre : "Sin datos de locación";

        if (!resumenPorLocacion[locacion]) {
            resumenPorLocacion[locacion] = {
                visitas: 0,
                pesoTotal: 0,
                totalValoracion: 0,
                conteoValoraciones: 0,
                gestor: user?.typeUser === "EMPRESA" ? visitaData.PuntoBodega ? visitaData.PuntoBodega.Nombre : "Sin datos de gestor" : undefined
            };
        }

        resumenPorLocacion[locacion].visitas++;
        resumenPorLocacion[locacion].pesoTotal += visita.getPesoTotal();

        if (visita.getValoracion() !== undefined && visita.getValoracion() !== null) {
            resumenPorLocacion[locacion].totalValoracion += visita.getValoracion();
            resumenPorLocacion[locacion].conteoValoraciones++;
        }
    });

    const resultadoFinal = [];

    for (const locacion in resumenPorLocacion) {
        const locacionData = resumenPorLocacion[locacion];
        resultadoFinal.push({
            Locacion: locacion,
            Visitas: locacionData.visitas,
            TotalRecuperado: locacionData.pesoTotal,
            MediaCalificacion: locacionData.conteoValoraciones > 0 ? (locacionData.totalValoracion / locacionData.conteoValoraciones).toFixed(2) : '-',
            gestor: user?.typeUser === "EMPRESA" ? locacionData.gestor : undefined
        });
    }

    return resultadoFinal;
};
