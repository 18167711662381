import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import { useSelector } from 'react-redux';

function descargarImagen(blob,name) {
    const url = URL.createObjectURL(blob);
    const enlace = document.createElement('a');
    enlace.href = url;
    enlace.download = "nombre_imagen.jpg";
    document.body.appendChild(enlace);
    enlace.click();
    document.body.removeChild(enlace);
    URL.revokeObjectURL(url);
  }

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #999',
    borderRadius:5,
    boxShadow: 24,
    p: 4,
};

export default function QrsModal({status,setStatus,qr}) {
  const user = useSelector(state => state.user)
    React.useEffect(() => {
    }, [qr])
    
    const descargarArchivo = async () => {
        try {
            // Obtener la imagen como Blob
            const respuesta = await fetch(qr.Img);
            const blob = await respuesta.blob();

            // Crear una URL para el Blob
            const url = window.URL.createObjectURL(blob);

            // Crear un elemento <a> y configurarlo para descargar
            const enlace = document.createElement('a');
            enlace.href = url;
            enlace.download = qr.Huella + ".png";

            // Agregar el enlace al DOM y hacer clic en él
            document.body.appendChild(enlace);
            enlace.click();

            // Limpiar y eliminar el enlace
            window.URL.revokeObjectURL(url);
            document.body.removeChild(enlace);
        } catch (error) {
            console.error("Error al descargar la imagen:", error);
        }
    };

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={status}
        onClose={()=>setStatus(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={status}>
          <Box sx={style}>
            <div className='d-flex justify-content-center flex-column'>
            {  qr.Img? <img src={qr.Img}/>:
                <Typography id="spring-modal-title" variant="h6" component="h2">
                    No hay qr
                </Typography>
            }
            {qr.Img&&
                
                    <Button variant="contained" onClick={(e)=>{setStatus(false);descargarArchivo(e)}} style={{marginTop:15,backgroundColor:user.colors[2]}}>
                        Descargar Qr
                    </Button>
            } 
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
