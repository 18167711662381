import { Avatar, Badge, Button, IconButton, MenuItem, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'

import AddRoadIcon from '@mui/icons-material/AddRoad';
import { DeleteAllWithAuth, PutAllWithAuth, UseGetWithAuth2 } from '../../service/base2';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ModalDetailRuta } from './modals/ModalDetailRuta';
import { NombrePersonalRuta } from './components/NombrePersonalRuta';
import Swal from 'sweetalert2';
import { Tab, Tabs } from 'react-bootstrap';
import { SplitingInitialsName, hasPermission } from '../../helpers/Utils';
import { RutaPendiente } from './components/RutaPendiente';
import { ModalSelectInforme } from './modals/ModalSelectInforme';
import { Link, useNavigate } from 'react-router-dom';
import { rutasAction } from '../../service/redux/action/DataActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const { v4: uuidv4 } = require('uuid');

export const Rutas = ({ setType, setRutaEdit, setInformesRutas }) => {

    const { empresas } = useSelector(state => state.empresa)
    const user = useSelector(state => state.user)
    let id_empresa = user?.typeUser === 'EMPRESA' ? user?.Personal?.Empresa?.id : user?.bodegas[0]?.Empresa.id;
    let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color
    const [menuOpen, setMenuOpen] = useState(false);
    const [allRutas, setAllRutas] = useState(user?.rutas)
    const [bodegas, setBodegas] = useState()
    const [personal, setPersonal] = useState([])
    const [puntosGeneradoresPendientesAsignar, setPuntosGeneradoresPendientesAsignar] = useState([])
    const [permisosPersonal, setPermisosPersonal] = useState(user?.Personal?.Permisos)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const dispatch =useDispatch()
    const navigate = useNavigate()
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // const getPersonal = async () => {
    //     const res = await UseGetWithAuth2(`/personals?_where[user]=${empresas[0].id}`, user?.Personal?.uid)
    //     setPermisosPersonal(res.resGet[0]?.Permisos)

    //     const { ok, resGet } = await UseGetWithAuth2(`/personals`, user?.Personal?.uid)
    //     if (ok) return setPersonal(resGet)
    // };

    // const getBodegas = async () => {
    //     const { ok, resGet } = await UseGetWithAuth2(`/bodegases?_where[Empresa]=${id_empresa}`, user?.Personal?.uid)
    //     if (ok) return setBodegas(resGet)
    // }

    const getAllRutas = async () => {
        if (empresas[0]?.typeUser === 'BODEGA') {
            const { ok, resGet } = await UseGetWithAuth2(`/rutas?_where[Bodega]=${empresas[0]?.idBodega}`, user?.Personal?.uid)
            if (ok) return setAllRutas(resGet)
        } else {
            const { ok, resGet } = await UseGetWithAuth2(`/rutas?_where[Bodega.Empresa]=${id_empresa}`, user?.Personal?.uid)
            if (ok) return setAllRutas(resGet)
        }
    };


    // const getPuntosGeneradores = async () => {
    //     async function getData(id) {
    //         const { resGet } = await UseGetWithAuth2(`/puntos-generadores?_where[PuntosBodega.Bodega]=${id}&_where[Llenado]=true&_where[asignado]=false`, user?.Personal?.uid)
    //         return resGet;
    //     }
    //     Promise.all(empresas[0]?.typeUser === 'EMPRESA' ? empresas[0]?.empresas?.data?.Bodegas : empresas[0]?.empresas?.data[0]?.Bodegas.map(ele => getData(ele.id)))
    //         .then(results => {
    //             const resultados = [];
    //             for (let i = 0; i < results.length; i++) {
    //                 if (results.length !== 0) {
    //                     resultados.push(...results[i]);
    //                 }
    //             }
    //             setPuntosGeneradoresPendientesAsignar(resultados);// almacena los resultados en el array local
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });
    // };

    const updateEstado = async (id, completada) => {
        const { resPut, ok } = await PutAllWithAuth(`/rutas/${id}`, user?.Personal?.uid, { Completada: !completada });
        if (ok) {
            Swal.fire({
                position: 'center', icon: 'success', title: `El estado ha sido actualizado`,
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(rutasAction(resPut))
        }
    }

    const eliminarRuta = async (id) => {
        console.log('====================================');
        console.log(id);
        console.log('====================================');
        setMenuOpen(false)
        await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esta acción!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: dataColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then(async(result) => {
            if (result.isConfirmed) {
                const { ok } = await DeleteAllWithAuth(`rutas/${id}`, user?.Personal?.uid);
                console.log(ok);
                if (ok) {
                    
                    Swal.fire({
                        position: 'center', icon: 'success', title: `La ruta ha sido eliminada`,
                        showConfirmButton: false,
                        timer: 2500
                    })
                   
                }
            }
        }).then(() => {
            const routes = allRutas.filter(e => e.id !== id)
            // for (let index = 0; index < route.PuntosGeneradores.length; index++) {     
            //     const { resPut } = await PutAllWithAuth(`/puntos-generadores/${route.PuntosGeneradores[index]._id}`, user?.Personal?.uid, { asignado: false})     
            // }
            console.log('====================================');
            console.log("routes",routes);
            console.log('====================================');
            dispatch(rutasAction(routes))
        })
    };

   

    const findUsername = ( ruta) => {
        for (const e of user?.PuntosBodega || []) {
          for (const i of e.empleados || []) {
            if(ruta.Personal?.id){
                if (i.id === ruta.Personal.id) {
                return i.user.username;
                }else{return "--";}
            }else{return "--";}
          }
        }
        return "--";
      };
     
    //  useEffect(()=>{
    //     console.log("==> aquiiiii",dataColor);
    // },[user?.rutas])
    return (
        <>
            <nav className='d-flex gap-3 justify-content-between p-2  mb-2 '
                style={{ backgroundColor: user.colors[1],borderRadius:20  }}>
                <div className='ml-5'>
                    {/* <h5 style={{ color: "white" }}>{bodegaEdit.Nombre ? 'Actualizar datos' : 'Nueva bodega'}</h5> */}
                    <h5 style={{ color: "white", backgroundColor: user?.colors[1],marginLeft:10  }} className='mt-1'>Rutas</h5>
                </div>
            </nav>
            {/* {empresas[0]?.empresas?.data?.id === '6497e833545f460014781d0b' ?
                <Tabs defaultActiveKey="pendientes" className="mt-3">
                    <Tab eventKey="pendientes"
                        title='Pendientes' >
                       <RutaPendiente hasPermission={hasPermission} setRutaEdit={setRutaEdit} puntosGeneradores={puntosGeneradoresPendientesAsignar} setType={setType} getPuntosGeneradores={getPuntosGeneradores} />
                    </Tab>
                    <Tab eventKey="asignar" title='Rutas'>

                        
                        {hasPermission(['Asignar ruta']) &&
                            // <Link className="navbar-brand" >
                                <Button  variant="contained"  style={{ backgroundColor: dataColor, position: "absolute", top: 200, right: 20, cursor: "pointer" }}>
                                    <div className='d-flex gap-3 ' style={{height:25}}>
                                            <p>Asignar rutgua</p>
                                            <AddRoadIcon />
                                    </div>
                                </Button>
                            // </Link>
                        }

                        <Paper sx={{ width: '100%', marginTop: 7 }}>
                            <TableContainer sx={{ minHeight: 440, maxHeight: 840 }}>
                                <Table  aria-label="sticky table">
                                    <TableHead>
                                        <TableRow hover tabIndex={-1} key={uuidv4()}>
                                            <TableCell align={"center"}>
                                                <b>Nombre</b>
                                            </TableCell>

                                            <TableCell align={"center"}>
                                                <b>Teléfono</b>
                                            </TableCell>

                                            <TableCell align={"center"}>
                                                <b>Fecha</b>
                                            </TableCell>

                                            <TableCell align={"center"}>
                                                <b>Estado</b>
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                <b>Locaciones</b>
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                <b>Informes</b>
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                <b>Acciones</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {allRutas && allRutas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ruta) => (

                                            <TableRow hover tabIndex={-1} key={uuidv4()}>
                                                <TableCell align={"center"}>
                                                    {findUsername(ruta)}
                                                </TableCell>

                                                <TableCell align={"center"}>
                                                    {ruta.Personal.Telefono}
                                                </TableCell>

                                                <TableCell align={"center"}>
                                                    {ruta.Fecha}
                                                </TableCell>

                                                <TableCell align={"center"}>
                                                    
                                                    {ruta.Completada ? <span className='text-success'>Completada</span> : <span className='text-danger'>Incompleta</span>}
                                                    <Switch
                                                        color="success"
                                                        checked={ruta.Completada}
                                                        onClick={() => {
                                                            updateEstado(ruta.id, ruta.Completada)
                                                        }}
                                                    />
                                                </TableCell>

                                                <TableCell align={"center"}>
                                                    <span className='mx-1'
                                                        style={{ cursor: 'pointer', color: ruta.Bodega.Color, textDecoration: 'underline' }}>
                                                        <ModalDetailRuta e={ruta.PuntosGeneradores} />
                                                    </span>
                                                </TableCell>

                                                <TableCell align={"center"}>
                                                    <span className='mx-1'
                                                        style={{ cursor: 'pointer', color: ruta.Bodega.Color, textDecoration: 'underline' }}>
                                                        <ModalSelectInforme setInformesRutas={setInformesRutas} e={ruta} color={ruta.Bodega.Color} setType={setType} />
                                                    </span>
                                                </TableCell>

                                                <TableCell key={uuidv4()} align={"center"}>
                                                    {hasPermission('Manejar rutas') &&
                                                        <div className='d-flex gap-3 justify-content-center'>
                                                            <Tooltip title="Modificar">
                                                                <EditRoadIcon color='primary'
                                                                    onClick={() => {
                                                                        setRutaEdit(ruta)
                                                                        setType('NewRuta')
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                            <Tooltip title="Eliminar">
                                                                <DeleteTwoToneIcon color='error'
                                                                    onClick={() => {
                                                                        eliminarRuta(ruta.id)
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                            {/* <Tooltip title="Ver en mapa">
                                                    <PersonPinCircleTwoToneIcon style={user.empresa && { color: user.empresa.Color }} onClick={() => showMaps(e.recoleccion)} />
                                                </Tooltip> 
                                                        </div>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                count={allRutas && allRutas.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Tab>
                </Tabs>
                : */}
                <div style={{ maxWidth:"100%" }}>
                    {hasPermission(user?.Personal?.Permisos,'Asignar ruta') &&
                    // to="/nueva-ruta
                        <Link className="navbar-brand" to="/nueva-ruta">
                            <Button  variant="contained"  style={{ backgroundColor: user.colors[2], position: "absolute", top: 150, right: 25, cursor: "pointer",borderRadius:40 }}>
                                {/* Asignar ruta <AddRoadIcon /> */}
                                <div className='d-flex gap-3 ' style={{height:25}}>
                                        <p>Asignar ruta</p>
                                        <AddRoadIcon />
                                </div>
                            </Button>
                        </Link>

                    }

                    <Paper sx={{ width: '100%', marginTop: 7,borderRadius:5 }}>
                        <TableContainer sx={{}}>
                            <Table  aria-label="sticky table">
                                <TableHead>
                                    <TableRow hover tabIndex={-1} key={uuidv4()}>
                                        {user?.typeUser === 'EMPRESA' &&<TableCell align={"center"} size='small'>  <b>Gestor</b> </TableCell>}
                                        <TableCell align={"center"} size='small'> <b>Nombre</b></TableCell>
                                        <TableCell align={"center"} size='small'> <b>Fecha</b> </TableCell>
                                        <TableCell align={"center"} size='small'> <b>Estado</b> </TableCell>
                                        <TableCell align={"center"} size='small'> <b>Locaciones</b> </TableCell>
                                        <TableCell align={"center"} size='small'> <b>Informes</b>  </TableCell>
                                        <TableCell align={"center"} size='small'> <b>Acciones</b> </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allRutas && allRutas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ruta) => (

                                        <TableRow hover tabIndex={-1} key={uuidv4()}>

                                            {user?.typeUser === 'EMPRESA' &&
                                            <TableCell align={"center"} size='small' > 
                                                <img src={ruta.Bodega.Logo} style={{ width: 40, height: 40, borderRadius: 100 }} alt={'profile imagen'} />
                                             </TableCell>
                                            }

                                            <TableCell align={"center"} size='small' > {findUsername(ruta)} </TableCell>
                                            <TableCell align={"center"} size='small'  > {ruta.Fecha} </TableCell>

                                            <TableCell align={"center"} size='small'>
                                                {ruta.Completada ? <span className='text-success'>Completada</span> : <span className='text-danger'>Incompleta</span>}
                                            </TableCell>

                                            <TableCell align={"center"} size='small'>
                                                <span className='mx-1'
                                                    style={{ cursor: 'pointer', color: ruta.Bodega.Color, textDecoration: 'underline' }}>
                                                    <ModalDetailRuta e={ruta.PuntosGeneradores} />
                                                </span>
                                            </TableCell>

                                            <TableCell align={"center"} size='small'>
                                                <span className='mx-1'
                                                    style={{ cursor: 'pointer', color: ruta.Bodega.Color, textDecoration: 'underline' }}>
                                                    <ModalSelectInforme setInformesRutas={setInformesRutas} e={ruta} color={ruta.Bodega.Color} setType={setType} />
                                                </span>
                                            </TableCell>

                                            <TableCell key={uuidv4()} align={"center"} size='small' style={{ position: "relative"}} onMouseLeave={()=>setMenuOpen(false)}>
                                                
                                                <IconButton aria-label="more" id="custom-button"
                                                    style={{ position: "relative", width: 50, height: 50 }}
                                                    onClick={() => setMenuOpen((menuOpen === false || menuOpen !==ruta.id) ? ruta.id : false)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                                    
                                                {menuOpen == ruta.id && (
                                                        <div 
                                                            style={{
                                                            position: "absolute",top:20, right: 20, backgroundColor: "#fff",boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                            zIndex: 2000, width: "17ch",borderRadius: 5,
                                                            }}
                                                        >
                                                        {hasPermission(user?.Personal?.Permisos,'Modificar rutas') && !ruta.Completada &&
                                                                <MenuItem onClick={() => { setMenuOpen(ruta.id);  navigate({ pathname:  `/nueva-ruta`, search: `?${new URLSearchParams({ruta:ruta.id}).toString()}`})}}>
                                                                    Editar
                                                                </MenuItem>
                                                        }
                                                        {hasPermission(user?.Personal?.Permisos,'Eliminar rutas') && new Date(ruta.Fecha) > new Date() &&
                                                            <MenuItem onClick={() => { setMenuOpen(ruta.id); eliminarRuta(ruta.id)  }}>
                                                                Eliminar
                                                            </MenuItem>
                                                      }
                                                        </div>
                                                                
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            component="div"
                            count={allRutas && allRutas.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>

                </div>

            {/* } */}
        </>
    )
}
