import { Button, Divider, Grid } from '@mui/material';
import React, { useState } from 'react'
import GoogleMap from '../../../helpers/PrintMaps/Maps';
import { useSelector } from 'react-redux';
import LlamadorModal from './ModaLlamador';
import FormLoadings from '../../../helpers/FormLoadings';

const Info = ({data}) => {
    const user = useSelector(state => state.user)
    console.log('===>',user.EmpresasGeneradoras);
    console.log("xdata",data);
    console.log('====================================');
        const [modal,setModal]=useState(false),
        [loading,setLoading]=useState(false),
    Responsable = user?.EmpresasGeneradoras&&user?.EmpresasGeneradoras.find(e =>  e.PuntoGenerador&&e.PuntoGenerador.some(i => i.id&& i.id === data._id) ),
    dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color
    
   
  return (
    <div>
        <div>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 12, sm: 12, md: 12 }} style={{
                padding:20,marginLeft:5,marginTop:10,backgroundColor:user.colors[8]+"1a",width:"97%",
                borderRadius:5,boxShadow:"1px 1px 1px 1px rgba(0,0,0,0.2)"}}>

                <Grid xs={12} sm={6} md={6}  style={{minHeight:300,borderRadius:5}} >
                    <div className='d-flex flex-column  h-100' style={{padding:10,backgroundColor:"white",marginRight:"1%",borderRadius:5}} >
                            
                        <p> <b>Nombre: </b>{data.Nombre}</p>
                        <p> <b>Dirección: </b>{data.Direccion}</p>
                        <p> <b>Estado: </b>{data.Llenado?"La locación necesita ser visitada":"Aún no es necesario visitar la locación"}</p>

                        <div>
                            <p> <b>Permisos: </b> </p>
                            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 6, sm: 4, md: 4 }} style={{padding:2,marginLeft:1}}>
                                {Array.isArray(data.Permisos)&&data.Permisos.length>0? data.Permisos.map(i=>
                                    <Grid xs={6} sm={4} md={4} >
                                    - {i.Permiso}
                                    </Grid> ):
                                    <p>Aún no se han asignado permisos</p>
                                }
                            </Grid>
                        </div>

                    </div>
                </Grid>

                <Grid xs={12} sm={6} md={6} style={{minHeight:300,maxHeight:300}}  >
                        <GoogleMap lat={Number(data.Latitud)} lng={Number(data.Longitud)}/>
                </Grid>

                <Grid xs={12} sm={12} md={12}  >
                    <Divider sx={{height:2, background:"#E3E3E3",marginTop:3, marginBottom:3}}/>
                </Grid>

                <Grid xs={12} sm={12} md={12}  >
                    <p style={{marginLeft:10}}> <b>Responsable: </b> </p>
                    {Responsable ?

                        <div className='d-flex  px-2'>

                            <div className='d-flex flex-column'>
                                <p> <b>Nombre del responsable: </b>{Responsable.NombreContacto}</p>
                                <p> <b>Cargo: </b>{Responsable.CargoContacto}</p>
                            </div>

                            <div className='d-flex flex-column' style={{marginLeft:"5%"}}>
                                <p> <b>Correo: </b>{Responsable.Email}</p>
                                <p> <b>Teléfono: </b>{Responsable.Telefono}</p>
                            </div>

                        </div>:
                        <p style={{marginLeft:10}}>La locación no tiene entidad responsable</p>
                        }
                </Grid>

                <Grid xs={12} sm={12} md={12}  >
                    <Divider sx={{height:2, background:"#E3E3E3",marginTop:3, marginBottom:3}}/>
                </Grid>

                <Grid xs={12} sm={12} md={12}  >
                    <p style={{marginLeft:10}}> <b>Llamadores: </b> { Array.isArray(data.Llamadores)?data.Llamadores.length:0} </p>
                    
                </Grid>
            </Grid>
            <Button variant="contained" onClick={(e)=>{setModal(true)}} style={{marginTop:15,backgroundColor:dataColor}}> Agregar llamador </Button>
            <LlamadorModal setStatus={setModal} status={modal} gene={data} setLoading={setLoading}/>
        </div>
        {loading &&
            <FormLoadings/>
        }
    </div>
  )
}

export default Info