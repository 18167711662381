import React from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import './Solicitudes.css'
import CanecaIcon from './Caneca'
import { Button, Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Mancha from '../../assets/blob-haikei.png';
const emptyPlaces = require('../../assets/emptyplaces.png')



const Solicitudes = () => {
    const user = useSelector(store => store.user)
    const solicitudes =  user?.PuntosGeneradores?.filter(e=>e.Llenado==true && e.asignado==false)||[]
    console.log("solicitudes",solicitudes);
    const navigate = useNavigate();
  return (
    <div style={{width:"99.8%"}}>
        
        <nav className='d-flex gap-3 justify-content-between p-2  mb-2 '
            style={{ backgroundColor: user.colors[1],borderRadius:20  }}>
            <div className='ml-5'>
                {/* <h5 style={{ color: "white" }}>{bodegaEdit.Nombre ? 'Actualizar datos' : 'Nueva bodega'}</h5> */}
                <h5 style={{ color: "white", backgroundColor: user?.colors[1],borderRadius:3,marginLeft:10  }} className='mt-1'>Solicitudes</h5>
            </div>
        </nav>
        {solicitudes.length>0?
            <>
                <h5 style={{marginLeft:20}}>Hay {solicitudes.length} solicitudes</h5>

                <div className="row p-2 w-100 ">
                    {solicitudes.map((e,i)=>(
                        <Paper key={i} style={{ height:230}} className="col-12 col-sm-12 col-md-6 col-lg-6 mb-1 ">
                            <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                <Card.Body>
                                    <div className="d-flex justify-content-around align-items-center w-100 h-100">
                                        <div className='align-self-center' >
                                            <h6 style={{fontWeight:"bolder"}}>{e.Nombre}</h6>
                                            {/* <p>{e.Direccion}</p> e.Llenado && !e.asignado?"Lleno, Prioridad alta":(e.Llenado && !e.asignado)?{color:"red"}:*/}
                                            <p style={{fontWeight:"bolder"}}>Estado: <span style={{fontWeight:"normal"}}>{(e.Llenado && !e.asignado)?"Lleno, Prioridad alta":"Operativo, Capacidad libre"}</span></p>
                                            <div className="d-flex justify-content-around align-items-center w-100 mt-5 g-5">
                                                <Button variant="contained" style={{backgroundColor:user.colors[3],color:"white",fontWeight:"bold"}} onClick={()=>navigate({ pathname:  `/nueva-ruta`, search: `?${new URLSearchParams({generador:e.Nombre}).toString()}`})}>
                                                    Crear ruta nueva 
                                                </Button>
                                            </div>
                                        </div>
                                        <div className='align-self-center'>
                                            {e.porcentaje !== undefined && (
                                                <div className="caneca">
                                                    <div className="caneca-lid" style={{backgroundColor:"whitesmoke"}}>
                                                        <div className="caneca-handle" style={{backgroundColor:"lightgrey"}}></div>
                                                    </div>
                                                    <p style={
                                                        {textAlign:"center", position:"absolute",top:"30%",left:"15%",color:user.colors[2],
                                                        fontWeight:"bold",fontSize:13,zIndex:20}}>
                                                            {e.porcentaje+'%'}
                                                    </p>
                                                    <div className="caneca-body">
                                                        <div className="caneca-fill" style={{height: e.porcentaje==0?100:e.porcentaje+'%',backgroundColor:"grey"}}></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    
                                </Card.Body>
                            </div>
                        </Paper>
                    ))}
                </div>
            </>
          :
            <div className='d-flex flex-column gap-3 justify-content-center p-2  mb-2 align-items-center'>
          
                <h3>No hay solicitudes en este momento</h3>
                <div style={{ backgroundImage: `url(${Mancha})`,width: 500, height: 500, display:"flex",alignItems:"center",justifyContent:"center",
                    backgroundSize: 'cover',backgroundPosition: 'center',marginTop:-60 }}>
                    <img src={emptyPlaces} alt="emptyPlaces" style={{width:160,height:160,}}/>
                </div>

            </div>
           
        }
    </div>
  )
}

export default Solicitudes