import { useEffect, useState } from "react";
import { UseGetWithAuth2 } from "../service/base2";
import { useSelector } from "react-redux";
import code from './Codephones.json'
import tinycolor from 'tinycolor2';


// Esta función genera un array de colores monocromáticos
export function generateMonochromaticColors(baseColor, count = 10) {
  const colors = [];
  let baseHsl = tinycolor(baseColor).toHsl();
  for (let i = 0; i < count; i++) {
    const color = tinycolor({ h: baseHsl.h, s: baseHsl.s, l: (i * 0.1) + 0.1 });
    colors.push(color.toHexString());
  }
  return colors;
}


export function filtrarPorFechaReportes(reportes, empresa, fechaInicio, fechaFin) {
  let report = { "Logisticos": { bodega: [], locaciones: [] }, "Recolectores": { bodega: [] }, "Gestores": [] }
  function filtrarBodegaPorFecha(bodega) {
    return bodega.filter(item => {
      const fechaItem = new Date(item.Fecha || item.Fecha_carga);
      return fechaItem >= fechaInicio && fechaItem <= fechaFin;
    });
  }

  function filtrarReportes(tipo) {
    if (tipo === "Gestores") {
      report[tipo] = filtrarBodegaPorFecha(reportes[tipo])
    } else {
      report[tipo].bodega = filtrarBodegaPorFecha(reportes[tipo].bodega);
      report[tipo].locaciones = filtrarBodegaPorFecha(reportes[tipo].locaciones);
    }
  }

  if (empresa === 'Publica') {
    filtrarReportes('Logisticos');
    filtrarReportes('Recolectores');
    filtrarReportes('Gestores');
  } else {
    filtrarReportes('Gestores');
    filtrarReportes('Logisticos');
  }

  return report;
}

export const filtrarPorFecha = (informes, opcion, fechaInicio, fechaFin) => {
  const ahora = new Date();
  let inicio;
  let fin;

  switch (opcion) {
    case 'ayer':
      inicio = new Date(ahora);
      inicio.setDate(ahora.getDate() - 1);
      fin = inicio;
      break;
    case 'hoy':
      inicio = ahora;
      fin = ahora;
      break;
    case 'esta semana':
      inicio = new Date(ahora);
      inicio.setDate(ahora.getDate() - ahora.getDay());
      fin = ahora;
      break;
    case 'semana anterior':
      inicio = new Date(ahora);
      inicio.setDate(ahora.getDate() - ahora.getDay() - 7);
      fin = new Date(inicio);
      fin.setDate(inicio.getDate() + 6);
      break;
    case 'personalizado':
      inicio = new Date(fechaInicio);
      fin = new Date(fechaFin);
      break;
    default:
      inicio = 0;
      fin = 0;
  }
  let res = informes ? opcion == "Todo" ? informes : informes.filter(
    (informe) => new Date(informe.Fecha) >= inicio && new Date(informe.Fecha) <= fin
  ) : [];
  return res
};

export function filtrarRutasPorFecha(rutas, empresa, fechaInicio, fechaFin) {
  function filtrarRutas(rutasArray) {
    return rutasArray.filter(item => {
      const fechaItem = new Date(item.Fecha);
      return fechaItem >= fechaInicio && fechaItem <= fechaFin;
    });
  }

  const rutasFiltradas = {
    rutas_Logisticos: filtrarRutas(rutas.rutas_Logisticos),
    rutas_Recolectores: empresa === 'Publica' ? filtrarRutas(rutas.rutas_Recolectores) : rutas.rutas_Recolectores
  };

  return rutasFiltradas;
}

export const SplitingInitialsName = (username) => {
  let initials = '';
  if (username) {
    const names = username.split(' ');
    names.forEach((name) => {
      const firstName = names[0] || '';
      const secondName = names[1] || '';
      initials = secondName !== '' ? `${firstName.substring(0, 1)}${secondName.substring(0, 1)}` : `${firstName.substring(0, 1)}${firstName.substring(1, 2)}`;
    });
  }
  return initials;
}

export const hasPermission = (permisosPersonal, permisoRequerido = ['no hay permisos']) => {
  const userPermissions = permisoRequerido
  return permisosPersonal ? permisosPersonal.some(permission => userPermissions.includes(permission.Permiso)) : false;
};

export const GetAllQr = ({ id }) => {
  const { empresas } = useSelector(state => state.empresa)
  const url = id && `/qrs/${id.id}`;
  const [qr, setQr] = useState([])
  const getQr = async () => {
    if (!id) { return setQr([]) } else {
      const { ok, resGet } = await UseGetWithAuth2(url, empresas[0].jwToken);
      return setQr(resGet)
    }

  }
  useEffect(() => {
    getQr()
  }, []);

  return (
    <> {id ? <img src={qr.Img} alt={qr.Huella} width="50px" /> : <span className="text-warning">No hay QR</span>}</>
  )
}

export const encontrarValores = (array, condicion) => {
  if (array && array.length > 0) {
    const resultados = [];
    for (let i = 0; i < array.length; i++) {
      if (condicion) {
        resultados.push(array[i].id);
      }
    }
    return resultados;
  } else { return [] }
};
export const encontrarValoresName = (array, condicion) => {
  const resultados = [];
  for (let i = 0; i < array.length; i++) {
    if (condicion) {
      resultados.push(array[i].Permiso);
    }
  }
  return resultados;
};

export const generateSerial = (prefix, length = 8) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    result += chars.charAt(randomIndex);
  }
  return `https://hilo.cool/${prefix}-${result}`;
};

export const getAbbreviation = (unit) => {
  switch (unit) {
    case 'gramo':
      return 'G';
    case 'kilogramo':
      return 'KG';
    case 'tonelada':
      return 'Tn';
    case 'unidad':
      return 'Ud';
    default:
      return unit;  // Retornar el valor original si no se encuentra una abreviatura
  }
}

export const convertWeight = (amount, currentUnit, targetUnit) => {
  let conversionRate = {
    gramo: { kilogramo: 0.001, tonelada: 0.000001, gramo: 1 },
    kilogramo: { gramo: 1000, tonelada: 0.001, kilogramo: 1 },
    tonelada: { gramo: 1000000, kilogramo: 1000, tonelada: 1 },
  };

  return (amount && currentUnit != "unidad" && targetUnit != "unidad") ? amount * conversionRate[currentUnit][targetUnit] : 0;
};

export const FormDates = (opcion) => {
  const ahora = new Date();
  let inicio;
  let fin;
  switch (opcion) {
    case 'ayer':
      inicio = new Date(ahora);
      inicio.setDate(ahora.getDate() - 1);
      fin = inicio;
      break;
    case 'hoy':
      inicio = ahora;
      fin = ahora;
      break;
    case 'esta semana':
      inicio = new Date(ahora);
      inicio.setDate(ahora.getDate() - ahora.getDay());
      fin = ahora;
      break;
    case 'semana anterior':
      inicio = new Date(ahora);
      inicio.setDate(ahora.getDate() - ahora.getDay() - 7);
      fin = new Date(inicio);
      fin.setDate(inicio.getDate() + 6);
      break;
    default:
      fin = 0;
      fin = 0;
  }
  return { inicio, fin }
}

export const formatCurrency = (amount, currencyCode) => {
  return new Intl.NumberFormat('es-CO', { style: 'currency', currency: currencyCode }).format(amount);
};

export function calcularDistancia(lat1 = 0, lon1 = 0, lat2 = 0, lon2 = 0) {
  const radioTierra = 6371; // Radio de la Tierra en kilómetros

  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distancia = radioTierra * c;

  return distancia;
}

export function formatDate(dateString) {
  // Extraer solo la parte de la fecha
  var datePart = dateString.split(',')[0];

  // Dividir la fecha en mes, día y año
  var parts = datePart.split('/');
  if (parts.length !== 3) {
    return null; // Devuelve null si el formato no es correcto
  }

  var month = parts[0];
  var day = Number(parts[1]) + 1;
  var year = parts[2];

  // Verifica que cada parte sea un número válido
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return null;
  }

  // Formatear la fecha en el formato deseado (dd/mm/yyyy)
  return month + '/' + day + '/' + year;
}

export const UnicadesDeMedida = [
  { value: 'unidad', Label: 'Unidad' },
  { value: 'gramo', Label: 'gramo' },
  { value: 'kilogramo', Label: 'Kilogramo' },
  { value: 'Tonelada', Label: 'Tonelada' },
]

export function separarCodigoYTelefono(telefono) {
  // Encuentra el país correspondiente al código en el número de teléfono
  if (telefono) {
    const pais = code.paises.find(p => telefono.startsWith(p.codigo));

    if (pais) {
      // Extrae el código y el número
      const codigo = pais.codigo;
      const numero = telefono.substring(codigo.length);

      return { codigo, numero, pais: pais.nombre };
    }
    // Si no se encuentra el país, devuelve un mensaje de error
    return { error: true };
  }
  // Si no se encuentra el país, devuelve un mensaje de error
  return { error: true };
}

export const isSameDay = (date1, date2) => {
  // Crea dos objetos Date a partir de las entradas.
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Compara los años, meses y días de las fechas. Si todos coinciden, devuelve true.
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
};

export const convertToUTC = (dateStr) => {
  // Crea un objeto Date a partir de la fecha local.
  const date = new Date(dateStr);
  const localDate = new Date(date);

  // Obtén el tiempo en milisegundos desde el epoch (1 de enero de 1970) para la fecha local.
  const localTime = localDate.getTime();

  // Obtén el offset de la zona horaria local en minutos.
  const localOffset = localDate.getTimezoneOffset() * 60000; // 1 minuto = 60000 milisegundos

  // Calcula el tiempo UTC sumando el offset de la zona horaria local al tiempo local.
  const utcTime = localTime - localOffset;

  // Crea un nuevo objeto Date a partir del tiempo UTC.
  const utcDate = new Date(utcTime);

  // Suma un día a la fecha UTC.
  utcDate.setDate(utcDate.getDate() + 1);

  // Convierte la fecha UTC a formato ISO y devuélvela.
  return utcDate.toISOString();
};

// Esta función toma una fecha y devuelve la misma fecha pero con la hora establecida a las 00:00:00.000.
export const setDateWithoutTime = (date) => {
  // Crea un nuevo objeto Date a partir de la fecha proporcionada.
  const localDate = new Date(date);
  const newDate = new Date(localDate);
  // Establece las horas, minutos, segundos y milisegundos a 0.
  newDate.setHours(0, 0, 0, 0);

  // Devuelve la nueva fecha.
  return newDate;
};