import React, { useEffect, useRef } from "react";

function GoogleMap({ lat = 0, lng = 0 }) {
  const mapRef = useRef(null);

  useEffect(() => {

    let map
    // Solo inicializa el mapa si las coordenadas son válidas
    if (window.google) {
      map = new window.google.maps.Map(mapRef.current, {
        center: { lat: lat, lng: lng },
        zoom: 8,
      });
      new window.google.maps.Marker({
        position: { lat: lat, lng: lng },
        map,
      });
    }
    return () => {
      if (map) {
        map = null;
      }
    };
  }, [lat, lng]);

  return <div ref={mapRef} style={{ width: "100%", height: "100%", borderRadius: 5 }}></div>;
}

export default GoogleMap;
