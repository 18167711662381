import {
    Routes, Route,
    Navigate,
    NavLink, 
} from 'react-router-dom';
import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ApartmentIcon from '@mui/icons-material/Apartment';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import ContactsIcon from '@mui/icons-material/Contacts';
import { useState, useEffect } from 'react';
import { Backdrop} from '@mui/material';
import Badge from '@mui/material/Badge';
import { ListItemButtonType } from '../../Navs/components/ListItemButtonType';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAsync } from '../redux/action/LoginAction';
import { TablaBodega } from '../../private/bodegas/components/tablas/TablaBodega';
import { Personal } from '../../private/personal/Personal';
import { Rutas } from '../../private/rutas/Rutas';
import MixingMetrial from '../../private/Material/CombinateM';
import Bolsas from '../../private/bolsas/Bolsas';
import ViewDashboard from '../../private/dashboard/ViewDashboard/ViewDashboard';
import { NewBodega } from '../../private/bodegas/components/NewBodega';
import { NewPersonalEmpresa } from '../../private/personal/NewPersonalEmpresa';
import { TablaBodegaById } from '../../private/bodegas/components/tablas/TablaBodegaById';
import { NewEmpresaGeneradora } from '../../private/bodegas/components/NewEmpresaGeneradora';
import { NewPersonalBodega } from '../../private/bodegas/components/NewPersonalBodega';
import { NewSede } from '../../private/bodegas/components/NewSede';
import { InformesRutas } from '../../private/rutas/components/InformesRutas';
import { CartaPdf } from '../../private/generadores/CartaPdf';
import { NewGenerador } from '../../private/generadores/NewGenerador';
import { ItemsPersonalEmpresa } from '../../private/personal/ItemsPersonalEmpresa';
import { PuntoGeneradorTbl } from '../../private/generadores/PuntoGeneradorTbl';
import { DetailPersonal } from '../../private/bodegas/components/DetailPersonal';
import Loader from '../../helpers/Loader';
import DetailsPuntosGeneradores from '../../private/generadores/DetailsPuntosGeneradores';
import BodegaDetail from '../../private/bodegas/components/Detalles/BodegaDetail';
import CreateRuta from '../../private/rutas/components/CreateRuta';
import Solicitudes from '../../private/Solicitudes/Solicitudes';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import RutajeWeb from '../../private/RutajeWeb/RutajeWeb';


const drawerWidth = 240;
let color = "#626E2A"
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: color,
    }),
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export const DashboardRoutes = () => {


    const [informesRutas, setInformesRutas] = useState([])
    const [validador, setValidador] = useState(true)
    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState("Dashboard");
    
    const [dropAdmin, setDropAdmin] = React.useState(false);
    const dataUser = useSelector(state => state)
    const user = useSelector(state => state.user)
    const [solicitudes, setSolicitudes] = React.useState(false);
    const [permisosPersonal, setPermisosPersonal] = useState(user?.Personal?.Permisos)
    // const services = user?.typeUser =="EMPRESA"?
    const dispatch = useDispatch()
    const { empresas } = useSelector(state => state.empresa)
   
    const hasPermission = (permisoRequerido = ['no hay permisos']) => {
        const userPermissions = permisoRequerido
        return user?.Personal?.Permisos?.some(permission => userPermissions.includes(permission.Permiso));
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    
    const services =  user?.typeUser =="EMPRESA"?( empresas&&empresas[0])?empresas[0]?.empresas?.Servicios.some(e=>e.id=="65fe002a1d553f0015ca2848"):false :(user?.PuntosBodega &&user?.[0])? user?.PuntosBodega[0]?.PermisosBodegas.some(e=>e=="65fe00441d553f0015ca2849"):false
    console.log('====================================');
    console.log("empresas",empresas);
    console.log('===services',services);
    useEffect(() => {
        if(user && user.loaded){
        //     setPermisosPersonal(user?.Personal?.Permisos)
        setValidador(false)
        //   setTimeout(() => { setValidador(false) }, 7000);
        }
        // getPersonal()
    }, [user])
    useEffect(() => {
        if(user.PuntosGeneradores){
        setSolicitudes(user?.PuntosGeneradores?.filter(e=>e?.Llenado==true && e?.asignado==false)||[])
        }
    }, [user?.PuntosGeneradores,empresas])
    
    const handleSetType = (type) => {
        setType(type)
     }
    const handleLogout = () => {
        dispatch(logoutAsync())
    }
    
    return (
        <>
            {validador ?
                <div className='text-center m-5 '>
                    <Backdrop className='d-flex flex-column'
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={validador}
                    >
                        {/* <CircularProgress width='550px' color="primary" /> */}
                        <h1 className='d-flex  '>Loading...</h1>
                       <Loader />
                    </Backdrop>
                </div> :
                <Box sx={{ display: 'flex',width:"99vw" }}>
                    <nav style={{width:"1"}}>
                        <AppBar position="fixed" open={open} style={user.typeUser?user?.typeUser  == "EMPRESA"? {backgroundColor: "#"+user?.Personal?.Empresa?.Color } : { backgroundColor: user?.bodegas[0]?.Color }: { backgroundColor: '#A0C49D' }}>
                            <Toolbar >
                                <IconButton
                                    color="inherit" 
                                    aria-label="open drawer"

                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{
                                        marginRight: 5,
                                        ...(open && { display: 'none' }),
                                    }}
                                >
                                    {user?.typeUser === 'EMPRESA' &&
                                        <img src={user?.Personal?.Empresa?.Logo ? user?.Personal?.Empresa?.Logo :'https://hilo.cool/wp-content/uploads/2022/12/logo_hilo.png'} alt={empresas ? user?.Personal?.Empresa?.Nombre : 'logo'}
                                            style={open ? { width: 100, height: 100, borderRadius: 100, } : { padding: '5px', width: 50, height: 50, borderRadius: 100, backgroundColor: '#fff' }} 
                                        />
                                    }
                                    {user?.typeUser !== 'EMPRESA' &&
                                    <img src={ user?.Personal?.Imagen ? user?.Personal?.Imagen:user?.bodegas[0].Logo?user?.bodegas[0].Logo : 'https://hilo.cool/wp-content/uploads/2022/12/logo_hilo.png'} alt={empresas ? user?.PuntosBodega[0].Nombre : 'logo'}
                                        style={open ? { width: 100, height: 100, borderRadius: 100, } : { padding: '5px', width: 50, height: 50, borderRadius: 100, backgroundColor: '#fff' }} 
                                    />
                                    }
                                </IconButton>
                                <Typography variant="h6" noWrap component="div" style={{ width: "100%", textAlign: "right" }}> {/*// <========================== color empresa */}

                                    {user ? user?.Personal?.user?.username : ''}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </nav>
                    
                      {/* {permisosPersonal.length>0 &&  <AsideLeft setDropAdmin={setDropAdmin}dropAdmin={dropAdmin}open={open}
                        permisosPersonal={permisosPersonal}handleDrawerClose={handleDrawerClose}privada={privada}
                        />} */}
                   
                    <aside>
                        <Drawer variant="permanent" open={open}>
                            <DrawerHeader style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <IconButton onClick={handleDrawerClose} >
                                    <img src={user? (user?.typeUser === 'EMPRESA' ? user?.Personal?.Empresa?.Logo :(user?.Personal?.Imagen?user?.Personal?.Imagen:user?.bodegas[0].Logo)) : 'https://hilo.cool/wp-content/uploads/2022/12/logo_hilo.png'} alt={empresas ? user?.Personal?.Empresa?.Nombre : 'logo'}
                                        style={open ? { width: 100, height: 100, borderRadius: 100 } : { width: 100, height: 100, borderRadius: 100, visibility: 'hidden' }} />
                                </IconButton>
                            </DrawerHeader>
                            <Divider />

                            <List>
                                
                                <>
                                    <NavLink  className="navbar-brand" to="/dashboard" style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType('Dashboard')} >
                                            <ListItemButtonType type={type} data={dataUser} open={open} types='Dashboard' icon={<DashboardIcon  />} />
                                    </NavLink>

                                    <NavLink  className="navbar-brand" to="/solicitudes" style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType('Solicitudes')} >
                                            <ListItemButtonType type={type} data={dataUser} open={open} types='Solicitudes' icon={
                                                <Badge badgeContent={solicitudes&&solicitudes.legth} color="error"> <ConnectWithoutContactIcon  /> </Badge>
                                            } />
                                    </NavLink>
                                
                                  
                                {user?.typeUser === 'EMPRESA' &&
                                    <NavLink  className="navbar-brand" to="/rutas" style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType('Rutas')} >
                                        <ListItemButtonType type={type} data={dataUser} open={open} types='Rutas' icon={<WhereToVoteIcon />} />
                                    </NavLink>
                                }
                                 </>
                               

                                {user?.typeUser === 'EMPRESA' && hasPermission('Visuallizar gestores') && Array.isArray(user?.bodegas) &&
                                    <div>
                                        <NavLink className="navbar-brand" to="/bodegas" style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType('Gestores')} >
                                            <ListItemButtonType  type={type} data={dataUser} open={open} types='Gestores' icon={<RoomPreferencesIcon />} />
                                        </NavLink>
                                    </div>
                                }
                                
                                     {/* <NavLink className="navbar-brand" to="/dashboard" style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }}>
                                        <ListItemButton onClick={() => handleSetType("Dashboard")}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                                background: type === "Dashboard" ? `#${empresas[0]?.typeUser === "EMPRESA" ? user?.Personal?.Empresa?.Color : user?.Personal?.Empresa?.Color}` : "white"
                                            }}
                                        >
                                            <DashboardIcon style={type === "Dashboard" ? { color: 'white', marginRight: "5%" } : { color: "#777", marginRight: "5%" }} />
                                            <ListItemText primary={"Dashboard"} sx={{ opacity: open ? 1 : 0 }} style={type ==="Dashboard"?{ color: 'white' }:{color:"grey"}} />
                                        </ListItemButton>
                                    </NavLink>  */}
                                    

                                {(hasPermission('Ver personal') && user?.typeUser  == "EMPRESA"&& Array.isArray(user?.empleados)) &&
                                    <div >
                                        <NavLink className="navbar-brand" to={"/personal"} style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType("Personal")}>
                                            <ListItemButtonType  type={type} data={dataUser} open={open} types='Personal' icon={<PeopleAltIcon />} 
                                            />
                                        </NavLink>
                                    </div>
                                }
                                {hasPermission('Ver personal') && user?.typeUser  !== "EMPRESA" && Array.isArray(user?.PuntosBodega)&&
                                    <div >
                                        <NavLink className="navbar-brand" to={`/sede/${user?.PuntosBodega[0].id}`} style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType("Personal")}>
                                            <ListItemButtonType  type={type} data={dataUser} open={open} types='Personal' icon={<ContactsIcon/>} 
                                            />
                                        </NavLink>
                                    </div>
                                }

                                {hasPermission('Visuallizar generadores') && 
                                    <div>
                                        <NavLink className="navbar-brand" to="/generadores" style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType('Generadores')}>
                                            <ListItemButtonType  type={type} data={dataUser} open={open} types='Generadores' icon={<ApartmentIcon/>} />
                                        </NavLink>
                                    </div>
                                }

                                

                                <Divider className='mt-2 mb-2' />

                                <ListItemButton onClick={() => setDropAdmin(!dropAdmin)} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                    <AdminPanelSettingsIcon style={!dropAdmin ? { color: "#777", marginRight: "5%" } : { color: dataUser.user.empresa?.Color, marginRight: "5%" }} />
                                    <ListItemText primary={"Administración"} sx={{ opacity: open ? 1 : 0 }} style={{ marginLeft: "5%" }} />
                                    {!dropAdmin ? <KeyboardArrowDownIcon style={{ color: "#777", marginRight: "5%" }} /> :
                                        <KeyboardArrowUpIcon style={{ color: dataUser.user.empresa?.Color, marginRight: "5%" }} />}
                                </ListItemButton>

                                {dropAdmin &&
                                    <>
                                       
                                            <>
                                                <div>
                                                    <NavLink className="navbar-brand" to="/materiales" style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType('Materiales')}>
                                                        <ListItemButtonType type={type} data={dataUser} open={open} types='Materiales' icon={<Inventory2OutlinedIcon />} />
                                                    </NavLink>
                                                </div>

                                                {user?.typeUser !== "EMPRESA"&& user?.PuntosBodega[0]?.PermisosBodegas.some(id=>id=="64679a5d25f79000140e8f16")&&
                                                    <div >
                                                        <NavLink className="navbar-brand" to="/bolsas" style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType('Bolsas')}>
                                                            <ListItemButtonType type={type} data={dataUser} open={open} types='Bolsas' icon={<RoomPreferencesIcon />} />
                                                        </NavLink>
                                                    </div>
                                                }
                                                
                                                <div >
                                                    <NavLink className="navbar-brand" to="/rutaje" style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType('Rutaje')}>
                                                        <ListItemButtonType type={type} data={dataUser} open={open} types='Rutaje' icon={<AddRoadIcon />} />
                                                    </NavLink>
                                                </div>
                                                

                                                <ListItem disablePadding sx={{ display: 'block' }} onClick={handleLogout}>
                                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5,}} >
                                                        <LogoutIcon style={open ? { color: "red", marginRight: "5%" } : { color: "#777" }} />
                                                        <ListItemText primary={"Salir"} sx={{ opacity: open ? 1 : 0 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                            {/* :
                                            <div >
                                                <NavLink className="navbar-brand" to="/permisos" style={({ isActive }) => isActive ? { color: "white" } : { color: "grey" }} onClick={() => handleSetType('Permisos')}>
                                                    <ListItemButtonType type={type} data={dataUser} open={open} types='Permisos' icon={<RoomPreferencesIcon />} />
                                                </NavLink>
                                            </div> */}
                                        
                                    </>
                                }
                            </List>

                            {/* <List>
                                <ListItem disablePadding sx={{ display: 'block' }} onClick={handleLogout}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <LogoutIcon style={open ? { color: "red", marginRight: "5%" } : { color: "#777" }} />
                                        <ListItemText primary={"Salir"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </List> */}

                        </Drawer>
                    </aside>

                    <Box component="main" sx={{ flexGrow: 1, p: 1, mt: 10 }}>
                        <Routes>

                        //!  DASHBOARD --------------------------------------------------
                            {hasPermission('Acceder al dashboard') &&
                                <Route path="/dashboard" element={<ViewDashboard />} />}


                        //! PERSONAL------------------------------------------------------
                             {hasPermission('Ver personal')&&
                                <Route path="/personal" element={<Personal />} />}

                            {hasPermission('Ver personal') &&
                                <Route path="/nuevo-personal-empresa" element={<NewPersonalEmpresa />} 
                            />}
                            {hasPermission('Modificar personal') &&
                                <Route path="/nuevo-personal-empresa/:id" element={<NewPersonalEmpresa />} 
                            />}
                            {hasPermission('Ver personal') &&
                                <Route path="/nuevo-personal/:id" element={<ItemsPersonalEmpresa />} 
                            />} 
                            
                                <Route path="/rutaje" element={<RutajeWeb />} 
                            />

                        //! GENERADORES----------------------------------------------------
                            {hasPermission('Visuallizar generadores') &&
                                // <Route path="/generadores" element={<RenderProve />} />}
                                <Route path="/generadores" element={<PuntoGeneradorTbl />} />}
                            
                            {hasPermission('Visuallizar generadores') &&
                                // <Route path="/generadores" element={<RenderProve />} />}
                                <Route path="/generadores/:id" element={<DetailsPuntosGeneradores />} />}
                                
                            {hasPermission('Agregar generadores') &&
                                <Route path="/nuevo-generador" element={<NewGenerador />} />}
                            {hasPermission('Agregar generadores') &&
                                <Route path="/nuevo-generador/:id" element={<NewGenerador />} />}


                            <Route path="/carta-pdf" element={<CartaPdf />} />


                        //! BODEGAS--------------------------------------------------------
                            {hasPermission('Visuallizar gestores') &&
                                <Route path="/bodegas" element={<TablaBodega />} />
                            }

                            {hasPermission('Agregar gestores') &&
                                <Route path="/nueva-bodega" element={<NewBodega />} />
                            }

                            {hasPermission('Modificar gestores') &&
                                <Route path="/actualizar-bodega" element={<NewBodega />} />
                            }

                        //! BODEGAS------------- SEDES ------------------
                            {hasPermission('Visuallizar gestores') &&
                                <Route path="/bodega/:id" element={<TablaBodegaById />} />
                            }
                            {(hasPermission('Visuallizar gestores')||user?.typeUser!=="EMPRESA") &&
                                <Route path="/sede/:id" element={<BodegaDetail />} />
                            }
                            {(hasPermission('Agregar Personal')||hasPermission('Modificar personal'))  &&
                                <Route path="/nuevo-personal-bodega/" element={<NewPersonalBodega />} />
                            }

                            
                            <Route path="/personal-id" element={<DetailPersonal />} />
                            
                            
                            {hasPermission('Agregar generadores') &&
                                <Route path="/nueva-sede-bodega/" element={<NewSede />} />
                            }
                        //! GENERADORES
                            {hasPermission('Agregar generadores') &&
                                <Route path="/nueva-empresa-generadora" element={<NewEmpresaGeneradora />} />
                            }

                            {hasPermission('Agregar generadores') &&
                                <Route path="/nuevo-punto-generador" element={<NewGenerador/>} />
                            }


                        //! Solicitudes----------------------------------------------------------
                            <Route path="/solicitudes" element={<Solicitudes />} />

                        //! RUTAS----------------------------------------------------------
                            {hasPermission(['Asignar ruta', 'Manejar rutas', 'Autoasignación de Rutas']) &&
                                <Route path="/rutas" element={<Rutas />} />}
                            {hasPermission(['Asignar ruta', 'Manejar rutas', 'Autoasignación de Rutas']) &&
                                <Route path="/nueva-ruta" element={<CreateRuta />} />}
                            {hasPermission(['Asignar ruta', 'Manejar rutas', 'Autoasignación de Rutas']) &&
                                <Route path="/actualizar-ruta" element={<CreateRuta />} />}
                            <Route path="/informes-ruta" element={<InformesRutas />} />

                        //! MATERIALES------------------------------------------------------
                            <Route path="/materiales" element={<MixingMetrial />} />

                        // ! BOLSAS
                            {user?.typeUser !== "EMPRESA"&& user?.PuntosBodega[0]?.PermisosBodegas.some(id=>id=="64679a5d25f79000140e8f16")&&<Route path="/bolsas" element={<Bolsas />} />}
                           

                            <Route path='*' element={<Navigate to={`/dashboard`} />} />
                        </Routes>
                    </Box>
                </Box>
            }

        </>
    )
}