import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Avatar, Button, FormControl, Select, Stack, Tooltip } from '@mui/material';
import { DeleteAllWithAuth, UseGetWithAuth2 } from '../../service/base2';
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect } from 'react';
import { SplitingInitialsName } from '../../helpers/Utils';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const { v4: uuidv4 } = require('uuid');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,

        },
    },
};

export const Personal = ({ setType, setPersonalEmpresaEdit, personalEmpresaEdit }) => {

    // const state = useSelector(state => state)
    const { empresas } = useSelector(state => state.empresa);
    const { user } = useSelector(state => state)
    let id_empresa = user?.typeUser === 'EMPRESA' ? user?.Personal?.Empresa.id : user?.empresas?.data[0]?.id;;
    let dataColor = user?.typeUser === 'EMPRESA' ? user?.Personal?.Empresa.Color : user?.empresas?.data[0]?.Color
    const [personal, setPersonal] = useState(user.empleados)
    const [permisosPersonal, setPermisosPersonal] = useState(user.Personal.Permisos)
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [personName, setPersonName] = React.useState([]);
    const [menuOpen, setMenuOpen] = React.useState(false);
    
    const handleChange = (event) => {
        const { target: { value }, } = event;
        setPersonName(typeof value === 'string' ? value.split(',') : value,);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const eliminarPersonalEmpresa = async (id) => {
        await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esta acción!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            if (result.isConfirmed) {
                if(id ==user.Personal.id){
                    Swal.fire(
                        'Error!',
                        'No puedes eliminar tu usuario',
                        'error'
                    );
                    return false;
                }else{
                    const res = DeleteAllWithAuth(`personals/${id}`, user?.Personal?.uid);
                    Swal.fire(
                        'Eliminado!',
                        'El personal ha sido eliminada.',
                        'success'
                    );
                }
            }
        });
    };

    const hasPermission = (permisoRequerido = ['no hay permisos']) => {
        const userPermissions = permisoRequerido
        return permisosPersonal.some(permission => userPermissions.includes(permission.Permiso));

    };

    const showDetails = (id) => {
        navigate('/nuevo-personal/'+id);
    };

    const Editetails = (id) => {
        navigate('/nuevo-personal-empresa/'+id);
    };

  
    return (
        <>
            <nav className='d-flex gap-3 justify-content-between p-2 mb-2 '
                // style={empresas ? { backgroundColor:  `#${empresas[0].empresas.data.Color}` , fontWeight: "bold" } : { color: "#666", fontWeight: "bold" }}
                style={{ backgroundColor:user.colors[1], fontWeight: "bold",borderRadius:20 }}
            >
                <div className='ml-5'>
                    {/* <h5 style={{ color: "white" }}>{bodegaEdit.Nombre ? 'Actualizar datos' : 'Nueva bodega'}</h5> */}
                    <h5 style={{ color: "white",marginLeft:10 }} className='mt-1'>Personal {user.Personal.Empresa.Nombre}</h5>
                </div>
            </nav>
            {hasPermission('Agregar Personal') &&
                <Link className="navbar-brand" to="/nuevo-personal-empresa">
                    <Button  variant="contained" style={{ backgroundColor: user.colors[2], position: "absolute", top: 150, right: 25, cursor: "pointer", color:"white",borderRadius:40}}>
                        Agregar personal <PersonAddAlt1Icon />
                    </Button>
                </Link>
            }

            <Box sx={{ width: '100%', marginTop: 7.5 }}>
                <Paper  sx={{ width: '100%',borderRadius:5 }}>
                    <TableContainer sx={{ maxHeight: 840}}>
                        <Table  aria-label="sticky table">
                            <TableHead>
                                <TableRow hover tabIndex={-1} key={uuidv4()}>

                                    <TableCell align={"center"} size='small'><b>Avatar</b> </TableCell>
                                    <TableCell align={"center"} size='small'><b>Nombre</b>  </TableCell>
                                    <TableCell align={"center"} size='small'><b>Cedula</b> </TableCell>
                                    <TableCell align={"center"} size='small'><b>Teléfono</b> </TableCell>
                                    <TableCell align={"center"} size='small'><b>Acciones</b> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {personal.map((personaEmpresa) => (
                                    <TableRow hover tabIndex={-1} key={uuidv4()}>
                                    
                                        <TableCell align={"center"} size='small'>
                                            {personaEmpresa?.Imagen ?
                                                <img src={personaEmpresa?.Imagen} style={{ width: 40, height: 40, borderRadius: 100 }} alt={`profile imagen `} />
                                                :
                                                <Stack direction="row" justifyContent={"center"}>
                                                    <Avatar sx={{ bgcolor: `#${dataColor}` }}>{SplitingInitialsName(personaEmpresa?.user?.username)}</Avatar>
                                                </Stack>
                                            }
                                        </TableCell>

                                        <TableCell align={"center"} size='small'> {personaEmpresa?.user?.username}</TableCell>
                                        <TableCell align={"center"} size='small'>{personaEmpresa?.Cedula}</TableCell>
                                        <TableCell align={"center"} size='small'>{personaEmpresa?.Telefono}</TableCell>

                                        <TableCell align={"center"} size='small'  style={{ position: "relative"}} onMouseLeave={()=>setMenuOpen(false)}>
                                            
                                        
                                            <IconButton
                                                aria-label="more"
                                                id="custom-button"
                                                style={{ position: "relative", width: 50, height: 50 }}
                                                onClick={() => setMenuOpen((menuOpen === false || menuOpen !==personaEmpresa.id) ? personaEmpresa.id : false)}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            
                                            {menuOpen == personaEmpresa.id && (
                                                <div 
                                                    style={{
                                                    position: "absolute",
                                                    top:20, right: 20,
                                                    backgroundColor: "#fff",
                                                    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                    zIndex: 2000,
                                                    width: "17ch",
                                                    borderRadius: 5,
                                                    }}
                                                >
                                                    <MenuItem onClick={() => { setMenuOpen(false); showDetails(personaEmpresa?.id) }}>
                                                        Detalles
                                                </MenuItem>
                                                {hasPermission('Modificar personal') && 
                                                        <MenuItem onClick={() => { setMenuOpen(false);  personaEmpresa?.id !=user.Personal.id && Editetails(personaEmpresa?.id) }}>
                                                            Editar
                                                        </MenuItem>
                                                }
                                                    {hasPermission('Eliminar personal') && 
                                                        <MenuItem onClick={() => { setMenuOpen(false);  eliminarPersonalEmpresa(personaEmpresa?.id) }}>
                                                            Eliminar
                                                        </MenuItem>
                                                    }
                                                </div>
                                                // <Menu
                                                //     id="long-menu"
                                                //     MenuListProps={{
                                                //     'aria-labelledby': 'custom-button',
                                                //     }}
                                                //     PaperProps={{
                                                //     style: {
                                                //         maxHeight: ITEM_HEIGHT * 4.5,
                                                //         width: '20ch',
                                                //         position: "absolute",
                                                //      right: 0,
                                                //      backgroundColor: "#fff",
                                                //      boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                //      zIndex: 1,
                                                //     },
                                                //     }}
                                                // >
                                                
                                                //     <MenuItem >
                                                //         Editar
                                                //     </MenuItem>
                                                //     <MenuItem >
                                                //         Eliminar
                                                //     </MenuItem>
                                                // </Menu>
                                                )
                                                }
                                        

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={user?.empleados && user?.empleados.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
            
        </>
    );
}








