import { jsPDF } from "jspdf";
import React, { useEffect } from 'react'
import './Styles.css'
const Baxter = require('../../assets/Pdfs/Vestolit/Baxter.png')
const Hilo= require('../../assets/Pdfs/Vestolit/Hilo.png')
const Vita = require('../../assets/Pdfs/Vestolit/Vita.png')
const Vinilo = require('../../assets/Pdfs/Vestolit/ViniloMov.png')
const Firma = require('../../assets/Pdfs/Vestolit/FJ.png')
const Vestolit = require('../../assets/Pdfs/Vestolit/Vestolit.png')
const Pavco = require('../../assets/Pdfs/Vestolit/Pavco.png')
const Kopelle = require('../../assets/Pdfs/Vestolit/Kopelle.png')
const W2W = require('../../assets/Pdfs/Vestolit/w2w.png')





function justifyText(pdf, text, x, y, maxWidth) {
  // Dividir el texto en palabras
  const words = text.split(' ');

  // Iniciar una línea vacía
  let line = '';
  
  // Altura de la línea
  const lineHeight = 5;

  words.forEach((word, index) => {
    const testLine = line + word + ' ';
    const testLineWidth = pdf.getTextWidth(testLine);

    if (testLineWidth > maxWidth) {
      // Justificar la línea actual
      const numOfSpaces = line.split(' ').length - 1;
      const extraSpace = maxWidth - pdf.getTextWidth(line);
      const spacePerGap = extraSpace / numOfSpaces;
      const justifiedLine = line.split(' ').map((word, index, array) => {
        return index === array.length - 1 ? word : ' '+word + ' '.repeat(spacePerGap);
      }).join('');

      // Dibujar la línea justificada
      pdf.text(justifiedLine, x, y);

      // Iniciar una nueva línea
      line = word + '  ';
      y += lineHeight;
    } else {
      line = testLine;
    }

    if (index === words.length - 1) {
      // Dibujar la última línea (no justificada)
      pdf.text(line, x, y);
    }
  });
}

export const CartaPdf = ({nombre="EXAMPLE",nombreEmpres="EXAMPLE - EXAMPLE"}) => {

    let texto1 =  `Desde Vinilo en Movimiento le damos una cordial bienvenida a ${nombreEmpres} al primer Programa de cierre de ciclo del Vinilo rígido y flexible del sector de la construcción, médico y textil; liderado por Vestolit en alianza con sus clientes como Baxter, Pavco, Grupo Kopelle, entre otros, y apoyado por W2W. ¡Estamos muy emocionados por su vinculación a esta red que transforma el mundo a través de la Economía Circular y la sustentabilidad!`
    let texto2 =  `Con esta vinculación se apoya el crecimiento y mejoramiento de la calidad de vida de los recicladores en el país, gracias al fortalecimiento técnico y financiero, y al apalancamiento en la obtención de equipos y capital de trabajo que brinda el Programa para robustecer la cadena de aprovechamiento del Vinilo. Adicionalmente, ${nombreEmpres} percibirá beneficios asociados al cumplimiento de metas de sostenibilidad internas, reducción de costos en la gestión de residuos, disminución de la huella de carbono y fortalecimiento en la trazabilidad de los residuos a través de una aplicación desarrollada a medida para la operación del Programa`
    let texto3 =`Vinilo en Movimiento agradece a ${nombreEmpres} su participación y su compromiso con la Sostenibilidad y la Circularidad, siendo líderes de la transición hacía la economía circular desde su sector.`
  let fecha = new Date()
  useEffect(()=>{
    const generateAndPreviewPDF = () => {
        // Tu código para generar el PDF con jsPDF
        const pdf = new jsPDF('portrait', 'mm', 'a4');
          
        const pageHeight = pdf.internal.pageSize.getHeight(); 
        const pageWidth = pdf.internal.pageSize.getWidth(); 
        const footerHeight = 40; 

        pdf.addImage(Hilo, 'PNG', 10, 8, 33, 18); 
        pdf.addImage(Vita, 'PNG', 150, 8, 25, 21); 
        pdf.addImage(Vinilo, 'PNG', 180, 8, 20, 20);
        pdf.setFontSize(10);
        pdf.text("Contacto: 3204881774 - gestionviniloenmovimiento@gmail.com",108,37)
        pdf.text("https://www.vestolit.com/vinyl-in-motion/",146,43)
        pdf.line(0, 38, 220, 39);
        pdf.setFontSize(14);
        pdf.text("Cordial Saludo,", 15, 66);
        pdf.setFontSize(14);
        pdf.text(nombre, 16, 76);
        pdf.setFontSize(12);
        let line1 = pdf.splitTextToSize(texto1, 175)
        let line2 = pdf.splitTextToSize(texto2, 175)
        let line3 = pdf.splitTextToSize(texto3, 175)
        pdf.text(line1, 15, 96);
        pdf.text(line2, 15, 130);
        pdf.text(line3, 15, 176);
        pdf.addImage(Firma, 'PNG', 17, 200, 30, 20); 
        pdf.line(15, 215, 50, 215);
        pdf.setFontSize(12); 
        pdf.text('Javier Pérez', 17, 225); 
        pdf.setFontSize(10); 
        pdf.text('Líder Vinilo en movimiento', 17, 230); 
        pdf.setFillColor("#95D3DB"); 
        pdf.rect(0, pageHeight - footerHeight, pageWidth, footerHeight, 'F');
        pdf.setTextColor(0, 0, 0); 
        pdf.setFontSize(14); 
        pdf.text('Una iniciativa de:', 10, pageHeight - 30); 
        pdf.text('En alianza con:', 60, pageHeight - 30); 
        pdf.text('Con el apoyo de:', 165, pageHeight - 30); 
        pdf.addImage(Vestolit, 'PNG', 10, pageHeight - 20, 40, 20); 
        pdf.addImage(Baxter, 'PNG', 55, pageHeight - 15, 37, 10); 
        pdf.addImage(Pavco, 'PNG', 100, pageHeight - 15, 30, 10); 
        pdf.addImage(Kopelle, 'PNG', 140, pageHeight - 17, 35, 15); 
        pdf.addImage(W2W, 'PNG', 180, pageHeight - 20, 27, 15); 

        
        
      
        

        

        
          
        
        const pdfDataUrl = pdf.output("datauristring");
        
        
        pdf.save("mi-documento.pdf");
      };
      
      // Llamar a la función cada vez que quieras previsualizar el PDF
      generateAndPreviewPDF();
  },[])
    return (
        <>
            <body className='card p-4 m-4'>
                <div styles="header">

                    <div className="navHeader">
                        <img src="https://www.emvarias.com.co/emvarias/Portals/0/logo-emvarias.png?ver=18HMVKX6gBHpncbJ6rnHBA%3D%3D" className="img-Hilo" alt="Logo Hilo"></img>

                        <div className="childNav">
                            <img src="https://res.cloudinary.com/dhu7txw3c/image/upload/v1677714133/HiloApp/assestHilo/chars_jvuacs.png" alt="chars" className="chars"></img>
                            <div>
                                <p>Contacto: 319 4987086 - hola@hilo.cool</p>
                            </div>
                        </div>
                    </div>

                    <hr className="line"></hr>
                </div>
                <p className="span"><span>{fecha.getDate()}/{fecha.getMonth()+1}/{fecha.getFullYear()}</span></p>

                <p>Estimado/a Cesar Fontalvo,</p>
                {/* <p>Estimado/a ${nombre},</p> */}
                <p>Damos seguimiento a nuestra reciente visita de recuperación con el informe adjunto, radicado bajo el número <strong>{/**${reportID}**/}123456</strong>. El informe detalla el tipo de material y el peso que se ha recolectado. Este es el resultado tangible de nuestros esfuerzos compartidos por un mundo más limpio y sostenible.
                </p>
                <strong>Personal encarcago:</strong>
                    <p>Logistico</p>
                    {/* <p>${logistico}</p> */}
                    <table className='table-pdf'>
                        <thead>
                            <tr>
                                <th className='th-pdf'>Material</th>
                                <th className='th-pdf'>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* ${data.map((e) => {
                                return "<tr> <td> <strong>" + e.material + "</strong></td><td> " + e.value.toString() + "  </td> </tr>"
                            }).join('')} */}
                            <tr>
                                <td className="total td-pdf"> <strong> Valor total </strong></td>
                                <td className="total td-pdf"> total  </td> </tr>
                                {/* <td className="total"> ${total}  </td> </tr> */}
                        </tbody>
                    </table>

                    <div className="footer">
                        <img src="https://res.cloudinary.com/dhu7txw3c/image/upload/v1677711905/HiloApp/assestHilo/hiloLogo_he1sio.png" alt="reuse" className="hilo-Logo"></img>
                        <div className="footer-Child">
                            <p>https://hilo.cool/</p>
                            <p>@iloapp</p>
                        </div>

                        <div className="footer-Child">
                            <p>reuse</p>
                            <img src="https://res.cloudinary.com/dhu7txw3c/image/upload/v1677719029/HiloApp/assestHilo/recycle-regular-24_slvted.png" alt="reuse" className="reuse"></img>
                            <p>reduce</p>
                            <img src="https://res.cloudinary.com/dhu7txw3c/image/upload/v1677719029/HiloApp/assestHilo/recycle-regular-24_slvted.png" alt="reuse" className="reuse"></img>

                            <p>recycle</p>
                            <img src="https://res.cloudinary.com/dhu7txw3c/image/upload/v1677719029/HiloApp/assestHilo/recycle-regular-24_slvted.png" alt="reuse" className="reuse"></img>
                        </div>
                    </div>

            </body>
        </>
    )
}
