export const types = {
  logout: "[user]logout",
  getCurrentUser: "[user]getcurrentuser",
  _rutas: "[user]rutas",
  _bodegas: "[user]bodegas",
  _puntosBodegas: "[user]puntosBodegas",
  _empresa: "[user]empresa",
  _personal: "[user]notifications",
  _reportes: "[user]reportes",
  _generadores: "[user]generadores",
  _PermisosPunGeneradores: "[user]PermisosPunGeneradores",
  _PermisosGestores: "[user]PermisosGestores",
  _empresasGeneradores: "[user]empresasGeneradores",
  _materiales: "[user]materiales",
  _materialesDescartables: "[user]materialesDescartables",
  _materialesDescartablesPropios: "[user]materialesDescartablesPropios",
  _bolsas: "[user]bolsas",
  _solicitudRecoleccions: "[user]solicitudRecoleccions",
  _empleados: "[user]empleados",
  _Pqrs: "[user]Pqrs"
};


