import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { existenciaUser } from '../base/base';
import { app } from '../firebase';
import { PrivateRoute } from './IsPrivate';
import { PublicRoute } from './IsPublic';
import { DashboardRoutes } from './PrivateRouter';
import { Inicio } from './PublicRouter';
import Splash from './Splash';

const AppRouter=()=> {
  const dispatch= useDispatch()
  const [checking, setChecking] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [oneTimeVerify, setOneTimeVerify] = useState(false)

  
    

  useEffect(() => {
    
      if (!oneTimeVerify) {
          const auth = getAuth(app);
          
        onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
            existenciaUser(user.uid, user.email, dispatch)
           
              setOneTimeVerify(true);
              setIsLoggedIn(true);
            
              setChecking(false);
            
        } else {
          setChecking(false);
          setIsLoggedIn(false);
          setOneTimeVerify(true);
        }
      });
      
    }
  }, [oneTimeVerify, ]);

  

  useEffect(() => {
    if (document.getElementById("googleMapsScript")) {
      return;
    }
    const script = document.createElement("script");

      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,geometry&callback=initMap`;
      script.async = true;
      script.defer = true;
      script.id = "googleMapsScript";  

      window.initMap = () => {
        console.log("initMap",script);
      };

      document.head.appendChild(script);
    }, []);

    useEffect(() => {
      const onBeforeUnload = () => {
        localStorage.setItem('reloading', 'true');
      };
  
      const onLoad = () => {
        const reloading = localStorage.getItem('reloading');
        
        if (reloading === 'true') {
          window.location.replace('/dashboard');
          localStorage.removeItem('reloading');
        }
      };
  
      window.addEventListener('beforeunload', onBeforeUnload);
      window.addEventListener('load', onLoad);
  
      
    }, []);
    
  if(checking){
    
    return(
        <Splash/>
    )
  }
  

  return <BrowserRouter>
    <Routes>
        <Route path='/login/*' element={
        <PublicRoute isAuthenticated={isLoggedIn} >
            <Inicio/>
        </PublicRoute> } />

        <Route path='/*' element={
        <PrivateRoute isAuthenticated={isLoggedIn}>
            <DashboardRoutes/>
        </PrivateRoute> } />
    </Routes>
     </BrowserRouter>;
}

export default AppRouter;