import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { types } from "../types/userType";
import { typesEmpresa } from "../types/typesEmpresa";



export const loginEmailAsync = (email,pass,dispatch)=>{
    return(dispatch)=>{
        const auth = getAuth();
        signInWithEmailAndPassword(auth,email,pass)
        .then(({user}) =>{
            // console.log({user})
            // dispatch(actionLogin(user.uid, user.displayName))
        })
        .catch(error =>{
            console.log(error);
        })
    }
}
// Capturando Usuario inmediato
export const currentUser = (user) => {
    return {
        type: types.getCurrentUser,
        payload:user
    }
}

export const logoutAsync=()=>{
    return(dispatch)=>{
        const auth = getAuth();
        signOut(auth)
        .then((user)=>{
            dispatch(actionLogout())
        })
        .catch(error =>{
            console.log(error);
        })
        
    }
}
export default function actionLogin(id,displayname) {
    return{
        type: types.login,
        payload:{
            id,
            displayname
        }
    }
}
export const actionLogout = () => {
    return {
        type: types.logout,
        payload: {
            user: {}
        }
    }
}