import { Avatar, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAll } from '../../../../service/base/base';
import Swal from 'sweetalert2';
import { ItemsPersonalBodega } from './ItemsPersonalBodega';
import { Tab, Tabs } from 'react-bootstrap';
import { PersonalBodegas } from '../PersonalBodegas';
import { UseGetWithAuth2 } from '../../../../service/base2';
import { TablaPuntosBodegas } from './TablaPuntosBodegas';
import { TablaPuntosGeneradores } from './TablaPuntosGeneradores';
import { TablaEmpresasGeneradoras } from './TablaEmpresasGeneradoras';
import { useNavigate, useParams } from 'react-router-dom';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
const { v4: uuidv4 } = require('uuid');

export const TablaBodegaById = ({ setType, setPersonalBodegaEdit, setPuntoGeneradorEdit, setEmpresaGeneradoraEdit, setSedeEdit }) => {
    const { empresas } = useSelector(state => state.empresa);
    const user = useSelector(state => state.user);
    let dataColor = empresas[0]?.typeUser === 'EMPRESA' ? empresas[0]?.empresas?.data?.Color : user?.bodegas[0]?.Color

    const { id } = useParams();
    const navigate = useNavigate();

    const [personalsById, setPersonalsById] = useState(user?.PuntosBodega.filter(punto => punto.Bodega === id).flatMap(e => e.empleados))
    const [bodegaById, setBodegaById] = useState(user.PuntosBodega.filter(punto => punto.Bodega === id)[0])
    const [PuntosBodega, setPuntosBodega] = useState(user.PuntosBodega.filter(punto => punto.Bodega === id))
    
 
            
    const hasPermission = (permisoRequerido = ['no hay permisos']) => {
        const userPermissions = permisoRequerido
        return user.Personal.Permisos.some(permission => userPermissions.includes(permission.Permiso));
    };

console.log(bodegaById);

    const onNavigateBack = () => {
        navigate(-1)
    };
    
    return (
        <>
            <nav className='d-flex gap-3 justify-content-between p-2 mb-2 '
               style={{ backgroundColor:user.colors[1],borderRadius:20 }}
            >
                <div className='ml-5'>
                    <h5 style={{ color: "white",paddingLeft: 10 }} className='mt-1'>{bodegaById?.Nombre ? `${bodegaById?.Nombre}` :  'Gestor:'}</h5>
                </div>
            </nav>
            <div className='d-flex justify-content-end'>
                <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ backgroundColor: `#${user?.Personal?.Empresa?.Color}`, cursor: "pointer",borderRadius:40 }}
                    onClick={onNavigateBack}
                >
                    Volver a gestores
                </Button>
            </div>
            {/* <Tabs
                defaultActiveKey="personal"
                className="mt-3"
                
            >
                {hasPermission('Ver personal') &&
                    <Tab eventKey="personal" title='Personal' >
                        <PersonalBodegas hasPermission={hasPermission} setType={setType} setPersonalBodegaEdit={setPersonalBodegaEdit} personalsById={personalsById} bodegaById={bodegaById} />
                    </Tab>
                } */}
                <h5>Sedes:</h5>
                {/* <Tab eventKey="sedes" title='Sedes'> */}
                    <TablaPuntosBodegas setSedeEdit={setSedeEdit}  setType={setType}  bodegaById={PuntosBodega} bodegaID={id}/>
                {/* </Tab>
            </Tabs> */}
        </>

    )
}
