import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from '../../hooks/useForm'
import { useDispatch, useSelector } from 'react-redux'
import { PostAllWithAuth, PutAllWithAuth, UseGetWithAuth2 } from '../../service/base2'
import Swal from 'sweetalert2'
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import GoogleMap from '../../helpers/PrintMaps/Maps'
import PermissionsCheck from '../../hooks/PermissionsCheck'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CheckBox } from '@mui/icons-material'
import NewEmpresaGene from './NewEmpresaGene'
import QRCode from 'qrcode.react';
import { SplitingInitialsName, generateSerial, hasPermission, separarCodigoYTelefono } from '../../helpers/Utils'
import { fileUploadQR, sendPdf } from '../../helpers/FileUpload'
import Vestolit_pdf_Welcome from '../../helpers/Pdfs/Vestolit_pdf_Welcome'
import { generatePDF } from '../../helpers/Pdfs/generatePdf'
import queryString from 'query-string'
import { empresasGeneradoresAction, generadoresAction } from '../../service/redux/action/DataActions'
import { CreatingNewempresa, UpdatingEmpresa, addpointToCompany, updatingPoint } from './ViewsDetails/UpdatingGene'
import FormLoadings from '../../helpers/FormLoadings'
import { PermissionsPointGener } from '../../helpers/Permissions'
import GenerateQr from './ViewsDetails/GenerateQr'



export const NewGenerador = () => {   
  const { empresas } = useSelector(state => state.empresa);
  const user = useSelector(state => state.user);
  let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color;
  const location = useLocation();
  const params = useParams()
  const navigate = useNavigate();
  const objetoRecibidoByparams = params?.id? user?.PuntosGeneradores.find(e=>e.id==params.id):false;
  const [address, setAddress] = useState(objetoRecibidoByparams?.Direccion?objetoRecibidoByparams?.Direccion:" ");
  const [show, setShow] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [coordinates, setCoordinates] = useState(objetoRecibidoByparams?.Latitud?{lat:Number(objetoRecibidoByparams?.Latitud), lng:Number(objetoRecibidoByparams?.Longitud)}:{ lat:4, lng:-72});
  const [permisosSeleccionados, setPermisosSeleccionados] = useState([]);
  const [EmpresaGeneradora,setEmpresaGeneradora]= useState("")
  const [Responsable,setResponsable]=useState(objetoRecibidoByparams?.id?user?.EmpresasGeneradoras.find(e=> e.PuntoGenerador.find(i=>i.id ==objetoRecibidoByparams.id)|| false):false)
  const [newEmpre,setNewEmpre]=useState(false)
  const [formi,setForm]=useState({})
  const [data,setData]=useState()
  const [loading,setLoading]=useState(false)
  const [myBlob,setmyBlob]=useState(undefined)
  const [permisosPuntosGeneradores,setPermisosGeneradores] =useState([])
  const qrRef = useRef(null),
  dispatch = useDispatch()
  // let canvas
  // let myBlob
  const [idSede, setIdSede] = React.useState(objetoRecibidoByparams.PuntosBodega ? objetoRecibidoByparams.PuntosBodega.Nombre:'');
  

  const { onInputChange, formState, Bodega, Nombre, Direccion, Latitud, Longitud, PuntosBodega,Permisos, onResetForm } =
    useForm({
      Nombre: objetoRecibidoByparams?.PuntosBodega ? objetoRecibidoByparams.Nombre : '',
      Direccion: objetoRecibidoByparams?.PuntosBodega ? objetoRecibidoByparams.Direccion : '',
      Latitud: objetoRecibidoByparams?.Latitud?objetoRecibidoByparams?.Latitud:'0',
      Longitud: objetoRecibidoByparams?.Longitud?objetoRecibidoByparams?.Longitud:'0',
      PuntosBodega: user.typeUser == "Empresa" ? idSede: user.PuntosBodega[0].id,
    });

  const ResponsableEnParams = objetoRecibidoByparams?.id?user?.EmpresasGeneradoras.find(e=> e.PuntoGenerador.find(i=>i.id ==objetoRecibidoByparams.id)|| false):false;
  const [Code,setCode]=useState(ResponsableEnParams? separarCodigoYTelefono(ResponsableEnParams.Telefono).error?"+57":separarCodigoYTelefono(ResponsableEnParams.Telefono).codigo:"+57")
  // console.log("ResponsableEnParams",ResponsableEnParams);
  useEffect(() => {
      if(idSede&&user.typeUser == "Empresa"){setData( generateSerial(SplitingInitialsName(idSede).toUpperCase()))}
      else{setData( generateSerial(SplitingInitialsName(user.PuntosBodega[0].Nombre).toUpperCase()))}
    }, [idSede,EmpresaGeneradora]);

  //==================> Manejo de permisos
  useEffect(()=>{
     //==================> Filtra los permisos según los servicios prestados a la empresa/Cliente
      let flatedPermissions = PermissionsPointGener(user)
        console.log("flatedPermissions",flatedPermissions);
        console.log("fResponsableEnParams",ResponsableEnParams);
        console.log("fResponsableEnParams",Responsable);
      //==================> Si no tiene responsable se le inabilitan los permisos de envio de informe
        if(!ResponsableEnParams && !Responsable){
          flatedPermissions= flatedPermissions.filter(e=> (e.id !== "64c851edeb605c0014653072"&&e.id !=="64c8524aeb605c0014653073"))
          console.log("flatedPermissions",flatedPermissions);
        }
      //==================> Envia los permisos para ser listados
      setPermisosGeneradores(flatedPermissions)

  },[Responsable])

  const handlePermisosChange = (selected) => {
      setPermisosSeleccionados(selected);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if(formState.Nombre.length>0&&formState.Direccion.length>0 ){

        if(formState.Latitud !== undefined && formState.Longitud !== undefined){

            let permissions = permisosSeleccionados.map(e => {
              let per = user.permisosPuntosGeneradores.find(i => e === i.id);
              return per !== undefined && per
            });

            if((hasPermission(permissions,"Recibe informe de recogida")||hasPermission(permissions,"Recibe informes")) && !Responsable){
              setLoading(false)
              Swal.fire({
                  position: 'center', icon: 'error', title: `Es necesario asignar un responsable para el envío de los informes.`,
                  showConfirmButton: false,
                  timer: 1500
              })
            }else{
              if(idSede ||user.typeUser !== "EMPRESA"){
                formState.Bodega = user.typeUser == "EMPRESA"?  user?.PuntosBodega?.find(b=>b.Nombre==idSede).Bodega:user.PuntosBodega[0].Bodega;
                const r = await fileUploadQR(myBlob,idSede)   

                if(r != undefined){   
                  const _Qrs = await PostAllWithAuth(`/qrs`, user?.Personal?.uid, 
                  {"Img":r,"Huella":data.split("/")[data.split("/").length - 1],"Bodegas":formState.Bodega});

                  if(_Qrs.resPost.data){
                      formState.Qr =_Qrs.resPost.data.id
                      formState.PuntosBodega = user.typeUser == "EMPRESA"?  user?.PuntosBodega?.find(b=>b.Nombre==idSede).id:user.PuntosBodega[0].id;
                      formState.Permisos = permisosSeleccionados
                
                      const { resPost, ok } = await PostAllWithAuth(`/puntos-generadores`, user?.Personal?.uid, formState);                     
                          if (ok) {
                            const filter = user?.PuntosGeneradores
                            filter.push(resPost.data)
                            dispatch(generadoresAction(filter))

                            if(Responsable && newEmpre){
                                formi.Empresa = user?.typeUser === 'EMPRESA'?  user.Personal.Empresa.id :user?.bodegas[0]?.Empresa.id
                                formi.Bodega = user.typeUser == "EMPRESA"?  user?.PuntosBodega?.find(b=>b.Nombre==idSede).Bodega :user.PuntosBodega[0].Bodega
                                formi.PuntoGenerador=[resPost.data.id]
                                formi.Telefono = Code+formi.Telefono
                            
                                const newEmpre = await PostAllWithAuth(`/empresas-generadoras`, user?.Personal?.uid, formi);

                                if(newEmpre.ok){
                                    const empre = user?.EmpresasGeneradoras
                                    empre.push(newEmpre.resPost.data)
                                    dispatch(empresasGeneradoresAction(empre))

                                    // =====================================================> Captura el nombre de la empresa a la que corresponde <===============================================================
                                    let nombreEmpr = user?.typeUser === 'EMPRESA'?  user.Personal.Empresa.Nombre :user?.bodegas[0]?.Empresa.Nombre
                                    // =====================================================> Correo electronico de bienvenida solo para la empresa VESTOLIT <=====================================================
                                    if(nombreEmpr=="Vestolit"){
                                      const generate = await generatePDF(formi.NombreContacto,formState.Nombre)
                                    await sendPdf(generate,formi.Email,'Cordial bienvenida','Cordial bienvenida',user?.Personal?.uid) 
                                    }

                                    Swal.fire({
                                      position: 'center', icon: 'success', title: `El punto ${formState.Nombre} ha sido agregado`,
                                      showConfirmButton: false,
                                      timer: 1500
                                    })
                                    setLoading(false)
                                    onNavigateBack();
                                }else{
                                    Swal.fire({
                                      position: 'center', icon: 'error', title: 'Oops...', text: 'Algo salió mal. No se han guardado los datos.',
                                      showConfirmButton: false,timer: 1500
                                    })
                                    setLoading(false)
                                }
                            }else if(Responsable && !newEmpre){
                                let puntosMas = user.EmpresasGeneradoras.find(element => element.id == EmpresaGeneradora).PuntoGenerador.map(i=>i.id);
                                puntosMas.push(resPost.data.id)
                                const newEmpre = await PutAllWithAuth(`/empresas-generadoras/${EmpresaGeneradora}`, user?.Personal?.uid, {'PuntoGenerador':puntosMas});

                                if(newEmpre.ok){
                                    const empre = user?.EmpresasGeneradoras.filter(i=>i.id ==EmpresaGeneradora)
                                    empre.push(newEmpre.resPut)
                                    dispatch(empresasGeneradoresAction(empre))
                                    Swal.fire({
                                        position: 'center', icon: 'success', title: `El punto ${formState.Nombre} ha sido agregado`,
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                    setLoading(false)
                                    onNavigateBack();
                                }else{
                                    Swal.fire({
                                      position: 'center', icon: 'error', title: 'Oops...', text: 'Algo salió mal. No se han guardado los datos.',
                                      showConfirmButton: false,timer: 1500
                                    })
                                    setLoading(false)
                                    onNavigateBack();
                                }
                            }else{
                                Swal.fire({
                                    position: 'center', icon: 'success', title: `El punto ${formState.Nombre} ha sido agregado`,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                setLoading(false)
                                onNavigateBack();
                            } 
                          } else {
                              setLoading(false)
                              Swal.fire({
                                  position: 'center', icon: 'error', title: 'Oops...', text: 'Algo salió mal. No se han guardado los datos.',
                                  showConfirmButton: false,timer: 1500
                              })
                          }
                    }else{
                      setLoading(false)
                      Swal.fire({
                        position: 'center', icon: 'error', title: `hubo un problema por favor vuleve a intentarlo`,
                        showConfirmButton: false,
                        timer: 1500
                      })
                    }
                }else{
                  setLoading(false)
                      Swal.fire({
                        position: 'center', icon: 'error', title: `hubo un problema por favor vuleve a intentarlo`,
                        showConfirmButton: false,
                        timer: 1500
                      })

                }
              }else{
                setLoading(false)
                Swal.fire({
                  position: 'center', icon: 'error', title: `Por favor Selecciona el gestor`,
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            }
        }else{
          setLoading(false)
          Swal.fire({
            position: 'center', icon: 'error', title: `Por favor ingresa una dirección valida`,
            showConfirmButton: false,
            timer: 1500
          })
        }
    }else{
      setLoading(false)
      Swal.fire({
        position: 'center', icon: 'error', title: `Por favor completa los campos`,
        showConfirmButton: false,
        timer: 1500
      })
    }
   
  };

 // Función para editar un Punto Generador.

  const EditPuntoGenerador = async (e) => {
      e.preventDefault()
      setLoading(true)

      // Mapea los permisos seleccionados capturando su identificación
      let permissions = permisosSeleccionados.map(e => {
        let per = user.permisosPuntosGeneradores.find(i => e === i.id);
        return per !== undefined && per
      });
      console.log("permissions",permissions);
      console.log("permisosSeleccionados",permisosSeleccionados);

      // Verifica si se requiere un responsable para el envío de los informes.
      if((hasPermission(permissions,"Recibe informe de recogida")||hasPermission(permissions,"Recibe informes")) && (!Responsable || typeof Responsable === 'undefined' || Responsable === "")){
        setLoading(false)
        Swal.fire({
          position: 'center', icon: 'error', title: `Es necesario asignar un responsable para el envío de los informes.`,
          showConfirmButton: false,
          timer: 1500
        })
      }
      else{
            // =================> La locación no tiene responsable pero se le asigna uno ya creado
            if (!ResponsableEnParams && EmpresaGeneradora){
                console.log("ENTRO EN EL PRIMER IF");
                console.log("EmpresaGeneradora",EmpresaGeneradora);
                addpointToCompany(objetoRecibidoByparams,user,dispatch,EmpresaGeneradora,setLoading)
            }

            // =================> La locación no tiene responsable y se crea nueva entidad
            if(!ResponsableEnParams && Responsable && !EmpresaGeneradora){
                  console.log("ENTRO EN EL SEGUNDO IF");
                if(formi.NombreContacto =="" ||formi.CargoContacto==""||formi.Email==""||formi.Direccion==""){
                  setLoading(false)
                    Swal.fire({
                      position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor, completa todos los campos del responsable',
                      showConfirmButton: false,
                      timer: 1500
                    })
                }else{
                  CreatingNewempresa(formi,user,objetoRecibidoByparams,dispatch,formState,idSede,setLoading)
                }
            }

            // =================> Se modifica los datos del responsable
            if(ResponsableEnParams && ( formi.NombreContacto != ResponsableEnParams.NombreContacto || formi.CargoContacto != ResponsableEnParams.CargoContacto || 
              formi.Email != ResponsableEnParams.Email ||  formi.Direccion != ResponsableEnParams.Direccion || formi.NombreEmpresa != ResponsableEnParams.NombreEmpresa || 
              formi.Telefono != ResponsableEnParams.Telefono)){
                console.log("ENTRO EN EL TERCER IF");
                  formi.Telefono = Code +formi.Telefono != ResponsableEnParams.Telefono ? formi.Telefono = Code + formi.Telefono : ResponsableEnParams.Telefono 
                  UpdatingEmpresa(formi,user,objetoRecibidoByparams,dispatch,ResponsableEnParams,idSede,setLoading)
            }

            // =================> Selecciona los permisos del punto generador si no tiene se le asigna un array vacio
            let point = user.PuntosGeneradores.find(i=>i.id==objetoRecibidoByparams.id).Permisos || []
                point = point.length>0?point.map(e=>e.id):[]
            // =================> Selecciona los permisos del punto generador si no tiene se le asigna un array vacio
            let sonIguales = point.length === permisosSeleccionados.length &&
                 point.every(id => permisosSeleccionados.includes(id));

            if(objetoRecibidoByparams.Nombre !=formState.Nombre ||
              objetoRecibidoByparams.Direccion !=formState.Direccion ||
              !sonIguales){

                updatingPoint(formState,objetoRecibidoByparams,user,dispatch,idSede,permisosSeleccionados,setLoading)

            }
            setTimeout(()=>{
              setLoading(false)
              onNavigateBack()
            },4000)
            //     else{
            //         formi.Empresa = user?.typeUser === 'EMPRESA'?  user.Personal.Empresa.id :user?.bodegas[0]?.Empresa.id
            //         formi.Bodega = user?.PuntosBodega?.find(b=>b.Nombre==idSede).Bodega.id
            //         formi.PuntoGenerador=[objetoRecibidoByparams.id]

            //         const newEmpre = await PostAllWithAuth(`/empresas-generadoras`, user?.Personal?.uid, formi);
                    
            //         if(newEmpre.ok){
            //             send = true
            //             const empre = user?.EmpresasGeneradoras
            //             empre.push(newEmpre.resPost.data)
            //             dispatch(empresasGeneradoresAction(empre))
                        
            //             // =====================================================> Captura el nombre de la empresa a la que corresponde <===============================================================
            //             let nombreEmpr = user?.typeUser === 'EMPRESA'?  user.Personal.Empresa.Nombre :user?.bodegas[0]?.Empresa.Nombre
            //             // =====================================================> Correo electronico de bienvenida solo para la empresa VESTOLIT <=====================================================
            //             if(nombreEmpr=="Vestolit"){
            //               const generate = await generatePDF(formi.NombreContacto,formState.Nombre)
            //             await sendPdf(generate,formi.Email,'Cordial bienvenida','Cordial bienvenida',user?.Personal?.uid) 
            //             }
            //         }
            //     }
            // }
            // if(send){
            //   formState.PuntosBodega = user?.PuntosBodega?.find(b=>b.Nombre==idSede).id;
            //   const { resPut, ok } = await PutAllWithAuth(`/puntos-generadores/${objetoRecibidoByparams.id}`, user?.Personal?.uid, formState);
            //   if (ok) {
                
            //     const filter = user?.PuntosGeneradores.filter(i=>i.id !== objetoRecibidoByparams.id)
            //     filter.push(resPut)
            //     dispatch(generadoresAction(filter))
            //     Swal.fire({
            //       position: 'center', icon: 'success', title: `El punto ${formState.Nombre} ha sido Actializado`,
            //       showConfirmButton: false,
            //       timer: 1500
            //     })
            //   } else {
            //     Swal.fire({
            //       position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
            //       showConfirmButton: false,
            //       timer: 1500
            //     })
            //   }
            //   onNavigateBack();
            // }else {
            //   Swal.fire({
            //     position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
            //     showConfirmButton: false,
            //     timer: 1500
            //   })
            // }
        
        
      }
    
  };

  const handleChangeSelect = (event) => {
  
    setIdSede(event.target.getAttribute("data-value"));
    setData(idSede?generateSerial(SplitingInitialsName(idSede).toUpperCase()):"")
  };

  const handleAddressChange = async (e,newInputValue) => {
    setAddress(newInputValue);
    formState.Direccion = newInputValue
    setShow(true);
      if (window.google && newInputValue.length > 4) {
        let service;
          service = new window.google.maps.places.AutocompleteService();
          service.getPlacePredictions({ "input":newInputValue,"language": 'es' }, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setPredictions(predictions);
            
              OnhandleAddressChange(e,newInputValue)
            }
          });
      }
  }

  const OnhandleAddressChange = (event, value) => {
 
    if (value) {
      setAddress(value);
      
      // Encuentra el place_id de la predicción seleccionada
      const selectedPrediction = predictions.find((prediction) => prediction.description === value);
      if (selectedPrediction) {
        const placeId = selectedPrediction.place_id;

        // Ahora, utiliza el PlacesService para obtener los detalles del lugar
        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails({ placeId }, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            // Aquí tendrás los detalles del lugar, incluyendo latitud y longitud
            const { lat, lng } = place.geometry.location.toJSON();
            setCoordinates({ lat, lng });
            formState.Latitud =lat.toString()
            formState.Longitud=lng.toString()
          }
        });
      }
    }
  }

  const onNavigateBack = () => { navigate(-1)};

  const filterByBodega =(newValue)=>{   
    console.log('====================================');
    console.log(newValue);
    console.log('====================================');
    if (!newValue) {
      setEmpresaGeneradora("")
    }else{
        const selectedObject = user.EmpresasGeneradoras.find((bodega) => bodega.NombreContacto=== newValue);
        console.log("selectedObject",selectedObject);
        setEmpresaGeneradora(selectedObject.id);
    }
  }

  const handleSunmitWithResponsible =(e)=>{
    setLoading(true)
    if(Responsable && newEmpre){
      if(formi.NombreContacto&&formi.CargoContacto&&formi.Email.length>0&&formi.Direccion){
        handleSubmit(e)
      }else if(EmpresaGeneradora&&formi.Direccion){
        handleSubmit(e)
      }else{
        Swal.fire({
          position: 'center', icon: 'error', title: `Por favor completa los campos del responsable`,
          showConfirmButton: false,
          timer: 1500
        })
      }
    }else{
      handleSubmit(e)
    }

  }

    return (
      <>
      { loading&&
        <FormLoadings/>}
        {/* :
        // <> */}
            {/* ======================>  Barra cabecera <======================*/}
            <nav className='d-flex gap-3 justify-content-between p-2  mb-2 '
              style={dataColor ? { backgroundColor: `${user.colors[1]}`, fontWeight: "bold",borderRadius:20 } : { color: "#666", fontWeight: "bold" }}>
                <h5 style={{ color: "white",marginLeft:10,marginTop:5 }}>{objetoRecibidoByparams.id? objetoRecibidoByparams.Nombre :"Nuevo Punto Generador"}</h5>
            </nav>

            {/* ======================>  Botón atras <======================*/}
            <div className='d-flex justify-content-end mt-2' >
                <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ cursor: "pointer", backgroundColor: user.colors[2],borderRadius:40 }}  onClick={onNavigateBack}>
                  Atras
                </Button>
            </div>

            {/* ======================> FORMULARIO PARA GENERADORES <======================*/}
            <main className='mt-4 card'>
              <Form className='w-100 row m-3' onSubmit={objetoRecibidoByparams!==false?EditPuntoGenerador:handleSunmitWithResponsible}>
                  <div className="row  w-100 ">
                      {!ResponsableEnParams &&
                          <FormControlLabel control={<Checkbox checked={Responsable} onClick={()=>{setResponsable(!Responsable);(!Responsable&&setNewEmpre(false))}}/>} 
                          label={!objetoRecibidoByparams?"¿Maneja entidad responsable?":"¿Desea crear una entidad responsable?"} />
                      }

                      {/* ======================> Selecciona responsable ya creado centralizando la información <======================*/}
                      {Responsable && !newEmpre && !ResponsableEnParams &&
                          <div className="row  w-100">

                              {/* =========> Seleccionador responsable ya creado <=========*/}
                              <div className='col-8 mb-3 '>
                                    <Autocomplete
                                        id="combo-box-demo" options={user?.EmpresasGeneradoras?.map((option) => option.NombreContacto)}
                                        onChange={(e)=>filterByBodega(e.target.textContent)} fullWidth
                                        renderInput={(par) => (
                                          <TextField {...par} label="Selecciona la empresa generadora" variant="outlined" />
                                        )}
                                    />
                              </div>

                              {/* =========> botón que permite agregar responsable <=========*/}
                              <div className='col-4 mb-3 d-flex justify-content-center align-items-center '>
                                  <Button variant="contained"  startIcon={<AddCircleIcon />} style={{ cursor: "pointer", backgroundColor: user.colors[2] }} 
                                          onClick={()=>setNewEmpre(!newEmpre)}>
                                      crear nueva entidad responsable
                                  </Button>
                              </div>
                              
                              <Divider sx={{height:2, background:"#E3E3E3",marginBottom:5}}/>
                          </div>
                      } 

                      {/* ======================> De no tener se crea o si ya tiene se abre la opcion para modificar <======================*/}
                      { ((Responsable && newEmpre ) || ResponsableEnParams) && 
                          <div className="container w-100 ">

                              {/* ======================> Modulo para agregar o editar entidad responsable <======================*/}
                              <NewEmpresaGene handleChangeSelect={handleChangeSelect} idSede={idSede} setNewEmpre={setNewEmpre} 
                                  setForm={setForm} responsable={ResponsableEnParams} obj={objetoRecibidoByparams} newEmpre={newEmpre}
                                   Code={Code} setCode={setCode}
                              />
                          </div>
                      }

                      {/* ======================> Lateral izquierdo del formulario <======================*/}
                      <div className={Responsable?` col-6 mb-3 mt-0`:`col-6 mb-3 mt-3`}>
                          <div className="d-flex flex-column  w-100" style={{}}>

                              {/* =====> Nombre generador <=====*/}
                              <TextField
                                label="Nombre de la locación" type="text" placeholder="Nombre"
                                fullWidth name="Nombre" value={Nombre}  onChange={onInputChange} required
                              />

                              {/* =====> Listado de permisos <=====*/}
                              <div className="mt-2 w-100">
                                  {objetoRecibidoByparams.id?<PermissionsCheck tipo={"Permisos punto generador"} permisos={permisosPuntosGeneradores} 
                                  selectedPermsInitial={user.PuntosGeneradores.find(i=>i.id==objetoRecibidoByparams.id).Permisos || []} onChange={handlePermisosChange}/>:
                                  <PermissionsCheck tipo={"Permisos punto generador"} permisos={permisosPuntosGeneradores} onChange={handlePermisosChange}/>}
                              </div>

                              {/* =====> Selector de gestor <=====*/}
                              {!objetoRecibidoByparams.id && user.typeUser == "EMPRESA" &&
                                <div className="mt-2 w-100">    
                                    <Box  >
                                        <FormControl fullWidth>
                                            <InputLabel >Gestor</InputLabel>
                                            
                                            <Select id="demo-simple-select"  onChange={handleChangeSelect} fullWidth
                                                value={idSede?idSede:user?.PuntosBodega[0]?.Nombre} required label="Gestor">

                                                {user?.PuntosBodega&&user?.PuntosBodega?.map((punto,i )=> 
                                                  <MenuItem key={i} value={punto.Nombre} onClick={handleChangeSelect}>{punto.Nombre}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                              }

                              
                          </div>
                      </div>

                      {/* ======================> Lateral derecho del formulario <======================*/}
                      <div className={Responsable?` col-6 mb-3 mt-0`:`col-6 mb-3 mt-3`} >

                          {/* =====> Campo de dirección  <=====*/}
                          <div className="d-flex flex-column w-100 " style={{}}>
                              <div  className="row">
                                  {/* =====> input de dirección  <=====*/}
                                  <div  className="col-12">
                                      <TextField
                                          id='address' type="text" 
                                          value={address}  label="Dirección" placeholder={objetoRecibidoByparams?.Direccion?objetoRecibidoByparams?.Direccion:"Dirección"}
                                          fullWidth required  onChange={(e)=>{handleAddressChange(e,e.target.value)}}
                                      />
                                  </div>

                                  {/* =====> Muestra las predicciones segun la dirección dada <=====*/}
                                  <div  className="col-12" style={{zIndex:20,backgroundColor:"white"}}>
                                    {predictions.length > 0 && show&& (
                                        <div style={{ border: '1px solid #ccc', width: '300px', position: 'absolute',zIndex:20,backgroundColor:"white" }}>
                                          {predictions.map((prediction) => (
                                            <div key={prediction.place_id} onClick={() => {OnhandleAddressChange(prediction,prediction.description);setShow(false);setAddress(prediction.description)}} style={{ padding: '10px', cursor: 'pointer' }}>
                                              {prediction.description}
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                  </div>
                              </div>
                          </div>

                          {/* =====> Mapa guia <=====*/}
                          <div className='mt-2' style={{height:300, width:"100%",zIndex:1}} >           
                                <GoogleMap lat={coordinates.lat} lng={coordinates.lng}/>
                          </div>
                      </div>
                  </div>

                  {/* =================> Parte final del formulario <=================*/}
                  <div className="d-flex justify-content-between w-100 mb-3 align-items-center m-3 " >

                      {/* ======> Generador de Qr "Empresa" <======*/}
                      {idSede&& !objetoRecibidoByparams.id && user.typeUser =="EMPRESA" &&
                        // <div style={{  }} ref={qrRef}> <QRCode value={data}   id='canvas'/> </div> 
                        <GenerateQr myBlob={myBlob} data={data} setmyBlob={setmyBlob}/>
                      }
                      {user.typeUser !="EMPRESA" &&
                         <GenerateQr myBlob={myBlob} data={data} setmyBlob={setmyBlob}/>
                      }

                      {/* ======> Boton de submit <======*/}
                      <div className="d-flex justify-content-center mx-5 w-75">
                          <Button variant="contained" className='btn w-100' style={{backgroundColor:user.colors[2] ,color:"white"}} 
                              onClick={objetoRecibidoByparams!==false?EditPuntoGenerador:handleSunmitWithResponsible}>
                              {objetoRecibidoByparams.PuntosBodega ? 'Actualizar' : 'Guardar'}
                          </Button>
                      </div>
                  </div>
              </Form>
            </main>
        {/* </>} */}
      </>
    )
}
