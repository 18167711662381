import { currentUser, logoutAsync } from "../redux/action/LoginAction";
import { basePromises, basePromisesAuth } from '../../api/hiloApp';
import { DeleteAllWithAuth, PostAllWithAuth, UseGetWithAuth2, UsePost2 } from "../base2";
import { checkingEmpresas, currentEmpresa } from "../redux/action/EmpresasAction";
import { generateMonochromaticColors } from "../../helpers/Utils";
import { PermisosGestoresAction, PermisosPunAction, bodegaAction, bolsasAction, empleadosAction, empresasGeneradoresAction, generadoresAction, materialesAction, personalAction, puntosBodegasAction, reportesAction, rutasAction } from "../redux/action/DataActions";
import Swal from "sweetalert2";


export const UsePost = async (url, data) => {

  const { base } = await basePromises();
  const Post = await base.post(url, data)
    .catch((e) => console.log(e));
  const resPost = Post?.data
  return {
    resPost
  }
}

export const UseGet = async (url) => {
  const { base } = await basePromises()
  const Get = await base.get(url)
    .catch((e) => console.log(e));
  const resGet = Get.data
  return {
    resGet
  }
}

export const UseGetWithAuth = async (url, token) => {
  if (!token) return null;
  const { baseAuth } = await basePromisesAuth(token)
  const get = await baseAuth.get(url)
    .catch((e) => console.log(e));
  const resGet = get.data

  return {
    resGet
  }

}

export const UsePutWithAuth = async (url, token, data) => {
  const { baseAuth } = await basePromisesAuth(token)
  const put = await baseAuth.put(url, data).catch((e) => console.log(e));
  console.log(put);
  const resPut = await put.data
  return {
    resPut
  }
}

export const UsePostWithAuth = async (url, token, data) => {
  const { baseAuth } = await basePromisesAuth(token)
  const post = await baseAuth.post(url, data).catch((e) => console.log(e));
  const resPost = post
  return {
    resPost
  }
}
// ------------------------------------------------------

export const getAll = async (url, token) => {
  if (!token) return null;
  let { ok, resGet } = await UseGetWithAuth2(url, token)
  return {
    ok,
    data: resGet
  }
}

export const PostAll = async (url, token, data) => {
  if (!token) return null;
  let { resPost } = await PostAllWithAuth(url, token, data)
  return {
    data: resPost
  }
}

export const DeleteAll = async (url, token) => {
  if (!token) return null;
  let { resGet } = await DeleteAllWithAuth(url, token)
  return {
    data: resGet
  }
}


export const existenciaUser = async (pass, email, dispatch) => {
  const data = {
    identifier: email,
    password: pass
  };


  let typeUser;
  let post2 = await UsePost2(`/auth/local`, data)
  let _user2 = post2.resPost.user;
  console.log("_id_user2", _user2);
  let _id_user2 = _user2._id;
  let jwToken = post2.resPost.jwt;

  // =======================================> Carga de datos inicial !!<==========================================
  const usuario = await getAll(`/users/${_id_user2}`, jwToken);
  console.log("usuario=======>", usuario);
  const validateUser = await usuario.ok
  console.log("validateUser", validateUser);
  // if (!validateUser) {
  //   dispatch(logoutAsync())
  //   return Swal.fire({
  //     position: 'center', icon: 'error', title: 'Oops...', text: 'No tienes permiso para acceder al panel',
  //     showConfirmButton: false,
  //     timer: 1500
  //   })
  // }
  console.log("_id_user2", _id_user2);
  let personal = await getAll(`/personals?_where[user]=${_id_user2}`, jwToken);
  console.log("personal", personal);
  const validate = await personal.ok
  console.log("validate", validate);

  let empresas = []
  let idBodega = ''
  let datas = {};
  if (!validate || !validateUser) {
    dispatch(logoutAsync())
    return Swal.fire({
      position: 'center', icon: 'error', title: 'Oops...', text: 'No tienes permiso para acceder al panel',
      showConfirmButton: false,
      timer: 1500
    })
  }
  console.log("usuario", personal?.data[0].PuntosBodega);
  if (personal?.data[0].Permisos.some((permiso) => permiso.Permiso === 'Acceder al dashboard')) {
    // =======================================> Valida tipo de rol que ingresa !!<==========================================
    if (personal?.data[0]?.Empresa) {
      dispatch(checkingEmpresas())

      // =======================================> prepara variables para los datos a cargar !!<==========================================
      let empresas,
        id_empresa = personal.data[0].Empresa.id,
        typeUser = 'EMPRESA',
        empleados,
        bodegas,
        permisos,
        materiales,
        PermisosPunGe,
        reportes = {
          Recogidas: [],
          Separacion: [],
          IngresoBodega: []
        },
        permisosBode;

      const requets = [
        getAll(`/empresas/${id_empresa}`, jwToken),
        getAll(`/personals?_where[Empresa]=${id_empresa}`, jwToken),
        getAll(`/bodegases?_where[Empresa]=${id_empresa}`, jwToken),
        getAll(`/permisos-personals`, jwToken),
        getAll(`/calculadora-materiales?_where[Empresa]=${id_empresa}`, jwToken),
        getAll(`/permisos-puntos-generadores`, jwToken),
        getAll(`/historial-recogidas?_where[Empresa]=${personal.data[0].Empresa.id}&_sort=Fecha:DESC`, jwToken),
        getAll(`/historial-separacions?_where[Empresa]=${personal.data[0].Empresa.id}&_sort=Fecha:DESC`, jwToken),
        getAll(`/historial-ingreso-bodegas?_where[Empresa]=${personal.data[0].Empresa.id}&_sort=Fecha:DESC`, jwToken),
        getAll(`/permisos-bodegas`, jwToken)
      ]

      await Promise.all(requets)
        .then((resultados) => {
          empresas = resultados[0].data
          empleados = resultados[1]
          bodegas = resultados[2]
          permisos = resultados[3]
          materiales = resultados[4]
          PermisosPunGe = resultados[5]
          reportes.Recogidas = resultados[6].data
          reportes.Separacion = resultados[7].data
          reportes.IngresoBodega = resultados[8].data
          permisosBode = resultados[9].data
        })
        .catch((error) => {

          dispatch(logoutAsync())
          console.error('Se produjo un error con alguna de las promesas:', error);
        });

      datas.colors = generateMonochromaticColors(`#${empresas.Color}`)
      datas.Permisos = permisos.data
      datas.typeUser = typeUser
      datas.Personal = { ...personal.data[0], uid: jwToken }
      datas.bodegas = bodegas.data
      datas.reportes = reportes
      datas.materiales = materiales
      datas.empleados = empleados.data
      datas.permisosPuntosGeneradores = PermisosPunGe.data
      datas.loaded = true
      datas.permisosGestores = permisosBode
      let empresa = {
        typeUser,
        idBodega,
        ..._user2,
        usuario,
        empresas: empresas
      }

      if (empresas?.Servicios.some(e => e.Permiso == "Compra y/o venta")) {
        let price = empresas.preciosMateriales
        const materialesCombinados = materiales.data.map((material) => {
          const precioRelacionado = price.find((precio) => precio.Material === material.id);
          // Si no hay un precio relacionado, descartamos el material
          if (!precioRelacionado) return null;
          const { Material: MaterialID, id: id, ...restoPrecio } = precioRelacionado;
          // Si hay un precio relacionado, lo combinamos con el material
          return {
            ...material,
            MaterialID,  // Aquí usamos el nuevo nombre
            ...restoPrecio,
            idPrecio: id
          };
        }).filter(material => material !== null);
        datas.materiales = materialesCombinados
        // dispatch(materialesAction(materialesCombinados))
      } else { datas.materiales = materiales.data }
      dispatch(currentEmpresa(empresa))

      let ids2 = bodegas.data.map((e) => {
        let text = ""
        if (e.id != undefined) { text += "Bodega_in=" + e.id + "&" }
        return text.toString()
      })

      if (bodegas.data.length > 0) {
        let ry = await getAll(`/rutas?${ids2.join('')}`, jwToken);
        // dispatch(rutasAction(ry.data))
        datas.rutas = ry.data
      } else { datas.rutas = [] }

      let pun = bodegas.data.map((e) => { return e.PuntosBodegas })
      const bodegasids = bodegas.data.map((e) => "Bodega_in=" + e.id + "&").join('')

      let bodegases = bodegas.data.length > 0 ? await getAll(`/puntos-bodegas?${bodegasids}`, jwToken) : { "data": [] };


      // ===================================== Peticiones condicionada por los resultados anteriores !!<==========================================

      datas.PuntosBodega = bodegases
      dispatch(currentUser(datas))
      pun = bodegases.data || []
      bodegases = pun.length > 0 ? pun.map(e => { return { ...e, Bodega: e.Bodega.id } }) : []
      let ids = pun.map((e) => "PuntosBodega_in=" + e.id + "&")
      let empleados2
      let ge
      let empreGe
      let punFlated = pun.map(e => { return { ...e, Bodega: e.Bodega.id } })

      const requets1 = [
        getAll(`/personals?${ids.join('')}`, jwToken),
        getAll(`/puntos-generadores?${ids.join('')}`, jwToken),
        getAll(`/empresas-generadoras?${ids2.join('')}`, jwToken)
      ]

      await Promise.all(requets1)
        .then((result) => {
          empleados2 = bodegas.data.length > 0 ? result[0] : []
          ge = bodegas.data.length > 0 ? result[1] : { 'data': [] }
          empreGe = bodegas.data.length > 0 ? result[2] : { 'data': [] }
        })
        .catch((error) => {
          dispatch(logoutAsync())
          console.error('Se produjo un error con alguna de las promesas:', error);
        });

      punFlated = punFlated.map((e) => {
        let emple = []
        empleados2.data.forEach(element => {
          if (e.id === element.PuntosBodega.id) { emple.push(element) }
        })
        return { ...e, empleados: emple, PermisosBodegas: e.PermisosBodegas.map(i => i.id) }
      })
      datas.PuntosBodega = punFlated || []

      datas.PuntosGeneradores = ge.data
      datas.EmpresasGeneradoras = empreGe.data
      dispatch(currentUser(datas))

    } else if (personal?.data[0]?.PuntosBodega?.Bodega) {

      // =======================================> prepara variables para los datos a cargar !!<==========================================
      let empresas,
        typeUser = 'PUNTOBODEGA',
        idBodega = personal?.data[0]?.PuntosBodega?.id,
        empleados,
        bodegases,
        bodegas,
        permisos,
        rutas,
        materiales,
        generadores,
        PermisosPunGe,
        reportes = {
          Recogidas: [],
          Separacion: [],
          IngresoBodega: []
        };

      const requets = [
        getAll(`/empresas?_where[Bodegas]=${personal?.data[0]?.PuntosBodega?.Bodega}`, jwToken),
        getAll(`/personals?_where[PuntosBodega]=${personal?.data[0]?.PuntosBodega?.id}`, jwToken),
        getAll(`/puntos-bodegas/${personal?.data[0]?.PuntosBodega?.id}`, jwToken),
        getAll(`/bodegases/${personal?.data[0]?.PuntosBodega?.Bodega}`, jwToken),
        getAll(`/permisos-personals`, jwToken),
        getAll(`/rutas?_where[PuntosBodega]=${personal?.data[0]?.PuntosBodega?.id}`, jwToken),
        getAll(`/calculadora-materiales?_where[PuntosBodegas]=${personal?.data[0]?.PuntosBodega?.id}`, jwToken),
        getAll(`/puntos-generadores?_where[PuntosBodega]=${personal?.data[0]?.PuntosBodega?.id}`, jwToken),
        getAll(`/permisos-puntos-generadores`, jwToken),
        getAll(`/historial-recogidas?_where[PuntoBodega]=${personal?.data[0]?.PuntosBodega?.id}&_sort=Fecha:DESC`, jwToken),
        getAll(`/historial-separacions?_where[PuntoBodega]=${personal?.data[0]?.PuntosBodega?.id}&_sort=Fecha:DESC`, jwToken),
        getAll(`/historial-ingreso-bodegas?_where[PuntoBodega]=${personal?.data[0]?.PuntosBodega?.id}&_sort=Fecha:DESC`, jwToken),
      ]

      await Promise.all(requets)
        .then((resultados) => {
          empresas = resultados[0].data
          empleados = resultados[1]
          bodegases = resultados[2]
          bodegas = resultados[3]
          permisos = resultados[4]
          rutas = resultados[5]
          materiales = resultados[6]
          generadores = resultados[7]
          PermisosPunGe = resultados[8]
          reportes.Recogidas = resultados[9].data
          reportes.Separacion = resultados[10].data
          reportes.IngresoBodega = resultados[11].data
        })
        .catch((error) => {
          dispatch(logoutAsync())
          console.error('Se produjo un error con alguna de las promesas:', error);
        });

      if (bodegases.data.PermisosBodegas.some(e => e.Permiso == "Comprar")) {
        let price = bodegases.data.precios_materiales
        const materialesCombinados = materiales.data.map((material) => {
          const precioRelacionado = price.find((precio) => precio.Material === material.id);

          // Si no hay un precio relacionado, descartamos el material
          if (!precioRelacionado) return null;
          const { Material: MaterialID, id: id, ...restoPrecio } = precioRelacionado;
          // Si hay un precio relacionado, lo combinamos con el material
          return {
            ...material,
            MaterialID,  // Aquí usamos el nuevo nombre
            ...restoPrecio,
            idPrecio: id
          };
        }).filter(material => material !== null);
        console.log("materialesCombinados===>", materialesCombinados);
        materiales = materialesCombinados
        // dispatch(materialesAction(materialesCombinados))
      } else {
        materiales = materiales.data
        // dispatch(materialesAction(materiales))
      }
      datas.colors = generateMonochromaticColors(bodegas.data.Color)
      datas.Permisos = await permisos.data
      datas.typeUser = typeUser
      datas.Personal = { ...personal.data[0], uid: jwToken }
      datas.rutas = rutas.data
      datas.bodegas = [bodegas.data]
      datas.reportes = reportes
      datas.materiales = materiales
      datas.PuntosGeneradores = generadores.data
      datas.permisosPuntosGeneradores = PermisosPunGe.data
      datas.PuntosBodega = [{ ...bodegases.data, empleados: empleados.data, PermisosBodegas: bodegases.data.PermisosBodegas.map(i => i.id) }]
      datas.loaded = true
      dispatch(currentUser(datas))
      dispatch(checkingEmpresas(empresas))
      let empreGe = await getAll(`/empresas-generadoras?_where[Bodega]=${bodegas.data.id}`, jwToken);
      dispatch(empresasGeneradoresAction(empreGe.data))

      if (bodegases.data.Bolsas && bodegases.data.Bolsas.length > 0) {
        let idsBolsas = bodegases.data.Bolsas.map((e) => "id_in=" + e.id + "&")
        console.log("idsBolsas", idsBolsas);
        let Bolsas = await getAll(`/bolsas?${idsBolsas.join('')}`, jwToken);
        console.log("Bolsas", Bolsas.data);
        dispatch(bolsasAction(Bolsas.data))
      } else { dispatch(bolsasAction([])) }
      return

    } else if (!personal?.data[0]?.Empresa && !personal?.data[0]?.PuntosBodega) {
      dispatch(logoutAsync())
      return Swal.fire({
        position: 'center', icon: 'error', title: 'Oops...', text: 'No tienes permiso para acceder al panel',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } else {
    dispatch(logoutAsync())
    Swal.fire({
      position: 'center', icon: 'error', title: 'Oops...', text: 'No tienes permiso para acceder al panel',
      showConfirmButton: false,
      timer: 1500
    })
  }
}


