import { Avatar,  Button,  Divider,  Grid,  Paper,  TableCell, TableRow, styled } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Stack from '@mui/material/Stack';
import PersonIcon from '@mui/icons-material/Person';
import WidthWideIcon from '@mui/icons-material/WidthWide';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { SplitingInitialsName } from '../../../helpers/Utils';
import queryString from 'query-string';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export const DetailPersonal= () => {
    const { empresas } = useSelector(state => state.empresa)
    const { user } = useSelector(state => state)
    const [usuarios, setUsuarios] = useState()
    const location = useLocation();
    const navigate = useNavigate();
    let objetoRecibidoByparams = new URLSearchParams(location.search);
    objetoRecibidoByparams = queryString.parse(location.search);
    const dataColor = user?.typeUser == "EMPRESA"? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color

    let permisoPersona = user?.PuntosBodega.find(e=>{return e.empleados.find(i=>i.id ===objetoRecibidoByparams?.id)})
        ?.empleados.find(i => i.id === objetoRecibidoByparams?.id)?.Permisos||[]
    let usuario =user?.PuntosBodega.find(e=>{return e.empleados.find(i=>i.id ===objetoRecibidoByparams?.id)})
    ?.empleados.find(i => i.id === objetoRecibidoByparams?.id)||{}

    React.useEffect(() => {
       setUsuarios(objetoRecibidoByparams)
    }, [])
   
    return (
        <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
            <div style={{display:"flex",justifyContent:"space-between",width:"99%"}}  >
                <div style={{display:"flex",alignItems:"center"}}  >
                    <PersonIcon style={{textAlign:"left",marginTop:-10,marginRight:10, color:user.colors[2],fontSize:50}} />
                    <h3 style={{textAlign:"right"}}>{usuario?.user?.username}</h3>
                </div>
                <Link className="navbar-brand" onClick={()=>navigate(-1)}>
                    
                    <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ backgroundColor: dataColor, cursor: "pointer" }}
                   
                >
                    Volver a Personal
                </Button>
                </Link>
            </div>
            <Grid container spacing={2} style={{alignItems:"center",padding:10,background:user.colors[8]+"1a",margin:7,width:"98%",
                    borderRadius:20,alignSelf:"center",boxShadow:`inset 2px 2px 5px ${user.colors[8]}`,marginTop:"4%"}}>

                <Grid item xs={2} md={6} style={{marginTop:"-3%"}}>
                        {usuario.Imagen?
                        <div style={{borderRadius:10,backgroundColor:dataColor, display:"flex",justifyContent:"center",padding:10}}>
                            <img src={usuario.Imagen} alt={usuario?.user?.username} style={{width:"100%",height:170,objectFit:"contain",borderRadius:35}}/>
                        </div>
                        // :<Stack direction="row" justifyContent={"center"} >
                        //     <Avatar sx={{ bgcolor: dataColor }} style={{width:125,height:125,fontSize:45}}>
                        //         {SplitingInitialsName(usuario?.user?.username)}
                        //     </Avatar>
                        // </Stack>
                        :<div style={{borderRadius:10,backgroundColor:dataColor, display:"flex",justifyContent:"center",alignItems:"center",height:150}}>
                            <p style={{verticalAlign:"center",marginTop:"1%",fontSize:30,fontWeight:900}}>{SplitingInitialsName(usuario?.user?.username,)}</p>
                            </div>}
                <Divider style={{height:10, background:user.colors[7],marginTop:10}} variant='fullWidth'/>
                </Grid>
                <Grid item xs={10} md={6} style={{marginTop:"-3%"}}>
                        
                    <div  className='mx-4 'style={{display:"flex",flexDirection:"column",minHeight:270,justifyContent:"center",}}>
                        <h3 className='mb-2 '>Información personal</h3>

                        <div style={{display:"flex",alignItems:"left"}}  >
                            <AlternateEmailIcon style={{textAlign:"left",marginTop:-15,marginRight:10,color:user.colors[2]}}/>
                            <p style={{textAlign:"right"}}>E-mail: {usuario?.user?.email||" - "}</p>
                        </div>
                        <div style={{display:"flex",alignItems:"left"}}  >
                            <WidthWideIcon style={{textAlign:"left",marginTop:-15,marginRight:10,color:user.colors[2]}}/>
                            <p style={{textAlign:"right"}}>Documento: {objetoRecibidoByparams?.Cedula}</p>
                        </div>
                        <div style={{display:"flex",alignItems:"left"}}  >
                            <LocalPhoneIcon style={{textAlign:"left",marginTop:-15,marginRight:10,color:user.colors[2]}}/>
                            <p style={{textAlign:"right"}}>Teléfono: {objetoRecibidoByparams?.Telefono}</p>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={12} md={12} style={{borderRadius:20,marginTop:"-2%"}} >
                    <Item   className='' style={{minHeight:270,borderRadius:20}}>
                    <h3 className='mb-5'>Permisos</h3>
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{padding:20,marginLeft:5}}>
                            {permisoPersona&&permisoPersona.map((p, index) => (
                                <Grid xs={6} sm={6} md={4} key={index}>
                                    <div style={{display:"flex"}}  >
                                     <RadioButtonCheckedIcon style={{fontSize:10,color:user.colors[5],marginTop:5,marginLeft:2}}/>
                                    <p style={{textAlign:"left",fontSize:15,width:"100%"}}> {p.Permiso}</p>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Item>
                </Grid>
                
            </Grid>
        </div>
    )
}
