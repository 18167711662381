import { Grid, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from '../../../hooks/useForm';
import { useSelector } from 'react-redux';

export const RegisterUser = ({ userEdit,setNewUser,handleSubmitUser}) => {
    const { empresas } = useSelector(state => state.empresa);

    const { email, password, username, formState, onInputChange } = useForm({
        email: userEdit?.user?.email ? userEdit?.user?.email : '',
        username: userEdit?.user ||userEdit?.idUser ? userEdit?.user?.username||userEdit?.Nombre : '',
    });

    useEffect(() => {
        setNewUser(formState)
    }, [userEdit,formState])

   
    

    return (
        <>
            <main style={{marginTop:35}}>
                <Form className='w-100 row m-4 mt-2'
                    onSubmit={handleSubmitUser} 
                >
                    { !userEdit &&
                        <div className="row w-100">
                            <Grid item xs={12} className='my-1'>
                                <TextField
                                    label="Email"
                                    type="email"
                                    placeholder="Email"
                                    fullWidth
                                    name="email"
                                    required={!userEdit?true:false}
                                    value={email}
                                    onChange={onInputChange}
                                    disabled={userEdit ? true : false}
                                />
                            </Grid>

                            <Grid item xs={12}  className='my-1'>
                                        <TextField
                                            label="Password"
                                            type="password"
                                            placeholder="Password"
                                            fullWidth
                                            name="password"
                                            required={!userEdit?true:false}
                                            value={password}
                                            onChange={onInputChange}
                                            disabled={userEdit ? true : false}

                                        />
                            </Grid>

                        </div>
                        
                    }
                    <div className="row w-100" style={userEdit&& {marginTop:-43}}>
                        <Grid item xs={12} className='my-1'>
                            {userEdit&& <p>Nombre:</p>}
                            <TextField
                                label={userEdit? userEdit?.user?.username||userEdit.Nombre :"Username"}
                                type="text"
                                placeholder={userEdit? userEdit?.user?.username||userEdit.Nombre  :"Username"}
                                fullWidth
                                required
                                name="username"
                                value={username}
                                onChange={onInputChange}
                                
                            />
                        </Grid>
                    </div>
                    
                </Form>
            </main>
        </>
    )
}
