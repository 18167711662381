import GoogleMap from '../../../../helpers/PrintMaps/Maps';
import React, { useEffect, useRef } from 'react'
import { Button, Divider, Grid, Paper, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const Info = ({sede}) => {
    const user = useSelector(state=>state.user)
    const mapRef = useRef(null);

  useEffect(() => {

    let map
    // Solo inicializa el mapa si las coordenadas son válidas
    if (window.google) {
     map = new window.google.maps.Map(mapRef.current, {
        center: { lat: Number(sede.Latitud), lng: Number(sede.Longitud) },
        zoom: 8,
      });
      new window.google.maps.Marker({
        position: { lat: Number(sede.Latitud), lng: Number(sede.Longitud)},
        map,
      });
    }
    return () => {
        if (map) {
          map = null;
        }
      };
  }, []);
  
  return (

    <div>
         <Grid container spacing={2} style={{alignItems:"center",padding:10,background:"#F1F1F1",margin:7,width:"99%",
                    borderRadius:5,alignSelf:"center",}}>

            <Grid  xs={12} md={6} style={{minHeight:300,maxHeight:300}}>
                {/* <GoogleMap lat={2} lng={4}/> */}
                <div ref={mapRef} style={{ width: "95%", height: 300,borderRadius:5,marginLeft:9 }}></div>
            </Grid>
            <Grid  xs={12} md={6} >
                    
                <div  className='m-2 'style={{display:"flex",flexDirection:"column",minHeight:270}}>
                    <h3 className='mb-5' style={{textAlign:"start"}}>Información: </h3>
                    
                    <div style={{display:"flex",alignItems:"center"}}  >
                        {/* <WidthWideIcon style={{textAlign:"left",marginTop:-15,marginRight:10,color:user.colors[2]}}/> */}
                        <p style={{textAlign:"right"}}>Empleados: </p>
                        <p>{sede?.empleados.length}</p>
                    </div>
                    <div style={{display:"flex",alignItems:"center"}}  >
                        {/* <LocalPhoneIcon style={{textAlign:"left",marginTop:-15,marginRight:10,color:user.colors[2]}}/> */}
                        <p style={{textAlign:"right"}}>Locaciones asignadas: </p>
                        <p>{user.PuntosGeneradores.filter(e=>e.PuntosBodega.id==sede.id).length}</p>
                    </div>

                </div>
            </Grid>

            {user?.typeUser=="EMPRESA"&&
              <Grid xs={6} md={12} >
                  <Item   className='m-2 ' >
                      <h3 className='mb-5'>Permisos</h3>
                      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{padding:20,marginLeft:5}}>
                          {sede&&sede?.PermisosBodegas.map((p, index) => (
                              <Grid xs={6} sm={6} md={4} key={index}>
                                  <div style={{display:"flex"}}  >
                                  <RadioButtonCheckedIcon style={{fontSize:10,color:user.colors[5],marginTop:5,marginLeft:2}}/>
                                  <p style={{textAlign:"left",fontSize:15,width:"100%",marginLeft:3}}> {user?.permisosGestores.find(i=>p==i.id).Permiso}</p>
                                  </div>
                              </Grid>
                          ))}
                      </Grid>
                  </Item>
              </Grid>
            }
            
        </Grid>
    </div>
  )
}

export default Info