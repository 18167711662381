import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Paper, Select, TextField } from '@mui/material'
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Form } from 'react-bootstrap';
import { useForm } from '../../../hooks/useForm';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PostAllWithAuth, PutAllWithAuth } from '../../../service/base2';
import Swal from 'sweetalert2';
import { hasPermission,formatDate } from '../../../helpers/Utils';
import { useDispatch } from 'react-redux';
import { rutasAction } from '../../../service/redux/action/DataActions';
import FormLoadings from '../../../helpers/FormLoadings';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,

        },
    },
};
const CreateRuta = ({rutaEdit = []}) => {
    const user = useSelector(state=>state.user)
    const { empresas } = useSelector(state => state.empresa)
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    let objetoRecibidoByparams = queryString.parse(location.search)
    if(user?.typeUser !== "EMPRESA"){
    //  objetoRecibidoByparams = {
        objetoRecibidoByparams.Bodega =objetoRecibidoByparams.Bodega&& user?.PuntosBodega.find(e=>e.id==objetoRecibidoByparams.PuntosBodega).Bodega.id

    //  }
    }
    console.log(objetoRecibidoByparams);
    const dataColor = user?.typeUser == "EMPRESA" ?"#"+user?.Personal?.Empresa?.Color: user?.bodegas[0]?.Color

    ////// ================== LISTA DE SELECTORES ==================  //////
    const [listPersonal, setListPersonal] = useState((objetoRecibidoByparams&&objetoRecibidoByparams.PuntosBodega)? user?.PuntosBodega.find(e=>e.id==objetoRecibidoByparams.PuntosBodega).empleados?.filter(e=> hasPermission(e.Permisos,'Manejar rutas'))
                                                    :user?.PuntosBodega?.flatMap(e=>e?.empleados?.filter(e=> hasPermission(e.Permisos,'Manejar rutas'))))
    const [listNamePuntoGenerador, setListNamePuntoGenerador] = React.useState(user?.PuntosGeneradores);
    const [bodegas, setBodegas] = useState(objetoRecibidoByparams.Bodega? user?.PuntosBodega.filter(r=>r.Bodega==user?.bodegas.find(e=>e.id==objetoRecibidoByparams.Bodega).id):user?.PuntosBodega)
    const [idBodegas, setIdBodegas] = useState(objetoRecibidoByparams.PuntosBodega?user?.PuntosBodega.find(e=>e.id==objetoRecibidoByparams.PuntosBodega).id:"")
    const [listPuntoGenerador, setListPuntoGenerador] = useState(user?.PuntosGeneradores)
    ///////////////////////////////////////////////////////////////////////////

    
    ////// ================== LISTA DE SELECCCIONADOS ==================  //////
    const [idPersonal, setIdPersonal] = useState("")
    const [rutaById, setRutaById] = useState([])
    const [idPuntoGenerador, setIdPuntoGenerador] = React.useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    //////////////////////////////////////////////////////////////////////////////////////////

    ////// ============================   FORMULARIO INICIAL ============================    //////
    const { Fecha, Bodega, PuntosGeneradores = [], Personal, onInputChange, formState } = useForm({
        Fecha:'ruta' in objetoRecibidoByparams?user?.rutas.find(e => e.id == objetoRecibidoByparams.ruta).Fecha : new Date().toISOString().split('T')[0],
        Bodega: objetoRecibidoByparams?.Bodega ? objetoRecibidoByparams?.Bodega : '',
        PuntosBodega: objetoRecibidoByparams?.PuntosBodega ? objetoRecibidoByparams?.PuntosBodega : "",
        PuntosGeneradores : objetoRecibidoByparams?.generador ? user?.PuntosGeneradores .filter(element => element.Nombre == objetoRecibidoByparams?.generador)  // Filtrar elementos cuyo Nombre está en 'valores'
        .map(element => element._id):[]
    });
    //////////////////////////////////////////////////////////////////////////////////////////

    const handleSelectChange = (event) => {
        const values = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedItems(values);
    };
    
    ////// ============================ SELECT PERSONAL ============================    //////
    const handleChangeSelectPersonal = (event) => { setIdPersonal(event.target.value);formState.Personal=event.target.value};
    //////////////////////////////////////////////////////////////////////////////////////////

    ////// ============================ SELECT PUNTOSBODEGAS ============================    //////
    const handleChangeSelectBodegas = (event) => { 
        setIdBodegas(event.target.value)
        console.log("event.target.value",event.target.value)
        const filter = user?.PuntosBodega.find(e=>e.id == event.target.value)
        console.log("PuntosBodega",filter);
        setListPersonal(filter.empleados?filter.empleados.filter(i=> hasPermission(i.Permisos,'Manejar rutas')):[])
    };
    //////////////////////////////////////////////////////////////////////////////////////////

    ////// ============================ SELECT PUNTOGERADOR ============================    //////
    const handleChangeSelectPuntoGenerador = (event) => {
        const { target: { value }, } = event;
        console.log(value);
        setIdPuntoGenerador(typeof value === 'string' ? value.split(',') : value,);
        
        const valores = typeof value === 'string' ? value.split(',') : value
        const filter = user?.PuntosGeneradores
        .filter(element => valores.includes(element.Nombre))  // Filtrar elementos cuyo Nombre está en 'valores'
        .map(element => element.id);  // Mapear los elementos filtrados a sus IDs
      
            formState.PuntosGeneradores = filter;
            console.log("formState",formState);
            console.log("filter==>",filter);
    };
    //////////////////////////////////////////////////////////////////////////////////////////

    ////// ============================ NUEVA RUTA ============================    //////
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const fecha = formState.Fecha ? new Date(formState.Fecha).toLocaleString() : new Date().toLocaleString()
        const filteringRoute = user?.rutas?.filter(e=> formatDate(new Date(e.Fecha).toLocaleString()) == formatDate(fecha))
        const per = filteringRoute?.find(e=> e.Personal&& e.Personal.id&&e.Personal.id == idPersonal) || false;
        let locationsAsigned = filteringRoute?.filter(e=>formState.PuntosGeneradores.some(id=>e.PuntosGeneradores.some(_id=>_id._id== id))) ;
        locationsAsigned = locationsAsigned.length > 0 ? locationsAsigned : false// Aquiiiiiiiiiiiiiiiiiiiiiiii
        
        
        if(per || locationsAsigned){

            if(per && !locationsAsigned){
                setLoading(false)
                Swal.fire({
                    position: 'center', icon: 'error', title: `Error`,
                    text:`El personal ya tiene una ruta asignada para esta fecha. Por favor, revisa y ajusta la asignación del personal.`,
                    showConfirmButton: false,
                    timer: 3000
                })
            }
            if(!per && locationsAsigned){
                setLoading(false)
                Swal.fire({
                    position: 'center', icon: 'error', title: `Error`,
                    text:`Algunas locaciones seleccionadas ya están asignadas para esta fecha. Por favor, revisa y ajusta las asignaciones de locación.`,
                    showConfirmButton: false,
                    timer: 3000
                })
            }
            if(per && locationsAsigned){
                setLoading(false)
                Swal.fire({
                    position: 'center', icon: 'error', title: `Error`,
                    text:`Se han detectado asignaciones duplicadas. El personal y locaciones ya tienen rutas asignadas para esta fecha. Revisa ambas asignaciones para evitar solapamientos.`,
                    showConfirmButton: false,
                    timer: 5000
                })
            }
        }else{

            if(idBodegas !== "" && PuntosGeneradores !== "" && formState.Personal && formState.PuntosGeneradores.length>0){
                formState.Personal = idPersonal
                formState.Bodega = user?.PuntosBodega.find(e=>e._id==idBodegas).Bodega
                formState.PuntosBodega = idBodegas

                const { resPost, ok } = await PostAllWithAuth(`/rutas`, user?.Personal?.uid, formState);

                if (ok) {      
                    for (let index = 0; index < formState.PuntosGeneradores.length; index++) {     
                        const { resPut } = await PutAllWithAuth(`/puntos-generadores/${formState.PuntosGeneradores[index]}`, user?.Personal?.uid, { asignado: true })     
                    }
                    const fil = user?.rutas
                    fil.push(resPost.data)
                    dispatch(rutasAction(fil))
                    setLoading(false)
                    Swal.fire({
                        position: 'center', icon: 'success', title: `La ruta ha sido agregada`,
                        showConfirmButton: false,timer: 1500
                    })
                } else {
                    setLoading(false)
                    Swal.fire({
                        position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                        showConfirmButton: false, timer: 1500
                    })
                }
                navigate(-1);
            }else{
                setLoading(false)
                Swal.fire({
                    position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor completa todos los datos',
                    showConfirmButton: false,timer: 1500
                })
            }
        }
    };
      
    //////////////////////////////////////////////////////////////////////////////////////////

    ////// ============================ EDITAR RUTA ============================    //////
    const editRuta = async (e) => {
        e.preventDefault()
        setLoading(true)
        const findeRuta = user?.rutas.find(e => e.id == objetoRecibidoByparams.ruta)
        const fecha = formState.Fecha ? new Date(formState.Fecha).toLocaleString() : new Date().toLocaleString()
        const filteringRoute = user?.rutas?.filter(e=> formatDate(new Date(e.Fecha).toLocaleString()) == formatDate(fecha) && e.id !== objetoRecibidoByparams.ruta)
        const per = filteringRoute?.find(e=>e.Personal.id == idPersonal) || false;
        let locationsAsigned = filteringRoute?.filter(e=>formState.PuntosGeneradores.some(id=>e.PuntosGeneradores.some(_id=>_id._id== id))) ;
        locationsAsigned = locationsAsigned.length > 0 ? locationsAsigned : false// Aquiiiiiiiiiiiiiiiiiiiiiiii
        

        if(per || locationsAsigned){

            if(per && !locationsAsigned){
                setLoading(false)
                Swal.fire({
                    position: 'center', icon: 'error', title: `Error`,
                    text:`El personal ya tiene una ruta asignada para esta fecha. Por favor, revisa y ajusta la asignación del personal.`,
                    showConfirmButton: false,timer: 3000
                })
            }
            if(!per && locationsAsigned){
                setLoading(false)
                Swal.fire({
                    position: 'center', icon: 'error', title: `Error`,
                    text:`Algunas locaciones seleccionadas ya están asignadas para esta fecha. Por favor, revisa y ajusta las asignaciones de locación.`,
                    showConfirmButton: false,timer: 3000
                })
            }
            if(per && locationsAsigned){
                setLoading(false)
                Swal.fire({
                    position: 'center', icon: 'error', title: `Error`,
                    text:`Se han detectado asignaciones duplicadas. El personal y locaciones ya tienen rutas asignadas para esta fecha. Revisa ambas asignaciones para evitar solapamientos.`,
                    showConfirmButton: false,timer: 5000
                })
            }
        }else{
            if(findeRuta !== undefined || findeRuta !== null || findeRuta !== ""){
                formState.Personal = idPersonal !==""?idPersonal:findeRuta.Personal.id
                formState.Bodega = findeRuta.Bodega.id
                formState.PuntosBodega = findeRuta.PuntosBodega.id
                const uniquePuntosGeneradores = new Set([
                    ...formState.PuntosGeneradores,
                    ...findeRuta.PuntosGeneradores.map(e => e.id)
                ]);
                const uniqueArray = [...uniquePuntosGeneradores];
                formState.PuntosGeneradores = uniqueArray
                // console.log("queeeeeeee pasa aqui ps",formState);

                const { resPut,ok } = await PutAllWithAuth(`/rutas/${objetoRecibidoByparams.ruta}`, user?.Personal?.uid, formState);
                if (ok) {
                    Swal.fire({
                        position: 'center', icon: 'success', title: `La ruta ha sido Actializado`,
                        showConfirmButton: false,
                        timer: 2500
                    })
                    const filter = user?.rutas.filter(ruta => ruta.id !== objetoRecibidoByparams.ruta)
                    filter.push(resPut)
                    dispatch(rutasAction(filter))
                    setLoading(false)
                    navigate(-1);
                } else {
                    setLoading(false)
                    Swal.fire({
                        position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }else {
                setLoading(false)
                Swal.fire({
                    position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
    };
    //////////////////////////////////////////////////////////////////////////////////////////
    console.log("listPersonal",listPersonal);

    useEffect(()=>{
        if ('ruta' in objetoRecibidoByparams) {
            const ruta = user?.rutas.find(e => e.id == objetoRecibidoByparams.ruta);
            const filter = user?.PuntosBodega.find(e => e.id == ruta.Personal.PuntosBodega);
            console.log("filter switch ", filter);
            setIdBodegas(filter.id);
            setIdPersonal(ruta.Personal.id)
            setListPersonal(filter.empleados);
            setIdPuntoGenerador(ruta.PuntosGeneradores.map(e=>e.Nombre))
            // formState.Fecha = new Date(ruta.Fecha).toLocaleString()
            console.log("formState",formState);
            return
        }
        if('Bodega' in objetoRecibidoByparams){
            if(objetoRecibidoByparams.Bodega!=undefined){
            const filter  =user?.PuntosBodega.filter(r=>r.Bodega==user?.bodegas.find(e=>e.id==objetoRecibidoByparams.Bodega).id||false)
            setBodegas(filter)
            return}
        }
        if('PuntosBodega' in objetoRecibidoByparams){
            const filter = user?.PuntosBodega.find(e => e.id == objetoRecibidoByparams.PuntosBodega);
            const filter2 = user?.PuntosGeneradores.filter(e=>e.PuntosBodega.id == objetoRecibidoByparams.PuntosBodega)
            setIdBodegas(filter.id)
            setListNamePuntoGenerador(filter2)
            setListPersonal(filter2.empleados)
            return
        }
        if('generador' in objetoRecibidoByparams){
            const filter = user?.PuntosGeneradores.find(e=>e.Nombre == objetoRecibidoByparams.generador),
            filter2 = user?.PuntosBodega.find(e=>e._id == filter.PuntosBodega._id),
            filter3 = user?.PuntosGeneradores.filter(e=>e.PuntosBodega._id == filter.PuntosBodega._id)
            console.log("filter",filter);
            setListNamePuntoGenerador(filter3)
            setIdBodegas(filter.PuntosBodega.id)
            setListPersonal(filter2.empleados)
            setIdPuntoGenerador([filter.Nombre])
            return
        }
    },[])
    
  return (
    <>{loading&&
        <FormLoadings/>}
   
    <div style={{ maxWidth:"99.9%" }}>
        <nav className='d-flex gap-3 justify-content-between p-2 mb-2 '
            style={user ? { backgroundColor: user.colors[1],borderRadius:20, fontWeight: "bold" } : { color: "#666", fontWeight: "bold",borderRadius:20 }}>
            <div className='ml-5' style={{borderRadius:20}}>
                <h5 style={{ color: "white",marginLeft:10 }}>{objetoRecibidoByparams.ruta ? 'Actualizar ruta' : 'Nueva Ruta'}</h5>
                {/* <h5 style={{ color: "white" }}>Nueva Ruta</h5> */}
            </div>
        </nav>

        <div className='d-flex justify-content-end mt-3'style={{ marginRight:25 }}>
            <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ cursor: "pointer", backgroundColor:user.colors[2] ,borderRadius:40 }}
                onClick={()=>navigate(-1)}
            >
                Atras
            </Button>
        </div>

        <Paper className='mt-2 p-2' style={{ maxWidth:"100%",marginRight:4 }}>
            <Form className='w-100 row m-4'
                onSubmit={objetoRecibidoByparams.ruta ? editRuta :  handleSubmit}>

                <div className="row">
                    <div className="col-6 mb-3 " >
                        <TextField  label="Fecha"  type="date" fullWidth
                            name="Fecha" focused  value={Fecha} onChange={onInputChange}/>
                    </div>

                    {user.typeUser== "EMPRESA" && 
                        <div className="col-6 mb-3 " >
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="label-bodega">Gestor</InputLabel>
                                    <Select  labelId="label-bodega"
                                        id="demo-simple-select" value={idBodegas}
                                        label="Bodega"  onChange={handleChangeSelectBodegas} >
                                        {
                                            bodegas?.map((punto) => (
                                                <MenuItem key={punto?.id} value={punto?.id}>{punto?.Nombre} </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                    }

                </div>

                <div className="row" style={{ maxWidth:"100%" }}>
                    
                    <div className="col-6 mb-3 " >
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="label-personal">Personal</InputLabel>

                                <Select disabled={idBodegas.length > 0 ? false : true} labelId="label-personal" 
                                    id="demo-simple-select" value={idPersonal} label="Personal" onChange={handleChangeSelectPersonal}>

                                    {listPersonal.map((punto) => ( punto?.PuntosBodega?.id == idBodegas && 
                                            <MenuItem key={punto?.id} value={punto?.id}>{punto?.user.username}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>

                    <div className="col-6 mb-3 " style={{ maxWidth:"100%" }} >
                        <Box sx={{ maxWidth:700 }}>
                            <FormControl fullWidth  >
                                <InputLabel id="label-punto">Puntos Generadores</InputLabel>

                                <Select disabled={idBodegas.length > 0 ? false : true}
                                    multiple displayEmpty labelId="label-punto"
                                    id="demo-simple-select-punto" fullWidth
                                    value={idPuntoGenerador} onChange={handleChangeSelectPuntoGenerador}
                                    input={<OutlinedInput label='Puntos Generadores' />}
                                    renderValue={
                                        (selected) => {
                                        if (objetoRecibidoByparams?.idRuta) {
                                            return listNamePuntoGenerador.join(', ');
                                        } else {
                                            console.log(selected);
                                            return selected.length > 0 ?selected.join(', '):selected ;
                                        }
                                    }
                                }
                                    MenuProps={MenuProps}
                                // inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem disabled value="k">
                                        <em>{listPuntoGenerador?.length > 0 ? 'Punto generador' : 'No hay Punto generador'}</em>
                                    </MenuItem>

                                    {listPuntoGenerador.map((puntoGenerador) => (
                                        puntoGenerador.PuntosBodega.id == idBodegas &&

                                        <MenuItem key={puntoGenerador.id}
                                            value={puntoGenerador.Nombre} >
                                                
                                            <Checkbox checked={idPuntoGenerador.indexOf(puntoGenerador.Nombre) > -1} />
                                            <ListItemText primary={puntoGenerador.Nombre} />

                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>

                </div>

                <div>
                    <Button variant="contained" className=' w-25' style={{ cursor: "pointer", backgroundColor: `${user?.colors[1]}` }} type='submit' >
                        {objetoRecibidoByparams?.ruta ? 'Actualizar' : 'Guardar'}
                    </Button>
                </div>
            </Form>
        </Paper>
    </div>
    </>
  )
}

export default CreateRuta