import React, { useState } from 'react'
import useDimensions from '../../../hooks/useDimensions';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { convertWeight, getAbbreviation, hasPermission } from '../../../helpers/Utils';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import "./styles/FormStyles.css"
import { PostAllWithAuth, PutAllWithAuth } from '../../../service/base2';
import Swal from 'sweetalert2';
import { reportesAction } from '../../../service/redux/action/DataActions';
const {_Monedas} = require('../../../helpers/Monedas.json')

const ModalDetailsMaterials = ({rutaID,IdGene,setReport,SetPuntosGeneradores}) => {
    const { height, width } = useDimensions();
    const user = useSelector(state => state.user)
    console.log("_Monedas",_Monedas);
    console.log(user?.PuntosGeneradores);;
    const PuntoGenerador = (IdGene&&user?.PuntosGeneradores)?user?.PuntosGeneradores.find(e=>e.id ==IdGene):{}
    const [Informe, setInforme] = useState([]);
    const [InformeDetallado, setInformeDetallado] = useState([]);
    const hasCompraVentaPermission = user?.typeUser === "EMPRESA" ? (user?.Personal.Empresa?.Servicios&& user?.Personal.Empresa?.Servicios.length>0)?user?.Personal.Empresa?.Servicios.some(e=>e=="65246345637f1900140aa59c"):false:(user.PuntosBodega[0].PermisosBodegas&&user.PuntosBodega[0].PermisosBodegas.length>0)?user.PuntosBodega[0].PermisosBodegas.some(e=>e=="64679ade25f79000140e8f17"):false;
    const ruta = user?.rutas.find(e=>e.id==rutaID)
    const sedePermissions = ruta.PuntosBodega.PermisosBodegas.some(e=>e=="64679ade25f79000140e8f17")
    const moneda = ruta.PuntosBodega.Moneda? _Monedas.find(e=>e.id==ruta.PuntosBodega.Moneda).moneda:false
console.log("hasCompraVentaPermission",ruta.PuntosBodega);
    const materiales = hasCompraVentaPermission && sedePermissions &&user?.typeUser === "EMPRESA" ?user.materiales.filter(e=>e.Moneda==moneda):user.materiales
    console.log("materiales",materiales);
    const [details,setDetail]=useState(false)
    const [puntoGestor,setPuntoGestor]=useState(user?.PuntosBodega[0])
    const [listPersonal, setListPersonal] = useState( puntoGestor.empleados?.filter(e=> hasPermission(e.Permisos,'Manejar rutas')))
    console.log("ruta",ruta);
    const dispatch = useDispatch()
    const [dataToSend,setDataToSend] =useState({
        Fecha: new Date(),
        Personal: ruta.Personal.id,
        PuntoGenerador: IdGene,
        Informe: details?InformeDetallado:Informe,
        Ruta: rutaID,
        Venta: false,
        Empresa: ruta.Bodega.Empresa,
        Bodega: ruta.PuntosBodega.Bodega,
        PuntoBodega: ruta.PuntosBodega.id,
    })

    let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color;
    console.log(new Date());
    const modalStyle = {
        position: 'fixed', 
        top: 0,
        left: 0,
        minHeight: height, // Ocupa toda la altura
        backgroundColor: 'rgba(50, 50, 60, 0.7)', // Un gris azulado claro con transparencia
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000, // Asegura que el modal esté sobre otros elementos
    };

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setDataToSend({
            ...dataToSend,
            [ name ]: value
        });
    }
      
    // console.log(Informe[0].value&&Informe[0].value);
    const handleToggleDetails = () => {
      setDetail(!details);
  
      if (details) {
          // Estamos desactivando los detalles, así que nos quedamos solo con los nombres de los materiales
          const newInforme = InformeDetallado.filter(item => item).map(item => item.material);
          setInforme(newInforme);
          setInformeDetallado([]);
          setDataToSend(prevData => ({
            ...prevData,
            Informe: newInforme
          }))
      } else {
          // Estamos activando detalles, así que preparamos para añadir peso y unidad
          const newInformeDetallado = Informe.map(material => {
              const materialData = materiales.find(m => m.Material.Material === material);
              
              return {
                  material: material,
                  value: '', // Inicializamos el peso en vacío para que el usuario pueda ingresar
                  Unidad_de_Peso: materialData.Unidad_de_Peso, // Asumimos que esta unidad de peso es un dato que ya tenemos
              };
          });
          setInformeDetallado(newInformeDetallado);
          setDataToSend(prevData => ({
            ...prevData,
            Informe: newInformeDetallado
          }))
      }
  };

  const handleChangeSelectMaterial = (event) => {
      const materialesSeleccionados = event.target.value;
      setInforme(materialesSeleccionados);
      setDataToSend(prevData => ({
        ...prevData,
        Informe: materialesSeleccionados
      }))
  };

  const handleInputChangeDetalle = (index, campo, valor) => {
      const nuevoInformeDetallado = [...InformeDetallado];
      nuevoInformeDetallado[index][campo] = valor;
      setInformeDetallado(nuevoInformeDetallado);
  };
  const handleCompraToggle = () => {
    // Actualiza el estado para reflejar el cambio en el estado de compra
    setDataToSend(prevData => ({
      ...prevData,
      Venta: !prevData.Venta
    }));
  
    // Dependiendo del estado de compra, actualiza el informe detallado
    setInformeDetallado(informeDetallado => 
      informeDetallado.map(item => {
        if (!dataToSend.Venta) {
          // Si se va a activar la compra, añadir campos de valor y moneda
          return {
            ...item,
            valor: convertWeight(item.value,item.Unidad_de_Peso,materiales.find(t=>t.Material.Material==item.material).Unidad_de_Precio)*materiales.find(t=>t.Material.Material==item.material).Precio, // Inicializar en vacío o con un valor por defecto
            moneda: materiales.find(t=>t.Material.Material==item.material).Moneda, // Asumir moneda o permitir que se cambie
          };
        } else {
          // Si se va a desactivar la compra, eliminar campos de valor y moneda
          const { valor, moneda, ...rest } = item;
          return rest;
        }
      })
    );
    setDataToSend(prevData => ({
      ...prevData,
      Informe: informeDetallado => 
      informeDetallado.map(item => {
        if (!dataToSend.Venta) {
          // Si se va a activar la compra, añadir campos de valor y moneda
          return {
            ...item,
            valor: convertWeight(item.value,item.Unidad_de_Peso,materiales.find(t=>t.Material.Material==item.material).Unidad_de_Precio)*materiales.find(t=>t.Material.Material==item.material).Precio, // Inicializar en vacío o con un valor por defecto
            moneda: materiales.find(t=>t.Material.Material==item.material).Moneda, // Asumir moneda o permitir que se cambie
          };
        } else {
          // Si se va a desactivar la compra, eliminar campos de valor y moneda
          const { valor, moneda, ...rest } = item;
          return rest;
        }
      })
    
    }))
  };
  
console.log("dataToSend",dataToSend);
console.log(puntoGestor.empleados);
    const RenderDetalleInforme = () => {
        return InformeDetallado.map((item, index) => (
            <Box key={index} sx={{ marginBottom: 2 }} className="d-flex justify-content-around w-100">
                <TextField
                    label="Material"
                    value={item.material}
                    id="standard-basic" variant="standard" 
                    margin="dense"
                    disabled
                    sx={{ width: '25%', marginRight: 1 }}
                />
                <TextField
                    label="Peso"
                    id="standard-basic" variant="standard" 
                    margin="dense"
                    value={item.value}
                    onChange={(e) => handleInputChangeDetalle(index, 'value', e.target.value)}
                    sx={{ width: '25%', marginRight: 1 }}
                />
                <TextField
                    id="standard-basic" variant="standard" 
                    label="Unidad de Peso"
                    margin="dense"
                    value={item.Unidad_de_Peso}
                    disabled
                    onChange={(e) => handleInputChangeDetalle(index, 'Unidad_de_Peso', e.target.value)}
                    sx={{ width: '25%' }}
                />
                {dataToSend.Venta&&<p style={{ width: '25%',marginTop:10,marginLeft:4 }}>$ {item.valor.toFixed(2)}</p>}
            </Box>
        ));
    };

    const createInforme = async () => {
        if (dataToSend.Personal && dataToSend.Bodega && dataToSend.Empresa && dataToSend.PuntoGenerador) {
            if (!InformeDetallado.length > 0 && !Informe.length>0) {
                Swal.fire({
                    position: 'center', icon: 'error', title: 'Oops...', text: 'El informe está vacío',
                    showConfirmButton: false,
                    timer: 1500
                });
                return;
            }
            
            // Comprueba que cada objeto en el informe tenga todas las propiedades necesarias con valores no vacíos.
            const esInformeValido = details ? InformeDetallado.every(item => {
                // Para los informes detallados
                return item.material && materiales.some(e=>e.Material.Material==item.material) && 
                    item.value && !isNaN(item.value) && 
                    item.Unidad_de_Peso && 
                    (!dataToSend.Venta || (item.valor && item.moneda));
            }) : Informe.every(material => {
                // Para los informes simples
                return material && materiales.some(e=>e.Material.Material==material);
            });

            if (!esInformeValido) {
                Swal.fire({
                    position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor completa todos los campos del informe correctamente',
                    showConfirmButton: false,
                    timer: 1500
                });
                return;
            }

            
                const response = await PostAllWithAuth("/historial-recogidas", user?.Personal?.uid, dataToSend);
                let ok = await response.ok
                console.log(ok);
                if(ok){
                    console.log("response",response.resPost.data);
                    const response1 = await PutAllWithAuth("/puntos-generadores"+IdGene, user?.Personal?.uid, {"Llenado":false,"asignado": false});
                    console.log("response1",response1.resPut);
                    let reportes ={
                        Recogidas: user?.reportes?.Recogidas,
                        Separacion:user?.reportes?.Separacion,
                        IngresoBodega:user?.reportes?.IngresoBodega
                    }
                    reportes.Recogidas.push(response.resPost.data)
                    dispatch(reportesAction(reportes))
                    setDataToSend({
                    Fecha: "",
                    Bolsa: "",
                    Personal: ruta.Personal.id,
                    PuntoGenerador: IdGene,
                    Informe: details?InformeDetallado:Informe,
                    Ruta: rutaID,
                    Venta: false,
                    Empresa: ruta.Bodega.Empresa,
                    Bodega: ruta.PuntosBodega.Bodega,
                    PuntoBodega: ruta.PuntosBodega.id,
                })
                setReport(false)
                    Swal.fire({ position: 'center', icon: 'success', title: `El informe ha sido creado exitosamente`,
                    showConfirmButton: false,timer: 1500})
                    

                }else{
                    Swal.fire({
                        position: 'center', icon: 'error', title: 'Error', text: 'No se pudo crear el informe',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                // Manejo del response adecuado según tu aplicación...
            

        } else {
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor completa los campos',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    const revertDate = (date)=>{
        const  d= new Date(date)
        d.setDate(d.getDate()-1)
        return d.toISOString().split("T")[0]
    }
    console.log(Informe);
    return (
        <div className="d-flex justify-content-center align-items-center w-100 " style={modalStyle}>
          
            
            <div className='MainBox'  >
                
                <IconButton style={{alignSelf:"center",position:"absolute",right:5,top:5}} onClick={()=>setReport(false)}>
                    <CancelIcon style={{color:user.colors[6],fontSize:30}} />
                </IconButton>

                {/* ================ FECHA ================*/}
                <div className="col-6 mb-3 m-3 mt-4" >
                <TextField id="standard-basic" variant="standard" label="Fecha" type="date" fullWidth
                        name="Fecha" focused
                        value={revertDate(dataToSend.Fecha)}
                        onChange={(e)=>{
                            let fe = new Date(e.target.value);
                            fe.setDate(fe.getDate() + 1);
                            // Formatear la fecha a YYYY-MM-DD antes de actualizar el estado
                            setDataToSend(prevData => ({
                            ...prevData,
                            Fecha: fe
                            }));
                            console.log("e.target.value", fe);
                        }}
                        />
                </div>
                {/* ================ Gestor ================*/}
                <div className='MainBox_Gextor d-flex flex-wrap gap-2 justify-content-between'>
                    {user.typeUser =="EMPRESA"&& !ruta.PuntosBodega &&
                    <div style={{width:"47%"}}>
                      <InputLabel id="demo-simple-select-standard-label">Gestor</InputLabel>
                      <Select
                      className='select'
                          labelId="demo-simple-select-standard-label"
                          id="standard"
                          value={ puntoGestor.Nombre}
                          onChange={(e)=>setPuntoGestor(user?.PuntosBodega?.find(i=>i.Nombre==e.target.value))}
                          style={{width:"100%"}}
                      >
                          {user?.PuntosBodega&&user?.PuntosBodega.map((item) => (
                              <MenuItem key={item.id} value={item.Nombre}>
                                  {item.Nombre}
                              </MenuItem>
                          ))}
                      </Select>
                    </div>
                    }

                    {/* ================ Personal ================*/}
                    { (!ruta.PuntosBodega || !ruta.Personal) &&
                      <div style={{width:"47%"}}>
                    <InputLabel id="demo-simple-select-standard-label">Personal</InputLabel>
                      <Select
                          labelId="demo-simple-select-standard-label"
                          id="standard"
                          value={ puntoGestor.empleados.find(i=>i.id ==dataToSend.Personal) ?puntoGestor.empleados.find(i=>i.id ==dataToSend.Personal).user.username:"No se encontró"}
                          onChange={(e)=>setDataToSend(prevData => ({
                            ...prevData,
                            Personal: puntoGestor.empleados.find(i=>i.user.username ==e.target.value).id
                          }))}
                          style={{width:"100%"}}
                      >
                          {puntoGestor.empleados.map((item) => (
                              <MenuItem key={item.id} value={item.user.username}>
                                  {item.user.username}
                              </MenuItem>
                          ))}
                      </Select>
                    </div>
                    }
                </div>

                <h4 className='text-center my-3'>Reportar materiales por tipo</h4>

                {/* ================ Reporte ================*/}

                <div>
                  {/* ================ Selección de materiales ================*/}
                  <Box sx={{ minWidth: "100%", maxWidth: "100%" }} className="d-flex">
                      <FormControl style={{width:"90%"}}>
                      <InputLabel id="demo-simple-select-standard-label">Material</InputLabel>
                          <Select
                              labelId="demo-simple-select-standard-label"
                              id="standard"
                              multiple
                              value={ !details?Informe:InformeDetallado.map(e=>e.material)}
                              onChange={handleChangeSelectMaterial}
                              renderValue={(selected) => selected.join(', ')}
                              style={{width:"100%"}}
                          >
                              {materiales.map((material) => (
                                  <MenuItem key={material.id} value={material.Material.Material}>
                                      {material.Material.Material}
                                  </MenuItem>
                              ))}
                          </Select>
                      </FormControl>

                      {/* ================ Boton add detalle de materiales ================*/}
                      {Informe.length>0&&
                        <Tooltip title="Agregar detalle">
                            <IconButton style={{alignSelf:"center"}} onClick={()=>{handleToggleDetails();}}>
                                {details?
                                  <RemoveCircleIcon style={{fontSize:30,color:user.colors[6]}}/>
                                  :
                                  <AddCircleIcon style={{fontSize:30,color:user.colors[6]}}/>}
                            </IconButton>
                        </Tooltip>}
                        {(details && hasCompraVentaPermission)&&
                            <IconButton style={{alignSelf:"center"}} onClick={()=>handleCompraToggle()}>
                                {!dataToSend.Venta?
                                    <AttachMoneyIcon style={{color:user.colors[6],fontSize:30}} />
                                    :
                                    <MoneyOffIcon style={{color:"#ccc",fontSize:30}} />
                                }
                            </IconButton>
                        }
                  </Box>

                  {/* ================ Peso del material ================*/}
                  {details && <RenderDetalleInforme/>}
                 
                </div>
                 
                <Button variant="contained" className="boton"style={{backgroundColor:user.colors[2]}} onClick={createInforme}>Guardar reporte</Button>
            </div>
        </div>
    );
}

export default ModalDetailsMaterials