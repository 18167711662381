
export const PermissionsPointGener = (user, setPermissions)=>{
    let Serviciosempr =user?.typeUser === 'EMPRESA'?  user.Personal.Empresa :user?.bodegas[0]?.Empresa
    let removeVende = Serviciosempr.Servicios.some(id=>id=="65246345637f1900140aa59c")
    let removeLeeCarnet = Serviciosempr.Servicios.some(id=>id=="657a1862997b320014a3bcd3")
    let removeInfoRecog =Serviciosempr.Servicios.some(id=>id=="64c8527eeb605c0014653075")
    let removeAllInforme =Serviciosempr.Servicios.some(id=>id=="64c8526feb605c0014653074")
    let removeBolsa = Serviciosempr.Servicios.some(id=>id=="657a1dbf997b320014a3bcd4")

    let bodegaPermissiosnRemove =[
        {"Permiso":"Recibe informes",
          "id":"64c8524aeb605c0014653073",
          "eliminar":!removeAllInforme
        },
        {"Permiso":"bolsa",
          "id":"647ab335d07e300014997ea5",
          "eliminar":!removeBolsa
        },
        {
          "Permiso":"lee Carnet",
          "id":"645f35eb62871ca92c022c9d",
          "eliminar":!removeLeeCarnet
        },
        {
          "Permiso":"Recibe informe de recogida",
          "id":"64c851edeb605c0014653072",
          "eliminar":!removeInfoRecog && !removeAllInforme
        },
        {
          "Permiso":"Vende",
          "id":"645f35cf62871ca92c022c9c",
          "eliminar":!removeVende
        }
    ]

    return user?.permisosPuntosGeneradores.filter(e=> !bodegaPermissiosnRemove.some(i=>i.id ==e.id && i.eliminar))

}