import React from 'react'
import {  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
const { v4: uuidv4 } = require('uuid');


const RecEnPlanta = ({ingreso}) => {

    console.log("ingreso",ingreso);
    const { user } = useSelector(state => state)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color
    const handleChangePage = (event, newPage) => { setPage(newPage);};
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


  return (
    <div className='mt-5'>
        <TableContainer sx={{   }}>
            <Table aria-label="sticky table">
                <TableHead style={{zIndex:-10}}>
                    <TableRow hover tabIndex={-10} key={uuidv4()} >

                        <TableCell align={"center"}>
                            <b>Generador</b>
                        </TableCell>

                        {user?.typeUser === 'EMPRESA'&&
                            <TableCell align={"center"}>
                                <b>Gestor</b>
                            </TableCell>
                        }

                        <TableCell align={"center"}>
                            <b>Fecha</b>
                        </TableCell>

                        <TableCell align={"center"}>
                            <b>Informe</b>
                        </TableCell>

                        <TableCell align={"center"}>
                            <b>Total entregado</b>
                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {ingreso != undefined && ingreso.length > 0 && ingreso.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                        <TableRow hover tabIndex={-1} key={uuidv4()} >
                            
                            <TableCell key={uuidv4()} align={"center"}>
                                {e.locaciones_visitadas[0]?.Nombre||"No hay datos"}
                            </TableCell>

                            {user?.typeUser === 'EMPRESA'&&
                                <TableCell key={uuidv4()} align={"center"}>
                                    {e.Bodega.Nombre}
                                </TableCell>
                            }

                            <TableCell key={uuidv4()} align={"center"}>
                                {new Date(e.Fecha).toLocaleDateString()}
                            </TableCell>

                            <TableCell key={uuidv4()} align={"center"}>
                                <span className='mx-1'
                                    style={{ cursor: 'pointer', color: dataColor , textDecoration: 'underline' }}>
                                    <SignalCellularAltIcon />
                                    {/* {console.log(ruta)} */}
                                </span>
                            </TableCell>

                            <TableCell key={uuidv4()} align={"center"}>
                                {e.Peso + " KG"||"No hay datos"}
                            </TableCell>

                            

                        </TableRow>

                    ))}

                </TableBody>
            </Table>
        </TableContainer>

        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={ingreso && ingreso.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  )
}

export default RecEnPlanta