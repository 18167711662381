import { Avatar, Backdrop, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Select from '@mui/material/Select';
import { SplitingInitialsName } from '../../../../helpers/Utils';
import { getAll } from '../../../../service/base/base';
import Swal from 'sweetalert2';
import { DeleteAllWithAuth } from '../../../../service/base2';
import { Link } from 'react-router-dom';
const { v4: uuidv4 } = require('uuid');


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,

        },
    },
};



export const ItemsPersonalBodega = ({ hasPermission, id, reload, setReload, setType, bodegaById }) => {
    const { empresas } = useSelector(state => state.empresa)
    const user = useSelector(state => state.user)
    let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color;

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const [personalById, setPersonalById] = useState()

    const getPersonalBodegaById = async () => {
        const personal = await getAll(`/personals/${id}`, user?.Personal?.uid);
        setPersonalById(personal.data);
    };

    useEffect(() => {
        if (id) {
            getPersonalBodegaById();
        }
    }, []);

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setPersonName(typeof value === 'string' ? value.split(',') : value,);
    };

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const eliminarPersonalBodega = async (id) => {
        await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esta acción!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            if (result.isConfirmed) {
                //TODO: La Funcion para elimianr está comentada
                const res = DeleteAllWithAuth(`personals/${id}`, user?.Personal?.uid);
                Swal.fire(
                    'Eliminado!',
                    'El personal ha sido eliminada.',
                    'success'
                );
            }
        });
    };


    return (
        <>
            <TableCell key={uuidv4()} align={"center"}>
                {personalById?.Imagen ?
                    <img src={personalById?.Imagen} style={{ width: 40, height: 40, borderRadius: 100 }} alt={'profile imagen'} />
                    :
                    <Stack direction="row" justifyContent={"center"}>
                        <Avatar sx={{ bgcolor: `#${dataColor}` }}>{SplitingInitialsName(personalById?.user?.username)}</Avatar>
                    </Stack>
                }
            </TableCell>

            <TableCell key={uuidv4()} align={"center"}>
                {personalById?.user?.username}
            </TableCell>

            <TableCell key={uuidv4()} align={"center"}>
                {personalById?.Cedula}
            </TableCell>

            <TableCell key={uuidv4()} align={"center"}>

                <FormControl sx={{ m: 1, width: 120, mt: 3 }}>
                    <Select
                        multiple
                        displayEmpty
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>Permisos</em>;
                            }

                            return selected.join(', ');
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem disabled value="">
                            <em>{personalById?.Permisos?.length > 0 ? 'Permisos:' : 'No tiene permisos'}</em>
                        </MenuItem>
                        {personalById?.Permisos.map((name) => (
                            <MenuItem disabled
                                key={name.id}
                                value={name.Permiso}
                                style={getStyles(name, personName, theme)}
                            >
                                {name.Permiso}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </TableCell>

            <TableCell key={uuidv4()} align={"center"}>
                <div className='d-flex gap-3 justify-content-center'>
                    <Tooltip title="Modificar">
                        {hasPermission('Modificar gestores') ? (
                            <Link className="navbar-brand" to={{ pathname: `/nuevo-personal-bodega/`, search: `?${new URLSearchParams({  username: personalById?.user?.username, email: personalById?.user?.email, id: personalById?.id, bodegaById: bodegaById.id, puntosBodega:personalById?.PuntosBodega?.id, Cedula:personalById?.Cedula, Telefono:personalById?.Telefono }).toString()}` }} >
                                <EditTwoToneIcon color='primary' />
                            </Link>
                        ) : (
                            // Si no se tienen los permisos necesarios no se muestra nada
                            <span style={{ display: 'none' }}></span>
                        )}
                    </Tooltip>
                    <Tooltip disabled title="Eliminar">
                        {hasPermission('Eliminar gestores') ? (
                            <DeleteTwoToneIcon color='error'
                                onClick={() => eliminarPersonalBodega(personalById?.id)}
                            />
                        ) : (
                            // Si no se tienen los permisos necesarios no se muestra nada
                            <span style={{ display: 'none' }}></span>
                        )}
                    </Tooltip>
                </div>
            </TableCell>
        </>
    )
}
