import Swal from "sweetalert2";
import { hasPermission } from "../../../helpers/Utils";
import { DeleteAllWithAuth, PostAllWithAuth, PutAllWithAuth, UseGetWithAuth2 } from "../../../service/base2";
import { materialesAction } from "../../../service/redux/action/DataActions";


export const CreateMatEmpresa = async (user, formState, setValue, setLoading, dispatch, materialesRecuperables, idUnidadPrecio, precio) => {
    console.log("// =================> user", user);
    // =================> Guarda en variable si tiene permiso de comprar <=================
    const hasCompraVentaPermission = user?.typeUser === "EMPRESA" ? (user?.Personal.Empresa?.Servicios && user?.Personal.Empresa?.Servicios.length > 0) ? user?.Personal.Empresa?.Servicios.some(e => e == "65246345637f1900140aa59c") : false : (user.PuntosBodega[0].PermisosBodegas && user.PuntosBodega[0].PermisosBodegas.length > 0) ? user.PuntosBodega[0].PermisosBodegas.some(e => e == "64679ade25f79000140e8f17") : false;

    // =================> Valida campos para la estructura y envio de data a las tablas de materiales <=================
    if ((hasCompraVentaPermission && precio !== "0" && idUnidadPrecio) || (!hasCompraVentaPermission && precio == "0")) {

        // =================> Envia información nueva a Con el nombre de material y la unidad de medida que maneja la empresa <=================
        const { resPost, ok } = await PostAllWithAuth(`/calculadora-materiales`, user?.Personal?.uid, formState);


        if (hasCompraVentaPermission && ok) {

            let formi = {
                Material: resPost.data.id,
                Precio: Number(precio),
                Moneda: formState.Moneda || "",
                Unidad_de_Precio: idUnidadPrecio,
            }
            if (user.typeUser == "EMPRESA") {
                formi.Empresa = user?.Personal?.Empresa?.id
            }
            if (user.typeUser == "PUNTOBODEGA") {
                formi.PuntosBodegas = [user?.PuntosBodega[0].id]
            }
            // =================> valida si esta seleccionados los campos necesarios para el envio de data sobre los precio <=================


            const resP = await PostAllWithAuth(`/precios-materiales`, user?.Personal?.uid, formi);
            console.log("PERICIOOO", resP);
            console.log("NO__PERICIOOO", resPost);

            if (resP.ok) {

                const MA = materialesRecuperables.find(e => e.id == resPost.data.Material.id)
                console.log("MAAAAAAAAAAA", MA.Material);
                const Material2 = resPost.data
                Material2.Material.Material = MA.Material
                const mate3 = Material2
                console.log("MAAAAAAA==>2", mate3);
                //Armado de data para enviar a redux
                let dat = {
                    ...resPost.data,
                    MaterialID: resPost.data.Material.id,
                    id: resPost.data.id,
                    Unidad_de_Precio: resP.resPost.data.Unidad_de_Precio,
                    Unidad_de_Peso: resPost.data.Unidad_de_Peso,
                    Precio: resP.resPost.data.Precio,
                    Moneda: resP.resPost.data.Moneda,
                    idPrecio: resP.resPost.data.id,
                }
                // materialesCombinados.push({...resPost.data,MaterialID:resPost.data.Material.id,Material:{...resPost.data.Material,Material:{...MA}},...resP.resPost.data,idPrecio:resP.resPost.data.id})

                console.log("DATTTTTT", dat);
                console.log([...user?.materiales, dat]);
                dispatch(materialesAction([...user?.materiales, dat]))
                Swal.fire({
                    position: 'center', icon: 'success', title: '¡Perfecto!',
                    text: 'El material se ha almacenado correctamente.', showConfirmButton: false,
                    timer: 1500
                })
                setLoading(false)
                setValue('1')
            } else {
                Swal.fire({
                    position: 'center', icon: 'error', title: '¡Vaya! Algo salió mal...',
                    text: 'Por favor, inténtalo de nuevo.', showConfirmButton: false, timer: 1500
                })
                setValue('1')
                setLoading(false)
            }

        } else if (ok) {
            let ma = (user?.materiales && user?.materiales.length > 0) ? [...user?.materiales, resPost.data] : [resPost.data]
            dispatch(materialesAction(ma))

            Swal.fire({
                position: 'center', icon: 'success', title: '¡Perfecto!', text: 'El material se ha almacenado correctamente.',
                showConfirmButton: false, timer: 1500
            })
            setLoading(false)
            setValue('1')
        } else { Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos', showConfirmButton: false, timer: 1500 }); setValue('1'); setLoading(false) }
    } else {
        Swal.fire({
            position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor, completa los campos',
            showConfirmButton: false, timer: 1500
        })
        setValue('1')
        setLoading(false)
    }





}

export const EditMatEmpresa = async (user, formState, materialEdit, setValue, setLoading, dispatch, idUnidadMedida, idUnidadPrecio, precio) => {

    console.log("ESTá editando el material <========");
    const hasCompraVentaPermission = user?.typeUser === "EMPRESA" ? (user?.Personal.Empresa?.Servicios && user?.Personal.Empresa?.Servicios.length > 0) ? user?.Personal.Empresa?.Servicios.some(e => e == "65246345637f1900140aa59c") : false : (user.PuntosBodega[0].PermisosBodegas && user.PuntosBodega[0].PermisosBodegas.length > 0) ? user.PuntosBodega[0].PermisosBodegas.some(e => e == "64679ade25f79000140e8f17") : false;
    if (hasCompraVentaPermission && precio == "0" || hasCompraVentaPermission && precio !== "0") {

        const { resPut, ok } = await PutAllWithAuth(`/calculadora-materiales/${materialEdit.MaterialID}`, user?.Personal?.uid, formState);
        console.log("resPut", resPut);

        let formi = {
            Material: resPut.id,
            Precio: Number(precio),
            Unidad_de_Precio: idUnidadPrecio ? idUnidadPrecio : 'kilogramo',
        }

        if (hasCompraVentaPermission) {
            if (ok) {
                const Put = await PutAllWithAuth(`/precios-materiales/${materialEdit.idPrecio}`, user?.Personal?.uid, formi);
                console.log("Put1", resPut);
                console.log("Put2", Put.resPut);
                let filtered = user?.materiales.filter(i => i.id !== materialEdit.id)
                let editing = user?.materiales.find(i => i.id == materialEdit.id)
                editing.Unidad_de_Peso = idUnidadMedida ? idUnidadMedida : 'kilogramo'
                editing.Precio = Number(precio)
                editing.Unidad_de_Precio = idUnidadPrecio ? idUnidadPrecio : idUnidadMedida || 'kilogramo'
                filtered.push(editing)
                console.log("Finded", user?.materiales.find(i => i.id == materialEdit.id))
                dispatch(materialesAction(filtered))
                Swal.fire({ position: 'center', icon: 'success', title: `El material ha sido actualizado`, showConfirmButton: false, timer: 1500 })
                setValue('1')
                setLoading(false)
            } else { Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos', showConfirmButton: false, timer: 1500 }); setValue('1'); setLoading(false) }
        } else if (ok) {
            let filtered = user?.materiales.filter(i => i.id !== materialEdit.id)
            let editing = user?.materiales.find(i => i.id == materialEdit.id)
            editing.Unidad_de_Peso = idUnidadMedida ? idUnidadMedida : 'kilogramo'
            filtered.push(editing)
            dispatch(materialesAction(filtered))
            Swal.fire({ position: 'center', icon: 'success', title: `El material ha sido actualizado`, showConfirmButton: false, timer: 1500 })
            setValue('1')
            setLoading(false)
        }
        else { Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos', showConfirmButton: false, timer: 1500 }); setValue('1'); setLoading(false) }
    }
    else { Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor, completa los campos', showConfirmButton: false, timer: 1500 }); setValue('1'); setLoading(false) }

}

// Solo puede manejar los materiales que maneja la empresa madre 
export const EditMate_Sede = async (user, formState, materialEdit, materialesRecuperables, setValue, setLoading, dispatch, idUnidadMedida, idUnidadPrecio, precio) => {

    let w
    const hasCompraVentaPermission = (user.PuntosBodega[0].PermisosBodegas && user.PuntosBodega[0].PermisosBodegas.length > 0) ? user.PuntosBodega[0].PermisosBodegas.some(e => e == "64679ade25f79000140e8f17") : false;
    let formi = {
        Precio: Number(precio),
        Unidad_de_Precio: idUnidadPrecio ? idUnidadPrecio : 'kilogramo',
    }
    console.log("QUE PASO?")
    console.log("hasCompraVentaPermission", hasCompraVentaPermission);
    // se llaman para filtrar el que se asocia a la planta
    const respGet = await UseGetWithAuth2(`/calculadora-materiales/${materialEdit.MaterialID}`, user?.Personal?.uid);
    console.log("respGet", respGet);

    if (hasCompraVentaPermission) {
        // se llaman para filtrar el que se asocia a la planta en la tabla de precios
        w = await UseGetWithAuth2(`precios-materiales/${materialEdit.idPrecio}`, user?.Personal?.uid);
        console.log("w", w);
    }
    console.log("respGet.ok", respGet.ok);
    if (respGet.ok) {
        console.log("respGet.resGet", respGet.resGet)
        if (respGet.resGet.Empresa) {
            console.log("CONTIENE EMPRESAAAAAAA")
            let PuntosSedes = respGet.resGet.PuntosBodegas.filter(E => E.id !== user?.PuntosBodega[0].id)
            PuntosSedes = PuntosSedes.map(e => e.id)
            // Elimina la pertencia de ese punto generador en la tabla de los materiales 
            const { resPut, ok } = await PutAllWithAuth(`/calculadora-materiales/${materialEdit.MaterialID}`, user?.Personal?.uid, { "PuntosBodegas": PuntosSedes })
            if (hasCompraVentaPermission) {
                if (ok) {
                    // Elimina la pertencia de ese punto generador en la tabla de  los precies de materiales 
                    const Put = await PutAllWithAuth(`/precios-materiales/${materialEdit.idPrecio}`, user?.Personal?.uid, { "PuntosBodegas": PuntosSedes })

                    let filtered = user?.materiales.filter(i => i.id !== materialEdit.id)
                    dispatch(materialesAction(filtered))
                    CreateMatEmpresa(user, formState, setValue, setLoading, dispatch, materialesRecuperables, idUnidadPrecio, precio)

                } else { Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos', showConfirmButton: false, timer: 1500 }); setValue('1'); setLoading(false) }
            } else if (ok) {
                CreateMatEmpresa(user, formState, setValue, setLoading, dispatch, materialesRecuperables, idUnidadPrecio, precio)
            } else { Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos', showConfirmButton: false, timer: 1500 }); setValue('1'); setLoading(false) }
        }
        if (!respGet.resGet.Empresa) {
            console.log("SE EDITA EL MATERIAL")
            EditMatEmpresa(user, formState, materialEdit, setValue, setLoading, dispatch, idUnidadMedida, idUnidadPrecio, precio)
        }
    } else { Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos', showConfirmButton: false, timer: 1500 }); setValue('1'); setLoading(false) }


    // if(respGet.ok){
    //     const resPost = await PostAllWithAuth(`/calculadora-materiales`, user?.Personal?.uid, formState);
    //         console.log(resPost.resPost)
    //         if(resPost.ok){
    //             formi.Material =resPost.resPost.data.id
    //             if( hasCompraVentaPermission ){

    //                 formi.PuntosBodegas=[user?.PuntosBodega[0].id]
    //                 formi.Unidad_de_Precio = idUnidadPrecio
    //                 const resP = await PostAllWithAuth(`/precios-materiales`, user?.Personal?.uid, formi);
    //                 if (resP.ok) {

    //                     const MA = materialesRecuperables.find(e=>e.id==resPost.resPost.data.Material.id)
    //                     console.log("MAAAAAAAAAAA",MA.Material);
    //                     const Material2= resPost.resPost.data
    //                     Material2.Material.Material=MA.Material
    //                     const mate3 = Material2
    //                     console.log("MAAAAAAA==>2",mate3);
    //                     let dat ={
    //                         ...resPost.resPost.data,
    //                         MaterialID: resPost.resPost.data.Material.id,
    //                         id: resPost.data.id,
    //                         Unidad_de_Precio:resP.resPost.data.Unidad_de_Precio,
    //                         Unidad_de_Peso:resPost.resPost.data.Unidad_de_Peso,
    //                         Precio:resP.resPost.data.Precio,
    //                         Moneda:resP.resPost.data.Moneda,
    //                         idPrecio: resP.resPost.data.id,
    //                     }
    //                     console.log("DATTTTTT",dat);
    //                     console.log([...user?.materiales,dat]);
    //                     dispatch(materialesAction([...user?.materiales,dat]))
    //                     Swal.fire({
    //                         position: 'center', icon: 'success', title: '¡Perfecto!', 
    //                         text: 'El material se ha almacenado correctamente.', showConfirmButton: false,
    //                         timer: 1500
    //                     })

    //                     setValue('1')
    //                 }
    //             }
    //         }    
    // }

}

export const eliminateMat_Empresa = async (user, dispatch, e, setCalculadoraMateriales, setLoading) => {
    console.log("ESTA ELIMINANDO");
    // =================> Guarda en variable si tiene permiso de comprar <=================
    const hasCompraVentaPermission = user?.typeUser === "EMPRESA" ? (user?.Personal.Empresa?.Servicios && user?.Personal.Empresa?.Servicios.length > 0) ? user?.Personal.Empresa?.Servicios.some(e => e == "65246345637f1900140aa59c") : false : (user.PuntosBodega[0].PermisosBodegas && user.PuntosBodega[0].PermisosBodegas.length > 0) ? user.PuntosBodega[0].PermisosBodegas.some(e => e == "64679ade25f79000140e8f17") : false;

    const t = await DeleteAllWithAuth(`calculadora-materiales/${e.id}`, user?.Personal?.uid);
    console.log('====================================');
    console.log(t.ok);
    console.log('====================================');
    if (t.ok) {
        if (hasCompraVentaPermission) {
            const w = await DeleteAllWithAuth(`precios-materiales/${e.idPrecio}`, user?.Personal?.uid);
            dispatch(materialesAction(user?.materiales.filter(u => u.id != e.id)))
            setCalculadoraMateriales(user?.materiales.filter(u => u.id != e.id))
            Swal.fire({
                position: 'center', icon: 'success', title: `El material ha sido eliminado`,
                showConfirmButton: false,
                timer: 2500
            })
            setLoading(false)
        } else {
            dispatch(materialesAction(user?.materiales.filter(u => u.id != e.id)))
            setCalculadoraMateriales(user?.materiales.filter(u => u.id != e.id))
            Swal.fire({
                position: 'center', icon: 'success', title: `El material ha sido eliminado`,
                showConfirmButton: false,
                timer: 2500
            })
            setLoading(false)
        }
    } else {
        Swal.fire({
            position: 'center', icon: 'error', title: `El material no ha sido eliminado`,
            showConfirmButton: false,
            timer: 2500
        })
        setLoading(false)
    }
}

export const EliminateMat_sede = async (user, dispatch, e, setCalculadoraMateriales, setLoading) => {
    const hasCompraVentaPermission = (user.PuntosBodega[0].PermisosBodegas && user.PuntosBodega[0].PermisosBodegas.length > 0) ? user.PuntosBodega[0].PermisosBodegas.some(e => e == "64679ade25f79000140e8f17") : false;

    const respGet = await UseGetWithAuth2(`/calculadora-materiales/${e.MaterialID}`, user?.Personal?.uid);
    console.log("respGet", respGet);


    if (respGet.resGet.Empresa) {
        console.log("CONTIENE EMPRESAAAAAAA")
        let PuntosSedes = respGet.resGet.PuntosBodegas.filter(E => E.id !== user?.PuntosBodega[0].id)
        PuntosSedes = PuntosSedes.map(e => e.id)
        // Elimina la pertencia de ese punto generador en la tabla de los materiales 
        const { resPut, ok } = await PutAllWithAuth(`/calculadora-materiales/${e.MaterialID}`, user?.Personal?.uid, { "PuntosBodegas": PuntosSedes })
        if (hasCompraVentaPermission) {
            if (ok) {
                // Elimina la pertencia de ese punto generador en la tabla de  los precies de materiales 
                const Put = await PutAllWithAuth(`/precios-materiales/${e.idPrecio}`, user?.Personal?.uid, { "PuntosBodegas": PuntosSedes })
                console.log("Saliendo de pertenecia");
                let filtered = user?.materiales.filter(i => i.id !== e.id)
                dispatch(materialesAction(filtered))
                setCalculadoraMateriales(user?.materiales.filter(u => u.id != e.id))
                Swal.fire({
                    position: 'center', icon: 'success', title: `El material ha sido eliminado`,
                    showConfirmButton: false,
                    timer: 2500
                })
                setLoading(false)
            } else {
                Swal.fire({
                    position: 'center', icon: 'error', title: `El material no ha sido eliminado`,
                    showConfirmButton: false,
                    timer: 2500
                })
                setLoading(false)
            }
        } else if (ok) {
            let filtered = user?.materiales.filter(i => i.id !== e.id)
            dispatch(materialesAction(filtered))
            setCalculadoraMateriales(user?.materiales.filter(u => u.id != e.id))
            Swal.fire({
                position: 'center', icon: 'success', title: `El material ha sido eliminado`,
                showConfirmButton: false,
                timer: 2500
            })
            setLoading(false)
        } else {
            Swal.fire({
                position: 'center', icon: 'error', title: `El material no ha sido eliminado`,
                showConfirmButton: false,
                timer: 2500
            })
            setLoading(false)
        }
    }
    if (!respGet.resGet.Empresa) {
        console.log("ENtro a eliminar");
        eliminateMat_Empresa(user, dispatch, e, setCalculadoraMateriales, setLoading)
    }
}