import { Box, Button, Modal, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React from 'react'
const { v4: uuidv4 } = require('uuid');

export const ModalDetailRuta = ({ e }) => {
    const [seeModal, setSeeModal] = React.useState(false);

    const handleClose = () => {
        setSeeModal(false)
    };

    const showModal = (array) => {
        // setSeeRutas(array)
        setSeeModal(true)
    }

    const style = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        pb: 8,
        borderRadius: 2
    };
    return (
        <>

            <span onClick={() => showModal(true)} >
                {e.length}
            </span>
            <Modal
                open={seeModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h5>Locaciones</h5>
                    <TableContainer sx={{ minHeight: 440, maxHeight: 440 }}>
                        <Table aria-label="sticky table">
                            <TableHead>
                                <TableRow hover tabIndex={-1} key={uuidv4()}>
                                    <TableCell align={"center"}>
                                        <b>Locación</b>
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <b>Dirección</b>
                                    </TableCell>
                                    {/* <TableCell align={"center"}>
                                Dirección
                            </TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {e.map((item) => (
                                    <TableRow hover tabIndex={-1} key={uuidv4()}>
                                        <TableCell align={"center"}>
                                            {item.Nombre}
                                            {/* {console.log(e.Nombre)} */}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {item.Direccion}
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button className='mt-2' variant='contained' color="error" onClick={handleClose}>Cerrar</Button>
                    {/* 
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={e && e.length}
                // rowsPerPage={rowsPerPage}
                // page={page}
                // onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
                </Box>
            </Modal>
        </>
    )
}
