import { types } from "../types/userType"


export const generadoresAction = (data) => {
    return {
        type: types._generadores,
        payload: data
    }
}

export const empresasGeneradoresAction = (data) => {
    return {
        type: types._empresasGeneradores,
        payload: data
    }
}

export const rutasAction = (data) => {
    return {
        type: types._rutas,
        payload: data
    }
}

export const reportesAction = (data) => {
    return {
        type: types._reportes,
        payload: data
    }
}

export const bodegaAction = (data) => {
    return {
        type: types._bodegas,
        payload: data
    }
}

export const puntosBodegasAction = (data) => {
    return {
        type: types._puntosBodegas,
        payload: data
    }
}

export const personalAction = (data) => {
    return {
        type: types._personal,
        payload: data.Personal
    }
}

export const materialesAction = (data) => {
    return {
        type: types._materiales,
        payload: data
    }
}

export const bolsasAction = (data) => {
    return {
        type: types._bolsas,
        payload: data
    }
}

export const solicitudRecoleccionsAction = (data) => {
    return {
        type: types._solicitudRecoleccions,
        payload: data
    }
}

export const empleadosAction = (data) => {
    return {
        type: types._empleados,
        payload: data
    }
}

export const empresaAction = (data) => {
    return {
        type: types._empresa,
        payload: data
    }
}

export const PermisosPunAction = (data) => {
    return {
        type: types._PermisosPunGeneradores,
        payload: data
    }
}

export const PermisosGestoresAction = (data) => {
    return {
        type: types._PermisosGestores,
        payload: data
    }
}

export const DescartablesAction = (data) => {
    return {
        type: types._materialesDescartables,
        payload: data
    }
}

export const DescartablesPropiosAction = (data) => {
    return {
        type: types._materialesDescartablesPropios,
        payload: data
    }
}

export const PqrsAction = (data) => {
    return {
        type: types._Pqrs,
        payload: data
    }
}