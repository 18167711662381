import axios from 'axios'

export const basePromisesAuth = async (token) => {
    const baseAuth = axios.create({
      baseURL: `https://hiloapi.herokuapp.com`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
  
    return {
      baseAuth
    }
  }
  
  
  export const basePromises = async () => {
    const base = axios.create({
      baseURL: `https://hiloapi.herokuapp.com`,
      headers: { 'Content-Type': 'application/json' },
    })
    return {
      base
    }
  }