import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Form } from 'react-bootstrap';
import { useForm } from '../../../hooks/useForm';
import { PostAllWithAuth, PutAllWithAuth, UseGetWithAuth2 } from '../../../service/base2';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { SplitingInitialsName, encontrarValores, generateSerial } from '../../../helpers/Utils';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import QRCode from 'qrcode.react';
import { fileUploadQR } from '../../../helpers/FileUpload';
import { puntosBodegasAction } from '../../../service/redux/action/DataActions';
import FormLoadings from '../../../helpers/FormLoadings';
const {_Monedas} = require('../../../helpers/Monedas.json')

export const NewSede = ({ setType, setSedeEdit, sedeEdit, BodegaById: bodegaById }) => {
  const { empresas } = useSelector(state => state.empresa);
  const user = useSelector(state => state.user);
  let id_empresa = empresas[0]?.typeUser === 'EMPRESA' ? user?.Personal?.Empresa?.id : user?.Personal?.Empresa?.id;
  let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color: user?.Personal?.Empresa?.color
  const [loading, setLoading] = useState(false)
  const qrRef = useRef(null)
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const objetoRecibidoByparams = queryString.parse(location.search)
  const sede = objetoRecibidoByparams.id? user?.PuntosBodega.find(e=>e.id==objetoRecibidoByparams.id):false
  const [show, setShow] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [coordinates, setCoordinates] = useState(sede?{lat:sede.Latitud,lng:sede.Longitud}:{ lat:4, lng:-72});
  const [visible, setVisible] = useState(false)
  const [locaciones, setLocaciones] = useState();
  const [Descartables,setDescartables]=useState([])
  const [permisosB,setPermisosB]=useState([])
  const [address, setAddress] = useState(sede?sede.Direccion:" ");
  const [idDescartable, setIdDescartable] = useState([]);
  const [idMaterial, setIdMaterial] = React.useState([]);
  const [idPermiso, setIdPermiso] = useState([]);
  const [materialesBodegas, setMaterialesBodegas] = useState([]);
  const [arrayIdMateriales, setArrayIdMateriales] = useState([])
  const [isChecked, setIsChecked] = useState(false);
  const [listNameMateriales, setListNameMateriales] = useState([]);
  const [listNameDescartables, setListNameDescartable] = useState([]);
  const [listpermisos, setLispermisos] = useState([]);
  const [puntosBodega, setPuntosBodega] = useState([])
  const [data,setData]=useState(undefined)
  const [ myBlob,setmyBlob]=useState()

    let canvas
    


  const { Nombre, Direccion, onInputChange, Bodega, Qr, Latitud, Longitud, calculadoraMateriales, formState } = useForm({
    Bodega: objetoRecibidoByparams.bodegaById,
    Nombre: sede ? sede?.Nombre : '',
    Direccion: sede ? sede?.Direccion : '',
    Latitud: sede ? sede?.Latitud : '0',
    Longitud: sede? sede?.Longitud : '0',
    calculadoraMateriales: sede? sede.calculadoraMateriales : '',

  });


  const onNavigateBack = () => { navigate(-1)};

  const handleChangeSelectDescarte = (event) => {
      const { target: { value }, } = event;
      setIdDescartable(typeof value === 'string' ? value.split(',') : value,);
  };

  const getMaterialesBodega = async () => {

    const mate = await UseGetWithAuth2("/materiales-descartables", user?.Personal?.uid)
    const Per = await UseGetWithAuth2("/permisos-bodegas", user?.Personal?.uid)
    setMaterialesBodegas(user?.materiales)

    if(mate.ok){ setDescartables(mate.resGet)}

    if(Per.ok){
        let permi = Per.resGet
        if(user?.Personal?.Empresa?.Servicios.some(e=>e=="65246345637f1900140aa59c")==false){ 
          let filter = permi.filter(i=>i.id !="64679ade25f79000140e8f17") 
              filter = filter.filter(i=>i.id !="646798ee25f79000140e8f14") 
          permi=filter
          
      }
      if(user?.Personal?.Empresa?.Servicios.some(e=>e=="64c8526feb605c0014653074")==false){ 
          let filter = permi.filter(i=>i.id !="64c852c8eb605c0014653077") 
               filter = filter.filter(i=>i.id !="64c85298eb605c0014653076") 
         permi=filter
         
      }
      if(user?.Personal?.Empresa?.Servicios.some(e=>e=="64c8527eeb605c0014653075")==false){ 
          let filter = permi.filter(i=>i.id !="64c85298eb605c0014653076") 
         permi=filter  
      }
      if(user?.Personal?.Empresa?.Servicios.some(e=>e=="657a1dbf997b320014a3bcd4")==false){ 
          let filter = permi.filter(i=>i.id !="64679a5d25f79000140e8f16") 
         permi=filter
      }
      if(user?.Personal?.Empresa?.Servicios.some(e=>e=="657e83c0a63d6300141eec29")==false){ 
          let filter = permi.filter(i=>i.id !="64679c1325f79000140e8f1e") 
         permi=filter
      }
          setPermisosB(permi)
      }

    if (objetoRecibidoByparams?.id) {
        console.log('====================================');
        console.log(objetoRecibidoByparams);
        console.log('====================================');
        let puntobodega = user?.PuntosBodega.find(e=>e.id==objetoRecibidoByparams.id)
        const filter = user?.materiales.filter(e=> {
            if(puntobodega?.calculadoraMateriales.length>0 &&puntobodega?.calculadoraMateriales[0].Material){
                return puntobodega?.calculadoraMateriales.find(i=>i.Material==e.Material.id)!== undefined &&puntobodega?.calculadoraMateriales.find(i=>i.Material==e.Material.id)
            }
            if(puntobodega?.calculadoraMateriales.length>0&&puntobodega?.calculadoraMateriales[0].Material ==undefined){
                return puntobodega?.calculadoraMateriales.find(i=>i==e.Material.id)!== undefined &&puntobodega?.calculadoraMateriales.find(i=>i==e.Material.id)
            }
        })
        console.log("i.Material==",filter);
        setListNameMateriales(filter.map(e=>e.id))
        setIdMaterial(filter.map(e=>e.id))
        setLispermisos(puntobodega.PermisosBodegas.length>0?puntobodega.PermisosBodegas:[])
        setIdPermiso(puntobodega.PermisosBodegas.length>0?puntobodega.PermisosBodegas:[])
        setListNameDescartable(puntobodega.MaterialesDescartables?puntobodega.MaterialesDescartables.map(e=>e.id):[])
        setIdDescartable(puntobodega.MaterialesDescartables?puntobodega.MaterialesDescartables.map(e=>e.id):[])
        // setMaterialesBodegas(resGet)
    } 

  };

  

  const handleChangeSelectMaterial = (event) => {
    const { target: { value }, } = event;
    setIdMaterial(typeof value === 'string' ? value.split(',') : value,);
  };

  const handleChangeSelectPermiso = (event) => {
    const { target: { value }, } = event;
    setIdPermiso(typeof value === 'string' ? value.split(',') : value,);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    formState.calculadoraMateriales = idMaterial.length !== 0 ? idMaterial : arrayIdMateriales
    formState.Latitud= coordinates.lat.toString()
    formState.Longitud=coordinates.lng.toString()
    formState.MaterialesDescartables = idDescartable
    formState.PermisosBodegas= idPermiso
    if(idPermiso.some(i=>i=="64679ade25f79000140e8f17")){
      let PreciosMateriales = user?.materiales.filter(e=>formState.calculadoraMateriales.some(i=>i==e.id) )
      formState.precios_materiales = PreciosMateriales.map(e=>e.idPrecio)
      formState.Moneda = user?.bodegas.find(e=>e.id==objetoRecibidoByparams.bodegaById).Monedas.id||""
    }
    console.log("formState",formState);
    
    if(myBlob!==undefined){
        const r = await fileUploadQR(myBlob,formState.Nombre)   
        const _Qrs = await PostAllWithAuth(`/qrs`, user?.Personal?.uid, {"Img":r,"Huella":data.split("/")[data.split("/").length - 1],
                            "Bodegas":objetoRecibidoByparams.bodegaById});
        if (_Qrs.ok) {
            formState.Qr= _Qrs.resPost?.data.id
            const { ok, resPost } = await  PostAllWithAuth(`/puntos-bodegas`, user?.Personal?.uid, formState);
            
            const Puntobode =user?.PuntosBodega
            Puntobode.push({...resPost.data,empleados:[],Bodega:formState.Bodega,PermisosBodegas:resPost.data.PermisosBodegas.map(e=>e.id)})
            dispatch(puntosBodegasAction(Puntobode))           

            if(ok){
              setLoading(false)
              Swal.fire({ position: 'center', icon: 'success', title: `La sede ${formState.Nombre} ha sido agregado`,
                  showConfirmButton: false,timer: 1500})
            }else {
              setLoading(false)
              Swal.fire({position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                showConfirmButton: false,timer: 1500})
            }

        } else {
          setLoading(false)
          Swal.fire({
            position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
            showConfirmButton: false,
            timer: 1500
          })
        }
    }else {
      setLoading(false)
      Swal.fire({
        position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
        showConfirmButton: false,
        timer: 1500
      })
    }
     onNavigateBack();
  }

  const EditSede = async (e) => {
    e.preventDefault()
    setLoading(true)
    formState.calculadoraMateriales = idMaterial.length !== 0 ? idMaterial : arrayIdMateriales
    formState.Latitud= coordinates.lat.toString()
    formState.Longitud=coordinates.lng.toString()
    formState.Moneda ="64c85ab91dbf8c00144b45e4"
    formState.MaterialesDescartables = idDescartable
    formState.PermisosBodegas= idPermiso
    if(idPermiso.some(i=>i=="64679ade25f79000140e8f17")){
      let PreciosMateriales = user?.materiales.filter(e=>formState.calculadoraMateriales.some(i=>i==e.id) )
      formState.precios_materiales = PreciosMateriales.map(e=>e.idPrecio)
    }
    console.log("formState",formState);
    const { resPut, ok } = await PutAllWithAuth(`/puntos-bodegas/${sede.id}`, user?.Personal?.uid, formState);
    const Puntobode =user?.PuntosBodega.filter(e=>e.id!==objetoRecibidoByparams.id)
        if(resPut.Personal && resPut.Personal.length>0){
          let empleados = await UseGetWithAuth2(`/personals?_where[PuntosBodega]=${sede.id}`, user?.Personal?.uid)
          console.log('====================================');
          console.log(empleados);
          console.log('====================================');
          Puntobode.push({...resPut,empleados:empleados.resGet,Bodega:formState.Bodega,PermisosBodegas:resPut.PermisosBodegas.map(e=>e.id)})
          dispatch(puntosBodegasAction(Puntobode))
        }else{
          Puntobode.push({...resPut,empleados:[],Bodega:formState.Bodega,PermisosBodegas:resPut.PermisosBodegas.map(e=>e.id)})
          dispatch(puntosBodegasAction(Puntobode))
        }
        
   
    if (ok) {
      setLoading(false)
      Swal.fire({
        position: 'center', icon: 'success', title: `La sede ${formState.Nombre} ha sido Actializado`,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(false)
      Swal.fire({
        position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
        showConfirmButton: false,
        timer: 1500
      })
    }
    onNavigateBack();
  };

  const encontrarValoresName = (arrayNombres) => {
    const resultados = [];
    for (let i = 0; i < arrayNombres.length; i++) {
      if (true) {
        resultados.push(arrayNombres[i].Material.Material);
      }
    }
    return resultados;
  };

  useEffect(() => {
    getMaterialesBodega()
    setArrayIdMateriales(materialesBodegas)
  }, []);
console.log("arrayIdMateriales",arrayIdMateriales);
  useEffect(() => {
    if (objetoRecibidoByparams.Nombre) {
      setListNameMateriales(materialesBodegas)
      setIdMaterial(materialesBodegas)
    }

  }, [materialesBodegas]);

  const handleAddressChange = async (e,newInputValue) => {
    setAddress(newInputValue);
    formState.Direccion = newInputValue
    setShow(true);
      if (window.google && newInputValue.length > 4) {
        let service;
          service = new window.google.maps.places.AutocompleteService();
          service.getPlacePredictions({ "input":newInputValue,"language": 'es' }, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setPredictions(predictions);
            
              OnhandleAddressChange(e,newInputValue)
            }
          });
      }
  }

  const OnhandleAddressChange = (event, value) => { 
    if (value) {
      setAddress(value);
      formState.Direccion = value
      
      // Encuentra el place_id de la predicción seleccionada
      const selectedPrediction = predictions.find((prediction) => prediction.description === value);
      if (selectedPrediction) {
        const placeId = selectedPrediction.place_id;

        // Ahora, utiliza el PlacesService para obtener los detalles del lugar
        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails({ placeId }, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            // Aquí tendrás los detalles del lugar, incluyendo latitud y longitud
            const { lat, lng } = place.geometry.location.toJSON();
            setCoordinates({ lat, lng });
            formState.Latitud =lat.toString()
            formState.Longitud=lng.toString()
          }
        });
      }
    }
  }
  useEffect(() => {
    if(objetoRecibidoByparams.id == undefined && objetoRecibidoByparams.bodegaById){
        const bode = user?.bodegas.find(i=>i.id==objetoRecibidoByparams.bodegaById)
        data == undefined&&setData( generateSerial(SplitingInitialsName(bode.Nombre).toUpperCase()))
      console.log("serial", generateSerial(SplitingInitialsName(bode.Nombre).toUpperCase()));
    if (qrRef.current ) {
      canvas = qrRef.current.querySelector('canvas');
      if (canvas) {
          canvas.toBlob(async(blob) => {
            setmyBlob(blob);    
            console.log('====================================');
            console.log(blob);
            console.log('====================================');
        });
      }
    }
    }
  }, [qrRef.current]);

  return (
    <>{ loading&&
      <FormLoadings/>}
      <>
        <nav className='d-flex gap-3 justify-content-between p-2  mb-2 '
          style={empresas ? { backgroundColor: user?.colors[1], fontWeight: "bold",borderRadius:20 } : { color: "#666", fontWeight: "bold",borderRadius:20 }}>
          <div className='ml-5'>
            {/* <h5 style={{ color: "white" }}>{bodegaEdit.Nombre ? 'Actualizar datos' : 'Nueva bodega'}</h5> */}
            <h5 style={{ color: "white",marginLeft:10 }}>{ sede?sede.Nombre:"Nueva Sede"} </h5>
          </div>
        </nav>
        <div className='d-flex justify-content-end'>
          <Button variant="contained"  startIcon={<ReplyAllIcon />} style={{ cursor: "pointer", backgroundColor: `${dataColor}`,borderRadius:20 }}
            onClick={onNavigateBack}
          >
            Atras
          </Button>
        </div>

        <main className='mt-3 card'>
          <Form className='w-100 row m-4' onSubmit={objetoRecibidoByparams.id ? EditSede : handleSubmit}>
            <div className="row">
              <div className="col-6 mb-3 ">
                <TextField
                  label="Nombre sede bodega"
                  type="text"
                  placeholder="Nombre sede bodega"
                  fullWidth
                  required
                  name="Nombre"
                  value={Nombre}
                  onChange={onInputChange}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-6 " style={{}}>
                  <div  className="row">
                      <div  className="col-12">
                        <TextField
                          id='address'
                          type="text" 
                          value={address} 
                          label="Dirección"
                          required
                          placeholder={objetoRecibidoByparams?.Direccion?objetoRecibidoByparams?.Direccion:"Dirección"}
                          fullWidth
                          onChange={(e)=>{handleAddressChange(e,e.target.value)}}
                          // onBlur={(e)=>{handleAddressChange(e,e.target.value); setShow(false)}}
                          
                        />
                      </div>
                      <div  className="col-12" style={{zIndex:20,backgroundColor:"white"}}>
                        {predictions.length > 0 && show&& (
                            <div style={{ border: '1px solid #ccc', width: '300px', position: 'absolute',zIndex:20,backgroundColor:"white" }}>
                              {predictions.map((prediction) => (
                                <div key={prediction.place_id} onClick={() => {OnhandleAddressChange(prediction,prediction.description);setShow(false);setAddress(prediction.description)}} style={{ padding: '10px', cursor: 'pointer' }}>
                                  {prediction.description}
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                  </div>
              </div>
            </div>

            <div className="row">
                {/* ================ Materiales ================ */}
                <div className='col-12 col-md-4 col-lg-4 mb-3'>
                  <Box sx={{ minWidth: 120, maxWidth: 600 }}>
                      <FormControl fullWidth>
                          <InputLabel id="label-punto">Materiales</InputLabel>
                          <Select
                              multiple
                              displayEmpty
                              labelId="label-punto"
                              value={idMaterial}
                              required
                              onChange={handleChangeSelectMaterial}
                              input={<OutlinedInput label='Materiales' />}
                              renderValue={(selected) => {
                                  if (objetoRecibidoByparams?.Nombre?.length > 0) {
                                      return listNameMateriales.join(', ');
                                  } else {
                                      return selected.join(', ');
                                  }
                              }}
                              inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem disabled value="">
                              <em>{materialesBodegas?.length > 0 ? 'Materiales:' : 'No tiene Materiales'}</em>
                            </MenuItem>
                            {materialesBodegas.filter(e=>{ 
                                  // Filtra solo los elementos con Moneda y que cumplen la condición adicional
                                      if (e.Moneda && _Monedas.some(i => i.moneda === e.Moneda && i.id === user?.bodegas.find(e=>e.id==objetoRecibidoByparams.bodegaById).Monedas.id)) {
                                          return true;
                                      }
                                      return !e.Moneda;
                                  })
                                .map((materialBodega) => (
                              <MenuItem
                                key={materialBodega.id}
                                value={materialBodega.id}
                              // style={getStyles(name, personName, theme)}
                              >
                                <Checkbox checked={idMaterial.indexOf(materialBodega.id) > -1} />
                                {materialBodega.Material.Material}
                              </MenuItem>
                            ))}
                          </Select>
                      </FormControl>
                  </Box>
                </div>
                {/* ================ Descartables ================ */}
                <div className='col-12 col-md-4 col-lg-4 mb-3'>
                    <Box sx={{ minWidth: 120, maxWidth: 600 }}>
                        <FormControl fullWidth>
                            <InputLabel id="label-punto">Descartables</InputLabel>
                            <Select
                                multiple
                                displayEmpty
                                labelId="label-punto"
                                value={idDescartable}
                                required
                                onChange={handleChangeSelectDescarte}
                                input={<OutlinedInput label='Descartables' />}
                                renderValue={(selected) => {
                                    if (sede) {
                                        return listNameDescartables?.join(', ');
                                    } else {
                                        return selected.join(', ');
                                    }
                                }}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem disabled value="">
                                    <em>{Descartables?.length > 0 ? 'Descartables:' : 'No tiene descartables'}</em>
                                </MenuItem>
                                {Descartables?.map((materialBodega) => (
                                    <MenuItem
                                        key={materialBodega.id}
                                        value={materialBodega.id}
                                    // style={getStyles(name, personName, theme)}
                                    >
                                        <Checkbox checked={idDescartable.indexOf(materialBodega.id) > -1} />
                                        {materialBodega.Material}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                {/* ================ Permisos ================ */}
                <div className='col-12 col-md-4 col-lg-4 mb-3'>
                    <Box sx={{ minWidth: 120, maxWidth: 600 }}>
                        <FormControl fullWidth>
                            <InputLabel id="label-punto">Permisos</InputLabel>
                            <Select
                                multiple
                                displayEmpty
                                labelId="label-punto"
                                required
                                value={idPermiso}
                                onChange={handleChangeSelectPermiso}
                                input={<OutlinedInput label='Materiales recuperables' />}
                                renderValue={(selected) => {
                                    if (objetoRecibidoByparams?.id) {
                                        return listpermisos.join(', ');
                                    } else {
                                        return selected.join(', ');
                                    }
                                }}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem disabled value="">
                                    <em>{permisosB?.length > 0 ? 'Permisos:' : 'No tiene Permisos'}</em>
                                </MenuItem>
                                {permisosB.map((e) => (
                                    <MenuItem
                                        key={e.id}
                                        value={e.id}
                                    // style={getStyles(name, personName, theme)}
                                    >
                                        <Checkbox checked={idPermiso.indexOf(e.id) > -1} />
                                        {e.Permiso}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
            </div>
            <div className='d-flex justify-content-around align-items-center'>
              
              {!objetoRecibidoByparams.id &&
                  <div style={{  }} ref={qrRef}>
                    <QRCode value={data?data:""}   id='canvas'/>
                  </div>
              }
              <Button type='submit'  variant='contained'
                className='btn  w-25' style={{background:user?.colors[1],height:40}}>
                {objetoRecibidoByparams.Nombre ? 'Actualizar' : 'Guardar'}
              </Button>
            </div>
          </Form>
        </main>

      </>
    </>
  )
}
