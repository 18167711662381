
export function actualizarPermisos(permisos) {
    // Constantes para los permisos de Aplicativo logistico
    const permisosEspeciales = [
        '6482646872ac35001402edab', '64c8c4db63f2710014b0140d', '6493453216243500149ca649',
        '64678a3725f79000140e8f03', '6467898125f79000140e8f00', '646789eb25f79000140e8f01',
        '64c85428eb605c0014653078', '64c8bdc463f2710014b0140c', '64678b7225f79000140e8f07',
        '64678a7225f79000140e8f04'
    ];

    const permisoExcluido = '64d18e761d0f490014d782bf';

    //  validacíon de entrada a la app
    if (permisos.some(permiso => permisosEspeciales.includes(permiso)) && !permisos.includes(permisoExcluido)) {
        permisos.push(permisoExcluido);
    }

    // Validación de permisos necesarios para la compra
    if (permisos.includes('64678a3725f79000140e8f03')) {
        if (!permisos.includes('64c8bdc463f2710014b0140c')) {
            permisos.push('64c8bdc463f2710014b0140c');
        }
        if (!permisos.includes('64678b7225f79000140e8f07')) {
            permisos.push('64678b7225f79000140e8f07');
        }
    }

    // Validación de permisos necesarios para el manejo de rutas
    if (permisos.includes('64c8c4db63f2710014b0140d') || permisos.includes('6482646872ac35001402edab')) {
        if (!permisos.includes('6467898125f79000140e8f00')) {
            permisos.push('6467898125f79000140e8f00');
        }
    }

    // validación de administración 
    const permisosEspeciales2 = [
        '64678bbf25f79000140e8f08', '64678c7225f79000140e8f09', '64678e5c25f79000140e8f0a',
        '64678e7f25f79000140e8f0b', '6467906125f79000140e8f0c', '6467916f25f79000140e8f0d',
        '6467919f25f79000140e8f0e', '646791b925f79000140e8f0f', '646791d525f79000140e8f10',
        '64d972f5d88151001445f19b', '64e994084e79a300146ba7a9', '651e062584c59100142d12b6',
        '651e065884c59100142d12b7', '652def00ff6fa00014a1aa7a', '652def1aff6fa00014a1aa7b',
        '6535ec35d13d2c00147fada2', '6535ec58d13d2c00147fada3', '6535ee33d13d2c00147fada4',
        '64ebcd8564981c00142f851e', '64ebcdab64981c00142f851f', '64ebcde564981c00142f8520',
        '64ebcdf864981c00142f8521'
    ];
    const permisoExcluido2 = '64678b5025f79000140e8f06';

    // Es administrador 
    if (permisos.some(permiso => permisosEspeciales2.includes(permiso)) && !permisos.includes(permisoExcluido2)) {
        permisos.push(permisoExcluido2);
    }

    return permisos;
}

// Ejemplo de uso
let permisosActuales = ['6482646872ac35001402edab', '64678a3725f79000140e8f03'];
console.log(actualizarPermisos(permisosActuales));
