import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Form, Tabs } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { PostAllWithAuth, PutAllWithAuth } from '../../../service/base2';
import { fileUploadPersonal, fileUploadQRUsuario, generarQREnBlob } from '../../../helpers/FileUpload';
import { useForm } from '../../../hooks/useForm';
import PermissionsCheck from '../../../hooks/PermissionsCheck';
import { RegisterUser } from './RegisterUser';
import { Tab } from 'bootstrap';
import { puntosBodegasAction } from '../../../service/redux/action/DataActions';
import { actualizarPermisos } from './Detalles/functionsPerisos';
import code from '../../../helpers/Codephones.json'
import FormLoadings from '../../../helpers/FormLoadings';
import { separarCodigoYTelefono } from '../../../helpers/Utils';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,

        },
    },
};

export const NewPersonalBodega = () => {
    const { empresas } = useSelector(state => state.empresa);
    const user = useSelector(state => state.user);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const dataColor = user?.typeUser === 'EMPRESA' ? "#" + user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color
    let objetoRecibidoByparams = new URLSearchParams(location.search);
    objetoRecibidoByparams = queryString.parse(location.search);
    const sede = objetoRecibidoByparams.id? user?.PuntosBodega.find(e=>e.empleados.some(i=>i.id==objetoRecibidoByparams.id)).empleados.find(i=>i.id==objetoRecibidoByparams.id):user.PuntosBodega.find(e=>e.id == objetoRecibidoByparams.item)
    const [file, setFile] = useState([]);
    const [newUser, setNewUser] = useState([])
    const [permisos, setPermisos] = useState(false);
    const [PuntosFlated, setPuntosFlated] = useState([])
    const [idUser,setIdUser]=useState("")
    const [activeTab, setActiveTab] = useState('PermisoApp');
    const [showFile,setShoFile]=useState(true)

    const [Code,setCode]=useState(objetoRecibidoByparams.id? !separarCodigoYTelefono(sede.Telefono).error?separarCodigoYTelefono(sede.Telefono).codigo: "+57": "+57")
    console.log('========user===========');
    console.log(user);
    console.log('====================================');
    let idBodega=user?.typeUser === 'EMPRESA' ?objetoRecibidoByparams.id?sede.PuntosBodega.Bodega:sede.Bodega:objetoRecibidoByparams.id?sede.PuntosBodega.Bodega:sede.Bodega.id
  console.log("objetoRecibidoByparams",objetoRecibidoByparams);

    const handleTabChange = (key) => {
         setActiveTab(key)
        console.log('====================================');
        console.log("key",key);
        console.log('===================================='); 
    };
    console.log('====================================');
    console.log(code);
    console.log('====================================');
    
     useEffect(() => {
        if(objetoRecibidoByparams?.id && !permisos){
            let  permisoPersona =objetoRecibidoByparams?.Permisos?.split(",") 
                permisoPersona =user?.PuntosBodega?.map(e=>{return e.empleados?.find(i=>i.id==objetoRecibidoByparams?.id) !==undefined&&e.empleados?.find(i=>i.id==objetoRecibidoByparams?.id)}).filter(i=>i!==undefined&&i!==false)
            //  permisoPersona =user.Permisos.filter(e=>permisoPersona[0].Permisos.includes(e.id));
                setPermisos(permisoPersona[0].Permisos)
                setIdUser(permisoPersona[0].user.id)
        }else{
            let flated = user.PuntosBodega.flatMap(e => e)
            flated = flated.filter(e => e.Bodega === objetoRecibidoByparams?.item)
            setPuntosFlated(flated)
        }
     }, [sede]);
    
    // const [objetoRecibidoByparams, setPersonalById] = useState({})
    const [permisosSeleccionados, setPermisosSeleccionados] = useState([]);
    const [permisosSelecc, setPermisosSelecc] = useState([]);
    const [permisosSeleccionadosAdmin, setPermisosSeleccionadosAdmin] = useState([]);
 /// =====================> Corregir la seleccion de permisos cuando se edita el personal
    const handlePermisosChange = (selected) => { 
        setPermisosSeleccionados([selected])
        console.log('====== selected ======');
        console.log(selected);
        console.log('====== selected ======');
        const array= permisosSeleccionados.concat(permisosSeleccionadosAdmin).flat();
        console.log('====== array ======');
        console.log(array);
        console.log('====== array ======');
        setPermisosSelecc(array) 
    };
    const handlePermisosChange2 = (selected) => { 
        setPermisosSeleccionadosAdmin([selected])
        const array= permisosSeleccionados.concat(permisosSeleccionadosAdmin).flat()
        setPermisosSelecc(array)
    };

    const onChangePer = (e) => {
        setPermisosSeleccionados(actualizarPermisos(e))
        console.log('====== permisosSeleccionados ======');
        console.log(actualizarPermisos(e));
        console.log('====== permisosSeleccionados ======');
    }


    const { Telefono, Cedula,  Imagen, formState, onInputChange, onResetForm } = useForm(
        {      
            Telefono: sede ? separarCodigoYTelefono(sede.Telefono).error?"":separarCodigoYTelefono(sede.Telefono).numero : '',
            Cedula: sede.Cedula ? sede.Cedula : '',
            Latitud: sede &&  sede.Latitud !== undefined ? sede.Latitud : '0',
            Longitud: sede && sede.Longitud !== undefined ? sede.Longitud : '0',
            PuntosBodega: objetoRecibidoByparams.item? objetoRecibidoByparams.item : sede.id,
        });

    const onNavigateBack = () => { navigate(-1)};

    const handleFileChanged = (e) => {setFile(e.target.files[0]);};

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShoFile(false)
        setLoading(true)
        if(!permisosSeleccionados.length >0 || !formState.PuntosBodega){
            setLoading(false)
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor completa los campos obligatorios',
                showConfirmButton: false,
                timer: 1500
            })
        }else{
            if (file?.name?.length > 0) {
                await fileUploadPersonal(file)
                .then(response => {
                    formState.Imagen = response
                })
            };
            const res = await PostAllWithAuth(`/users-permissions/auth/register-with-firebase/`, user?.Personal?.uid, newUser)
            let userCurrent = await res.resPost?.data?.user
            
            if (res.ok && res.resPost) {
                formState.user = userCurrent.id;
                formState.Permisos = permisosSelecc;
                formState.Telefono = Code+formState.Telefono.toString()
                console.log(formState);
                const { ok, resPost } = await PostAllWithAuth(`/personals/`, user?.Personal?.uid, formState);

                if (ok) {
                    if(permisosSelecc.some(e=>e=="64d18e761d0f490014d782bf")||permisosSelecc.some(e=>e=="6467895e25f79000140e8eff")){
                        // Uso de la función
                            const textoQR = 'Hola, mundo!';
                            generarQREnBlob(resPost.data.id).then(async(blob) => {
                                // Hacer algo con el blob, como descargarlo
                                const r = await fileUploadQRUsuario(blob);
                                console.log("r",r);
                                const _Qrs = await PostAllWithAuth(`/qrs`, user?.Personal?.uid, {"Img":r,"Huella":resPost.data.id});
                                if(_Qrs.ok){
                                    const updateuser = await PutAllWithAuth(`/personals/${resPost.data.id}`, user?.Personal?.uid,{Qr:_Qrs.resPost.data.id})
                                    if(updateuser.ok){
                                        const updateEmployes = user?.PuntosBodega.find(e=>e.id==formState.PuntosBodega)
                                        const employes = updateEmployes.empleados
                                            employes.push(updateuser.resPut)
                                        let UpdatePunto = user?.PuntosBodega.filter(e=>e.id!=formState.PuntosBodega)
                                            UpdatePunto.push({...updateEmployes,empleados:employes})
                                            console.log('====================================');
                                            console.log(UpdatePunto);
                                            console.log('====================================');
                                            dispatch(puntosBodegasAction(UpdatePunto))
                                            setLoading(false)
                                            Swal.fire({
                                                position: 'center', icon: 'success', title: `${newUser.username} ha sido agregado`,
                                                showConfirmButton: false,
                                                timer: 1500
                                            })
                                            onNavigateBack()
                                    }else{
                                        Swal.fire({
                                            position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                                            showConfirmButton: false,
                                            timer: 1500
                                        })
                                        setLoading(false)
                                        onNavigateBack()
                                    }
                                }else{
                                    Swal.fire({
                                        position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                    setLoading(false)
                                    onNavigateBack()
                                }
                            
                            });
        
                    }else{
                        const updateEmployes = user?.PuntosBodega.find(e=>e.id==formState.PuntosBodega)
                        const employes = updateEmployes.empleados
                              employes.push(resPost.data)
                        let UpdatePunto = user?.PuntosBodega.filter(e=>e.id!=formState.PuntosBodega)
                            UpdatePunto.push({...updateEmployes,empleados:employes})
                            dispatch(puntosBodegasAction(UpdatePunto))
                            setLoading(false)
                            Swal.fire({
                                position: 'center', icon: 'success', title: `${newUser.username} ha sido agregado`,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            onNavigateBack()
                    } 
                } else {
                    setLoading(false)
                    Swal.fire({
                        position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                        showConfirmButton: false,timer: 1500
                    })
                    onNavigateBack()
                }
            } else {
                Swal.fire({
                    position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                    showConfirmButton: false, timer: 1500
                })
                onNavigateBack()
            }

        }

    };
    

    const EditPersonal = async (e) => {
        e.preventDefault();
        setShoFile(false)
        setLoading(true)
        let formi={}
        if (file?.name?.length > 0) {
            await fileUploadPersonal(file)
                .then(response => {
                    formi.Imagen = response
                })
        };
            
        formi.Cedula = !formState.Cedula ? Number(sede?.Cedula):Number(formState.Cedula)
        formi.Telefono = !formState.Telefono ? sede?.Telefono:Code+formState.Telefono 
               
        let data = { ...formi, Permisos: [...new Set(permisosSeleccionados.length>0?permisosSeleccionados:sede.Permisos.map(e=>e.id))]};
        if(newUser.username !== idUser?.username){
            const data = await PutAllWithAuth(`/users/`+sede.user.id, user?.Personal?.uid, {"username":newUser.username})
        }
        
        const { ok,resPut } = await PutAllWithAuth(`/personals/${objetoRecibidoByparams?.id}`, user?.Personal?.uid, data);
        //=============> Here is where i need generate QR
        console.log(resPut);
        console.log(ok);
        if (ok) {
            
            const Punto = user?.PuntosBodega.filter(e=>e.id!=sede.PuntosBodega.id)
            const PuntoAc = user?.PuntosBodega.find(e=>e.id==sede.PuntosBodega.id)
            const empleados = PuntoAc.empleados.filter(e=>e.id!==resPut.id)
            console.log('====================================');
            console.log("permisosSelecc",data.Permisos);
            console.log('====================================');
            if((data.Permisos.some(e=>e=="64d18e761d0f490014d782bf")||data.Permisos.some(e=>e=="6467895e25f79000140e8eff")&& !resPut.Qr)){
                // Uso de la función
                    
                    generarQREnBlob(resPut.id).then(async(blob) => {
                        // Hacer algo con el blob, como descargarlo
                        const r = await fileUploadQRUsuario(blob);
                        console.log("r",r);
                        const _Qrs = await PostAllWithAuth(`/qrs`, user?.Personal?.uid, {"Img":r,"Huella":resPut.id});
                        console.log("r",_Qrs);
                        if(_Qrs.ok){
                            const updateuser = await PutAllWithAuth(`/personals/${resPut.id}`, user?.Personal?.uid,{Qr:_Qrs.resPost.data.id})
                            console.log(updateuser);
                            if(updateuser.ok){
                                if(newUser.username !== idUser?.username){ empleados.push({...resPut,user:{...resPut.user,username:newUser.username}})
                                }else{empleados.push(resPut)}

                                Punto.push({...PuntoAc,empleados:empleados})
                                dispatch(puntosBodegasAction(Punto))
                                Swal.fire({
                                    position: 'center', icon: 'success', title: `${resPut.user.username} ha sido Actializado`,
                                    showConfirmButton: false, timer: 1500
                                })
                                setLoading(false)
                                onNavigateBack()
                            }else{
                                Swal.fire({
                                    position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                setLoading(false)
                                onNavigateBack()
                            }
                        }else{
                            Swal.fire({
                                position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            setLoading(false)
                            onNavigateBack()
                        }
                    
                    });

            }else{   
                if(newUser.username !== idUser?.username){ empleados.push({...resPut,user:{...resPut.user,username:newUser.username}})
                }else{empleados.push(resPut)}
                Punto.push({...PuntoAc,empleados:empleados})
                dispatch(puntosBodegasAction(Punto))
                Swal.fire({
                    position: 'center', icon: 'success', title: `${resPut.user.username} ha sido Actializado`,
                    showConfirmButton: false, timer: 1500
                })
                setLoading(false)
                onNavigateBack()
            }
        } else {
            setLoading(false)
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                showConfirmButton: false,timer: 1500
            })
            onNavigateBack()
        }
        
        onNavigateBack()
    };
    
    const filterByBodega =(newValue)=>{
       
        if (!newValue) {
            formState.PuntosBodega = ""
        }else{
            const selectedObject = user?.PuntosBodega.find((bodega) => bodega.Nombre === newValue);
          
            formState.PuntosBodega = selectedObject.id;

         
        }

    }
 

    const handleSubmitUser = async () => {
        
        if(!formState.PuntosBodega){
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor selecciona una sede',
                showConfirmButton: false,
                timer: 1500
            })

        }else{
            // const { ok, resPost } = await PostAllWithAuth(`/users-permissions/auth/register-with-firebase/`, user?.Personal?.uid, newUser)
            // // console.log(resPost.data.user)
            // if (ok) {
            //     Swal.fire({
            //         position: 'center', icon: 'success',
            //         showConfirmButton: false,
            //         timer: 1500
            //     })
            //     console.log('====================================');
            //     console.log("resPost.data",resPost.data);
            //     console.log('====================================');
            //     setNewUser(resPost.data)
                
            // } else {
            //     Swal.fire({
            //         position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
            //         showConfirmButton: false,
            //         timer: 1500
            //     })
            // }
        }
        
        
        
    }

    
    return (
        <>{loading&&
            <FormLoadings/>}
            <>
                <header className='mb-2'>
                    <nav className='d-flex gap-3 justify-content-between p-2  mb-2 '
                        style={{ backgroundColor: `${user?.colors[1]}`, fontWeight: "bold",fontWeight: "bold",borderRadius:20 }}>

                        <div className='ml-5'>
                            <h5 style={{ color: "white",marginLeft:5,marginTop:3 }}>{objetoRecibidoByparams.id ? `Actualizar datos de ${sede.user.username}` : 'Agregar personal en sede'}</h5>
                        </div>
                    </nav>

                    <div className='d-flex justify-content-end'>
                        <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ backgroundColor: user.colors[2], cursor: "pointer",marginBottom:20 ,borderRadius:40,fontWeight: "bold"}}
                            onClick={()=>{navigate(-1)}}> Volver a Personal 
                        </Button>
                    </div>
                </header>
            
                <main style={{marginTop:-40}}>
                    <Form className='w-100 m-3' 
                        onSubmit={objetoRecibidoByparams?.id ? EditPersonal : handleSubmit}>

                        <Grid item xs={12} style={{alignItems:"center",margin:7,width:"95%", borderRadius:5,alignSelf:"center",marginLeft:-10}} >
                            <RegisterUser userEdit={objetoRecibidoByparams?.id && sede} setNewUser={setNewUser} handleSubmitUser={handleSubmitUser}/>
                        </Grid>

                        <Grid container spacing={2} style={{alignItems:"center",margin:7,width:"93%", borderRadius:5,alignSelf:"center"}}>
                            <Grid item xs={12} md={12} >          
                                    <TextField
                                        label="Cédula"  type="number"
                                        placeholder={objetoRecibidoByparams?.id ?sede.Cedula:"Cédula"}
                                        fullWidth
                                        name="Cedula" value={Cedula}
                                        onChange={onInputChange} style={{marginTop:10}}
                                    />
                                
                                    <TextField
                                        label={<>Imagen (<span style={{ fontSize: '70%' }} className='text-warning'>Opcional</span>)</>}
                                        type="file"fullWidth
                                        name="Imagen" focused
                                        value={showFile?Imagen:""}  onChange={handleFileChanged}
                                        style={{marginTop:10}}
                                    />

                                    <div className='d-flex'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            aria-required={true}
                                            options={code.paises.map((option) => option.codigo)}
                                            onChange={(e)=>setCode(e.target.textContent)}
                                            style={{ width: 100,marginTop:10 }}
                                            renderInput={(params) => (
                                            <TextField {...params} label="Prefijo" variant="outlined" />
                                            )}
                                            defaultValue={objetoRecibidoByparams.id? !separarCodigoYTelefono(sede.Telefono).error?separarCodigoYTelefono(sede.Telefono).codigo:Code:Code}
                                        />
                                        <TextField
                                            required
                                            label="Teléfono" type="number"
                                            placeholder={objetoRecibidoByparams?.id ?sede.Telefono:"Teléfono"}
                                            fullWidth  name="Telefono"
                                            value={Telefono}
                                            defaultValue={objetoRecibidoByparams.id? !separarCodigoYTelefono(sede.Telefono).error?separarCodigoYTelefono(sede.Telefono).numero:Telefono:Telefono} onChange={onInputChange}
                                            style={{marginTop:10}}
                                        />
                                    </div>
                                    
                                
                            </Grid>

                            <Grid item xs={12} md={12} >
                                <Paper className='w-100'>
                                    <Tabs defaultActiveKey="PermisoApp" className="mt-3" onSelect={(e)=>handleTabChange(e)} >

                                        <Tab eventKey="PermisoApp" title={<span style={{ color: activeTab === 'PermisoApp' ?user?.colors[2] : 'grey',fontWeight: activeTab === 'PermisoApp' ?"bold":'inherit' }}>Logistico</span>} 
                                                className="p-3"  > 
                                            {objetoRecibidoByparams?.id ?
                                                <PermissionsCheck onChange={onChangePer} permisos={user.Permisos} tipo={"PermisoApp"} selectedPermsInitial={permisos} idBodega={sede?.PuntosBodega? sede.PuntosBodega.Bodega:sede.Bodega} />
                                                :
                                                <PermissionsCheck onChange={handlePermisosChange} permisos={user.Permisos} tipo={"PermisoApp"} idBodega={idBodega}/>
                                            } 
                                        </Tab>
                                        <Tab eventKey="Admin" title={<span style={{color: activeTab === 'Admin' ?user?.colors[2]: 'grey',fontWeight: activeTab === 'Admin' ?"bold":'inherit'  }}>Administrador</span>}
                                                className="p-3" >
                                            {objetoRecibidoByparams?.id ?
                                                <PermissionsCheck onChange={onChangePer} permisos={user.Permisos} tipo={"AdministradorPuntoG"} selectedPermsInitial={permisos} idBodega={sede?.PuntosBodega? sede.PuntosBodega.Bodega:sede.Bodega}/>
                                                :
                                                <PermissionsCheck onChange={handlePermisosChange2} permisos={user.Permisos} tipo={"AdministradorPuntoG"} idBodega={idBodega}/>
                                                    
                                            } 
                                        </Tab>

                                    </Tabs>
                                </Paper>
                            </Grid>

                            {/* {!objetoRecibidoByparams?.id && user.typeUser == "EMPRESA" &&
                                <Autocomplete
                                id="combo-box-demo"
                                options={user?.PuntosBodega.map((option) => option.Nombre)}
                                onChange={(e)=>filterByBodega(e.target.textContent)}
                                style={{ width: 300,marginLeft:15 }}
                                renderInput={(params) => (
                                <TextField {...params} label="Selecciona la sede" variant="outlined" />
                                )}
                            />
                            } */}

                            <Grid item xs={12} md={12} >
                                <Button  variant='contained' type='submit'
                                    style={{ backgroundColor: user?.colors[2]}}
                                // onClick={handleFileChanged}
                                >
                                    {objetoRecibidoByparams?.id ? 'Actualizar' : 'Guardar'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </main>
                

            </>
        </>
    )
}
