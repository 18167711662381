import * as React from 'react';
import { useEffect } from 'react';
const image = require("../../assets/espera.png")
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
const Splash = () => {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    
    return (
        <div className='incio' style={{
            width:windowDimensions.width,
            height:windowDimensions.height,
            display:"flex",
            backgroundColor:"#77A49E",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:"column"
        }}>
            <img src={image} alt=""  style={{
                backgroundImage:image,
                backgroundSize:"cover",
                width:200,
                height:200,
                backgroundColor:"#77A49E",
                alignSelf:"center"
            }}/>
            <h5 style={{
                alignSelf:"center",
                color:"white",
                marginTop:30,
                textAlign:"center"
            }}>
            Dashboard de Trazabilidad de Hilo: Mejora la Gestión de tus Procesos</h5>
        </div>
    );
}

export default Splash;