import React from 'react'
import { fetchImageAsBase64 } from '../FileUpload'

const Baxter = require('../../assets/Pdfs/Vestolit/Baxter.png')
const Hilo= require('../../assets/Pdfs/Vestolit/Hilo.png')
const Vita = require('../../assets/Pdfs/Vestolit/Vita.png')
const Vinilo = require('../../assets/Pdfs/Vestolit/ViniloMov.png')
const Firma = require('../../assets/Pdfs/Vestolit/FJ.png')
const Vestolit = require('../../assets/Pdfs/Vestolit/Vestolit.png')
const Pavco = require('../../assets/Pdfs/Vestolit/Pavco.png')
const Kopelle = require('../../assets/Pdfs/Vestolit/Kopelle.png')
const W2W = require('../../assets/Pdfs/Vestolit/w2w.png')


const Vestolit_pdf_Welcome = async (nombre,nombreEmpresa) => {

    const HTML =`
    
    <html>
    <head>
    <style>
        body{
            font-family:helvetica;
            margin:0px;
            padding:0px;
        }
        .navHeader{
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            width: 100%; 
            padding-right:15px;
            margin-left:25px;
        }
        .navHeader1{
            display:flex;
            flex-direction:column;
            align-items:flex-end;
            padding-top:15px;
            padding-right:15px;
            padding-left:25px;
        }
        .chars{
            height: 30px;
        }
        .hilo-Logo{
            height:60px;
            margin-left:2%;
            margin-top:20px;
        }
        .img-Hilo{
            width:200px;
            height:100px;
            object-fit:"cover";
            border-radius: 10px;
        }
        .childNav{
            display:flex;
            flex-direction:column;
            align-items:flex-end;
            gap:5px
        }
        .childNav div p{
            margin-bottom:-10px;
        }
        .line{
            width:100%;
            height:2px;
            background-color:#2E4F40;
            outline:none;
            border:none;
        }
        .span{
            text-align:right;
            margin-top:-7px;
            font-weight: bold;
            padding-right:15px;
        }
        .Box-text{
            display: flex;
            flex-direction:column;
            width: 100vw;
            justify-content: center;
            align-items:center;
        }
        .text{
            width:80%;
            line-height: 1.5;
            text-align:justify;
        }
        .footer{
            display:flex;
            flex-direction:row;
            width:100%;
            justify-content:space-around;
            margin-top:40px;
            background-color:#95D3DB;
            padding:5px;
            height:100px;
        }
        .footer-Child,.logos{
            display:flex;
            flex-direction:row;
            gap:8px
        }
        .footer-Child{
            justify-content:space-around;
            width:100%;
            background-color:#95D3DB;
        }
        .reuse{
            height:35px;
            align-self:center;
            margin-top:3px
        }
        .logos {
            justify-content:flex-end;
            margin-bottom:10px;
            margin-top:20px;
        }
        .logos img{
            height:80px;
        }
        .Box-firma{
            display:flex;
            flex-direction:column;
            margin-top:30px;
            width:200px;
            margin-left:10%;
        }
        .firma{
            height:80px;
            width:100px;
            margin-left:10px;
        }
        .linefirma{
            width:100%;
            height:1px;
            margin-top:-25px;
            background-color:#000000;
            outline:none;
            border:none;
        }
    </style>
    </head>
    <body>

    <div styles="header">
        <div class="navHeader1">

            <div class="navHeader">
                <img src=${'../../assets/Pdfs/Vestolit/Hilo.png'} alt="reuse" class="hilo-Logo"></img>

                <div class="logos">
                    <img src=${'../../assets/Pdfs/Vestolit/Vita.png'} alt="reuse" ></img>
                    <img src=${'../../assets/Pdfs/Vestolit/ViniloMov.png'} alt="reuse" ></img>
                </div>
            </div>

            <div class="childNav">
                <div><p>Contacto: 3204881774 - gestionviniloenmovimiento@gmail.com</p></div>
            </div>

        </div>
        <hr class="line"></hr>
    </div>

    <p class="span"> <span>https://www.vestolit.com/vinyl-in-motion/</span></p>

    <p style="margin-left:10%;margin-top:50px;">Cordial Saludo,</p>
    <p style="margin-left:10%;">${nombre}</p>

    <div class="Box-text">

        <p class="text">
            Desde Vinilo en Movimiento le damos una cordial bienvenida a ${nombreEmpresa} al primer programa de cierre de ciclo del vinilo rígido y flexible del sector de la
            construcción, médico y textil; liderado por Vestolit en alianza con sus clientes como
            Baxter, Pavco, Grupo Kopelle, entre otros, y apoyado por W2W.<br> ¡Estamos muy
            emocionados por su vinculación a esta red que transforma el mundo a través de la
            economía circular y la sustentabilidad!
        </p>

        <p class="text">
            Con esta vinculación se apoya el crecimiento y mejoramiento de la calidad de vida de los
            recicladores en el país, gracias al fortalecimiento técnico y financiero, y al
            apalancamiento en la obtención de equipos y capital de trabajo que brinda el programa
            para robustecer la cadena de aprovechamiento del vinilo. Adicionalmente, ${nombreEmpresa} percibirá beneficios asociados al cumplimiento de metas de
            sostenibilidad internas, reducción de costos en la gestión de residuos, disminución de la
            huella de carbono y fortalecimiento en la trazabilidad de los residuos a través de una
            aplicación desarrollada a medida para la operación del Programa.
        </p>

        <p class="text">
            Vinilo en Movimiento agradece a ${nombreEmpresa} su participación y su
            compromiso con la sostenibilidad y la circularidad, siendo líderes de la transición hacía la
            economía circular desde su sector.
        </p>

    </div>        

    <div class="Box-firma">

        <div>
            <img src=${'../../assets/Pdfs/Vestolit/FJ.png'} alt="reuse" class="firma"/>
        </div> 

        <hr class="linefirma">
        <p> <b>Javier Pérez </b></p>
        <span>Líder Vinilo en movimiento </span>

    </div> 
         
    <footer class="footer">
        <div class="footer-Child">

            <div>
                <p>Una iniciativa de:</p>
                <img src=${'../../assets/Pdfs/Vestolit/Vestolit.png'} alt="reuse" class="reuse" style="margin-top:-3px;height:50px;"></img>
            </div>

            <div>
                <p>En alianza con: </p>
                <img src=${'../../assets/Pdfs/Vestolit/Baxter.png'} alt="reuse" class="reuse"></img>
            </div>
            
            <img src=${'../../assets/Pdfs/Vestolit/Pavco.png'} alt="reuse" class="reuse" style="margin-top:42px;"></img>
            <img src=${'../../assets/Pdfs/Vestolit/Kopelle.png'} alt="reuse" class="reuse" style="margin-top:45px;height:55px;"></img>
            
            <div>
                <p>Con el apoyo de:</p>
                <img src=${'../../assets/Pdfs/Vestolit/w2w.png'} alt="reuse" class="reuse" style="margin-top:-5px;height:45px;"></img>
            </div>

        </div>
    </footer>

    </body>
  </html>`
  return HTML
}

export default Vestolit_pdf_Welcome