import React, { useEffect, useState } from 'react'



export const NombrePersonalRuta = ({ id, personal }) => {
    const [usuario, setUsuario] = useState([]);

    const personalFilter = () => {
        setUsuario(personal.filter((persona) => persona.id === id))
    };

    useEffect(() => {
        personalFilter()
    }, []);
    // console.log(Nombre[0]?.user?.username)
    return (
        <>
            {usuario[0]?.user?.username||"--"}
        </>
    )
}
