import React, { useState } from 'react'
import { Button, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { hasPermission } from '../../../../../../helpers/Utils';
import { useDispatch, useSelector } from 'react-redux';
import Mancha from '../../../../../../assets/blob-haikei.png';
import { PutAllWithAuth } from '../../../../../../service/base2';
import { PqrsAction } from '../../../../../../service/redux/action/DataActions';
import Swal from 'sweetalert2';
import ModalTextarea from './ModalTextarea/ModalTextarea';
const { v4: uuidv4 } = require('uuid');
const emptyPlaces = require('../../../../../../assets/emptyplaces.png')

const TablasActivas = ({pqrs}) => {
    const user = useSelector(state=>state.user)
    const [menuOpen, setMenuOpen] = useState(false)
    const dispatch = useDispatch()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [gene, setGene] = useState({})

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const hasPermission = (permisoRequerido = ['no hay permisos']) => {
        const userPermissions = permisoRequerido
        return user.Personal.Permisos.some(permission => userPermissions.includes(permission.Permiso));
    };

    const findUsername = (ruta) => {
        if (!ruta) return "--"; 
    
        for (const e of user?.PuntosBodega || []) {
            for (const i of e.empleados || []) {
                if (i._id === ruta) {
                    console.log('====================================');
                    console.log(i.user.username);
                    console.log('====================================');
                    return i.user.username;
                }
            }
        }
    
        return "--";
    };

    const Cerrar = async (id) => {
        const { resPut, ok } = await PutAllWithAuth(`/pqrs/${id}`, user.Personal.uid, { Activo: false });

        if(ok){
            let Pqrs = user.Pqrs.map((e) => {
                if (e.id == id) { e.Activo = false}
                return e
            })
            dispatch(PqrsAction(Pqrs))
            Swal.fire({
                icon: 'success',
                title: 'PQRS cerrada con éxito',
                showConfirmButton: false,
                timer: 1500
            })
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Algo salió mal!',
            })
        }
       
    }
    

  return (
    <main style={{ width: '100%' }}>
               
        {pqrs.length>0?
        <Paper sx={{ width: '100%', marginTop: 2 }}>
            <TableContainer sx={{ }}>
                <Table  aria-label="sticky table">

                    <TableHead >
                        <TableRow hover tabIndex={-1} key={uuidv4()} >
                            <TableCell align={"center"} size='small'> <b>Tipo</b> </TableCell>
                            <TableCell align={"center"} size='small'><b>Creación</b></TableCell>
                            <TableCell align={"center"} size='small'> <b>Personal</b> </TableCell>
                            <TableCell align={"center"} size='small'> <b>Descripción</b> </TableCell>
                            <TableCell align={"center"} size='small'> <b>Acciones</b> </TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody>
                        {
                            pqrs?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                                <TableRow hover tabIndex={-1} key={uuidv4()} >

                                    <TableCell key={uuidv4()} size='small' align={"center"} width={"13%"}> {e.Tipo}</TableCell>
                                    <TableCell key={uuidv4()} size='small' align={"center"} width={"13%"}> {e.Creacion}</TableCell>
                                    <TableCell key={uuidv4()} size='small' align={"center"} width={"33%"}> {findUsername(e.Personal?e.Personal.id:"-")} </TableCell>
                                    <TableCell key={uuidv4()} size='small' align={"justify"} width={"33%"}> {e.Descripcion} </TableCell>
                                    <TableCell align={"center"} size='small'  style={{ position: "relative"}} onMouseLeave={()=>setMenuOpen(false)}>
                                
                            
                                        <IconButton
                                            aria-label="more"
                                            id="custom-button"
                                            style={{ position: "relative", width: 50, height: 50 }}
                                            onClick={() => setMenuOpen((menuOpen === false || menuOpen !==e.id) ? e.id : false)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        
                                        {menuOpen == e.id && (
                                            <div 
                                                style={{
                                                position: "absolute", top:20, right: 20,
                                                backgroundColor: "#fff",boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                zIndex: 2000,width: "17ch",borderRadius: 5
                                                }}
                                            >

                                                {hasPermission('Modificar gestores') && e.Generador &&
                                                    <MenuItem onClick={() => { setMenuOpen(false); console.log('====================================');
                                                    console.log(e.Generador);
                                                    console.log('===================================='); setIsModalOpen(true); setGene(e)}}>
                                                        Responder
                                                    </MenuItem>
                                                }
                                                {hasPermission('Eliminar gestores') && 
                                                    <MenuItem onClick={() => { setMenuOpen(e.id); Cerrar(e.id)  }}>
                                                        Cerrar
                                                    </MenuItem>
                                                }
                                            </div>
                                            
                                            )
                                            }
                                    

                                    </TableCell>
                                </TableRow>
                            ))
                            

                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={pqrs?.length ? pqrs.length : 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
        :
                    
        <div className='d-flex flex-column gap-3 justify-content-center p-2  mb-2 align-items-center'>
    
            <h3>No hay PQRS abiertas en este momento.</h3>
            <div style={{ backgroundImage: `url(${Mancha})`,width: 500, height: 500, display:"flex",alignItems:"center",justifyContent:"center",
                backgroundSize: 'cover',backgroundPosition: 'center',marginTop:-60 }}>
                <img src={emptyPlaces} alt="emptyPlaces" style={{width:160,height:160,}}/>
            </div>

        </div>
        }
        <ModalTextarea
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        Gene={gene}
        Cerrar={Cerrar}
      />
    </main>
  )
}

export default TablasActivas