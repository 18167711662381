import React, { useEffect } from 'react'
import MainDash from '../MainDash/MainDash'
import Tables from '../MainDash/Tables';

const ToRenderDash = ({ view, data, privada }) => {
  // useEffect(() => {
  // }, [data])
  return (
    <>
      {view === "Graficas" && <MainDash data={data} privada={privada} />}
      {view === "Tablas" && <Tables data={data}  />}
    </>
  )
}

export default ToRenderDash