import React from 'react'
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { getAbbreviation } from '../../../../../helpers/Utils';
const { v4: uuidv4 } = require('uuid');


const Recovered = ({recovered}) => {
  const { user } = useSelector(state => state)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => { setPage(newPage);};
console.log(recovered);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

  //   const formated = recovered.map(e=>{
      
  //     let obj
  //     e.MareterialesRecuperables.map(h=>{
  //       obj = {
  //         ...h,
  //         Fehca:e.Fecha,
  //         Bodega:e.PuntoBodega.Nombre,
  //         Personal: user?.PuntosBodega.length>0&&user?.PuntosBodega.find(i=>i.id===e.PuntoBodega.id).empleados.find(i=>i.user.id===e.Personal.user) || "Sin datos",
  //     }
  //   })
  //   return obj !== undefined&& obj
  // }).filter(e=>e!==false)


  return (
    <div>
       <TableContainer sx={{  marginTop:5 }}>
                    <Table aria-label="sticky table">
                        <TableHead style={{zIndex:-10}}>
                            <TableRow hover tabIndex={-10} key={uuidv4()} >

                                <TableCell align={"center"} size='small'> <b>Material</b> </TableCell>

                                {user.typeUser == "EMPRESA"&&
                                  <TableCell align={"center"} size='small'><b>Gestor</b> </TableCell>
                                }
                                <TableCell align={"center"} size='small'> <b>Cantidad</b> </TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {recovered .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                                <TableRow hover tabIndex={-1} key={uuidv4()} >

                                    <TableCell key={uuidv4()} align={"center"} size='small'> {e.material||"Sin moneda"} </TableCell>
                                    {user.typeUser == "EMPRESA"&&
                                      <TableCell key={uuidv4()} align={"center"} size='small'>{e.Gestor||"Sin valor"} </TableCell>
                                    }
                                    <TableCell key={uuidv4()} align={"center"} size='small'> {e.peso ||"Sin cantidad"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={recovered.length>0?recovered.length:0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
    </div>
  )
}

export default Recovered