export const stylesBt1 = (backgroundColor) => ( {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // gap: '10px',
    // fontFamily: 'inherit',
    // fontSize: '13px',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.4px',
    color: '#333',
    backgroundColor: backgroundColor,
    borderStyle: 'solid',
    borderWidth: '2px 2px 2px 2px',
    borderColor: 'rgba(255, 255, 255, 0.333)',
    borderRadius: '10px 10px 10px 10px',
    padding: '10px 24px 10px 28px',
    transform: 'translate(0px, 0px) rotate(0deg)',
    transition: '0.2s',
    height:'50px',
    boxShadow: '-4px -2px 4px 0px #ffffff, 4px 2px 4px 0px rgba(95, 157, 231, 0.48)'
  });
export  const stylesBt2 = {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // gap: '10px',
    // fontFamily: 'inherit',
    // fontSize: '13px',
    color: '#888',
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderWidth: '2px 2px 2px 2px',
    borderColor: 'rgba(255, 255, 255, 0.333)',
    borderRadius: '10px 10px 10px 10px',
    padding: '10px 24px 10px 28px',
    transform: 'translate(0px, 0px) rotate(0deg)',
    transition: '0.6s',
    height:'50px',
    boxShadow: '-4px -2px 4px 0px #ffffff, 4px 2px 4px 0px rgba(95, 157, 231, 0.48)'
  };