import React, { useEffect, useState } from 'react'
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { useSelector } from 'react-redux';
import { Backdrop, Box, Button, Chip, CircularProgress, Divider, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ScaleIcon from '@mui/icons-material/Scale';
import { UseGetWithAuth2 } from '../../../service/base2';
// import { Bar } from 'react-chartjs-2';
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
// } from 'chart.js';
import { NombrePersonalRuta } from './NombrePersonalRuta';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { MyBarChart } from '../../../helpers/Charts';
const { v4: uuidv4 } = require('uuid');


export const InformesRutas = ({ setType, setInformesRutas = [], informesRutas = [] }) => {
    const { empresas } = useSelector(state => state.empresa)
    const user = useSelector(state => state.user)
    const dataUser = useSelector(state => state.user)
    let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa.Color : user?.bodegas[0].Color 

    const location = useLocation();
    const navigate = useNavigate();
    const objetoRecibidoByparams = queryString.parse(location.search)
    console.log(objetoRecibidoByparams);
    const [dataInforme, setDataInforme] = useState([])
    const [valuesInforme, setValuesInforme] = useState([])
    const [materialInforme, setMaterialInforme] = useState([])
    const [personal, setPersonal] = useState([])
    const [validador, setValidador] = useState(true)
    const [Weight,setWeight]= useState(false)

    const getInforme = async () => {
        const info = user?.reportes?.Recogidas.find(e=>e.Ruta&&e.Ruta.id==objetoRecibidoByparams.fechaRuta && e.PuntoGenerador.id==objetoRecibidoByparams.idPuntoGenerador)
        console.log("info",info);
        // const Pers = user?.PuntosBodega.find(e=>e.id==info.PuntoBodega.id) 
        // setPersonal(Pers.empleados||[])
        if(info !== undefined){
            setDataInforme([info])
            setValidador(false) 
        }else{
            setDataInforme([]) 
            setValidador(false) 
        }
        // const { ok, resGet } = await UseGetWithAuth2(`/historial-recogidas/?_where[Ruta.Fecha]=${objetoRecibidoByparams.fechaRuta}&_where[PuntoGenerador]=${objetoRecibidoByparams.idPuntoGenerador}`, empresas[0].jwToken)
        // if (resGet.length === 0) {
        //     return setValidador(false)
        // } else {
        //     return setDataInforme(resGet), setValidador(false)
        // }
    };
    // const getPersonal = async () => {
    //     const info = user?.reportes?.Recogidas.find(e=>e.Ruta.id==objetoRecibidoByparams.fechaRuta)
    //     const Pers = user?.PuntosBodega.find(e=>e.id==info.PuntoBodega.id) 
    //     if (ok) return setPersonal(Pers.empleados||[])
    //     const { ok, resGet } = await UseGetWithAuth2(`/personals`, empresas[0].jwToken)
    // };

    const pData = valuesInforme.length !== 0 ? valuesInforme : [35, 25];;
    const xLabels = materialInforme.length !== 0 ? materialInforme : ['Pasta Blanca', 'Medicamentos',]

    const encontrarValoresName = (array, condicion) => {
        const resultados = [];
        for (let i = 0; i < array[0]?.Informe.length; i++) {
            if (array.length !== 0) {
                resultados.push(array[0]?.Informe[i].material);
            }
        }
        return resultados;
    };
    const encontrarValores = (array, condicion) => {
        const resultados = [];
        for (let i = 0; i < array[0]?.Informe.length; i++) {
            if (array.length !== 0) {
                resultados.push(+array[0]?.Informe[i].value);
            }
        }
        return resultados;
    };

    useEffect(() => {
        getInforme()
        // getPersonal()
    }, [])

    useEffect(() => {
        if (dataInforme.length >0 &&dataInforme[0]?.Informe) {
            setValuesInforme(encontrarValores(dataInforme))
            setMaterialInforme(encontrarValoresName(dataInforme))
        }
    }, [dataInforme])

    const separarFechaHora = (cadena) => {
        const fechaHora = new Date(cadena)
        const fecha = fechaHora.toISOString().split('T')[0];
        const hora = fechaHora.toTimeString().split(' ')[0];

        return {
            fecha,
            hora
        };
    }

    //Todo ----------------------graficas----------------------------
   console.log("dataInforme",dataInforme);
   console.log("objetoRecibidoByparams",objetoRecibidoByparams);

    const data = {
        labels: xLabels,
        values:pData
    };

    const opciones = {
        maintainAspectRatio: false,
        responsive: true,
    }

    const onNavigateBack = () => {
        navigate(-1)
    };

    // console.log(dataInforme)
    return (
        <>
            {validador ?
                <div className='text-center m-5 '>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={validador}
                    >
                        <CircularProgress width='550px' color="primary" />
                        <h1 className='text-primary mx-3'>Loading...</h1>
                    </Backdrop>
                </div>
                :
            <main>
                <nav className='d-flex gap-3 justify-content-between p-2  mb-2 '
                    style={{ backgroundColor: user.colors[1], fontWeight: "bold",borderRadius:20 }}>
                    <div className='ml-5'>
                        <h5 style={{ color: "white",marginLeft:10,marginTop:5 }}>Informes</h5>
                    </div>
                </nav>

                <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ cursor: "pointer", backgroundColor: user.colors[2],borderRadius:40}}
                    onClick={onNavigateBack}
                >
                    Atras
                </Button>

                <div className="card mt-3 p-5">
                    {dataInforme.length >0 ?
                        <div className="row ">
                            <div className="col-12 col-md-6 col-lg-6  ">
                                <Box >
                                    <Box sx={{ my: 2, mx: 2 }}>
                                        {dataInforme.length &&
                                            <Typography gutterBottom variant="" component="div">
                                                <CalendarMonthIcon /> Fecha: <b>{separarFechaHora(dataInforme[0]?.Fecha).fecha} </b>
                                                <AccessTimeIcon /> Hora: <b>{separarFechaHora(dataInforme[0]?.Fecha).hora}</b>
                                            </Typography>
                                        }
                                        <Divider />
                                        <Typography className='mt-2' gutterBottom variant="" component="div">
                                            <AssignmentIndIcon />  Personal: <b><NombrePersonalRuta id={dataInforme[0]?.Personal?dataInforme[0]?.Personal.id:"--"} personal={personal} /></b>
                                        </Typography>
                                        <Divider />
                                        <Typography className='mt-2' color="text.secondary" >
                                            <MenuBookIcon /> Informe de la recolección del material en la locación <b>{dataInforme[0]?.PuntoGenerador.Nombre}</b>
                                        </Typography>
                                        <Divider />
                                        <Grid container alignItems="center">
                                            <Typography className='mt-2' gutterBottom component="div">
                                                <ScaleIcon />  Peso total: <b>{dataInforme[0]?.PesoCarga}</b>
                                            </Typography>
                                        </Grid>
                                    </Box>

                                    <Divider />
                                    {dataInforme[0]?.Informe&&<Box sx={{ m: 2 }}>
                                        <Typography gutterBottom variant="h6">
                                            Materiales:
                                        </Typography>
                                        <Grid container alignItems="center">

                                            <Table className='card'  aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow hover tabIndex={-1} key={uuidv4()}>
                                                        <TableCell align={"center"}>
                                                            <b>Material</b>
                                                        </TableCell>
                                                        {dataInforme[0]?.Informe&&dataInforme[0]?.Informe[0]?.value &&
                                                            <TableCell align={"center"}>
                                                                <b>Peso</b>
                                                            </TableCell>
                                                        }
                                                        {dataInforme[0]?.Informe&&dataInforme[0]?.Informe[0]?.valor &&
                                                            <TableCell align={"center"}>
                                                                <b>Valor</b>
                                                            </TableCell>
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {dataInforme[0]?.Informe&&dataInforme[0]?.Informe.map((info) => (

                                                        <TableRow>
                                                            <TableCell key={uuidv4()} align={"center"}>
                                                                {info.material}
                                                            </TableCell>
                                                            <TableCell key={uuidv4()} align={"center"}>
                                                                {info.value} {info.Unidad_de_Peso == 'kilogramo' ? ' Kg.' : info.Unidad_de_Peso}
                                                            </TableCell>
                                                            {
                                                                info.valor &&
                                                                <TableCell key={uuidv4()} align={"center"}>
                                                                    {info.valor} 
                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Box>}
                                </Box>
                            </div>
                            
                            {dataInforme[0]?.Informe&&
                                <div className="col-12 col-md-6 col-lg-6 ">
                                    <MyBarChart data={data} colors={dataUser?.colors} title={"Recuperado enn ruta"}/>
                                </div>
                            }
                        </div>
                        :
                        <h1 className='text-warning text-center'>Oops no existe el informe !</h1>
                    }
                </div>
            </main>
            }
        </>
    )
}
