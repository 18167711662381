import React, { useState } from 'react'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import { TextField } from '@mui/material';


const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #999',
    borderRadius:5,
    boxShadow: 24,
    p: 4,
};


const Recuperar = ({status,setStatus}) => {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);

    const onInputChange = (e) => {
        setEmail(e.target.value)
    }
    const handleResetPassword = async () => {
  
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
          .then((e) => {
            setOpen(true);
            setMessage(`Se envió el correo de recuperación de contraseña. Revisa tu bandeja de entrada.`);
            setTimeout(()=>setStatus(false),5000)
          })
          .catch((error) => {
            setOpen(true);
            setMessage(`Error: Correo no reconocido`);
            setTimeout(()=>setStatus(false),5000)
          });
    };
    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={status}
            onClose={()=>setStatus(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                TransitionComponent: Fade,
              },
            }}
        >
            <Fade in={status}>
            
                <Box sx={style}>
                    <div className='d-flex justify-content-center flex-column'>
                    {!open ?
                        <>
                          <TextField
                              label="Email"
                              type="email"
                              placeholder="Email"
                              fullWidth
                              name="email"
                              required
                              value={email}
                              onChange={onInputChange}
                          />
                          <Button variant="contained" onClick={handleResetPassword}>Enviar</Button>
                        </>
                        :
                      <p>{message}</p>
                      }
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default Recuperar