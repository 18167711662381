import { Avatar, Paper, Stack, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React from 'react'
import { Button, Table } from 'react-bootstrap'
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { SplitingInitialsName } from '../../../../helpers/Utils';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const { v4: uuidv4 } = require('uuid');

export const TablaEmpresasGeneradoras = ({ hasPermission, EmpresasGeneradoras, puntosGeneradores, setType, bodegaById, setEmpresaGeneradoraEdit }) => {
    const { empresas } = useSelector(state => state.empresa);
    let dataColor = empresas[0]?.typeUser === 'EMPRESA' ? empresas[0]?.empresas?.data?.Color : empresas[0]?.empresas?.data[0]?.Color

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // const [nombrePunto, setNombrePunto] = useState()

    const puntoGeneradorById = (id) => {
        const result = puntosGeneradores.filter(puntoGenerador => puntoGenerador.id === id);
        return result.Nombre;
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            {hasPermission('Agregar generadores') &&
                <Link className="navbar-brand" to={{ pathname: `/nueva-empresa-generadora`, search: `?${new URLSearchParams(bodegaById).toString()}` }} >
                    <Button variant="contained" style={{ backgroundColor: `#${dataColor}`, position: "absolute", top: 200, right: 25, cursor: "pointer", color: 'white' }}>
                        Agregar empresa<DomainAddIcon className='mx-1 text-white' />
                    </Button>
                </Link>
            }

            <Paper sx={{ width: '100%', marginTop: 6.5 }}>
                <TableContainer sx={{ minHeight: 440, maxHeight: 840 }}>
                    <Table  aria-label="sticky table">
                        <TableHead >
                            <TableRow hover tabIndex={-1} key={uuidv4()} >

                                <TableCell align={"center"}>
                                    <b>Avatar</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Nombre de empresa</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Nombre de contacto</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Cargo</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Teléfono</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Punto generador</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Direccion</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Acciones</b>
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {EmpresasGeneradoras && EmpresasGeneradoras.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                                <TableRow hover tabIndex={-1} key={uuidv4()} >
                                    <TableCell key={uuidv4()} align={"center"}>

                                        <Stack direction="row" justifyContent={"center"}>
                                            <Avatar sx={{ bgcolor: `#${dataColor}` }}>{SplitingInitialsName(e.NombreContacto)}</Avatar>
                                        </Stack>

                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.NombreEmpresa ? e.NombreEmpresa : <span className='text-info'>No hay empresa !</span>}
                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.NombreContacto}
                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.CargoContacto}
                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.Telefono}
                                    </TableCell>


                                    <TableCell key={uuidv4()} align={"center"}>
                                        <>
                                            {puntoGeneradorById(e.id)}
                                            {/* {result = puntoGeneradorById(e.id)} */}
                                            {/* {result} */}
                                        </>
                                    </TableCell>


                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.Direccion}
                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        <div className='d-flex gap-3 justify-content-center'>
                                            <Tooltip title="Modificar">
                                                {hasPermission('Modificar generadores') ? (
                                                    <Link className="navbar-brand" to={{ pathname: `/nueva-empresa-generadora`, search: `?${new URLSearchParams(e).toString()}` }} >
                                                        <EditTwoToneIcon color='primary'/>
                                                    </Link>
                                                ) : (
                                                    // Si no se tienen los permisos necesarios no se muestra nada
                                                    <span style={{ display: 'none' }}></span>
                                                )}
                                            </Tooltip>

                                            <Tooltip disabled title="Eliminar">
                                                {hasPermission('Eliminar generadores') ? (
                                                    <DeleteTwoToneIcon color='error'
                                                    // onClick={() => eliminarBodega(e.id)}
                                                    />
                                                ) : (
                                                    // Si no se tienen los permisos necesarios no se muestra nada
                                                    <span style={{ display: 'none' }}></span>
                                                )}
                                            </Tooltip>
                                        </div>
                                    </TableCell>

                                </TableRow>

                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={EmpresasGeneradoras && EmpresasGeneradoras.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    )
}
