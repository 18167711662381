import * as XLSX from 'xlsx';

// export const generateExcel = (data) => {
//     const ws = XLSX.utils.json_to_sheet(data, {skipHeader: false});
//     // Agregar un encabezado en la primera fila
//     ws['A1'] = { v: "Periodo desde 11/10/2021 hasta 12/12/2023" };
//     ws['A2'] = { v: "Gestor: EcoAmbiente" };
//   const wscols = [{wch: 20}, {wch: 20}, {wch: 30}, {wch: 10}, {wch: 30}];
//   ws['!cols'] = wscols;
  
//     const range = XLSX.utils.decode_range(ws['!ref']);
//     const cols = [];
//     for(let C = range.s.c; C <= range.e.c; ++C) {
//         let max_length = 0;  // Máxima longitud en esta columna
//         for(let R = range.s.r; R <= range.e.r; ++R) {
//         const cell_ref = XLSX.utils.encode_cell({c:C, r:R});
//         /* Encontrar la celda en la hoja de trabajo */
//         const cell = ws[cell_ref];
//         /* Si no hay celda en la hoja de trabajo, saltear */
//         if(!cell) continue;
//         /* Obtener el texto de la celda */
//         const text = (cell.w ? cell.w : '').toString();
//         /* Encontrar el máximo tamaño de la celda en la columna */
//         if(text.length > max_length) max_length = text.length;
//         }
//         /* Guardar el tamaño máximo encontrado en la columna */
//         cols[C] = {wch:max_length + 24};  // Agregar un poco más para evitar que el texto se corte
//     }

//     /* Establecer el ancho de las columnas */
//     ws['!cols'] = cols;
    
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "Hoja1");
//     XLSX.writeFile(wb, "Informe.xlsx");
//   };
export const generateExcel = (data,title,gestor) => {
  // Convertir datos JSON en hoja de cálculo, empezando desde la fila 3
  const ws = XLSX.utils.json_to_sheet(data, {skipHeader: false, origin: "A5"});

  // Definir los valores de las cabeceras
  ws['A1'] = { v: title };
  ws['A2'] = gestor&&{ v: gestor };

  // Definir estilos
  const headerStyle = {
      font: { name: "Verdana", sz: 12, bold: true },
      alignment: { horizontal: "center" },
      border: { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "ffffff" } }, right: { style: "thin", color: { rgb: "ffffff" }}}
  };

  // Aplicar estilos a las cabeceras
  ['A1', 'A2','B1','B2','C1','C2','D1','D2','E1','E2'].forEach(cell => {
      if(ws[cell]) {
          ws[cell].s = headerStyle;
      }
  });

  // Configuración del ancho inicial de las columnas (puede ajustarse según sea necesario)
  const wscols = [{wch: 20}, {wch: 30}, {wch: 30}, {wch: 30}, {wch: 30}];
  ws['!cols'] = wscols;

  // El resto del código para calcular el ancho de las columnas...
  const range = XLSX.utils.decode_range(ws['!ref']);
    const cols = [];
    for(let C = range.s.c; C <= range.e.c; ++C) {
        let max_length = 0;  // Máxima longitud en esta columna
        for(let R = range.s.r; R <= range.e.r; ++R) {
        const cell_ref = XLSX.utils.encode_cell({c:C, r:R});
        /* Encontrar la celda en la hoja de trabajo */
        const cell = ws[cell_ref];
        /* Si no hay celda en la hoja de trabajo, saltear */
        if(!cell) continue;
        /* Obtener el texto de la celda */
        const text = (cell.w ? cell.w : '').toString();
        /* Encontrar el máximo tamaño de la celda en la columna */
        if(text.length > max_length) max_length = text.length;
        }
        /* Guardar el tamaño máximo encontrado en la columna */
        cols[C] = {wch:max_length + 24};  // Agregar un poco más para evitar que el texto se corte
    }
  // ...

  // Crear un nuevo libro y agregar la hoja
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

  // Guardar el libro en un archivo
  XLSX.writeFile(wb, "Informe.xlsx");
};
