import { Button, Checkbox, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import CancelIcon from '@mui/icons-material/Cancel';
import { DeleteAllWithAuth,  PutAllWithAuth } from '../../../service/base2';
import Swal from 'sweetalert2';
import { rutasAction } from '../../../service/redux/action/DataActions';
import ModalDetailsMaterials from './ModalDetailsMaterials';
import CheckIcon from '@mui/icons-material/Check';


const FormuarioRutaje = ({rutaID,setFormu}) => {
    const user = useSelector(state=>state.user)
     const ruta = user.rutas.find(e=>e.id ==rutaID)
     const dataColor = user?.typeUser === 'EMPRESA' ? "#" + user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color
     const dispatch = useDispatch()
     const [visitas,setVisitas]=useState(false)
     const [report,setReport]=useState(false)
     const [IdGene,setIdGene]=useState("")
    console.log(ruta);

    const removeGene = async (id)=>{
        console.log(id);
        const filterGenes = ruta.PuntosGeneradores.filter(e=>e.id!==id)
        console.log(filterGenes);

        await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esta acción!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: dataColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resPut =  await PutAllWithAuth("rutas/"+rutaID, user?.Personal?.uid,{"PuntosGeneradores":filterGenes})
                console.log("resPut",resPut);
                if(resPut.ok){
                    console.log("Paso OK",resPut.resPut);
                    Swal.fire({position: 'center', icon: 'success', title: `El generador se ha eliminado correctamente`,
                                showConfirmButton: false,timer: 2500})
                    console.log(resPut);
                    const rutas = user.rutas.filter(e=>e.id !==rutaID)
                    rutas.push(resPut.resPut)
                    dispatch(rutasAction(rutas))
                    setFormu(false)
                }else{
                    console.log("no pasó");
                    Swal.fire({     icon: 'error', title: 'Oops...', text: 'Algo salio mal!' })
                }
            }
        })
    }

    const removeRuta = async ()=>{
        await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esta acción!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: dataColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await DeleteAllWithAuth(`rutas/${rutaID}`, user?.Personal?.uid);
                console.log(res);
                if(res.ok){
                    Swal.fire({position: 'center', icon: 'success', title: `La ruta se ha eliminado correctamente`,
                                showConfirmButton: false,timer: 2500})
                    const rutas = user.rutas.filter(e=>e.id !==rutaID)
                    dispatch(rutasAction(rutas))
                    setFormu(false)
                }else{
                    Swal.fire({     icon: 'error', title: 'Oops...', text: 'Algo salio mal!' })
                }
            }
        })
    }

    const tieneReportes = ruta ? (
        ruta.PuntosGeneradores.length === 0 || // Retorna true si no hay puntos generadores.
        ruta.PuntosGeneradores.some(puntoGenerador => {
          // Verifica si hay al menos un punto generador sin reporte.
          const reporteEncontrado = user?.reportes?.Recogidas.some(recogida => {
            const rutaCoincide = recogida.Ruta && recogida.Ruta.id === ruta.id;
            const puntoGeneradorCoincide = recogida.PuntoGenerador && recogida.PuntoGenerador.id === puntoGenerador.id;
            
            return rutaCoincide && puntoGeneradorCoincide;
          });
      
          return !reporteEncontrado; // Si no se encuentra reporte, some devuelve true.
        })
      ) : false; // Retorna false si no se proporciona una ruta.
      
      
       
    
    const completarRuta = async ()=>{
        console.log(rutaID);
        const {resPut,ok} = await PutAllWithAuth("/rutas/"+rutaID, user?.Personal?.uid, {Completada: true});
        console.log(resPut);
        if(ok){
            const ActuRuta = user.rutas.filter(e=>e.id !==rutaID)
            ActuRuta.push(resPut)
            Swal.fire({position: 'center', icon: 'success', title: `La ruta se ha completado correctamente`,
                                showConfirmButton: false,timer: 2500})
            dispatch(rutasAction(ActuRuta))
            setFormu(false)
        }else{
            Swal.fire({     icon: 'error', title: 'Oops...', text: 'Algo salio mal!' })
        }
    }
    useEffect(()=>{},[ruta,user?.reportes?.Recogidas])
  return (
    <form style={{marginTop:40,position:"relative"}}>

        {/* ================ CERRAR FORMULARIO ================*/}
        <IconButton style={{alignSelf:"center",position:"absolute",right:20,top:-15}} onClick={()=>setFormu(false)}>
            <CancelIcon style={{color:user.colors[6],fontSize:30}} />
        </IconButton>

        {/* ================ Datos de ruta ================*/}
        <h3>Ruta: {ruta.id}</h3>
        <p><b>Fecha generación:</b> <i>{ruta.Fecha}</i></p>
        {tieneReportes ? <p><b>Locaciones a visitar</b></p>:<p><CheckIcon/><b>Todas las locaciones fueron visitadas</b></p>}

        {/* ================ Eliminar ruta en caso de no tener puntos generadores ================*/}
        {!ruta.PuntosGeneradores.length>0 && 
            <Tooltip title="Remover ruta">
                <div className='d-flex align-items-center justify-content-center gap-2 w-25 pt-2' style={{backgroundColor:user.colors[6]+"9e",borderRadius:10,cursor:"pointer",height:33}}>
                    <p style={{verticalAlign:"center",marginTop:2}}>Remover ruta</p>
                    <IconButton style={{alignSelf:"center"}} onClick={()=>removeRuta()}>
                        <BookmarkRemoveIcon style={{fontSize:20,marginTop:-10}}/>
                    </IconButton>
                </div>
            </Tooltip>
        }

        {/* ================ Lista de los puntos generadores asignados en la ruta ================*/}
        <ul>
            {!ruta.PuntosGeneradores.length > 0 ? (
                <li className='m-3'>No hay generadores o estos fueron eliminados</li>
                ) : (
                    ruta.PuntosGeneradores.map(e => {
                        // {/* ================ CORREGIR CONDICIONAL ================*/}
                    const tieneReportesNoAsociados = user?.reportes?.Recogidas?.some(recogida => {
                        const puntoGeneradorCoincide = recogida.PuntoGenerador && recogida.PuntoGenerador.id === e.id;
                        const rutaCoincide = recogida.Ruta && recogida.Ruta.id === ruta.id;
                        return  puntoGeneradorCoincide && rutaCoincide;
                    });
                    console.log("tieneReportesNoAsociados",tieneReportesNoAsociados);
                    if (!tieneReportesNoAsociados) {
                        return (
                        <li key={e.id}>
                            <div>{e.Nombre}</div>
                            {/* ================ Registro de datos o acciones para los generadores ================ */}
                            <div>
                            <Tooltip title="Crear reporte">
                                <IconButton onClick={() => { setIdGene(e.id); setReport(true); }}>
                                <ReceiptLongIcon style={{ fontSize: 26 }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Remover generador en ruta">
                                <IconButton onClick={() => removeGene(e.id)}>
                                <BookmarkRemoveIcon style={{ fontSize: 26 }} />
                                </IconButton>
                            </Tooltip>
                            {/* Posibles elementos adicionales comentados */}
                            </div>
                        </li>
                        );
                    }
                    // No retorna nada si no se cumple la condición
                    return null;
                    })
                )}

                {!tieneReportes && (
                    <Button variant='contained' className='botonRuta' style={{ backgroundColor: user.colors[5] }} onClick={() => completarRuta()}>
                    <IconButton>
                        <CheckIcon />
                    </IconButton>
                    Marcar ruta como completada
                    </Button>
                )}
            </ul>

        {report && <ModalDetailsMaterials rutaID={rutaID} IdGene={IdGene} setReport={setReport}/>}
    </form>
  )
}

export default FormuarioRutaje