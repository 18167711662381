import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import { PutAllWithAuth, UseGetWithAuth2 } from '../../../service/base2';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { hasPermission } from '../../../helpers/Utils';
import { DescartablesAction, DescartablesPropiosAction } from '../../../service/redux/action/DataActions';
import NewDescarte from './NewDescarte';
const { v4: uuidv4 } = require('uuid');



const Descartables = ({setModal,modal,setLoading }) => {
  const { empresas } = useSelector(state => state.empresa)

  const { user } = useSelector(state => state)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [materialesDescartables, setMaterialesDescartables] = useState([])
  const [menuOpen, setMenuOpen] = React.useState(false);
  const dispatch = useDispatch()

  const getMaterialesDescartables = async () => {
    if(!user.materialesDescartables){
      const { ok, resGet } = await UseGetWithAuth2(`/materiales-descartables`, user?.Personal?.uid)
      if (ok){
        console.log(resGet);
        dispatch(DescartablesAction(resGet))
        setMaterialesDescartables(resGet)}
    }else return setMaterialesDescartables(user?.materialesDescartables)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  

  useEffect(() => {
    getMaterialesDescartables()
  }, [])

  const eliminarMaterial = async (e) => {

    await Swal.fire({
        title: '¿Estás seguro de eliminar este elemento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'

    }).then(async(result) => {
        if (result.isConfirmed) {
          setLoading(true)
          const idMateriales = user?.PuntosBodega[0].MaterialesDescartables.filter(item=>item.id !== e)
          let data ={MaterialesDescartables:idMateriales.map(i=>i.id)}
          console.log(idMateriales);
          const Put = await PutAllWithAuth(`/puntos-bodegas/${user?.PuntosBodega[0].id}`, user?.Personal?.uid,data);
          if(Put.ok){
            dispatch(DescartablesPropiosAction(Put.resPut.MaterialesDescartables))
            setLoading(false)
            Swal.fire({
                position: 'center', icon: 'success', title: `El material ha sido eliminado`,
                showConfirmButton: false,
                timer: 2500
            })
          }else{
            setLoading(false)
            Swal.fire({
                position: 'center', icon: 'error', title: `El material no ha sido eliminado`,
                showConfirmButton: false,
                timer: 2500
              })
          }
            // if(user?.typeUser === "EMPRESA" ){
            //     const t = await DeleteAllWithAuth(`calculadora-materiales/${e.id}`, user?.Personal?.uid);
            //     console.log('====================================');
            //     console.log(t.ok);
            //     console.log('====================================');
            //     if (t.ok) {
            //       if(hasPermission(empresas[0]?.empresas?.Servicios, "Compra y/o venta")){
            //           const w = await DeleteAllWithAuth(`precios-materiales/${e.idPrecio}`, user?.Personal?.uid);
            //             dispatch(materialesAction(user?.materiales.filter(u=>u.id!=e.id)))
            //             setCalculadoraMateriales(user?.materiales.filter(u=>u.id!=e.id))
            //             Swal.fire({
            //                 position: 'center', icon: 'success', title: `El material ha sido eliminado`,
            //                 showConfirmButton: false,
            //                 timer: 2500
            //             })
            //       }else{
            //         dispatch(materialesAction(user?.materiales.filter(u=>u.id!=e.id)))
            //         setCalculadoraMateriales(user?.materiales.filter(u=>u.id!=e.id))
            //         Swal.fire({
            //           position: 'center', icon: 'success', title: `El material ha sido eliminado`,
            //           showConfirmButton: false,
            //           timer: 2500
            //         })
                    
            //       }
            //     }else{
            //       Swal.fire({
            //         position: 'center', icon: 'error', title: `El material no ha sido eliminado`,
            //         showConfirmButton: false,
            //         timer: 2500
            //       })
            //     }
            // }
        }
    }).then(() => {
      // getCalculadoraMateriales()
    })
  };
  return (
    <>
      {modal && <NewDescarte setModal={setModal} Descartes={materialesDescartables} setMaterialesDescartables={setMaterialesDescartables}/> }
      <Paper sx={{ width: '80%',borderRadius:5, margin:"auto" }}>

          <TableContainer>

              <Table  aria-label="sticky table">
                  <TableHead>

                      <TableRow hover tabIndex={-1} key={uuidv4()}>
                          <TableCell align={"center"} size='small'> <b>Nombre</b> </TableCell>
                          <TableCell align={"center"} size='small'> <b>Acciones</b> </TableCell>
                      </TableRow>

                  </TableHead>

                  <TableBody>
                      {user?.PuntosBodega[0].MaterialesDescartables && user?.PuntosBodega[0].MaterialesDescartables.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(e => (
                        <TableRow hover tabIndex={-1} key={uuidv4()}>
                            <TableCell key={uuidv4()} align={"center"} size='small'>{e.Material}</TableCell>
                            <TableCell key={uuidv4()} align={"center"} size='small'  style={{ position: "relative"}} onMouseLeave={()=>setMenuOpen(false)} >
                                  <IconButton aria-label="more" id="custom-button" style={{ position: "relative", width: 50, height: 50 }} 
                                              onClick={() => setMenuOpen((menuOpen === false || menuOpen !==e.id) ? e.id : false)} >
                                      <MoreVertIcon />
                                  </IconButton>
                                  {menuOpen == e.id && (
                                    <div style={{ position: "absolute", top:20, right: 20, backgroundColor: "#fff",
                                        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",zIndex: 2000,width: "17ch",borderRadius: 5,
                                    }}>
                                          {hasPermission(user?.Personal?.Permisos,'Eliminar material') && 
                                              <MenuItem onClick={() => { setMenuOpen(e.id); eliminarMaterial(e.id)  }}>
                                                  Eliminar
                                              </MenuItem>
                                          }
                                    </div>
                                  )}
                            </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
              </Table>

          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]} component="div"
            count={user.PuntosBodega[0].MaterialesDescartables?user.PuntosBodega[0].MaterialesDescartables.length:0} rowsPerPage={rowsPerPage}
            page={page}  onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </>
  );
}



export default Descartables
