import React, { useState } from 'react';
import './Login.css';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { loginEmailAsync } from '../../service/redux/action/LoginAction';
import Recuperar from '../recuperar/Recuperar';
const Logo = require( "./Logo.png")
const Login =()=>{
    const dispatch = useDispatch();
    const [status, setStatus] = useState(false)
    const [registro, setRegistro] = useState({
        email:'',
        pass:''
    })
  
    const {email,pass}=registro;
  
    const handleInputChange=({target})=>{
        setRegistro({
            ...registro,
            [target.name]:target.value
        })
    }
    
    const handleSubmit = (e)=>{
      e.preventDefault()
      if(email && pass){
      dispatch(loginEmailAsync(email,pass,dispatch))
      }else{
        alert("por favor complete los campos")
      }
    }
  
    return(
        <>
        {status && <Recuperar status={status} setStatus={setStatus}/>}
        <div className='containerL'>
            <img src={Logo} alt="Logo - Hilo" className='Logo'/>
            <div className='imgBackground'>
                
                
            </div>
            <div className='containerLogin'>
                    <h1>¡Bienvenido!</h1>
                <h3>Ingresa a Hilo Dashboard</h3>
                <Form className='w-100' onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 w-75" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Email" name='email' onChange={handleInputChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3 w-75" controlId="formBasicPassword">
                        <Form.Control type="password" placeholder="Contraseña" name='pass' onChange={handleInputChange}/>
                    </Form.Group>
                    <button className='send'>Ingresar</button>
                </Form>
                    <button className='recuperar' onClick={()=>setStatus(true)}>¿No recuerdas la contraseña?</button>
                <div className='LastDiv'>
                    <p>Gestiona y administra la logística en tu empresa</p>
                    <p className='subtitle'>No te pierdas ningun detalle</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default Login