import { typeBodegas } from "../types/typeBodegas";

const initialState = {
    loading: false,
    bodegas: [],
    error: null
  };

const bodegasReducers = (state = initialState, action) => {
    switch (action.type) {
      
            case typeBodegas.addBodegasStarted:
                    return {
                        ...state,
                        loading: true
                    };
                case typeBodegas.addBodegasSuccess:
                    return {
                        ...state,
                        loading: false,
                        error: null,
                        bodegas: [...state.bodegas, action.payload]
                    };
                case typeBodegas.addBodegasFailure:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload.error
                    };
            
            default:
                return state;
            }
        };
        

export default bodegasReducers;