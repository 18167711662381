import * as React from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import Descartables from './descartados/Descartables';
import Materiales from './utiles/Materiales';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Tab, Tabs } from '@mui/material';
import { NewMateialEmpresa } from './NewMateialEmpresa';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { useState } from 'react';
import { hasPermission } from '../../helpers/Utils';
import FormLoadings from '../../helpers/FormLoadings';

const MixingMetrial = () => {
  const { empresas } = useSelector(state => state.empresa)
  const user = useSelector(state => state.user)
  const [value, setValue] = useState('1');
  const [materialEdit, setMaterialEdit] = useState([])
  const [loading, setLoading] = React.useState(false);
  const [modal,setModal]=useState(false)
  const  dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(()=>{},[value])
  console.log('====================================');
  console.log(user);
  console.log('====================================');

  return (
    <>
      { loading && <FormLoadings/>}

      <h2 style={{ textAlign: "center" }}>Materiales {user?.Personal?.Empresa&& user?.Personal?.Empresa?.Nombre}</h2>
      <hr />

      <div className='d-flex justify-content-end'>
          {value === "3" &&
              <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ cursor: "pointer" ,marginBottom:10,marginTop:-10, backgroundColor:user.colors[2],borderRadius:40 }}
                onClick={() => {setValue("1");setMaterialEdit([]) }} >
                  Atras
              </Button>
          }
      </div>
      
      {value === "3" ?   <NewMateialEmpresa setValue={setValue} materialEdit={materialEdit} setLoading={setLoading}/> :
        <>
          
          <Box sx={{ width: '100%' }}>

              {user?.typeUser !== "EMPRESA"&&
                  <Tabs value={value}  onChange={handleChange} aria-label="Materiales"  sx={{ width: '100%', display: "flex", justifyContent: "space-around" }} >
                      <Tab value="1" label="Utiles" sx={value === "1" && { fontWeight: "900" }} />
                      <Tab value="2" label="Descartables" sx={ value === "2" && { fontWeight: "900" }} />
                  </Tabs>
              }
              {value === "1" &&
                  <>
                      {hasPermission(user?.Personal.Permisos,'Agregar material')&& (user?.typeUser !== "EMPRESA"&& user.bodegas[0].PermisosBodegas.some(e=>e.Permiso =="Comprar") && hasPermission(user?.Personal.Permisos,'Modificar precios'))&&
                        <div className='d-flex justify-content-end'>
                            <Button variant="contained" endIcon={<AddCircleIcon />}  onClick={() => {setValue("3")}}
                                style={{  cursor: "pointer" ,marginBottom:10,marginTop:-10,backgroundColor:user.colors[2],borderRadius:40 }}>
                                  Nuevo
                            </Button>
                        </div>
                      }
                      {user?.typeUser == "EMPRESA"&&
                        <div className='d-flex justify-content-end'>
                        <Button variant="contained" endIcon={<AddCircleIcon />}  onClick={() => {setValue("3")}}
                            style={{  cursor: "pointer" ,marginBottom:10,marginTop:-10,backgroundColor:user.colors[2],borderRadius:40 }}>
                              Nuevo
                        </Button>
                    </div>
                      }
                      <Materiales setValue={setValue} setMaterialEdit={setMaterialEdit} setLoading={setLoading}/>
                  </>
              }
              {value === "2" &&
                  <>
                      {hasPermission(user?.Personal.Permisos,'Agregar material')&&
                          <div className='d-flex justify-content-end my-3'>
                              <Button variant="contained" startIcon={<AddCircleIcon />} style={{ backgroundColor: user.colors[2], cursor: "pointer",borderRadius:40 }}
                                onClick={()=>setModal(true)}>
                                  Agregar
                              </Button>
                          </div>
                      }
              
                      <Descartables setLoading={setLoading} setValue={setValue} modal={modal} setModal={setModal} />
                  </>
              }

          </Box>

        </>
      }

    </>
  );

}

export default MixingMetrial