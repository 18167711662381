import { Button, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import Info from './Info';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import TablasBodega from './TablasBodega';


const BodegaDetail = () => {
    const user = useSelector(state => state.user)
    const { empresa } = useSelector(state => state.empresa)
    const dataColor = user?.typeUser === 'EMPRESA' ? "#" + user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color
    const { id } = useParams()
    const sede = user?.PuntosBodega?.find(e => e._id == id)
    const [view, setView] = useState("Tablas")
    const navigate = useNavigate()


    return (
        <div>
            <nav className='d-flex gap-3 justify-content-between p-2  mb-2 '
                style={{ backgroundColor: user?.colors[1], borderRadius: 20 }}
            >
                <div className='ml-5 '>
                    <h5 style={{ color: "white", marginLeft: 10 }} className='mt-1'>Sede: {sede.Nombre}</h5>
                </div>

                <div className='NavIcons mx-4 my-2' >
                    {user?.typeUser === 'EMPRESA' &&
                        <Tooltip title="Info" >
                            <PersonPinIcon onClick={() => setView("Info")}
                                style={view === "Info" ? { color: "#fff" } : { color: '#666' }} />
                        </Tooltip>
                    }

                    <Tooltip title="Tablas">
                        <BackupTableIcon onClick={() => setView("Tablas")}
                            style={view === "Tablas" ? { color: "#fff" } : { color: '#666' }} />
                    </Tooltip>

                    {/* <Tooltip title="Graficas" >
                    <EqualizerIcon onClick={() => setView("Graficas")}
                    style={view === "Graficas" ?  { color: "#fff" }:{ color: '#666' }} />
                </Tooltip> */}
                </div>

            </nav>

            <div className='d-flex justify-content-end'>
                <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ cursor: "pointer", backgroundColor: user.colors[2], marginTop: 10, borderRadius: 40 }}
                    onClick={() => navigate(-1)}>
                    Atras
                </Button>
            </div>
            {view == "Info" && <Info sede={sede} />}
            {view == "Tablas" && <TablasBodega sede={sede} />}

        </div>
    )
}

export default BodegaDetail