import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../hooks/useForm';
import { PostAll } from '../../../service/base/base';
import { PostAllWithAuth, PutAllWithAuth, UseGetWithAuth2 } from '../../../service/base2';
import { fileUploadBodegas, fileUploadQR } from '../../../helpers/FileUpload';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import OutlinedInput from '@mui/material/OutlinedInput';
import { SplitingInitialsName, encontrarValores, generateSerial } from '../../../helpers/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { currentUser } from '../../../service/redux/action/LoginAction';
import QRCode from 'qrcode.react';
import Swal from 'sweetalert2';
import { bodegaAction, puntosBodegasAction } from '../../../service/redux/action/DataActions';
import FormLoadings from '../../../helpers/FormLoadings';
const {_Monedas} = require('../../../helpers/Monedas.json')

export const NewBodega = () => {
    const { empresas } = useSelector(state => state.empresa)
    const user = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    let dataColor = user?.typeUser === 'EMPRESA' ? user?.Personal?.Empresa?.Color : user?.Personal?.Empresas?.Color
    let id_empresa = empresas[0]?.typeUser === 'EMPRESA' ? user?.Personal?.Empresas?.id : user?.Personal?.Empresas?.id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const qrRef = useRef(null)
    const [file, setFile] = useState("")
    const [idMaterial, setIdMaterial] = useState([]);
    const [idPermiso, setIdPermiso] = useState([]);
    const [idDescartable, setIdDescartable] = useState([]);
    const [materialesBodegas, setMaterialesBodegas] = useState(user?.materiales||[]);
    const [arrayIdMateriales, setArrayIdMateriales] = useState([])
    const [permisosB,setPermisosB]=useState([])
    const [Descartables,setDescartables]=useState([])
    const [isChecked, setIsChecked] = useState(false);
    const [listNameMateriales, setListNameMateriales] = useState([]);
    const [listNameDescartables, setListNameDescartable] = useState([]);
    const [listpermisos, setLispermisos] = useState([]);
    const [bodegaEdit, setBodegaEdit] = useState([])
    const [show, setShow] = useState(false);
    const [predictions, setPredictions] = useState([]);
    const [coordinates, setCoordinates] = useState({ lat:4, lng:-72});
    const [address, setAddress] = useState(" ");
    const [data,setData]=useState()
    const [sub,setSub]=useState(true)
    const objetoRecibidoByparams = {};
    let canvas
    let myBlob
    for (let [key, value] of params.entries()) {
        objetoRecibidoByparams[key] = value;
    }
    const [Moneda,setMoneda]=useState((user?.typeUser === 'EMPRESA' && user?.Personal?.Empresa?.Servicios.some(e=>e=="65246345637f1900140aa59c")&& bodegaEdit) ? bodegaEdit.Monedas?.id || "64c85ab91dbf8c00144b45e4" : user?.Personal?.Empresa?.Monedas[0]? user?.Personal?.Empresa?.Monedas[0].id:"64c85ab91dbf8c00144b45e4")

    console.log('Monedas',_Monedas);
console.log(empresas[0].empresas);
console.log(bodegaEdit.Monedas?.id);
    let { Nombre, Logo, Color, Nit, Telefono, Direccion, Empresa, calculadoraMateriales, Correo, Monedas,
        formState, onInputChange, onResetForm } = useForm({
            Nombre: objetoRecibidoByparams ? objetoRecibidoByparams.Nombre : '',
            Empresa: id_empresa,
            Color:   objetoRecibidoByparams.Color? objetoRecibidoByparams.Color : `#${dataColor}`,
            // Logo: objetoRecibido ? objetoRecibido.Logo : '',
            Nit: objetoRecibidoByparams.id ? objetoRecibidoByparams.Nit : '',
            Telefono: objetoRecibidoByparams.id ? objetoRecibidoByparams.Telefono : '',
            Direccion: objetoRecibidoByparams.id ? objetoRecibidoByparams.Direccion : '',
            calculadoraMateriales: bodegaEdit ? bodegaEdit.calculadoraMateriales : '',
            Correo: objetoRecibidoByparams.Correo ? objetoRecibidoByparams.Correo: '',
            Monedas: Moneda,
        });
console.log('=======bodegaEdit=============');
console.log(bodegaEdit);
console.log(Monedas);
console.log('====================================');
    const getBodegas = async () => {
        // let jwToken = empresas[0].jwToken
        const mate = await UseGetWithAuth2("/materiales-descartables", user?.Personal?.uid)
        const Per = await UseGetWithAuth2("/permisos-bodegas", user?.Personal?.uid)
       
        if(mate.ok){ setDescartables(mate.resGet)}
        if(Per.ok){
            let permi = Per.resGet
            if(user?.Personal?.Empresa?.Servicios.some(e=>e=="65246345637f1900140aa59c")==false){ 
                let filter = permi.filter(i=>i.id !="64679ade25f79000140e8f17") 
                filter = filter.filter(i=>i.id !="646798ee25f79000140e8f14") 
                permi=filter
                
            }
            if(user?.Personal?.Empresa?.Servicios.some(e=>e=="64c8526feb605c0014653074")==false){ 
                let filter = permi.filter(i=>i.id !="64c852c8eb605c0014653077") 
                    filter = filter.filter(i=>i.id !="64c85298eb605c0014653076") 
               permi=filter
               
            }
            if(user?.Personal?.Empresa?.Servicios.some(e=>e=="64c8527eeb605c0014653075")==false){ 
                let filter = permi.filter(i=>i.id !="64c85298eb605c0014653076") 
               permi=filter  
            }
            if(user?.Personal?.Empresa?.Servicios.some(e=>e=="657a1dbf997b320014a3bcd4")==false){ 
                let filter = permi.filter(i=>i.id !="64679a5d25f79000140e8f16") 
               permi=filter
            }
            if(user?.Personal?.Empresa?.Servicios.some(e=>e=="657e83c0a63d6300141eec29")==false){ 
                let filter = permi.filter(i=>i.id !="64679c1325f79000140e8f1e") 
               permi=filter
            }
            setPermisosB(permi)
             
             
             
            
         }
        if (objetoRecibidoByparams?.id) {
            const filter = user?.bodegas.find(e=>e.id==objetoRecibidoByparams?.id)
            setBodegaEdit(filter)
            setAddress(filter.Direccion)
            setCoordinates(filter.Latitud?{lat:Number(filter.Latitud),lng:Number(filter.Longitud)}:{ lat:4, lng:-72})
            setListNameDescartable(filter.MaterialesDescartables?filter.MaterialesDescartables.map(e=>e.id):[])
            setIdDescartable(filter.MaterialesDescartables?filter.MaterialesDescartables.map(e=>e.id):[])
            setIdPermiso(filter.PermisosBodegas.map(e=>e.id))
            setLispermisos(filter.PermisosBodegas.map(e=>e.id))
        }
        
    }
    // const getMaterialesBodega = async () => {
    //     // const { resGet } = await UseGetWithAuth2(`/calculadora-materiales/?_where[Empresa]=${id_empresa}`, empresas[0].jwToken)
    //     setMaterialesBodegas(user?.materiales)
    // };
    const handleChangeSelectMaterial = (event) => {
        const { target: { value }, } = event;
        setIdMaterial(typeof value === 'string' ? value.split(',') : value,);
    };
    const handleChangeSelectDescarte = (event) => {
        const { target: { value }, } = event;
        setIdDescartable(typeof value === 'string' ? value.split(',') : value,);
    };
    const handleChangeSelectPermiso = (event) => {
        const { target: { value }, } = event;
        setIdPermiso(typeof value === 'string' ? value.split(',') : value,);
    };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        if(!objetoRecibidoByparams.id){  setData( generateSerial(SplitingInitialsName(formState.Nombre).toUpperCase()))}
    };

    const onNavigateBack = () => {
        navigate(-1)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        let bodega;
        let puntoBodega;
        setSub(false)
        formState.Empresa = user?.Personal?.Empresa?.id
        formState.MaterialesDescartables = idDescartable
        formState.PermisosBodegas= idPermiso
        formState.calculadoraMateriales = idMaterial.length !== 0 ? idMaterial : arrayIdMateriales
        
        
        if(idPermiso.some(i=>i=="64679ade25f79000140e8f17")){
            let PreciosMateriales = user?.materiales.filter(e=>formState.calculadoraMateriales.some(i=>i==e.id) )
            formState.PreciosMateriales = PreciosMateriales.map(e=>e.idPrecio)
            console.log("PreciosMateriales",PreciosMateriales);
            formState.Monedas =Moneda
        }
        
        if (objetoRecibidoByparams.Nombre) {
            // ACTUALIZAR------------------
            if (file?.name?.length > 0) {
                await fileUploadBodegas(file)
                .then(response => {
                    formState.Logo = response
                }).catch(error => {
                    console.log(error.message);
                })
                    
            }
           
            
            console.log(formState);
            const putBO =    await PutAllWithAuth(`/bodegases/${objetoRecibidoByparams.id}`, user?.Personal?.uid, formState)
            console.log('====================================');
            console.log("putBO",putBO.resPut);
            console.log('====================================');
            
            
            if(putBO.ok){
                onResetForm()
                Swal.fire({
                    position: 'center', icon: 'success', title: `El punto ${formState.Nombre} ha sido agregado`,
                    showConfirmButton: false,
                    timer: 1500
                })
                const bode =user?.bodegas.filter(e=>e.id!=bodegaEdit.id)
                bode.push(putBO.resPut)
                setLoading(false)
                dispatch(bodegaAction(bode))
                  navigate('/bodegas')
                  Logo=""
                  onResetForm()
            }else{
                Swal.fire({     icon: 'error', title: 'Oops...', text: 'Algo salio mal!' })
                onResetForm()
                setLoading(false)
                navigate('/bodegas')
            }
            // navigate('/bodegas')
        } else {
            // AGREGAR------------------------
            if (file?.name?.length > 0) {
                await fileUploadBodegas(file)
                    .then(response => {
                        formState.Logo = response
                    })
                    .catch(error => {
                        console.log(error.message);
                    })
            }
            console.log("formState",formState);
            formState.calculadoraMateriales = idMaterial.length !== 0 ? idMaterial : arrayIdMateriales
            formState.Color = formState.Color
            console.log(formState);
            await PostAllWithAuth(`/bodegases/`, user?.Personal?.uid, formState)
                .then(async(res) => {
                
                    if (isChecked) {
                        const r = await fileUploadQR(myBlob,formState.Nombre)   
                        const _Qrs = await PostAllWithAuth(`/qrs`, user?.Personal?.uid, {"Img":r,"Huella":data.split("/")[data.split("/").length - 1],
                            "Bodegas":res.resPost.data.id});
                        if(_Qrs.ok){                                                       
                            const resQrs = await _Qrs.resPost.data

                            let dataSedeBodega = {
                                calculadoraMateriales: formState.calculadoraMateriales||[],
                                Nombre: formState.Nombre,
                                Direccion: formState.Direccion,
                                Latitud: coordinates.lat.toString(),
                                Longitud: coordinates.lng.toString(),
                                Bodega: res.resPost.data.id,
                                Qr:resQrs.id,
                                MaterialesDescartables:formState.MaterialesDescartables ,
                                Moneda:Moneda,
                                PermisosBodegas:idPermiso
                            }
                            if(idPermiso.some(i=>i=="64679ade25f79000140e8f17")){
                                let PreciosMateriales = user?.materiales.filter(e=>formState.calculadoraMateriales.some(i=>i==e.id) )
                                dataSedeBodega.precios_materiales = PreciosMateriales.map(e=>e.idPrecio||"")
                            }
                            const sede = await  PostAllWithAuth(`/puntos-bodegas`, user?.Personal?.uid, dataSedeBodega)
                        
                            if(sede.ok){
                                onResetForm()
                                const bode =user?.bodegas
                                bode.push({...res.resPost.data})
                                dispatch(bodegaAction(bode))
                                const Puntobode =user?.PuntosBodega
                                Puntobode.push({...sede.resPost.data,empleados:[],Bodega:res.resPost.data.id,PermisosBodegas:sede.resPost.data.PermisosBodegas.map(e=>e.id)})
                                dispatch(puntosBodegasAction(Puntobode))
                                Swal.fire({
                                    position: 'center', icon: 'success', title: `El punto ${formState.Nombre} ha sido agregado`,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                Logo=""
                                setLoading(false)
                                navigate('/bodegas')
                            }else{
                                onResetForm()
                                setLoading(false)
                                Swal.fire({     icon: 'error', title: 'Oops...', text: 'Algo salio mal!' })
                            }
                        }else{
                            onResetForm()
                            setLoading(false)
                            Swal.fire({     icon: 'error', title: 'Oops...', text: 'Algo salio mal!' })
                        }
                    }else{
                        onResetForm()
                        const bode =user?.bodegas
                        bode.push(res.resPost.data)
                        dispatch(bodegaAction(bode))
                        Logo=""
                        if(res.ok){
                            Swal.fire({
                                position: 'center', icon: 'success', title: `El punto ${formState.Nombre} ha sido agregado`,
                                showConfirmButton: false,
                                timer: 1500
                              })
                              navigate('/bodegas')
                              setLoading(false)
                        }else{
                            onResetForm()
                            setLoading(false)
                            Swal.fire({     icon: 'error', title: 'Oops...', text: 'Algo salio mal!' })
                        }
                    }
                })
                .catch(error => {
                    console.log(error.message);
                    setLoading(false)
                    Swal.fire({     icon: 'error', title: 'Oops...', text: 'Algo salio mal!' })
                })
            // onResetForm()
            // navigate('/bodegas')
        }
    }

    const handleFileChanged = (e) => {
        setFile(e.target.files[0])
    }

    const encontrarValoresName = (arrayNombres) => {
        const resultados = [];
        for (let i = 0; i < arrayNombres.length; i++) {
            if (true) {
                resultados.push(arrayNombres[i].Material.Material);
            }
        }
        return resultados;
    };

    useEffect(() => {
        // if (id_empresa !== undefined) {
        //     getMaterialesBodega()
        // }
        getBodegas()
        setArrayIdMateriales(encontrarValores(user?.materiales, true))
    }, [id_empresa])

   

    useEffect(() => {
        if(bodegaEdit.Monedas){
            setMoneda(bodegaEdit.Monedas.id)
        }
        if (bodegaEdit.calculadoraMateriales) {
            const filter = user?.materiales.filter(e=> {
                if(bodegaEdit?.calculadoraMateriales.length>0 &&bodegaEdit?.calculadoraMateriales[0].Material){
                    return bodegaEdit?.calculadoraMateriales.find(i=>i.Material==e.Material.id)!== undefined &&bodegaEdit?.calculadoraMateriales.find(i=>i.Material==e.Material.id)
                }
                if(bodegaEdit?.calculadoraMateriales.length>0&&bodegaEdit?.calculadoraMateriales[0].Material ==undefined){
                    return bodegaEdit?.calculadoraMateriales.find(i=>i==e.Material.id)!== undefined &&bodegaEdit?.calculadoraMateriales.find(i=>i==e.Material.id)
                }
            })
            console.log("i.Material==",filter);
            setListNameMateriales(filter.map(e=>e.id))
            setIdMaterial(filter.map(e=>e.id))
        }
    }, [bodegaEdit])

    const handleAddressChange = async (e,newInputValue) => {
        setAddress(newInputValue);
        formState.Direccion = newInputValue
        setShow(true);
          if (window.google && newInputValue.length > 4) {
            let service;
              service = new window.google.maps.places.AutocompleteService();
              service.getPlacePredictions({ "input":newInputValue,"language": 'es' }, (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                  setPredictions(predictions);
                
                  OnhandleAddressChange(e,newInputValue)
                }
              });
          }
      }

      const OnhandleAddressChange = (event, value) => { 
        if (value) {
          setAddress(value);
          formState.Direccion = value
          
          // Encuentra el place_id de la predicción seleccionada
          const selectedPrediction = predictions.find((prediction) => prediction.description === value);
          if (selectedPrediction) {
            const placeId = selectedPrediction.place_id;
    
            // Ahora, utiliza el PlacesService para obtener los detalles del lugar
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));
            service.getDetails({ placeId }, (place, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                // Aquí tendrás los detalles del lugar, incluyendo latitud y longitud
                const { lat, lng } = place.geometry.location.toJSON();
                setCoordinates({ lat, lng });
                formState.Latitud =lat.toString()
                formState.Longitud=lng.toString()
              }
            });
          }
        }
      }

      useEffect(() => {
        if(!objetoRecibidoByparams.id && isChecked){
        if (qrRef.current) {
          canvas = qrRef.current.querySelector('canvas');
          if (canvas) {
              canvas.toBlob(async(blob) => {
              myBlob = blob;    
            });
          }
        }
        }
      }, [qrRef.current,data]);

    return (
        <>
        { loading && <FormLoadings/>}

        <div>
            {/* //  ====================> Navegación <====================*/}
            <nav className='d-flex gap-3 justify-content-between p-2 '
                style={empresas ? { backgroundColor: `#${dataColor}`, fontWeight: "bold",borderRadius:20 } : { color: "#666", fontWeight: "bold" ,borderRadius:20}}>
                <div className='ml-5'>
                    <h5 style={{ color: "white",marginLeft:10 }}>{bodegaEdit.Nombre ? 'Actualizar datos' : 'Nueva bodega'}</h5>
                </div>

            </nav>

            {/* //  ====================> Botón atras <====================*/}
            <Button variant="contained" startIcon={<ReplyAllIcon />} 
                style={{ cursor: "pointer", backgroundColor: user.colors[2],marginTop:10,borderRadius:40 }}
                onClick={onNavigateBack}
            >
                Atras
            </Button>

            
            <main className='mt-5 card'>

                 {/* //  ====================> Formulario <====================*/}
                <Form className='w-100 row m-4' onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 mb-3 ">
                            <TextField
                                label="Nombre bodega"
                                type="text"
                                placeholder="Nombre bodega"
                                fullWidth
                                required
                                name="Nombre"
                                value={Nombre}
                                onChange={onInputChange}
                            />
                        </div>

                        {/* <div className="col-12 col-md-6 col-lg-6  mb-3 " >
                            <TextField
                                label="Dirección bodega"
                                type="text"
                                placeholder="Dirección bodega"
                                fullWidth
                                name="Direccion"
                                value={Direccion}
                                onChange={onInputChange}
                            />
                        </div> */}
                        <div className="col-12 col-md-6 col-lg-6 " style={{}}>
                          <div  className="row">
                            <div  className="col-12">
                              <TextField
                                id='address'
                                type="text" 
                                value={address} 
                                label="Dirección"
                                required
                                placeholder={objetoRecibidoByparams?.Direccion?objetoRecibidoByparams?.Direccion:"Dirección"}
                                fullWidth
                                onChange={(e)=>{handleAddressChange(e,e.target.value)}}
                                // onBlur={(e)=>{handleAddressChange(e,e.target.value); setShow(false)}}
                                
                              />
                            </div>
                            <div  className="col-12" style={{zIndex:20,backgroundColor:"white"}}>
                              {predictions.length > 0 && show&& (
                                  <div style={{ border: '1px solid #ccc', width: '300px', position: 'absolute',zIndex:20,backgroundColor:"white" }}>
                                    {predictions.map((prediction) => (
                                      <div key={prediction.place_id} onClick={() => {OnhandleAddressChange(prediction,prediction.description);setShow(false);setAddress(prediction.description)}} style={{ padding: '10px', cursor: 'pointer' }}>
                                        {prediction.description}
                                      </div>
                                    ))}
                                  </div>
                                )}
                            </div>
                          </div>

                          
                      </div>
                    </div>
                    
                    <div className="row">
                        {/* //  ====================> Telefono <====================*/}
                        <div className="col-12 col-md-6 col-lg-6  mb-3 " >
                            <TextField
                                label="Teléfono bodega"
                                type="number"
                                placeholder="Teléfono bodega"
                                fullWidth
                                required
                                // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                name="Telefono"
                                value={Telefono}
                                onChange={onInputChange}
                            />
                        </div>
                           {/* //  ====================> NIT <====================*/}
                        <div className="col-12 col-md-6 col-lg-6  mb-3 " >
                            <TextField
                                label="Nit bodega"
                                type="number"
                                required
                                placeholder="Nit bodega"
                                fullWidth
                                name="Nit"
                                value={Nit}
                                onChange={onInputChange}
                            />
                        </div>
                          {/* //  ====================> Correo <====================*/}
                        <div className="col-12 col-md-6 col-lg-6  mb-3 " >
                            <TextField
                                label={bodegaEdit?bodegaEdit.Correo||"Email":"Email"}
                                type="email"
                                required
                                placeholder={bodegaEdit?bodegaEdit.Correo||"Email":"Email"}
                                fullWidth
                                name="Correo"
                                value={bodegaEdit?bodegaEdit.Correo||Correo:Correo}
                                onChange={onInputChange}
                            />
                        </div>
                    </div>

                    {/* //  ======================================> Selectores <======================================*/}
                    <div className="row">

                        {/* //  ====================> moneda <====================*/} 
                        {  user?.typeUser === 'EMPRESA' && user?.Personal?.Empresa?.Servicios.some(e=>e=="65246345637f1900140aa59c")&& 
                            <div className='col-12 col-md-4 col-lg-4 mb-3'>
                                <Box sx={{ minWidth: 120, maxWidth: 600 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="label-punto">Moneda</InputLabel>
                                        <Select
                                            displayEmpty
                                            labelId="label-punto"
                                            required
                                            value={Moneda==="64c85ab91dbf8c00144b45e4"? "COP" :Moneda=="64c85ad21dbf8c00144b45e5"?"MXN":"PEN"}
                                            onChange={(eve)=>{onInputChange(eve);setMoneda(eve.target.value)}}
                                            input={<OutlinedInput label='Monedas' />}
                                            renderValue={(selected) => { return selected; }}
                                            defaultValue={bodegaEdit ? bodegaEdit.Monedas?.Moneda ||Moneda==="64c85ab91dbf8c00144b45e4"?Moneda=="64c85ad21dbf8c00144b45e5"?"MXN":"PEN":"MXN":Moneda==="64c85ab91dbf8c00144b45e4"?Moneda=="64c85ad21dbf8c00144b45e5"?"MXN":"PEN":"MXN"}
                                            
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {user?.Personal?.Empresa?.Monedas.map((e) => (
                                                <MenuItem  value={e}  >
                                                    <em>{e=="64c85ab91dbf8c00144b45e4"?"COP":e=="64c85ad21dbf8c00144b45e5"?"MXN":"PEN"}</em>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        }
                        
                        {/* //  ====================> Descartables <====================*/}
                        <div className='col-12 col-md-4 col-lg-4 mb-3'>
                            <Box sx={{ minWidth: 120, maxWidth: 600 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="label-punto">Descartables</InputLabel>
                                    <Select
                                        multiple
                                        displayEmpty
                                        labelId="label-punto"
                                        value={idDescartable}
                                        required
                                        onChange={handleChangeSelectDescarte}
                                        input={<OutlinedInput label='Descartables' />}
                                        renderValue={(selected) => {
                                            if (bodegaEdit?.id) {
                                                return listNameDescartables?.join(', ');
                                            } else {
                                                return selected.join(', ');
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>{Descartables?.length > 0 ? 'Descartables:' : 'No tiene descartables'}</em>
                                        </MenuItem>
                                        {Descartables?.map((materialBodega) => (
                                            <MenuItem
                                                key={materialBodega.id}
                                                value={materialBodega.id}
                                            // style={getStyles(name, personName, theme)}
                                            >
                                                <Checkbox checked={idDescartable.indexOf(materialBodega.id) > -1} />
                                                {materialBodega.Material}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        {/* //  ====================> Logo Bodega <====================*/}
                        <div className="col-12 col-md-4 col-lg-4  mb-3 " >
                            <TextField
                                label="Logo bodega"
                                type="file"
                                placeholder="Logo bodega"
                                fullWidth
                                required={objetoRecibidoByparams.id?false:true}
                                focused
                                name="Logo"
                                value={sub?Logo:""}
                                // onClick={handlePictureClick}
                                onChange={handleFileChanged}
                            />
                        </div>
                        {/* //  ====================> Color <====================*/}  
                        <div className="col-12 col-md-4 col-lg-4  mb-3 " >
                            <TextField
                                label="Color bodega"
                                type="color"
                                placeholder="Color bodega"
                                fullWidth
                                required
                                name="Color"
                                value={Color}
                                onChange={onInputChange}
                            />
                        </div>
                        {/* //  ====================> Permisos <====================*/} 
                        <div className='col-12 col-md-4 col-lg-4 mb-3'>
                            <Box sx={{ minWidth: 120, maxWidth: 600 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="label-punto">Permisos</InputLabel>
                                    <Select
                                        multiple
                                        displayEmpty
                                        labelId="label-punto"
                                        required
                                        value={idPermiso}
                                        onChange={handleChangeSelectPermiso}
                                        input={<OutlinedInput label='Materiales recuperables' />}
                                        renderValue={(selected) => {
                                            if (bodegaEdit?.id) {
                                                return listpermisos.join(', ');
                                            } else {
                                                return selected.join(', ');
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>{permisosB?.length > 0 ? 'Permisos:' : 'No tiene Permisos'}</em>
                                        </MenuItem>
                                        {permisosB.map((e) => (
                                            <MenuItem
                                                key={e.id}
                                                value={e.id}
                                            // style={getStyles(name, personName, theme)}
                                            >
                                                <Checkbox checked={idPermiso.indexOf(e.id) > -1} />
                                                {e.Permiso}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        {/* //  ====================> Materiales <====================*/}
                        <div className='col-12 col-md-4 col-lg-4 mb-3'>
                            <Box sx={{ minWidth: 120, maxWidth: 600 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="label-punto">Materiales</InputLabel>
                                    <Select
                                        multiple
                                        displayEmpty
                                        labelId="label-punto"
                                        value={idMaterial}
                                        required
                                        onChange={handleChangeSelectMaterial}
                                        input={<OutlinedInput label='Materiales recuperables' />}
                                        renderValue={(selected) => {
                                            if (bodegaEdit?.id) {
                                                return listNameMateriales?.join(', ');
                                            } else {
                                                return selected.join(', ');
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>{(materialesBodegas&&materialesBodegas?.length) > 0 ? 'Materiales:' : 'No tiene Materiales'}</em>
                                        </MenuItem>
                                        {materialesBodegas&&materialesBodegas.filter(e=>{ 
                                        // Filtra solo los elementos con Moneda y que cumplen la condición adicional
                                            if (e.Moneda && _Monedas.some(i => i.moneda === e.Moneda && i.id === Moneda)) {
                                                return true;
                                            }
                                            return !e.Moneda;
                                        }).map((materialBodega) => (
                                            <MenuItem
                                                key={materialBodega.id}
                                                value={materialBodega.id}
                                            // style={getStyles(name, personName, theme)}
                                            >
                                                <Checkbox checked={idMaterial.indexOf(materialBodega.id) > -1} />
                                                {materialBodega.Material.Material}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>

                    </div>

                    {bodegaEdit.length === 0 &&
                        <div className='mb-3 col-12 col-md-4 col-lg-4  '>
                            <FormControlLabel
                                control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                                label="Agregar como sede bodega" />
                        </div>
                    }
                    {bodegaEdit.length === 0 && isChecked &&
                        <div style={{  }} ref={qrRef}>
                            <QRCode value={data?data:""}   id='canvas'/>
                        </div>
                    }

                    <div>
                        <button style={{backgroundColor:user.colors[1],color:"white",fontWeight:"bold"}}
                            className='btn  w-25' >
                            {objetoRecibidoByparams.Nombre ? 'Actualizar' : 'Guardar'}
                        </button>
                    </div>
                </Form>
            </main>

        </div>
        </>
    )
}
