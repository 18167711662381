import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Autocomplete,  Button, IconButton, MenuItem, Paper,  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField,  } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DeleteAllWithAuth } from '../../service/base2';
import { generadoresAction } from '../../service/redux/action/DataActions';
import Swal from 'sweetalert2';
import QrsModal from '../../helpers/Madales/Qrs';
const { v4: uuidv4 } = require('uuid');


export const PuntoGeneradorTbl = () => {
    const { empresas } = useSelector(state => state.empresa)
    const user = useSelector(state => state.user)
    let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa.Color : user?.bodegas[0]?.Color
    const [menuOpen, setMenuOpen] = React.useState(false);
    const navigate = useNavigate();
    const [generadores, setGeneradores] = useState(user.PuntosGeneradores)||[];
    const BodegasFlated = user.PuntosBodega?user.PuntosBodega.flatMap(e => e):[],
    dispatch = useDispatch()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [qr, setQr] = useState({})
    const [status, setStatus] = useState(false)

     const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const hasPermission = (permisoRequerido = ['no hay permisos']) => {
        const userPermissions = permisoRequerido
        return user?.Personal?.Permisos.some(permission => userPermissions.includes(permission.Permiso));
    };

 

    const filterByBodega =(newValue)=>{
        if (!newValue) {
            setGeneradores(user?.PuntosGeneradores);
        }else{
        const selectedObject = BodegasFlated.find((bodega) => bodega.Nombre === newValue);
       
        const punto = user?.PuntosGeneradores.filter(e => e.PuntosBodega.id === selectedObject.id);
       
        setGeneradores(punto);
        }
    }

    // useEffect(() => { }, [generadores])
 
    const DeleteGenerator = async(e)=>{

        await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esta acción!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteAllWithAuth('/puntos-generadores/'+e,user?.Personal?.uid)
                const filter = user?.PuntosGeneradores.filter(i=>i.id !== e)
         
                dispatch(generadoresAction(filter))
                Swal.fire(
                    'Eliminado!',
                    'El personal ha sido eliminada.',
                    'success'
                );
            }
        });
        
       
    }
    
    return (
        <>
            <nav className='d-flex gap-3 justify-content-between p-2  mb-2 '
                style={{ backgroundColor:user.colors[1],borderRadius:20 }}>
                <div className='ml-5'>
                    <h5 style={{ color: "white",marginLeft:10 }} className='mt-1'>Puntos generadores</h5>
                </div>
            </nav>
            
            
            <div className='d-flex justify-content-between'>
                {user?.typeUser === 'EMPRESA' &&<Autocomplete
                    id="combo-box-demo"
                    options={BodegasFlated.map((option) => option.Nombre)}
                    onChange={(e)=>filterByBodega(e.target.textContent)}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                    <TextField {...params} label="Filtrar por sede" variant="outlined" />
                    )}
                />}

                {hasPermission('Agregar generadores')&& (user.PuntosBodega && user.PuntosBodega.length>0) &&
                <Link className="navbar-brand my-4" to={`/nuevo-punto-generador`}>
                    <Button variant="contained" endIcon={<AddCircleIcon />} style={{ backgroundColor: user.colors[2],  cursor: "pointer",borderRadius:40}}>
                        Nuevo
                    </Button>
                </Link>
                }

            </div>

            <Paper sx={{ width: '100%', marginTop: 2,borderRadius:5 }}>
                <TableContainer >
                    <Table  aria-label="sticky table">
                        <TableHead>
                            <TableRow hover tabIndex={-1} key={uuidv4()}>
                                
                                <TableCell align={"center"} size='small'><b>Nombre</b></TableCell>
                                <TableCell align={"center"} size='small'> <b>Dirección</b></TableCell>
                                {user?.typeUser === 'EMPRESA'&& <TableCell align={"center"} size='small'><b>Gestor</b></TableCell>}
                                <TableCell align={"center"} size='small'><b>Qr</b></TableCell>
                                <TableCell align={"center"} size='small'> <b>Estado</b></TableCell>
                                <TableCell align={"center"} size='small'> <b>Acciones</b></TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {generadores && generadores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(e => (
                                <TableRow hover tabIndex={-1} key={uuidv4()}>
                                    
                                    <TableCell key={uuidv4()} align={"center"} size='small'> {e?.Nombre} </TableCell>
                                    <TableCell key={uuidv4()} align={"center"} size='small'> {e?.Direccion} </TableCell>
                                    { user?.typeUser === 'EMPRESA'&& <TableCell key={uuidv4()} align={"center"} size='small'>{e.PuntosBodega.Nombre} </TableCell>}
                                    <TableCell key={uuidv4()} align={"center"} size='small'>
                                        {e.Qr?
                                            <img src={e.Qr.Img} style={{width:40,height:40,cursor:"pointer"}} onClick={()=>{setQr(e.Qr); setStatus(true)}} />
                                            :"Sin Qr"
                                        }
                                    </TableCell>
                                    <TableCell key={uuidv4()} align={"center"} size='small' style={(e.Llenado && !e.asignado)?{color:"red",fontWeight:"bolder"}:{fontWeight:"normal"}}>
                                       {e.Llenado && !e.asignado?"¡Lleno, Prioridad alta!":"Operativo, Capacidad libre"}
                                    </TableCell>

                                    <TableCell align={"center"} size='small' style={{ position: "relative"}} onMouseLeave={()=>setMenuOpen(false)} >
                                    
                                        <IconButton aria-label="more"id="custom-button"
                                            style={{ position: "relative", width: 50, height: 50 }}
                                            onClick={() =>{ setMenuOpen( (menuOpen === false || menuOpen !==e._id) ? e.id : false)}}>
                                                <MoreVertIcon />
                                        </IconButton>
                                        
                                        {menuOpen == e.id && (
                                            <div 
                                                style={{    
                                                    position: "absolute",
                                                    top:20, right: 20,
                                                    backgroundColor: "#fff",
                                                    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                    zIndex: 2000, width: "17ch", borderRadius: 5,
                                                }}
                                            >
                                                <MenuItem onClick={() => { setMenuOpen(e.id);navigate(`/generadores/${e.id}`)}}>
                                                    Detalles
                                                </MenuItem>

                                               {hasPermission('Modificar generadores') && 
                                                    <MenuItem onClick={() => { setMenuOpen(e.id);navigate( `/nuevo-generador/${e.id}` ) }}>
                                                        Editar
                                                    </MenuItem>
                                               }

                                                {hasPermission('Eliminar generadores') && 
                                                    <MenuItem onClick={() => { setMenuOpen(e.id); DeleteGenerator(e.id)  }}>
                                                        Eliminar
                                                    </MenuItem>
                                                }
                                            </div>
                                            
                                            
                                            )
                                            }
                                       

                                    </TableCell>
                                </TableRow>

                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={generadores && generadores.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <QrsModal qr={qr} setStatus={setStatus} status={status}/>
        </>
    )
}
