import { Box, Button, Modal, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { UseGetWithAuth2 } from '../../../service/base2';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const { v4: uuidv4 } = require('uuid');

export const ModalSelectInforme = ({ e: ruta, color, setType, setInformesRutas }) => {
    const { empresas } = useSelector(state => state.empresa)
    const user = useSelector(state => state.user)


    const [seeModal, setSeeModal] = React.useState(false);
    // console.log(e)
    const handleClose = () => {
        setSeeModal(false)
    };

    const showModal = (array) => {
        // setSeeRutas(array)
        setSeeModal(true)
    }

    const style = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        pb: 8,
        borderRadius: 2
    };

    // const [dataInforme, setDataInforme] = useState([])
    // const [valuesInforme, setValuesInforme] = useState([])
    // const [materialInforme, setMaterialInforme] = useState([])

    // const getInforme = async () => {
    //     const { ok, resGet } = await UseGetWithAuth2(`/historial-recogidas/?_where[Ruta.Fecha]=${ruta?.Fecha}`, empresas[0].jwToken)
    //     if (ok) return setDataInforme(resGet)
    // };

    // useEffect(() => {
    //     getInforme()
    // }, [])
     console.log("tuta",ruta)


    return (
        <>

            <span onClick={() => showModal(true)} >
                <SignalCellularAltIcon />
            </span>
            <Modal
                open={seeModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h5>Informes</h5>
                    <TableContainer sx={{ minHeight: 440, maxHeight: 440 }}>
                        <Table aria-label="sticky table">
                            <TableHead>
                                <TableRow hover tabIndex={-1} key={uuidv4()}>
                                    <TableCell align={"center"}>
                                        <b>Locación</b>
                                    </TableCell>

                                    <TableCell align={"center"}>
                                        <b>Dirección</b>
                                    </TableCell>

                                    <TableCell align={"center"}>
                                        <b>Ver informe</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ruta.PuntosGeneradores.map((item) => (
                                    <TableRow hover tabIndex={-1} key={uuidv4()}>
                                        <TableCell align={"center"}>
                                            {item.Nombre}
                                        </TableCell>

                                        <TableCell align={"center"}>
                                            {item.Direccion}
                                        </TableCell>

                                       {ruta.Completada&&
                                        <TableCell align={"center"}>
                                            <Tooltip title="Ver informe">
                                                <Link className="navbar-brand" to={{ pathname: `/informes-ruta`, search: `?${new URLSearchParams({ idPuntoGenerador: item.id, fechaRuta: ruta?.id, color: color||user.colors[3] }).toString()}` }} >
                                                    <span className='mx-1'
                                                        style={{ cursor: 'pointer', color: color, textDecoration: 'underline' }}>
                                                        <SignalCellularAltIcon />
                                                        {/* {console.log(ruta)} */}
                                                    </span>
                                                </Link>
                                            </Tooltip>
                                        </TableCell>
                                        }
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button className='mt-2' variant='contained' color="error" onClick={handleClose}>Cerrar</Button>
                    {/* 
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={e && e.length}
                // rowsPerPage={rowsPerPage}
                // page={page}
                // onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
                </Box>
            </Modal>
        </>
    )
}
