
import QRCode from 'qrcode.react';
import ReactDOM from 'react-dom'; 
export const fileUploadBodegas = async (file) =>{

    const cloudUrl = 'https://api.cloudinary.com/v1_1/dhu7txw3c/upload';
    const formData = new FormData();
    formData.append('upload_preset','hilo-app-bodegas');
    formData.append('file',file);
    
    
    const resp = await fetch(cloudUrl, {
        method: 'POST',
        body: formData
    })
       const cloudResp = await resp.json();
       return cloudResp.secure_url;

}

export const fileUploadPersonal = async (file) =>{

    const cloudUrl = 'https://api.cloudinary.com/v1_1/dhu7txw3c/upload';
    const formData = new FormData();
    formData.append('upload_preset','hilo-app-personal');
    formData.append('file',file);
    
    
    const resp = await fetch(cloudUrl, {
        method: 'POST',
        body: formData
    })
       const cloudResp = await resp.json();
       return cloudResp.secure_url;

}

export const fileUploadQR = async (file,empresa) =>{
    let bodega

    switch (empresa) {
        case "ASEMAR":
            bodega="ASEMAR"
            break;
        case "Ambientes Plasticos":
            bodega="AmbientesPlasticos"
            break;
        case "ECOFIXING":
            bodega="ECOFIXING"
            break;
        case "Veolia":
            bodega="Veolia"
            break;
        default:
            bodega=empresa
            break;
    }

    const cloudUrl = 'https://api.cloudinary.com/v1_1/dhu7txw3c/upload';
    const formData = new FormData();
    formData.append('upload_preset',`HiloApp-Qrs-Bodegas`);
    formData.append('file',file);
    formData.append('folder',`HiloApp/Qrs/${bodega}`)
    formData.append(`quality`,`auto:best`)
    
    
    const resp = await fetch(cloudUrl, {
        method: 'POST',
        body: formData
    })
       const cloudResp = await resp.json();
       console.log('====================================');
       console.log("cloudResp",cloudResp);
       console.log('====================================');
      
       return cloudResp.secure_url;

}
export const fileUploadQRUsuario = async (file) =>{

    const cloudUrl = 'https://api.cloudinary.com/v1_1/dhu7txw3c/upload';
    const formData = new FormData();
    formData.append('upload_preset',`HiloApp-Qrs-Bodegas`);
    formData.append('file',file);
    formData.append('folder',`HiloApp/Qrs/Personal`)
    formData.append(`quality`,`auto:best`)
    
    
    const resp = await fetch(cloudUrl, {
        method: 'POST',
        body: formData
    })
       const cloudResp = await resp.json();
       console.log('====================================');
       console.log("cloudResp",cloudResp);
       console.log('====================================');
      
       return cloudResp.secure_url;

}




export async function generarQREnBlob(texto) {
    try {
      // Crea un elemento de div
      const container = document.createElement('div');
  
      // Renderiza el componente QRCode en el div
      ReactDOM.render(<QRCode value={texto} />, container);
  
      // Espera a que el componente QRCode se renderice completamente
      await new Promise(resolve => setTimeout(resolve, 0));
  
      // Obtiene el canvas del div
      const canvas = container.querySelector('canvas');
  
      if (!canvas) {
        throw new Error('No se pudo obtener el canvas.');
      }
  
      // Convierte el canvas a un blob
      return new Promise(resolve => {
        canvas.toBlob(blob => {
          if (!blob) {
            throw new Error('Error al convertir el canvas a blob.');
          }
          resolve(blob);
        }, 'image/png');
      });
    } catch (error) {
      console.error('Error al generar el código QR:', error);
    }
  }

  
export const sendPdf = async (file,to,subject,text,token)=>{
    const Url = `https://hiloapi.herokuapp.com/send-email`
    const formData = new FormData();
    formData.append('file',file)
    formData.append('to',to)
    formData.append('subject',subject)
    formData.append('text',text)

    const resp = await fetch(Url,{method:'POST',body:formData,headers:{Authorization: `Bearer ${token}`}})
    const response = await resp.json();
   

    return response
}

export async function fetchImageAsBase64(url) {
    const response = await fetch(url);
  
    // Verificación de la respuesta
    if (!response.ok) {
      throw new Error('Failed to fetch image');
    }
  
    const blob = await response.blob();
  
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        const base64data = reader.result.split(",")[1];
        
  
        resolve(base64data);
      };
      reader.onerror = function (error) {
        reject(error);
      };
      
      reader.readAsDataURL(blob);
    });
  }
  