import React, { useEffect } from 'react'
import {  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { convertWeight, getAbbreviation, formatDate } from '../../../../../helpers/Utils';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { generateExcel } from '../../../../../helpers/Pdfs/GenerateExcel';
import { procesarVisitas } from './functions/functions';
const { v4: uuidv4 } = require('uuid');


const HistorialVisitas = ({historialRecogida,dates}) => {
    const { user } = useSelector(state => state)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const functionSum = (data) => {
        
        let sum = 0;
        let unidad_de_peso = "kilogramo"
        data.forEach(element => {
           let num = convertWeight(Number(element.value), element.Unidad_de_Peso,unidad_de_peso)
              sum += Number(num)
             
        });

        return sum + " " + getAbbreviation(unidad_de_peso)
    }
    
   const  dataTosend = historialRecogida.length>0? procesarVisitas(historialRecogida,user)
        // (e=>{
        // return {
        //     Fecha:new Date(e.Fecha).toLocaleDateString(),
        //     generador:e.PuntoGenerador.Nombre,
        //     Gestor:e.Bodega.Nombre,
        //     Ruta:e.Ruta.id,
        //     Total_recuperado:e.Informe?functionSum(e.Informe):"No hay datos"
        // }
        //})
    :[]
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {}, [dates])
    const Excel =()=>{
        if(dataTosend.length>0){
            let title = dates == {}||dates.inicio == undefined ?"Informe historico":`Informe periodo de  ${formatDate(new Date(dates.inicio).toLocaleString())} a ${formatDate(new Date(dates.fin).toLocaleString())}`
            generateExcel(dataTosend,title)
        }else{
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'No hay datos para generar archivo.',
                showConfirmButton: false,
                timer: 1500
              })
        }
    }
   
   

  return (
    <div>
        <div style={{position:"absolute",top:22, right:50,zIndex:20}}>
            <Tooltip title="Descargar excel" >
                <CloudDownloadIcon style={{ color: '#666' }} onClick={Excel}/>
            </Tooltip>
        </div>

         <TableContainer sx={{  maxHeight: 840, borderRadius:5}}>
                    <Table aria-label="sticky table">
                        <TableHead style={{zIndex:-10}}>
                            <TableRow hover tabIndex={-10} key={uuidv4()} >

                                <TableCell align={"center"} size='small'> <b>Generador</b> </TableCell>
                                {user?.typeUser === 'EMPRESA'&&
                                    <TableCell align={"center"} size='small'>
                                        <b>Gestor</b>
                                    </TableCell>
                                }

                                <TableCell align={"center"} size='small'> <b>Visitas</b> </TableCell>
                                <TableCell align={"center"} size='small'> <b>Atención en locación</b> </TableCell>
                                <TableCell align={"center"} size='small'> <b>Total recuperado</b> </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataTosend.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                                <TableRow hover tabIndex={-1} key={uuidv4()} >
                                    
                                    <TableCell key={uuidv4()} align={"center"} size='small'> {e.Locacion} </TableCell>

                                    {user?.typeUser === 'EMPRESA'&&
                                        <TableCell key={uuidv4()} align={"center"} size='small'>
                                             {e.gestor?e.gestor:"No hay datos"}
                                        </TableCell>
                                    }

                                    <TableCell key={uuidv4()} align={"center"}>{e.Visitas?e.Visitas:"No hay datos"}</TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.MediaCalificacion?e.MediaCalificacion:"No hay datos"}
                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.TotalRecuperado?e.TotalRecuperado+" KG.":"No hay datos"}
                                    </TableCell>

                                    

                                </TableRow>

                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={dataTosend&& dataTosend.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
    </div>
  )
}

export default HistorialVisitas