import { Avatar, IconButton, MenuItem, Paper, Stack, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Table, Button } from 'react-bootstrap'
import { GetAllQr, SplitingInitialsName, hasPermission } from '../../../../helpers/Utils';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { DeleteAllWithAuth, UseGetWithAuth2 } from '../../../../service/base2';
import { useDispatch, useSelector } from 'react-redux';
import { ViewSelectMaterialBodega } from '../ViewSelectMaterialBodega';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { puntosBodegasAction } from '../../../../service/redux/action/DataActions';
import QrsModal from '../../../../helpers/Madales/Qrs';
const { v4: uuidv4 } = require('uuid');

export const TablaPuntosBodegas = ({ bodegaById, setType, setSedeEdit = [],bodegaID }) => {
  const { empresas } = useSelector(state => state.empresa)
  const user = useSelector(state => state.user);
  let dataColor = empresas[0]?.typeUser === 'EMPRESA' ? empresas[0]?.empresas?.data?.Color : user?.bodegas[0]?.Color;
  const [menuOpen, setMenuOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [PuntosBodega, setPuntosBodega] = useState(bodegaById)
  const [qr, setQr] = useState({})
  const [status, setStatus] = useState(false)
  const navigate =useNavigate()


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DeleteSede=async(e)=>{
      setMenuOpen(false)
      console.log("bodega",e);
      await Swal.fire({
          title: '¿Estás seguro?',
          text: `Se perderan todos los datos asociados  \n\n ¡No podrás revertir esta acción!`,
          icon: 'warning',
          iconColor:"red",
          showCancelButton: true,
          confirmButtonColor: user?.colors[2],
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminar!'
      }).then(async(result) => {
          const filter = user?.PuntosBodega.filter(t=>t.id!==e.id)
          const t = await  DeleteAllWithAuth(`/puntos-bodegas/${e.id}`, user?.Personal?.uid)
          setPuntosBodega(filter.filter(r=>r.Bodega==e.Bodega))
          if(t.ok){
            dispatch(puntosBodegasAction(filter))
            if(e.empleados.length>0){
              const idU =e.empleados.map(idPunto => +"id_in"+idPunto.user.id+"&")
             const q = await DeleteAllWithAuth("/personals?_where[PuntosBodega]="+e.id,user?.Personal?.uid)
             const r = await DeleteAllWithAuth("/users?_where[id_in]="+idU.join(','),user?.Personal?.uid)
             console.log("res1",q);
             console.log("res2",r);
             if(q.ok && r.ok){
                Swal.fire(
                  'Eliminado!',
                  'La sede ha sido eliminada.',
                  'success'
                )
                
             }else{
              Swal.fire({
                title: 'Algo salio mal',
                text: `inténtalo nuevamente más tarde. Si el problema continúa, ponente contacto con nuestro equipo de soporte`,
                icon: 'warning',
              })
             }
            }else{
              Swal.fire(
                'Eliminado!',
                'La sede ha sido eliminada.',
                'success'
              )
            }
          }
          // deleteS(e.id)
          
      })

  }
  
  return (
    <>
    {/*  */}
      <Link className=" d-flex justify-content-end m-2"  to={{ pathname: `/nueva-sede-bodega`, search: `?${new URLSearchParams({ bodegaById: bodegaID  }).toString()}` }}
        style={{borderRadius:20}}>
        <Button variant="contained" style={{ backgroundColor: user.colors[2], cursor: "pointer", color: 'white',borderRadius:3 }}>
          <DomainAddIcon className=' text-white' />
        </Button>
      </Link>

      <Paper sx={{ width: '100%',  }}>
        <TableContainer sx={{ }}>
          <Table  aria-label="sticky table">

            <TableHead >
                <TableRow hover tabIndex={-1} key={uuidv4()} >
                    <TableCell align={"center"} size='small' > <b>Logo</b> </TableCell>
                    <TableCell align={"center"} size='small' > <b>Nombre</b> </TableCell>
                    <TableCell align={"center"} size='small' > <b>Direccion</b> </TableCell>
                    <TableCell align={"center"} size='small' > <b>Personal</b> </TableCell>
                    <TableCell align={"center"} size='small' > <b>QR</b> </TableCell>
                    <TableCell align={"center"} size='small' > <b>Acciones</b> </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
              {PuntosBodega&&PuntosBodega?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                <TableRow hover tabIndex={-1} key={uuidv4()} >

                  <TableCell key={uuidv4()} align={"center"} size='small' onClick={()=>navigate(`/sede/${e._id}`)} >
                      <Stack direction="row" justifyContent={"center"} style={{cursor:"pointer"}}>
                          <Avatar sx={{ bgcolor: `#${dataColor}` }}>{SplitingInitialsName(e.Nombre)}</Avatar>
                      </Stack>
                  </TableCell>

                  <TableCell key={uuidv4()} align={"center" } size='small' >{e.Nombre} </TableCell>
                  <TableCell key={uuidv4()} align={"center"}  size='small'> {e.Direccion} </TableCell>
                  <TableCell align={"center"} size='small' > {e.empleados.length} </TableCell>
                  <TableCell align={"center"} size='small' > 
                  {e.Qr?
                    <img src={e.Qr.Img} style={{width:35,height:35,cursor:"pointer"}} onClick={()=>{setQr(e.Qr); setStatus(true)}} />
                    :"Sin Qr"
                  }
                  </TableCell>

                  <TableCell key={uuidv4()} align={"center"} size='small' style={{ position: "relative"}} onMouseLeave={()=>setMenuOpen(false)} >

                      <IconButton aria-label="more" id="custom-button"
                          style={{ position: "relative", width: 50, height: 50 }}
                          onClick={() => setMenuOpen((menuOpen === false || menuOpen !==e._id) ? e._id : false)} >
                          <MoreVertIcon />
                      </IconButton>
                                                    
                      {menuOpen == e._id && (
                            <div  style={{
                                position: "absolute",top:20, right: 20, backgroundColor: "#fff",boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                zIndex: 2000, width: "17ch",borderRadius: 5,
                                }}
                            >
                              <MenuItem onClick={() => { setMenuOpen(e._id); navigate(`/sede/${e._id}`) }}>
                                    Detalles
                              </MenuItem>
                            {hasPermission(user?.Personal?.Permisos,'Modificar sedes') && 
                                    <MenuItem onClick={() => { setMenuOpen(e._id);  navigate({ pathname:  `/nueva-sede-bodega`, search: `?${new URLSearchParams({...e,bodegaById: bodegaID }).toString()}`})}}>
                                        Editar
                                    </MenuItem>
                            }
                            {hasPermission(user?.Personal?.Permisos,'Eliminar sede') && 
                                <MenuItem onClick={() => { setMenuOpen(e._id); DeleteSede(e)  }}>
                                    Eliminar
                                </MenuItem>
                            }
                            </div>
                                    
                      )}
                  </TableCell>
                </TableRow>

              ))}

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={PuntosBodega && PuntosBodega.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <QrsModal qr={qr} setStatus={setStatus} status={status}/>
    </>
  )
}
