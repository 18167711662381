// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCGaFIVVAmh0ZJXYeTBCAmeqL-d6ufGo08",
  authDomain: "nidoapp-97e45.firebaseapp.com",
  projectId: "nidoapp-97e45",
  storageBucket: "nidoapp-97e45.appspot.com",
  messagingSenderId: "816778511441",
  appId: "1:816778511441:web:a931267ac673c7d679a470",
  measurementId: "G-BJQZBBB58C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export{
    app,
}