import { Button, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ModalDetailRuta } from '../../../../rutas/modals/ModalDetailRuta'
import { ModalSelectInforme } from '../../../../rutas/modals/ModalSelectInforme'
import { useSelector, useDispatch } from 'react-redux'
import { hasPermission } from '../../../../../helpers/Utils'
import AddRoadIcon from '@mui/icons-material/AddRoad';
import { Link, useNavigate } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { rutasAction } from '../../../../../service/redux/action/DataActions'
import Swal from 'sweetalert2'
import { DeleteAllWithAuth } from '../../../../../service/base2'
const { v4: uuidv4 } = require('uuid');



const Rutas = ({ sede }) => {
    const user = useSelector(state => state.user)
    const [menuOpen, setMenuOpen] = useState(false);
    const personalsById = user?.PuntosBodega ? user?.PuntosBodega.filter(punto => punto.Bodega === sede.Bodega).flatMap(e => e.empleados) : []
    const dataColor = user?.typeUser === 'EMPRESA' ? "#" + user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const PersonalIds = personalsById.map(person => person.id); // Extrae los ids de Personal

    const rutasFiltradas = user?.rutas
        .filter(ruta => {
            return ruta.PuntosBodega && sede.id == ruta.PuntosBodega.id
            //return PersonalIds.some(id => (ruta.Personal && id === ruta.Personal.id));
        });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const findUsername = (ruta) => {
        for (const e of user?.PuntosBodega || []) {
            for (const i of e.empleados || []) {
                if (ruta.Personal?.id) {
                    if (i.id === ruta.Personal.id) {
                        return i.user.username;
                    } else { return "--"; }
                } else { return "--"; }
            }
        }
        return "--";
    };

    const eliminarRuta = async (id) => {
        console.log('====================================');
        console.log(id);
        console.log('====================================');
        setMenuOpen(false)
        await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esta acción!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: dataColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const { ok } = await DeleteAllWithAuth(`rutas/${id}`, user?.Personal?.uid);
                console.log(ok);
                if (ok) {

                    Swal.fire({
                        position: 'center', icon: 'success', title: `La ruta ha sido eliminada`,
                        showConfirmButton: false,
                        timer: 2500
                    })

                }
            }
        }).then(() => {
            const routes = user?.rutas?.filter(e => e.id !== id)
            // for (let index = 0; index < route.PuntosGeneradores.length; index++) {     
            //     const { resPut } = await PutAllWithAuth(`/puntos-generadores/${route.PuntosGeneradores[index]._id}`, user?.Personal?.uid, { asignado: false})     
            // }
            console.log('====================================');
            console.log("routes", routes);
            console.log('====================================');
            dispatch(rutasAction(routes))
        })
    };
    useEffect(() => { }, [user?.rutas])
    return (
        <Paper sx={{ width: '100%' }}>
            {/* // to="/nueva-ruta" */}
            {hasPermission(user?.Personal?.Permisos, 'Asignar ruta') &&
                <div className='d-flex justify-content-end mb-3'>
                    {/* <Link className="navbar-brand" to="/nueva-ruta"  > */}
                    <Button variant="contained" style={{ backgroundColor: dataColor, cursor: "pointer", borderRadius: 40 }} onClick={() => { navigate({ pathname: `/nueva-ruta`, search: `?${new URLSearchParams({ Bodega: sede.Bodega, PuntosBodega: sede.id }).toString()}` }) }}>
                        <div className='d-flex gap-3 ' style={{ height: 25 }}>
                            <p>Asignar ruta</p>
                            <AddRoadIcon />
                        </div>
                    </Button>
                    {/* </Link> */}
                </div>
            }
            <TableContainer sx={{ minHeight: 440, maxHeight: 840 }}>

                <Table aria-label="sticky table">

                    <TableHead>
                        <TableRow hover tabIndex={-1} key={uuidv4()}>
                            <TableCell align={"center"} size='small'> <b>Nombre</b>  </TableCell>
                            <TableCell align={"center"} size='small'> <b>Teléfono</b> </TableCell>
                            <TableCell align={"center"} size='small'> <b>Fecha</b> </TableCell>
                            <TableCell align={"center"} size='small'> <b>Estado</b>  </TableCell>
                            <TableCell align={"center"} size='small'> <b>Locaciones</b> </TableCell>
                            <TableCell align={"center"} size='small'> <b>Informes</b> </TableCell>
                            <TableCell align={"center"} size='small'> <b>Acciones</b> </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rutasFiltradas && rutasFiltradas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ruta) => (

                            <TableRow hover tabIndex={-1} key={uuidv4()}>

                                <TableCell align={"center"}> {findUsername(ruta)} </TableCell>
                                <TableCell align={"center"}> {ruta?.Personal ? ruta?.Personal.Telefono || "--" : "--"} </TableCell>
                                <TableCell align={"center"}>  {ruta.Fecha} </TableCell>

                                <TableCell align={"center"} size='small'>
                                    {ruta.Completada ? <span className='text-success'>Completada</span> : <span className='text-danger'>Incompleta</span>}
                                </TableCell>

                                <TableCell align={"center"}>
                                    <span className='mx-1'
                                        style={{ cursor: 'pointer', color: ruta.Bodega.Color, textDecoration: 'underline' }}>
                                        <ModalDetailRuta e={ruta.PuntosGeneradores} />
                                    </span>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <span className='mx-1'
                                        style={{ cursor: 'pointer', color: ruta.Bodega.Color, textDecoration: 'underline' }}>
                                        <ModalSelectInforme e={ruta} color={ruta.Bodega.Color} />
                                    </span>
                                </TableCell>

                                <TableCell key={uuidv4()} align={"center"} style={{ position: "relative" }} onMouseLeave={() => setMenuOpen(false)}>

                                    <IconButton aria-label="more" id="custom-button"
                                        style={{ position: "relative", width: 50, height: 50 }}
                                        onClick={() => setMenuOpen((menuOpen === false || menuOpen !== ruta.id) ? ruta.id : false)}>
                                        <MoreVertIcon />
                                    </IconButton>

                                    {menuOpen == ruta.id && (
                                        <div style={{
                                            position: "absolute", top: 20, right: 20, backgroundColor: "#fff", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                            zIndex: 2000, width: "17ch", borderRadius: 5,
                                        }}>

                                            {hasPermission(user?.Personal?.Permisos, 'Modificar rutas') && !ruta.Completada &&
                                                <MenuItem onClick={() => { setMenuOpen(ruta.id); navigate({ pathname: `/nueva-ruta`, search: `?${new URLSearchParams({ ruta: ruta.id, Bodega: sede.Bodega, PuntosBodega: sede.id }).toString()}` }) }}>
                                                    Editar
                                                </MenuItem>
                                            }
                                            {hasPermission(user?.Personal?.Permisos, 'Eliminar rutas') && new Date(ruta.Fecha) > new Date() &&
                                                <MenuItem onClick={() => { setMenuOpen(ruta.id); eliminarRuta(ruta.id) }}>
                                                    Eliminar
                                                </MenuItem>
                                            }
                                        </div>

                                    )}
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={rutasFiltradas && rutasFiltradas.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}

export default Rutas