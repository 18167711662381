import { Avatar, Badge, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditRoadIcon from '@mui/icons-material/EditRoad';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useSelector } from 'react-redux';
import { SplitingInitialsName } from '../../../helpers/Utils';
const { v4: uuidv4 } = require('uuid');

export const RutaPendiente = ({ hasPermission, puntosGeneradores, setType, getPuntosGeneradores, setRutaEdit }) => {
    const { empresas } = useSelector(state => state.empresa)

    return (
        <div>
            <Button variant="contained" style={{ backgroundColor: `#${empresas[0]?.empresas?.data?.Color}`, position: "absolute", top: 200, right: 25, cursor: "pointer", color: 'white' }}
                onClick={() => {
                    getPuntosGeneradores()
                }}
            >
                {/* Pendientes <NotificationsActiveIcon />
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {puntosGeneradores.length}
                            <span class="visually-hidden">unread messages</span>
                        </span> */}

                <Badge badgeContent={puntosGeneradores.length} color="primary">
                    <div className='d-flex gap-3 ' style={{height:25}}>
                        <p>Pendientes</p>
                        <NotificationsActiveIcon />
                   </div>
                </Badge>

            </Button>

            <Paper sx={{ width: '100%', marginTop: 7 }}>
                <TableContainer sx={{ minHeight: 440, maxHeight: 840 }}>
                    <Table aria-label="sticky table">
                        <TableHead >
                            <TableRow hover tabIndex={-1} key={uuidv4()} >

                                <TableCell align={"center"}>
                                    <b>Avatar</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Locación</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Direccion</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Acciones</b>
                                </TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {puntosGeneradores?.map((e) => (
                                <TableRow hover tabIndex={-1} key={uuidv4()} >

                                    <TableCell key={uuidv4()} align={"center"}>

                                        <Stack direction="row" justifyContent={"center"}>
                                            <Avatar sx={{ bgcolor: `#${empresas[0]?.empresas?.data?.Color}` }}>{SplitingInitialsName(e.Nombre)}</Avatar>
                                        </Stack>

                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.Nombre}
                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.Direccion}
                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        <div className='d-flex gap-3 justify-content-center'>
                                            <Tooltip title="Asignar ruta">
                                                {hasPermission(['Asignar ruta', 'Autoasignación de Rutas']) ? (
                                                    <AutoStoriesIcon color='primary'
                                                        onClick={() => {
                                                            setRutaEdit(e)
                                                            setType('NewRuta')
                                                        }}
                                                    />

                                                ) : (
                                                    // Si no se tienen los permisos necesarios no se muestra nada
                                                    <span style={{ display: 'none' }}></span>
                                                )}
                                            </Tooltip>
                                        </div>
                                    </TableCell>


                                </TableRow>

                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={20}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
            </Paper>
        </div>
    )
}
