import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteAllWithAuth, UseGetWithAuth2 } from '../../../service/base2'
import { Avatar, IconButton, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import { ItemsPersonalBodega } from './tablas/ItemsPersonalBodega'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { SplitingInitialsName } from '../../../helpers/Utils'
import Swal from 'sweetalert2'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { puntosBodegasAction } from '../../../service/redux/action/DataActions'
const { v4: uuidv4 } = require('uuid');

export const PersonalBodegas = ({bodegaById, hasPermission, personalsById, setPersonalBodegaEdit, setType }) => {
    const { empresas } = useSelector(state => state.empresa)
    const user = useSelector(state => state.user)
    const dispatch= useDispatch()
    let dataColor = user?.typeUser === 'EMPRESA' ? user?.Personal?.Empresa?.Color :user?.bodegas[0]?.Color
    const [employes, setEmployes] = useState(personalsById)
    const [menuOpen, setMenuOpen] = useState(false)
    const [idBodega, setIdBodega] = useState(bodegaById?.id)
    const navigate = useNavigate();
   

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const eliminarPersonalEmpresa = async (id) => {
        console.log(id);
        await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esta acción!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then(async(result) => {
            if (result.isConfirmed) {
                const res = await DeleteAllWithAuth(`personals/${id.id}`, user?.Personal?.uid);
                const res2 = await DeleteAllWithAuth(`users/${id.user.id}`, user?.Personal?.uid);
                console.log(1,res);
                console.log(2,res2);
                let empleados = employes.filter(e => e.id !== id);
                const updatePunto = user?.PuntosBodega.filter(e=>e.id!=id.PuntosBodega.id)
                updatePunto.push({...user?.PuntosBodega.find(e=>e.id==id.PuntosBodega.id),empleados:user?.PuntosBodega.find(e=>e.id==id.PuntosBodega.id).empleados.filter(e => e.id !== id.id)})
                console.log(updatePunto);
                dispatch(puntosBodegasAction(updatePunto))
                Swal.fire(
                    'Eliminado!',
                    'El personal ha sido eliminada.',
                    'success'
                );
            }
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const gettingPersonal = async ()=>{
      
        let _q = personalsById.map(e=>e.user).join('&id_in=');
        
        let empleados = await UseGetWithAuth2(`/users?${_q}`, user?.Personal?.uid);
        let empleadosFlated = personalsById.map(e => {
            return {
                ...e,
                Nombre: empleados.resGet.find(empleado => empleado.id === e.user).username,
                idUser: empleados.resGet.find(empleado => empleado.id === e.user).id,
            }
        })
        setIdBodega(bodegaById?.id)
        // }
      return  setEmployes(empleadosFlated);
    }

    useEffect(() => {
    }, [personalsById,bodegaById])

    const showDetails = (id) => {
        navigate('/nuevo-personal/'+id);
    };

    const Editetails = (id) => {
        navigate('/nuevo-personal-empresa/'+id);
    };

    return (
        <>
            
            <Paper sx={{ width: '100%',}}>
            {hasPermission('Agregar Personal') &&
                <Link className="d-flex justify-content-end p-2" to={{pathname:`/nuevo-personal-bodega/`,search: `?${new URLSearchParams({item:idBodega}).toString()}`}} >
                    <div style={{ backgroundColor: user.colors[2], cursor: "pointer", color: 'white',borderRadius:3 }} className='p-2 '>
                        <Tooltip disabled title="Agregar personal">
                            <PersonAddAlt1Icon className='mx-1 text-white' />
                        </Tooltip>
                    </div>
                </Link>
            }

                <TableContainer >
                    <Table  aria-label="sticky table">

                        <TableHead >
                            <TableRow hover tabIndex={-1} key={uuidv4()} >

                                <TableCell align={"center"} size='small'> <b>Avatar</b></TableCell>
                                <TableCell align={"center"} size='small'><b>Nombre</b></TableCell>
                                <TableCell align={"center"} size='small'> <b>Cedula</b></TableCell>
                                <TableCell align={"center"} size='small'> <b>Teléfono</b> </TableCell>
                                <TableCell align={"center"} size='small'> <b>Acciones</b></TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {personalsById&&personalsById.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((personal) => (
                                <TableRow hover tabIndex={-1} key={uuidv4()} >

                                        <TableCell key={uuidv4()} align={"center"} size='small'>
                                            {personal.Imagen ?
                                                <img src={personal?.Imagen} style={{ width: 40, height: 40, borderRadius: 100 }} alt={'profile imagen'} />
                                                :
                                                <Stack direction="row" justifyContent={"center"}>
                                                    <Avatar sx={{ bgcolor: `#${dataColor}` }}>{SplitingInitialsName(personal?.user.username)}</Avatar>
                                                </Stack>
                                            }
                                        </TableCell>

                                        <TableCell key={uuidv4()} align={"center"} size='small'>{personal?.user.username}</TableCell>
                                        <TableCell key={uuidv4()} align={"center"} size='small'>{personal?.Cedula}</TableCell>
                                        <TableCell key={uuidv4()} align={"center"} size='small'>{personal?.Telefono}</TableCell>

                                        <TableCell align={"center"} size='small' style={{ position: "relative"}} onMouseLeave={()=>setMenuOpen(false)}>
                                    
                                                <IconButton
                                                    aria-label="more"
                                                    id="custom-button"
                                                    style={{ position: "relative", width: 50, height: 50 }}
                                                    onClick={() => setMenuOpen((menuOpen === false || menuOpen !==personal.id) ? personal.id : false)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                
                                                {menuOpen == personal.id && (
                                                    <div 
                                                        style={{
                                                        position: "absolute",
                                                        top:20, right: 20,
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                        zIndex: 2000,
                                                        width: "17ch",
                                                        borderRadius: 5,
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => { setMenuOpen(false); navigate({pathname: `/personal-id/`, search: `?${new URLSearchParams(personal).toString()}` }) }}>
                                                            Detalles
                                                    </MenuItem>
                                                    {hasPermission('Modificar personal') && 
                                                            <MenuItem onClick={() => { setMenuOpen(false); personal.id !=user.Personal.id && navigate({pathname: `/nuevo-personal-bodega/`, search: `?${new URLSearchParams({...personal,item:idBodega})}` }) }}>
                                                                Editar
                                                            </MenuItem>
                                                    }
                                                        {hasPermission('Eliminar personal') && 
                                                            <MenuItem onClick={() => { setMenuOpen(false);  personal.id !=user.Personal.id && eliminarPersonalEmpresa(personal) }}>
                                                                Eliminar
                                                            </MenuItem> 
                                                        }
                                                    </div>
                                                    )
                                                    }
                                       

                                    </TableCell>

                                </TableRow>
                            ))} 
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={personalsById&&personalsById.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    )
}
