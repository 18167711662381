import React, { useEffect, useState } from 'react'
import { Button, Divider, ListItem, ListItemText, Paper } from '@mui/material'
import './Styles.css';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { calcularFechas, filtrarPorFechaReportes, filtrarRutasPorFecha } from '../../../helpers/Utils';
import { useSelector } from 'react-redux';


const FilterDate = ({selectDate,setSelectDate,setData,setOpen,filter,setFilter,reports}) => {
   const state = useSelector(state=>state.user),
   [value1, setValue1] = useState(),
   [value2, setValue2] = useState(),
   [dateRange,setDateRange]=useState(false)

   const personal =(da)=>{
    if(value1== undefined ||value2== undefined){
      setOpen(true);
        }else{ 
          setDateRange(da)
          let date = {
            fecha1: value1,
            fecha2: value2,
            type: dateRange
          }
          setData(date)
      }
    }

    useEffect(() => {
        if(!dateRange){
          setDateRange("Todo")
        }
        
    }, [value1,value2])
      
    const changeData =(da)=>{
      setDateRange(da)
        let date = {
          fecha1: value1,
          fecha2: value2,
          type: dateRange
        }
        setData(date)
          
    }
      // useEffect(() => { }, [dateRange])
  return (
    <div>
        {/* ----------------------- filtrar por fecha personalizada ----------------------- */}
    <Paper elevation={2} className='boxDate' style={!selectDate?{display:"none"}:{zIndex:200,padding:10}}>
      <div className='Boxclose'>
        <HighlightOffIcon className='close' onClick={()=>setSelectDate(false)}/>
      </div>
      <div className='BoxesDate' style={{}}>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DatePicker 
            label="de"
            value={value1}
            onChange={(newValue) => {
              setValue1(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="a"
            value={value2}
            onChange={(newValue) => {
              setValue2(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
      <Button className="filt" style={state?.Personal&&{color:state?.Personal?.Empresa?.Color,marginTop:10}} 
      onClick={()=>personal("personalizado")}>Filtrar</Button>
    </Paper>

    {/* ----------------------- filtros fechas selected ----------------------- */}
    <Paper elevation={2} className='boxDateSelect' style={!filter?{display:"none"}:{zIndex:200}} onMouseLeave={()=>setFilter(false)}>
      <ListItem button>
        <ListItemText primary="Todo" onClick={()=>changeData('Todo')}/>
      </ListItem>
      <ListItem button onClick={()=>changeData('ayer')}>
        <ListItemText primary="Ayer" />
      </ListItem>
      <Divider/>
      <ListItem button onClick={()=>changeData('hoy')}>
        <ListItemText primary="Hoy" />
      </ListItem>
      <Divider/>
      <ListItem button onClick={()=>changeData('esta semana')}>
        <ListItemText primary="Esta semana" />
      </ListItem>
      <Divider/>
      <ListItem button>
        <ListItemText primary="Semana anterior" onClick={()=>changeData('semana anterior')}/>
      </ListItem>
      <Divider/>
      <ListItem button onClick={()=>{setFilter(false);setSelectDate(true)}}>
        <ListItemText primary="Personalizado" />
      </ListItem>
    </Paper>
    </div>
  )
}

export default React.memo(FilterDate)