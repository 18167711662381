import React from 'react'
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { convertWeight, getAbbreviation, formatDate } from  '../../../../../helpers/Utils';
import { generateExcel } from'../../../../../helpers/Pdfs/GenerateExcel';
import Swal from 'sweetalert2';
import { stylesBt1, stylesBt2 } from '../IngresosPlanta/Styles';
import Recovered from './Recovered';
import Discarted from './Discarted';
const { v4: uuidv4 } = require('uuid');

const HistSeparacion = ({splitter,dates}) => {
  const { user } = useSelector(state => state)
  const [view,setView] = React.useState("Recovers");
  const [reports, setReports] = React.useState({})

console.log("splitter",splitter);
  const functionSum = (data) => {

    let sum = 0;
    let unidad_de_peso = "kilogramo"
    data.forEach(element => {
        let num = convertWeight(Number(element.value), element.Unidad_de_Peso,unidad_de_peso)
            sum += Number(num)
          
      });

    return sum + " " + getAbbreviation(unidad_de_peso)
  }

  function procesarDatos(datos) {
    const resultado = {};
  
    datos.forEach(dato => {
      const gestor = dato.PuntoBodega.Nombre;
      dato.MareterialesRecuperables.forEach(({ material, value }) => {
        const clave = material;
        if (user.typeUser === "EMPRESA" && !user.materiales.some(e => e.Material.Material === material)) {
          return 
        }
        if (!resultado[clave]) {
          resultado[clave] = { material, gestor, peso: 0 };
        }
        resultado[clave].peso += parseFloat(value);
      });
    });
  
    // Convertir el objeto acumulado a un array de objetos
    return Object.values(resultado).map(item => (
      {
     
      material: item.material,
      Gestor: item.gestor,
      peso: `${item.peso.toFixed(2)} KG`
    }));
  }
  
  console.log("procesarDatos",procesarDatos(splitter));

  function procesarDatos2(datos) {
    const resultado = {};
  
    datos.forEach(dato => {
      const gestor = dato.PuntoBodega.Nombre;
      dato.MaterialDescartado.forEach(({ material, value }) => {
        
        const clave = material;
        if (!resultado[clave]) {
          resultado[clave] = { material, gestor, peso: 0 };
        }
        resultado[clave].peso += parseFloat(value);
      });
    });
  
    // Convertir el objeto acumulado a un array de objetos
    return Object.values(resultado).map(item => ({
      material: item.material,
      Gestor: item.gestor,
      peso: `${item.peso.toFixed(2)} KG`
    }));
  }
  
  console.log("procesarDatos2",procesarDatos2(splitter))

  const Excel =(i)=>{
    let dataTosend 
    if(i==1){
        dataTosend =procesarDatos(splitter)
        //  splitter.length>0? splitter.map(e=>{
        //     if(user?.typeUser === 'EMPRESA' ){
        //         let obj
        //         e.MareterialesRecuperables&&e.MareterialesRecuperables.map(h=>{
        //             obj = {
        //               Personal: user.PuntosBodega.find(i=>i.id===e.PuntoBodega.id).empleados.find(i=>i.user.id===e.Personal.user)?.user?.username || "Sin datos",
        //               Material:h.material||"Sin material",
        //               Cantidad:h.value?h.value +(h.Unidad_de_Peso? getAbbreviation(h.Unidad_de_Peso):" KG"):"Sin cantidad",
        //               Fecha:new Date(e.Fecha).toLocaleString(),
        //               Gestor:e.PuntoBodega.Nombre,
        //                   }
        //           })
        //         return obj !== undefined&& obj
            
        //     }else{
        //         let obj
        //         e.MareterialesRecuperables&&e.MareterialesRecuperables.map(h=>{
        //         obj = {
        //           Personal: user.PuntosBodega.find(i=>i.id===e.PuntoBodega.id).empleados.find(i=>i.user.id===e.Personal.user)?.user?.username || "Sin datos",
        //           Material:h.material||"Sin material",
        //           Cantidad:h.value?h.value +(h.Unidad_de_Peso? getAbbreviation(h.Unidad_de_Peso):" KG"):"Sin cantidad",
        //           Fecha:new Date(e.Fecha).toLocaleString(),
        //             }
        //         })
        //         return obj !== undefined&& obj
        //     }
        // }).filter(e=>e!==false):[]
    }else if (i==2){
        dataTosend = procesarDatos2(splitter)
        // splitter.length>0? splitter.map(e=>{
        //     if(user?.typeUser === 'EMPRESA'){
        //           let obj
        //           e.MaterialDescartado&&e.MaterialDescartado.map(h=>{
        //               obj = {
        //                 Personal: user.PuntosBodega.find(i=>i.id===e.PuntoBodega.id).empleados.find(i=>i.user.id===e.Personal.user)?.user?.username || "Sin datos",
        //                 Material:h.material||"Sin material",
        //                 Cantidad:h.value?h.value +(h.Unidad_de_Peso? getAbbreviation(h.Unidad_de_Peso):" KG"):"Sin cantidad",
        //                 Fecha:new Date(e.Fecha).toLocaleString(),
        //                 Gestor:e.PuntoBodega.Nombre,
        //               }
        //         })
        //       return obj !== undefined&& obj
            
        //     }else{
        //       console.log('====================================');
        //       console.log("e",e.e);
        //       console.log('====================================');
        //         let obj
        //         e.MaterialDescartado&&e.MaterialDescartado.map(h=>{
        //             obj = {
        //                 Personal: user.PuntosBodega.find(i=>i.id===e.PuntoBodega.id).empleados.find(i=>i.user.id===e.Personal.user)?.user?.username || "Sin datos",
        //                 Material:h.material||"Sin material",
        //                 Cantidad:h.value?h.value +(h.Unidad_de_Peso? getAbbreviation(h.Unidad_de_Peso):" KG"):"Sin cantidad",
        //                 Fecha:new Date(e.Fecha).toLocaleString(),
        //               }
        //         })
        //         return obj !== undefined&& obj
        //     }
        // }).filter(e=>e!==false):[]
    }
    if(dataTosend.length>0){
        console.log("dates",dates);
        let title = !dates||dates.inicio == undefined ?"Informe historico":`Informe periodo de  ${formatDate(new Date(dates.inicio).toLocaleString())} a ${formatDate(new Date(dates.fin).toLocaleString())}`
        let gestor = i==1?"Material recuperado":"Material descartado"
        generateExcel(dataTosend,title,gestor)
    }else{
        Swal.fire({
            position: 'center', icon: 'error', title: 'Oops...', text: 'No hay datos para generar archivo.',
            showConfirmButton: false,
            timer: 1500
          })
    }
  }

  return (
    <div>
       <div className="d-flex justify-content-center gap-5">
            <div className='d-flex justify-content-center gap-3 w-50' onClick={()=>setView("Recovers")} style={view=="Recovers"?stylesBt1(user.colors[8]):stylesBt2}>
                <p style={{width:"95%"}}> Recuperado</p>
                <div style={{width:"5%"}} >
                    <Tooltip title="Descargar excel" >
                        <CloudDownloadIcon style={{ color: view=="Rutaje"?user.colors[2]:'#666',marginTop:-2,cursor:"pointer" }} onClick={()=>{Excel(1)}}/>
                    </Tooltip>
                </div>
            </div>

            <div className='d-flex justify-content-center gap-3 w-50' onClick={()=>setView("Discarded")} style={view=="Discarded"?stylesBt1(user.colors[8]):stylesBt2}>
                <p style={{width:"95%"}}>Descartado</p>
                <div style={{width:"5%"}} >
                    <Tooltip title="Descargar excel" >
                        <CloudDownloadIcon style={{ color: view=="reception"?user.colors[2]:'#666',marginTop:-2,cursor:"pointer" }} onClick={()=>{Excel(2)}}/>
                    </Tooltip>
                </div>
            </div>
        </div>

        {view=="Recovers"&& <Recovered recovered={procesarDatos(splitter)}/>}
        {view=="Discarded"&& <Discarted discarted={procesarDatos2(splitter)}/>}
    </div>
  )
}

export default HistSeparacion