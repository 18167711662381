import { typesEmpresa } from "../types/typesEmpresa"


export const currentEmpresa = (empresas) => {
    return {
        type: typesEmpresa.addEmpresaSuccess,
        payload:empresas
    }
}

export const checkingEmpresas = () => {
    return {
        type: typesEmpresa.addEmpresaStarted,
    }
}