import React from 'react'
import { useSelector } from 'react-redux';
import { MyBarChart, MyPieChart } from '../../../helpers/Charts';

const Graficas = ({data}) => {

    const user = useSelector(state=> state.user),
    Informes = user?.reportes?.Recogidas.filter(e=>e.PuntoGenerador.id==data.id)

    function calcularFrecuenciaVisitas(dataArray) {
        const frequencyMap = {};
        let data = {
            labels: [],
            values: [],
        }
      
        // Contar la frecuencia de cada punto generador
        dataArray.forEach(item => {
          const generatorName = item.PuntoGenerador.Nombre;
          if (frequencyMap[generatorName]) {
            frequencyMap[generatorName]++;
            let index = data.labels.indexOf(generatorName)
            data.values[index] = frequencyMap[generatorName]+1
          } else {
            frequencyMap[generatorName] = 1;
            data.labels.push(generatorName)
            data.values.push(frequencyMap[generatorName])
          }
        });
      
        // Construir el objeto de salida
        const result = {
          labels: Object.keys(frequencyMap),
          values: Object.values(frequencyMap),
        };
      
        return data;
      }

      function getMaterialsByBodega(reports) {
        const data = {
          labels: [],
          values: []
        };
      
        const materialsByBodega = {};
      
        // Recorre los informes y organiza los datos para cada material
        reports.forEach(report => {
            if(report.Informe){
                report.Informe.forEach(informe => {
                    const material = informe.material;
                    const value = parseFloat(informe.value) / 1000; // Convierte de kilogramos a toneladas
            
                    if (materialsByBodega[material]) {
                    materialsByBodega[material].value += value;
                    } else {
                    materialsByBodega[material] = {
                        value: value,
                        name: report.PuntoGenerador.Nombre
                    };
                    }
                });
            }
        });
      
        // Convierte el objeto en los dos arrays que necesitas
        for (const [material, info] of Object.entries(materialsByBodega)) {
          data.labels.push(material + ' - ' + info.name);
          data.values.push(info.value);
        }
      
        return data;
      }
  return (
    <div>
        <MyBarChart data={getMaterialsByBodega(Informes)} colors={user.colors} title={"Pesos en KG"} />
    </div>
  )
}

export default Graficas