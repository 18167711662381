
import { Line, Bar,Pie } from 'react-chartjs-2';
import {
    Chart as Charttt,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    ArcElement,
    LineElement,
    
} from 'chart.js';

Charttt.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    ArcElement,
    LineElement,

);
export const MyLineChart = ({ data, colors,  title }) => {

   

    
    const chartData = {
      labels: data.labels,
      datasets: [
        {
          label: title,
          data: data.values,
          backgroundColor: colors.map(element => element+'b8'),
          borderColor: colors,
          borderWidth: 6,
          borderRadius: 10,
          fill: false,
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };
  
    return (
      <div style={{ height: '400px' }}>
        <Bar data={chartData} options={options} />
      </div>
    );
  };
  
  export const MyPieChart = ({ data, colors,  title }) => {


    
    const chartData = {
      labels: data.labels,
      datasets: [
        {
          label: title,
          data: data.values,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
          fill: false,
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    return (
        <div style={{ height: '400px' }}>
          <Pie data={chartData} options={options} />
        </div>
      );
  };
  
  export const MyBarChart = ({ data, colors,title }) => {
    const chartData = {
      labels: data.labels,
      datasets: [
        {
          label: title,
          data: data.values,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
          fill: false,
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    return (
        <div style={{ height: '400px' }}>
          <Bar data={chartData} options={options} />
        </div>
      );
  };