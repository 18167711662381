import React from 'react'
import {  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
const { v4: uuidv4 } = require('uuid');

const IngRutaje = ({ingreso}) => {

    
    const { user } = useSelector(state => state)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    
    const handleChangePage = (event, newPage) => { setPage(newPage);};
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function calcularMerma(ruta, pesoEsperado) {
        
        const informes =user?.reportes?.Recogidas.filter(i=>i.Ruta && i.Ruta.id === ruta)
        // Verificar si hay informes y pesoEsperado
        console.log(informes);
        if (!informes.length || !pesoEsperado) {
            return "Sin registro";
        }
    
        let pesoTotalInformes = 0;
    
        informes.forEach(({ Informe }) => {
            if (Informe && Informe.length) {
                Informe.forEach(item => {
                    const material = typeof item === 'string' ? item : item.material;
                    if(user.typeUser === "EMPRESA" && !user.materiales.some(e => e.Material.Material === material)) return 
           
                    // Filtrar por informes que contengan 'value' y 'Unidad_de_Peso'
                    if (item.value && item.Unidad_de_Peso && item.Unidad_de_Peso !== "unidad") {
                        const pesoEnKilos = convertirAKilos(parseFloat(item.value), item.Unidad_de_Peso);
                        pesoTotalInformes += pesoEnKilos;
                    }
                });
            }
        });
    
        // Calcular la merma
        const merma =   pesoTotalInformes - Number(pesoEsperado);
    
        return merma >= 0 ? merma.toFixed(2)+" KG" : "Sin registro";
    }
    
    function convertirAKilos(value, unidad) {
        switch (unidad.toLowerCase()) {
            case 'gramo':
                return value / 1000;
            case 'tonelada':
                return value * 1000;
            default:
                return value; // Asumimos que el valor ya está en kilogramos.
        }
    }
    

  return (
    <div className='mt-5'>
        <TableContainer sx={{   }}>
            <Table aria-label="sticky table">
                <TableHead style={{zIndex:-10}}>
                    <TableRow hover tabIndex={-10} key={uuidv4()} >

                        <TableCell align={"center"}>
                            <b># Generadores visitados</b>
                        </TableCell>

                        {user?.typeUser === 'EMPRESA'&&
                            <TableCell align={"center"}>
                                <b>Gestor</b>
                            </TableCell>
                        }

                        <TableCell align={"center"}>
                            <b>Fecha</b>
                        </TableCell>

                        <TableCell align={"center"}>
                            <b>Merma</b>
                        </TableCell>

                        <TableCell align={"center"}>
                            <b>Total entregado</b>
                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {ingreso != undefined && ingreso.length > 0 && ingreso.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                        <TableRow hover tabIndex={-1} key={uuidv4()} >
                            
                            <TableCell key={uuidv4()} align={"center"}>
                                {e.locaciones_visitadas.length}
                            </TableCell>

                            {user?.typeUser === 'EMPRESA'&&
                                <TableCell key={uuidv4()} align={"center"}>
                                    {e.Bodega.Nombre}
                                </TableCell>
                            }

                            <TableCell key={uuidv4()} align={"center"}>
                                {e.Fecha}
                            </TableCell>

                            <TableCell key={uuidv4()} align={"center"}>
                                {e.Ruta? calcularMerma(e.Ruta.id, e.Peso):"Sin registro"}
                            </TableCell>

                            <TableCell key={uuidv4()} align={"center"}>
                                {e.Peso||"No hay datos"}
                            </TableCell>

                            

                        </TableRow>

                    ))}

                </TableBody>
            </Table>
        </TableContainer>

        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={ingreso != undefined && ingreso.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  )
}

export default IngRutaje