import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Paper, Tooltip } from '@mui/material'
import BackupTableIcon from '@mui/icons-material/BackupTable';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Info from './ViewsDetails/Info';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import Tablas from './ViewsDetails/Tablas';
import Graficas from './ViewsDetails/Graficas';


const DetailsPuntosGeneradores = () => {
    const {id} = useParams(),
    { empresas } = useSelector(state => state.empresa),
    user = useSelector(state => state.user),
    [view,setView]=useState("Info")
    console.log("user.PuntosGeneradores",user?.PuntosGeneradores?.find(e=>e._id==id));
    console.log("id",id);
    const [filter, setFilter] = useState(false),
    
    PuntoGerador = user.PuntosGeneradores?user?.PuntosGeneradores?.find(e=>e._id==id):{},
    dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color,
    navigate = useNavigate();
    const onNavigateBack = () => { navigate(-1)};
  return (
    <div>

        
        {/* ----------------------- Barra Navegacion fnciones ----------------------- */}
      <Paper elevation={2} className="navDash text-white" style={{ backgroundColor:user?.colors?user?.colors[1]:"lightgrey",borderRadius:20}}>
        <h3> {PuntoGerador.Nombre} </h3>
        <div className='NavIcons' >
        <Tooltip title="Info" >
            <PersonPinIcon onClick={() => setView("Info")}
              style={view === "Info"  ?  { color: "#fff" }:{ color: '#666' }} />
          </Tooltip>
          <Tooltip title="Tablas">
            <BackupTableIcon onClick={() => setView("Tablas")}
              style={view === "Tablas"  ?  { color: "#fff" }:{ color: '#666' }}/>
          </Tooltip>

         {/* <Tooltip title="Graficas" >
                          <EqualizerIcon onClick={() => setView("Graficas")}
                              style={view === "Graficas" ?  { color: "#fff" }:{ color: '#666' }} />
                                        </Tooltip>*/}
        </div>

      </Paper>
      <div className='d-flex justify-content-end'>
          <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ cursor: "pointer", backgroundColor: `${dataColor}`,marginTop:10,borderRadius:40  }} 
            onClick={onNavigateBack}>
            Atras
          </Button>
        </div>
      <div>

        {view=="Info"&&
            <Info data={PuntoGerador} />
        }
        {view=="Tablas"&&
            <Tablas data={PuntoGerador} />
        }
        {view =="Graficas"&&
            <Graficas data={PuntoGerador} />
        }

      </div>
    </div>
  )
}

export default DetailsPuntosGeneradores