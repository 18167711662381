import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import CheckIcon from '@mui/icons-material/Check';
import { PostAllWithAuth, PutAllWithAuth, UseGetWithAuth2 } from '../../service/base2';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import Swal from 'sweetalert2';
import { UnicadesDeMedida, hasPermission } from '../../helpers/Utils';
import { materialesAction } from '../../service/redux/action/DataActions';
import { CreateMatEmpresa, EditMatEmpresa, EditMate_Sede } from './utiles/FunctionsCrud';


export const NewMateialEmpresa = ({ materialEdit, setValue,setLoading }) => {
    const  empresas  = useSelector(state => state.empresa.empresas)
    const user= useSelector(state => state.user)
   
    const dispatch =useDispatch()
    const [materialesRecuperables, setMaterialesRecuperables] = useState([])
    const [idMaterial, setIdMaterial] = useState("")
    const [idUnidadMedida, setIdUnidadMedida] = useState(materialEdit?materialEdit?.Unidad_de_Peso||"kilogramo":"kilogramo")
    const [idUnidadPrecio, setIdUnidadPrecio] = useState(materialEdit?materialEdit.Unidad_de_Precio||"gramo":"gramo")
    
    const [calculadoraMateriales, setCalculadoraMateriales] = useState(user?.materiales||[])
    const [precio, setPrecio] = useState(materialEdit?materialEdit?.Precio||"0":"0")
    let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color: user?.bodegas[0]?.color
    const [unidadesPrecio,setUnidadesPrecios]=useState(UnicadesDeMedida)
    const [Moneda, setMoneda] = useState(user?.typeUser !== 'EMPRESA'?user?.PuntosBodega[0]?.Moneda.Moneda||"COP":"COP")
    console.log("empresas",empresas);
    const MonedasEstablecidas = user.typeUser=="EMPRESA"?empresas[0]?.empresas?.Monedas.map(e=>e.Moneda):[user?.PuntosBodega[0]?.Moneda.Moneda]

    // =================> Guarda en variable si tiene permiso de comprar <=================
    const hasCompraVentaPermission = user?.typeUser === "EMPRESA" ? (user?.Personal.Empresa?.Servicios&& user?.Personal.Empresa?.Servicios.length>0)?user?.Personal.Empresa?.Servicios.some(e=>e=="65246345637f1900140aa59c"):false:(user.PuntosBodega[0].PermisosBodegas&&user.PuntosBodega[0].PermisosBodegas.length>0)?user.PuntosBodega[0].PermisosBodegas.some(e=>e=="64679ade25f79000140e8f17"):false;


    const { formState, Empresa, Unidad_de_Peso, Material } = useForm({
        Unidad_de_Peso:idUnidadMedida,
        Material:idMaterial,
    });

    //Lista de materiales disponibles para su uso
    const getMaterialesRecuperables = async () => {
        const { ok, resGet } = await UseGetWithAuth2(`/mareteriales-recuperables`, user?.Personal?.uid)
        if (ok) return setMaterialesRecuperables(resGet)
    };
    
 
    const handleChangeSelectMaterial = (event) => { setIdMaterial(event.target.value); };
    const handleChangeSelectUnidadMedida = (event) => { setIdUnidadMedida(event.target.value);
        if(event.target.value=="unidad"){setUnidadesPrecios(UnicadesDeMedida.filter(e=>e.value =="unidad"))
        setIdUnidadPrecio(event.target.value)
            console.log(unidadesPrecio);
        }else{
            setUnidadesPrecios(UnicadesDeMedida.filter(e=>e.value !=="unidad"))

        }};
    const handleChangeSelectUnidadPrecio= (event) => { setIdUnidadPrecio(event.target.value);};
    const handleChangePrecio = (event) => { setPrecio(event.target.value); };

    const handleSubmit = async (e) => {
        e.preventDefault();
        formState.Unidad_de_Peso = idUnidadMedida
        formState.Material = idMaterial
        formState.Moneda = hasCompraVentaPermission&& Moneda
        console.log("idUnidadMedida,idMaterial",idUnidadMedida,idMaterial);
        setLoading(true)
        if(idUnidadMedida.length>0 && idMaterial.length>0){

            if(user?.typeUser === "EMPRESA" ){
                formState.Empresa = user?.Personal?.Empresa?.id 
                CreateMatEmpresa(user,formState,setValue,setLoading,dispatch,materialesRecuperables,idUnidadPrecio,precio)
            }else if(user.typeUser =="PUNTOBODEGA"){
                formState.PuntosBodegas = [user.PuntosBodega[0].id]
                CreateMatEmpresa(user,formState,setValue,setLoading,dispatch,materialesRecuperables,idUnidadPrecio,precio)
            }
        }else{
            Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor, completa los campos',
                        showConfirmButton: false,timer: 1500 })
                        setLoading(false)
            setValue('1')
        }
    };
console.log("idUnidadMedida",idUnidadPrecio);

    const editMateriles = async (e) => {
        setLoading(true)
        e.preventDefault();
        console.log("idUnidadMedida,idMaterial",idUnidadMedida,idMaterial);
        if(idUnidadMedida && idMaterial){
            formState.Unidad_de_Peso = idUnidadMedida
            formState.Material = idMaterial
            if(user?.typeUser === "EMPRESA"){
                formState.Empresa = user?.Personal?.Empresa?.id 
                EditMatEmpresa(user,formState,materialEdit,setValue,setLoading,dispatch,idUnidadMedida ,idUnidadPrecio,precio)
                setValue('1')
            }else{
                formState.Unidad_de_Peso = idUnidadMedida
                formState.Material = idMaterial
                formState.PuntosBodegas = [user.PuntosBodega[0].id]
                EditMate_Sede(user,formState,materialEdit,materialesRecuperables,setValue,setLoading,dispatch,idUnidadMedida ,idUnidadPrecio,precio)
                console.log("ENtroooo")
            }
        }else{
            Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor, completa los campos', showConfirmButton: false,timer: 1500})
        }
        
        
    };

    console.log(materialEdit);
    console.log(hasCompraVentaPermission);
    console.log(materialesRecuperables.find(e=>e.id=="645f123480ea83a2db3d0a3d"));

    useEffect(() => {
        
        // Condicional evita llamados inecesarios y carga los materiales disponibles para ser trazados
        if(user.typeUser =="EMPRESA"){
            getMaterialesRecuperables()
        }else if(user.typeUser =='PUNTOBODEGA' && user.Personal.Permisos.some(e=>e.Permiso =="Agregar material")){
            getMaterialesRecuperables()
        }else{
            setMaterialesRecuperables([])
        }
    }, [hasCompraVentaPermission])

    useEffect(() => {
        if (materialEdit?.Unidad_de_Peso) {
            setIdMaterial(materialEdit?.Material?.id)
            setIdUnidadMedida(materialEdit?.Unidad_de_Peso)
        }
        if(materialEdit?.Unidad_de_Precio){
            setIdUnidadPrecio(materialEdit?.Unidad_de_Precio)
            if(materialEdit?.Unidad_de_Peso=="unidad"){ setUnidadesPrecios(unidadesPrecio.filter(e=>e.value =="unidad"))
            }else{setUnidadesPrecios(UnicadesDeMedida.filter(e=>e.value !=="unidad"))}
        }
    }, [materialesRecuperables,materialEdit])

   
    
    return (
        <main className='mt-5 card'>
            <Form className='w-100 row m-4'
                onSubmit={materialEdit.Unidad_de_Peso ? editMateriles : handleSubmit}
            >
                <div className="row">
                    {/* // =================> Material <================= */}
                    <div className="col-6 mb-3 " >
                        {!materialEdit.Unidad_de_Peso ? 
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="label-bodega">Material</InputLabel>
                                    <Select labelId="label-bodega" id="demo-simple-select"
                                        required
                                        value={idMaterial} label="Material" onChange={handleChangeSelectMaterial} disabled={materialesRecuperables.length>0?false:true}>
                                        {materialesRecuperables?.map((punto) => (
                                            <MenuItem key={punto?.id} value={punto?.id}>{punto?.Material} {calculadoraMateriales.some(item => item?.Material.id === punto?.id) && <CheckIcon color='success' />} </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            :
                            <h3><CheckIcon color='success' /> {materialEdit.Material.Material}</h3>
                        }
                    </div>

                    {/* // =================> Unidad de peso <================= */}
                    <div className="col-6 mb-3 " >
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="label-bodega">Unidad de peso</InputLabel>
                                <Select labelId="label-bodega" id="demo-simple-select" value={idUnidadMedida}
                                    required
                                    label="Unidad de peso" onChange={handleChangeSelectUnidadMedida}>
                                    <MenuItem value='gramo'>Gramo</MenuItem>
                                    <MenuItem value='kilogramo'>Kilogramo</MenuItem>
                                    <MenuItem value='tonelada'>Tonelada</MenuItem>
                                    <MenuItem value='unidad'>Unidad</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>

                </div>

                {hasCompraVentaPermission&& (
                    <div className="row">
                        {/* // =================> Precio <================= */}
                        <div className="col-6 mb-3 " >
                            <TextField
                                label="Precio"
                                type="number"
                                // placeholder="Fecha"
                                required
                                fullWidth
                                name="Precio"
                                focused
                                value={precio}
                                onChange={handleChangePrecio}
                            />
                        </div>
                        {/* // =================> Unidad de precio <================= */}
                        <div className="col-6 mb-3 " >
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="label-bodega">Unidad de precio</InputLabel>

                                    <Select   value={idUnidadPrecio} labelId="label-bodega"
                                        label="Unidad de precio" onChange={handleChangeSelectUnidadPrecio}>
                                            {unidadesPrecio.map(e=><MenuItem value={e.value}>{e.Label}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>

                    {user.typeUser=="EMPRESA" && 
                        // =================> Moneda <================= 
                        <div className="col-12 mb-3  my-3" >
                            <FormControl fullWidth>
                                <InputLabel id="label-bodega">Moneda</InputLabel>
                                <Select labelId="label-bodega" id="demo-simple-select" value={Moneda} label="Moneda" onChange={(eve)=>setMoneda(eve.target.value)}>
                                    {/* <MenuItem value='COP'>COP</MenuItem> */}
                                    {MonedasEstablecidas.map((e) => (
                                        <MenuItem value={e} > <em>{e}</em></MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    }
                    </div>
                )}
                <div>
                    <button
                        className='btn  w-25'
                        style={{ backgroundColor: user.colors[1],color:"white" }}
                    >
                        {materialEdit.Unidad_de_Peso ? 'Actualizar' : 'Guardar'}
                    </button>
                </div>
            </Form>
        </main>
    )
}
