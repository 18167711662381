import React,{ useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Avatar, Button, IconButton, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import {calcularDistancia,} from '../../../../helpers/Utils'
const { v4: uuidv4 } = require('uuid');


const Kilometros = ({historial}) => {
    const user = useSelector(state => state.user)
    const [page, setPage] = useState(0);
   
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();
    const kilome = user?.PuntosBodega?user?.PuntosBodega :[]
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const distanciaTotal2 =(historial)=>{
        let distanciaTotal = 0;
        historial.length>0 && historial.map(i=>i.PuntoGenerador).reduce((acumulador, actual, index, array) => {
           if (index < array.length - 1) {
               const distancia = calcularDistancia(
                (actual && actual.Latitud !== undefined) ? actual.Latitud : 0,
                (actual && actual.Longitud !== undefined) ? actual.Longitud : 0,
                (array[index + 1] && array[index + 1].Latitud !== undefined) ? array[index + 1].Latitud : 0,
                (array[index + 1] && array[index + 1].Longitud !== undefined) ? array[index + 1].Longitud : 0
               );
               distanciaTotal =acumulador + distancia
               return acumulador + distancia;
           }
           distanciaTotal =acumulador
           return acumulador;
         }, 0)
        return distanciaTotal.toFixed(2)};

    
  return (
    <div>
        <TableContainer sx={{  marginTop:3 }}>
                    <Table aria-label="sticky table">
                        <TableHead style={{zIndex:-10}}>
                            <TableRow hover tabIndex={-10} key={uuidv4()} >

                                <TableCell align={"center"} size='small'> <b>Gestor</b> </TableCell>
                                <TableCell align={"center"} size='small'> <b>Kilometros recorridos</b> </TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                        {kilome.length>0&&kilome?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                            <TableRow hover tabIndex={-1} key={uuidv4()} >
                                <TableCell key={uuidv4()} align={"center"} size='small'> 
                                    {e.Nombre || "Sin registro"}  
                                </TableCell>
                                <TableCell key={uuidv4()} align={"center"} size='small'>
                                    {historial.length>0?distanciaTotal2(historial.filter((i) => i.PuntoBodega.id == e._id)):0} Km
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={kilome.length>0? kilome.length:0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
    </div>
  )
}

export default Kilometros