import React, { useRef,useEffect } from 'react'
import QRCode from 'qrcode.react';

const GenerateQr = ({myBlob,data,setmyBlob}) => {
    const qrRef = useRef(null)
    let canvas
  
    useEffect(() => {
        if (qrRef.current) {
          canvas = qrRef.current.querySelector('canvas');
          if (canvas) {
            canvas.toBlob(async(blob) => {
              setmyBlob( blob);    
            });
          }
        }
      }, [qrRef.current,myBlob,data]);

  return (
    <div style={{  }} ref={qrRef}> <QRCode value={data}   id='canvas'/> </div> 
  )
}

export default GenerateQr