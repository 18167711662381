import axios from "axios"
import { basePromises } from "../api/hiloApi"

export const basePromisesAuth = async (token) => {
  const baseAuth = axios.create({
    baseURL: `https://hiloapi.herokuapp.com`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  })

  return {
    baseAuth
  }
}

export const PostAllWithAuth = async (url, token, data) => {
  try {
    const { baseAuth } = await basePromisesAuth(token)
    const post = await baseAuth.post(url, data)
      .catch((e) => console.log(e));
    const resPost = post
    console.log('====================================');
    console.log(post.status >= 200 && post.status < 300);
    console.log('====================================');
    return {
      ok: post.status >= 200 && post.status < 300,
      resPost
    }
    
  } catch (error) {
    return { ok: false, errorMessage: 'error al enviar los datos' }
  }
}


export const UseGetWithAuth2 = async (url, token) => {
  try {
    if (!token) return null;
    const { baseAuth } = await basePromisesAuth(token)
    const get = await baseAuth.get(url)
      .catch((e) => console.log(e.response.data));
    const resGet = get.data
    return {
      ok: get.status >= 200 && get.status < 300,
      resGet
    }
  } catch (error) {
    return { ok: false, errorMessage: 'error' }
  }


}

export const UsePost2 = async (url, data) => {
  const { base } = await basePromises();
  const Post = await base.post(url, data)
    .catch((e) => console.log(e));
  const resPost = Post?.data
  return {
    resPost
  }
}

export const DeleteAllWithAuth = async (url, token) => {
  try {
    const { baseAuth } = await basePromisesAuth(token)
    const post = await baseAuth.delete(url)
      .catch((e) => console.log(e));
    const resPost = await post
    console.log("resPost", resPost);
    return {
      ok: resPost.status >= 200 && resPost.status < 300,
      resPost
    }

  } catch (error) {
    return { ok: false, errorMessage: 'El usuario no se pudo eliminar' }
  }
}

export const PutAllWithAuth = async (url, token, data) => {
  try {
    const { baseAuth } = await basePromisesAuth(token)
    const put = await baseAuth.put(url, data);
    const resPut = await put.data
    console.log(put.status);
    console.log(put);
    return {
      ok: put.status >= 200 && put.status < 300,
      resPut
    }
  } catch (error) {
    return { ok: false, errorMessage: 'El usuario no se pudo actualizar' }
  }
}

export const Permisos = [
  {
    grupo: "Aplicativo expert",
    subgrupo: "Logistico",
    permisos: [
      "Acceder a Expert",
      "Califica locación",
      "Califica material",
      "Comprar",
      "Manejar rutas",
      "Reporta peso en bodega",
      "Reportar materiales",
      "Reportar peso"
    ]
  },
  {
    grupo: "Aplicativo expert",
    subgrupo: "Separador",
    permisos: ["Separar"]
  },
  {
    grupo: "Aplicativo expert",
    subgrupo: "Recepcion",
    permisos: ["Recepción de materiales", "Acceder a Expert", "Califica material"]
  },
  {
    grupo: "Administrador punto gestor",
    subgrupo: "",
    permisos: [
      "Acceder al dashboard",
      "Agregar Personal",
      "Agregar generadores",
      "Asignar ruta",
      "Descargar reportes",
      "Eliminar generadores",
      "Eliminar personal",
      "Modificar generadores",
      "Modificar permisos generadores",
      "Modificar personal",
      "Modificar precios",
      "Ver personal",
      "Visuallizar generadores",
      "Visuallizar reportes"
    ]
  },
  {
    grupo: "Administrador gestor",
    subgrupo: "",
    permisos: [
      "Acceder al dashboard",
      "Agregar Personal",
      "Agregar generadores",
      "Asignar ruta",
      "Descargar reportes",
      "Eliminar generadores",
      "Eliminar personal",
      "Modificar generadores",
      "Modificar permisos generadores",
      "Modificar personal",
      "Modificar precios",
      "Ver personal",
      "Visuallizar generadores",
      "Visuallizar reportes",
      "Agregar punton gestor",
      "Visualizar puntos gestores",
      "Modificar puntos gestores",
      "Eliminar puntos gestores"
    ]
  },
  {
    grupo: "Administrador empresa",
    subgrupo: "",
    permisos: [
      "Acceder al dashboard",
      "Agregar Personal",
      "Agregar generadores",
      "Asignar ruta",
      "Descargar reportes",
      "Eliminar generadores",
      "Eliminar personal",
      "Modificar generadores",
      "Modificar permisos generadores",
      "Modificar personal",
      "Modificar precios",
      "Ver personal",
      "Visuallizar generadores",
      "Visuallizar reportes",
      "Agregar gestor",
      "Visualizar gestores",
      "Modificar gestores",
      "Eliminar gestros",
      "Agregar punton gestor",
      "Visualizar puntos gestores",
      "Modificar puntos gestores",
      "Eliminar puntos gestores"
    ]
  }
];

