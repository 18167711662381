import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertToUTC, isSameDay, setDateWithoutTime } from '../../helpers/Utils';
import Mancha from '../../assets/blob-haikei.png';
import { Button } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { IconButton, Tooltip } from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import FormuarioRutaje from './Components/FormuarioRutaje';
import { PutAllWithAuth } from '../../service/base2';
import { rutasAction } from '../../service/redux/action/DataActions';
const emptyPlaces = require('../../assets/emptyplaces.png')

const RutajeWeb = () => {
    const user = useSelector(state=>state.user)
    const { empresas } = useSelector(state => state.empresa)
    const services =  user?.typeUser =="EMPRESA"?( empresas&&empresas[0])?empresas[0]?.empresas?.Servicios.some(e=>e.id=="65fe002a1d553f0015ca2848"):false :(user?.PuntosBodega[0]?.PermisosBodegas.some(e=>e=="65fe00441d553f0015ca2849"))
    const [currentIndex, setCurrentIndex] = useState(0);
    const [ru_activas,setRu_activas]=useState(services?true:false)
    const [formu,setFormu]=useState(false)
    const [rutaID,setRutaID]=useState(false)
    const [PuntosGeneradores,SetPuntosGeneradores]=useState([])
    const [rutasActivas,setRutasActivas] =useState([])
    const [rutasIncompleta,setRutasIncompleta] =useState([])
    const [rutajes,setrutajes]=useState(rutasActivas||[])
    const dispatch =useDispatch()
    console.log(rutasActivas,rutasActivas);

    const actualizarRutaje =()=>{
     let r1 =user?.rutas?.map(e => {
        // Crea una copia de la ruta actual.
  
        let rutaConQR = { ...e };
  
        rutaConQR.PuntosGeneradores = Array.isArray(e.PuntosGeneradores) && e.PuntosGeneradores.length > 0  ?  e.PuntosGeneradores.map(element => {
  
        
  
          // Encuentra el informe de recogida que corresponde a este PuntoGenerador; si existe y es la misma fecha, entonces fue ejecutada sino fue abandonada.
          const ejecutada = !user.reportes.Recogidas.length>0?[]:user.reportes.Recogidas.some((r) =>r.PuntoGenerador&& r.PuntoGenerador.id === element.id && isSameDay(new Date(r.Fecha)
              , new Date(convertToUTC(e.Fecha))));
  
  
          // Si el punto no fue ejecutado y su fecha es anterior a la fecha actual, entonces fue abandonado.
          const abandonado = !ejecutada
  
          // Devuelve una copia del PuntoGenerador con la información adicional.
          return {
            ...element,
            // Añade la información de ejecución y abandono.
            ejecutada,
            "abandonado": isSameDay(setDateWithoutTime(new Date()), new Date(convertToUTC(e.Fecha))) ? false : abandonado,
            
          };
        }):[]
  
        // Devuelve la ruta con la información adicional añadida.
        return rutaConQR;
      }).filter(e => {
        const fechaActual = setDateWithoutTime(new Date());
        return (isSameDay(convertToUTC(e.Fecha), fechaActual)&& !e.Completada) ;
      })
      setRutasActivas(r1)
      
    let r2=user?.rutas.map(e =>  {
        // Crea una copia de la ruta actual.
  
        let rutaConQR = { ...e };
  
        rutaConQR.PuntosGeneradores = Array.isArray(e.PuntosGeneradores) && e.PuntosGeneradores.length > 0  ?  e.PuntosGeneradores.map(element => {
  
        
  
          // Encuentra el informe de recogida que corresponde a este PuntoGenerador; si existe y es la misma fecha, entonces fue ejecutada sino fue abandonada.
          const ejecutada = !user.reportes.Recogidas.length>0?[]:user.reportes.Recogidas.some((r) =>r.PuntoGenerador&& r.PuntoGenerador.id === element.id && isSameDay(new Date(r.Fecha)
              , new Date(convertToUTC(e.Fecha))));
  
  
          // Si el punto no fue ejecutado y su fecha es anterior a la fecha actual, entonces fue abandonado.
          const abandonado = !ejecutada
  
          // Devuelve una copia del PuntoGenerador con la información adicional.
          return {
            ...element,
            // Añade la información de ejecución y abandono.
            ejecutada,
            "abandonado": isSameDay(setDateWithoutTime(new Date()), new Date(convertToUTC(e.Fecha))) ? false : abandonado,
            
          };
        }):[]
  
        // Devuelve la ruta con la información adicional añadida.
        return rutaConQR;
      }).filter(e => {
        const fechaActual = setDateWithoutTime(new Date());
        return (!isSameDay(convertToUTC(e.Fecha), fechaActual)&& !e.Completada && new Date(e.Fecha) < fechaActual ) ;
      })
    setRutasIncompleta(r2)
    setrutajes(services?r1:r2)
    }
      console.log('rutasActivas ===>',rutasActivas);
      console.log('rutasIncompleta ===>',rutasIncompleta);
    useEffect(()=>{
      actualizarRutaje()
    },[user.rutas,user?.reportes?.Recogidas])
console.log("ru_activas",ru_activas);
  return (
    <div style={{width:"100%"}}>

        <h4 className='text-center h1'>Reportar visitas</h4>
        <hr />
        <p className='h4'>Gestión análoga de visitas</p>
        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
            <Button variant="contained" className='mb-3' style={{backgroundColor:user.colors[8],color:user.colors[1],fontWeight:"bold",alignSelf:"flex-end"}}
            onClick={()=>{services &&!ru_activas? setrutajes(rutasActivas):setrutajes(rutasIncompleta);services &&setRu_activas(!ru_activas);setFormu(false)}}>
                    {services &&!ru_activas?"Gestionar rutas activas":"Gestionar rutas incompletas"}
            </Button>
            {ru_activas && services && (rutajes.length>0?<h6 className='mb-3' style={{textAlign:"center"}}>Rutas Activas</h6>:<h6 className='my-3' style={{textAlign:"center"}}>No hay rutas Activas</h6>)}
            {!ru_activas && (rutajes.length>0?<h6 className='mb-3' style={{textAlign:"center"}}>Rutas incompletas</h6>:<h6 className='my-3' style={{textAlign:"center"}}>No hay rutas incompleta</h6>)}
            {
                rutajes.length>0?
                <div style={{display:"flex",flexDirection:"column",alignItems:"center",width:"100%"}}>
                    <Splide options={ {
                        rewind: true, width : "100%",
                        gap   : '1rem',perPage: 3, arrows:false
                        } }>
                        {rutajes.map(e=>
                        <SplideSlide style={{background:user.colors[8]+"5e",padding:10,borderRadius:5,color:user.colors[2],boxShadow: "2px 2px 2px 0px rgba(0,0,0,0.2)",minWidth:300}} className='my-2' >
                            <div className='d-flex  justify-content-around'>
                                <div style={{fontSize:15}}>
                                    <p><b>Fecha: </b> <i>{e.Fecha}</i></p>
                                    <p><b>Gestor: </b> <i>{e.PuntosBodega.Nombre||"_"}</i></p>
                                    <p><b># Locaciones: </b> <i>{e.PuntosGeneradores.length||"No hay datos"}</i></p>
                                </div>
                                <div className='d-flex justify-content-center align-items-center align-self-center'
                                    style={{background:user.colors[7]+"9e",padding:10,borderRadius:100,color:user.colors[2],boxShadow: "1px 1px 2px 0px #ccc inset",height:50,width:50}}>
                                        <Tooltip title="Registrar visitas">
                                            <IconButton onClick={()=>{setFormu(true);setRutaID(e.id)}}>
                                                <AppRegistrationIcon style={{fontSize:40,color:"#fff"}} />
                                            </IconButton>
                                        </Tooltip>
                                </div>
                            </div>
                        </SplideSlide>
                        )}
                    </Splide>
                </div>
                
                :
                <div className='d-flex flex-column gap-3 justify-content-center p-2  mb-2 align-items-center'>
                    <div style={{ backgroundImage: `url(${Mancha})`,width: 500, height: 500, display:"flex",alignItems:"center",justifyContent:"center",
                        backgroundSize: 'cover',backgroundPosition: 'center',marginTop:-60 }}>
                        <img src={emptyPlaces} alt="emptyPlaces" style={{width:160,height:160,}}/>
                    </div>
                </div>
            }
        </div>
        {formu&& <hr />}
            {formu&& <FormuarioRutaje rutaID={rutaID} setFormu={setFormu} SetPuntosGeneradores={SetPuntosGeneradores}/>}
    </div>
  )
}

export default RutajeWeb