class Compra {
    constructor(compraData, user) {
        this.locacion = compraData.PuntoBodega.Nombre;
        this.informe = compraData.Informe || [];
        this.user = user;
    }

    esMaterialPermitido(material) {
        if (this.user.typeUser === "EMPRESA") {
            return this.user.materiales.some(e => e.Material.Material === material);
        }
        return true;
    }

    convertirAKilos(value, unidad) {
        switch (unidad.toLowerCase()) {
            case 'gramo':
                return value / 1000;
            case 'tonelada':
                return value * 1000;
            default:
                return value; // Asumimos que el valor ya está en kilogramos.
        }
    }

    procesarInformes() {
        return this.informe.reduce((acum, item) => {
            const material = typeof item === 'string' ? item : item.material;
            if (!this.esMaterialPermitido(material)) return acum;
            // Asumiendo que ya se ha determinado si el item es de tipo completo y se ha filtrado por material permitido
            if (typeof item !== 'string' && item.valor && item.moneda) {
                const clave = `${item.material}-${item.moneda}`;
                const pesoEnKilos = this.convertirAKilos(parseFloat(item.value || 0), item.Unidad_de_Peso || "kilogramo");
                if (!acum[clave]) {
                    acum[clave] = {
                        material: item.material,
                        pesoTotal: pesoEnKilos, // Asumiendo que ya se ha convertido el peso a kilos
                        totalValor: 0,
                        moneda: item.moneda,
                        calificacion: Number(item.rating) || 0
                    };
                }

                // Procesamiento del valor monetario
                const valorSinSimbolo = this.user.typeUser === "EMPRESA" ? this.user.materiales.find(e => e.Material.Material === material).Precio || 0 : item.valor.replace(/[^0-9,.-]/g, '').replace(',', '.');
                const valorLimpiado = parseFloat(valorSinSimbolo);
                acum[clave].totalValor += valorLimpiado;
            }
            return acum;
        }, {});
    }


    // procesarInformes() {
    //     const resultados = [];

    //     this.informe.forEach(item => {
    //         const material = typeof item === 'string' ? item : item.material;
    //         if (!this.esMaterialPermitido(material)) return;

    //         if (typeof item === 'object' && item.Unidad_de_Peso !== "unidad") {
    //             const pesoEnKilos = this.convertirAKilos(parseFloat(item.value || 0), item.Unidad_de_Peso || "kilogramo");
    //             resultados.push({
    //                 material,
    //                 pesoEnKilos,
    //                 calificacion: Number(item.rating) || 0,
    //                 valor: item.valor ? parseFloat(item.valor.replace(/[^0-9,.-]/g, '').replace(',', '.')) : 0,
    //                 moneda: item.moneda || false
    //             });
    //         }
    //     });

    //     return resultados;
    // }
}

// class Compra {
//     constructor(compraData, user) {
//         this.locacion = compraData.PuntoBodega.Nombre; // Asumiendo que cada compra está vinculada a una bodega
//         this.informe = compraData.Informe || [];
//         this.user = user;
//     }

//     esMaterialPermitido(material) {
//         console.log('====================================');
//         console.log('this.user', this.user.materiales.some(e => e.Material.Material == material));
//         console.log('=>', material);
//         if (this.user.typeUser === "EMPRESA") {
//             return this.user.materiales.some(e => e.Material.Material == material);
//         } else return true;
//     }

//     procesarInformes() {
//         return this.informe.reduce((acum, item) => {
//             const material = typeof item === 'string' ? item : item.material
//             // Si el item no cumple con los requisitos básicos o el material no está permitido, se omite
//             if (!this.esMaterialPermitido(material)) return acum;

//             if (typeof item === 'string') {
//                 // Aquí puedes decidir cómo manejar estos casos, por ejemplo, contabilizarlos sin peso/calificación
//                 return acum; // Si solo quieres omitir estos casos, mantén esta línea
//             }

//             const clave = `${item === 'string' ? item : item.material}-${item.Unidad_de_Peso}`;
//             if (!acum[clave]) {
//                 acum[clave] = {
//                     material: material,
//                     pesoTotal: 0,
//                     calificaciones: [],
//                     totalValor: 0,
//                     moneda: item.moneda
//                 };
//             }

//             // Convertir el peso a kilogramos si es necesario y sumar
//             const pesoEnKilos = this.convertirAKilos(item.value, item.Unidad_de_Peso);
//             acum[clave].pesoTotal += pesoEnKilos;

//             // Agregar calificación si está disponible
//             if (item.rating) acum[clave].calificaciones.push(item.rating);

//             // Sumar el valor si está disponible
//             if (item.valor) {
//                 const valorLimpiado = parseFloat(item.valor.replace(/[^0-9,.-]/g, '').replace(',', '.'));
//                 acum[clave].totalValor += valorLimpiado;
//             }

//             return acum;
//         }, {});
//     }

//     convertirAKilos(peso, unidad) {
//         switch (unidad.toLowerCase()) {
//             case 'gramo':
//                 return peso / 1000;
//             case 'tonelada':
//                 return peso * 1000;
//             default:
//                 return peso;
//         }
//     }
// }

export const procesarHistorialCompras = (datos, user) => {
    const resumen = {};

    datos.forEach(compraData => {
        const compra = new Compra(compraData, user);
        const materialesProcesados = compra.procesarInformes();

        // Incorporar los resultados en el resumen general
        Object.values(materialesProcesados).forEach(({ material, pesoTotal, calificacion, totalValor, moneda }) => {
            const clave = `${compra.locacion}-${material}-${moneda}`;
            if (!resumen[clave]) {
                resumen[clave] = { locacion: compra.locacion, material, pesoTotal: 0, totalValor: 0, calificacion: [], moneda };
            }

            resumen[clave].pesoTotal += pesoTotal;
            resumen[clave].totalValor += totalValor;
            resumen[clave].calificacion = resumen[clave].calificacion.concat(calificacion);
        });
    });
    console.log('====================================');
    console.log('resumen', resumen);
    console.log('====================================');
    // Convertir el resumen a un array para el resultado final
    return Object.values(resumen).map(({ locacion, material, pesoTotal, calificacion, totalValor, moneda }) => ({

        Locacion: locacion,
        Material: material,
        TotalRecuperadoPeso: pesoTotal ? `${Number(pesoTotal).toFixed(2)} kg` : "-",
        MediaCalificacion: calificacion.length > 0 ? (calificacion.reduce((acc, val) => Number(acc) + Number(val), 0) / calificacion.length).toFixed(1) : '0',
        TotalInvertido: moneda ? `$ ${new Intl.NumberFormat('es-ES', { style: 'currency', currency: moneda }).format(totalValor)}` : "0"
    }));
};



// export const procesarHistorialCompras = (compras, user) => {
//     console.log('====================================');
//     console.log(compras);
//     console.log('====================================');
//     const agrupadoPorMaterial = {};

//     compras.forEach(compra => {
//         // Omitir registros sin material o valor monetario
//         if (!compra.material || !compra.valor) return;

//         // Filtrar por materiales permitidos para el usuario tipo "EMPRESA"
//         if (user.typeUser === "EMPRESA" && !user.materiales.some(e => e.Material.Material === compra.material)) {
//             return; // Salta esta compra si el material no está permitido
//         }

//         // Inicializar el objeto para este material si aún no existe
//         if (!agrupadoPorMaterial[compra.material]) {
//             agrupadoPorMaterial[compra.material] = {
//                 material: compra.material,
//                 totalPeso: 0,
//                 unidadDePeso: compra.Unidad_de_Peso,
//                 calificaciones: [],
//                 totalInvertido: {},
//                 gestores: new Set()
//             };
//         }

//         // Agregar peso total
//         if (compra.value) {
//             agrupadoPorMaterial[compra.material].totalPeso += parseFloat(compra.value);
//         }

//         // Agregar calificación, si existe
//         if (compra.rating) {
//             agrupadoPorMaterial[compra.material].calificaciones.push(compra.rating);
//         }

//         // Sumar el valor monetario por moneda
//         const valorMonetario = parseFloat(compra.valor.replace(/[^\d,.]/g, '').replace(',', '.'));
//         const moneda = compra.moneda;
//         agrupadoPorMaterial[compra.material].totalInvertido[moneda] = (agrupadoPorMaterial[compra.material].totalInvertido[moneda] || 0) + valorMonetario;

//         // Agregar gestor si el usuario es una empresa
//         if (user.typeUser === "EMPRESA") {
//             agrupadoPorMaterial[compra.material].gestores.add(compra.PuntoBodega.Nombre);
//         }
//     });

//     // Transformar el objeto agrupado en un array y calcular la calificación media
//     const resultado = Object.values(agrupadoPorMaterial).map(item => {
//         const mediaCalificacion = item.calificaciones.length > 0 ? (item.calificaciones.reduce((acc, val) => acc + val, 0) / item.calificaciones.length).toFixed(2) : 'N/A';

//         // Formatear total invertido por moneda
//         Object.keys(item.totalInvertido).forEach(moneda => {
//             item.totalInvertido = new Intl.NumberFormat('es', { style: 'currency', currency: moneda }).format(item.totalInvertido[moneda]);
//         });

//         return {
//             Material: item.material,
//             TotalPeso: `${item.totalPeso.toFixed(2)} ${item.unidadDePeso}`,
//             CalificacionMedia: mediaCalificacion,
//             TotalInvertido: item.totalInvertido,
//             ...(user.typeUser === "EMPRESA" && { Gestores: Array.from(item.gestores) })
//         };
//     });

//     return resultado;
// };
