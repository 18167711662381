import { Tabs,Tab } from '@mui/material'
import React, { useEffect } from 'react'
import Swal from 'sweetalert2';
import {  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useSelector } from 'react-redux';
import { generateExcel } from '../../../../../helpers/Pdfs/GenerateExcel';
import { convertWeight, getAbbreviation, formatDate } from '../../../../../helpers/Utils';
import IngRutaje from './IngRutaje';
import RecEnPlanta from './RecEnPlanta';
import { stylesBt1, stylesBt2 } from './Styles';

const IngresosPlanta = ({historialRecogida, Ingreso,dates}) => {
  const { user } = useSelector(state => state)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [view,setView] = React.useState("Rutaje");
  const [reports, setReports] = React.useState({})
  const functionSum = (data) => {
        
    let sum = 0;
    let unidad_de_peso = "kilogramo"
    data.forEach(element => {
       let num = convertWeight(Number(element.value), element.Unidad_de_Peso,unidad_de_peso)
          sum += Number(num)
         
    });

    return sum + " " + getAbbreviation(unidad_de_peso)
}

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
};
function calcularMerma(ruta, pesoEsperado) {
        
  const informes =user?.reportes?.Recogidas.filter(i=> i.Ruta.id === ruta)
  // Verificar si hay informes y pesoEsperado
  console.log(informes);
  if (!informes.length || !pesoEsperado) {
      return "Sin registro";
  }

  let pesoTotalInformes = 0;

  informes.forEach(({ Informe }) => {
      if (Informe && Informe.length) {
          Informe.forEach(item => {
              const material = typeof item === 'string' ? item : item.material;
              if(user.typeUser === "EMPRESA" && !user.materiales.some(e => e.Material.Material === material)) return 
     
              // Filtrar por informes que contengan 'value' y 'Unidad_de_Peso'
              if (item.value && item.Unidad_de_Peso && item.Unidad_de_Peso !== "unidad") {
                  const pesoEnKilos = convertirAKilos(parseFloat(item.value), item.Unidad_de_Peso);
                  pesoTotalInformes += pesoEnKilos;
              }
          });
      }
  });

  // Calcular la merma
  const merma =   pesoTotalInformes - Number(pesoEsperado);

  return merma >= 0 ? merma.toFixed(2)+" KG" : "Sin registro";
}

function convertirAKilos(value, unidad) {
  switch (unidad.toLowerCase()) {
      case 'gramo':
          return value / 1000;
      case 'tonelada':
          return value * 1000;
      default:
          return value; // Asumimos que el valor ya está en kilogramos.
  }
}

  const Excel =(i)=>{
    let dataTosend 
    if(i==1){
        dataTosend = reports?.ingre.length>0? reports?.ingre.map(e=>{
          if(user?.typeUser === 'EMPRESA'){
            return {
                Fecha:new Date(e.Fecha).toLocaleDateString(),
                "# Generadores visitados":e.locaciones_visitadas.length,
                Gestor:e.Bodega.Nombre,
                Merma:e.Ruta? calcularMerma(e.Ruta.id, e.Peso):"Sin registro",
                Total_entregado:e.Peso||"No hay datos"
            }
          }else{
            return {
                Fecha:new Date(e.Fecha).toLocaleDateString(),
                "# Generadores visitados":e.locaciones_visitadas.length,
                Merma:e.Ruta? calcularMerma(e.Ruta.id, e.Peso):"Sin registro",
                Total_entregado:e.Peso||"No hay datos"
            }
          }
          }):[]
    }else if (i==2){
        dataTosend = reports.reception.length>0? reports.reception.map(e=>{
          if(user?.typeUser === 'EMPRESA'){
            return {  
                Fecha:new Date(e.Fecha).toLocaleDateString(), 
                Gestor:e.Bodega.Nombre||"--",
                Generador:e.locaciones_visitadas[0]?.Nombre||"No hay datos",
                Total_entregado:e.Peso + " KG"||"No hay datos",
            }
          }else{
            return {  
              Fecha:new Date(e.Fecha).toLocaleDateString(), 
              Generador:e.locaciones_visitadas[0]?.Nombre||"No hay datos",
              Total_entregado:e.Peso + " KG"||"No hay datos",
            }
          }
          }):[]
    }
    if(dataTosend.length>0){
        let title = !dates||dates.inicio == undefined ?"Informe historico":`Informe periodo de  ${formatDate(new Date(dates.inicio).toLocaleString())} a ${formatDate(new Date(dates.fin).toLocaleString())}`
        let gestor = i==1?"Ingresos de rutas":"Recepción en planta"
        generateExcel(dataTosend,title,gestor)
    }else{
        Swal.fire({
            position: 'center', icon: 'error', title: 'Oops...', text: 'No hay datos para generar archivo.',
            showConfirmButton: false,
            timer: 1500
          })
    }
  }

  useEffect(() => {
      if(Ingreso.length>0){
          let data = {reception: [], ingre: []};
          Ingreso.forEach(e=>{if(e.Recepcion){ data.reception.push(e) } else{data.ingre.push(e)}})
          setReports(data)
      }
  } , [Ingreso])

  return (
    <div>   
        <div className="d-flex justify-content-center gap-5">
            <div className='d-flex justify-content-center gap-3 w-50' onClick={()=>setView("Rutaje")} style={view=="Rutaje"?stylesBt1(user.colors[8]):stylesBt2}>
                <p style={{width:"95%"}}> Ingresos rutas</p>
                <div style={{width:"5%"}} >
                    <Tooltip title="Descargar excel" >
                        <CloudDownloadIcon style={{ color: view=="Rutaje"?user.colors[2]:'#666',marginTop:-2,cursor:"pointer" }} onClick={()=>{Excel(1)}}/>
                    </Tooltip>
                </div>
            </div>

            <div className='d-flex justify-content-center gap-3 w-50' onClick={()=>setView("reception")} style={view=="reception"?stylesBt1(user.colors[8]):stylesBt2}>
                <p style={{width:"95%"}}>Recepción en planta</p>
                <div style={{width:"5%"}} >
                    <Tooltip title="Descargar excel" >
                        <CloudDownloadIcon style={{ color: view=="reception"?user.colors[2]:'#666',marginTop:-2,cursor:"pointer" }} onClick={()=>{Excel(2)}}/>
                    </Tooltip>
                </div>
            </div>
        </div>

        {view == "Rutaje"&& <IngRutaje ingreso={reports?.ingre}/> }
        {view == "reception"&& <RecEnPlanta ingreso={reports.reception}/> }
    </div>
  )
}

export default IngresosPlanta