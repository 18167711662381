import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {IconButton,MenuItem, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { fileUploadQR, generarQREnBlob } from '../../helpers/FileUpload';
import { DeleteAllWithAuth, PostAllWithAuth, PutAllWithAuth } from '../../service/base2';
import { SplitingInitialsName, generateSerial, hasPermission } from '../../helpers/Utils';
import { bolsasAction } from '../../service/redux/action/DataActions';
import Swal from 'sweetalert2';
import QrsModal from '../../helpers/Madales/Qrs';
const { v4: uuidv4 } = require('uuid');

const columns = [
  { id: 'Huella', label: 'Huella', minWidth: 170 },
  {
    id: 'Qr',
    label: 'Qr',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  }
];

const Bolsas = () => {

  const { user } = useSelector(state => state)
  const [page, setPage] = React.useState(0);
  const [menuOpen,setMenuOpen]=React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [qr,setQr]=React.useState({})
  const [status,setStatus]=React.useState(false)
  const dispatch = useDispatch()
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const GenerateMarker =async ()=>{
    await Swal.fire({
        title: '¿Deseas generar un nuevo marcador?',
        text:"Esto podría incurrir en un pequeño costo adicional.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Generar'

      }).then(async(result) => {
        if (result.isConfirmed) {
            let serial = generateSerial(SplitingInitialsName(user.bodegas[0].Nombre).toUpperCase())
            generarQREnBlob(serial).then(async(blob) => {
                const r = await fileUploadQR(blob);
                console.log("r",r);
                serial = serial.split("/");
                const _Qrs = await PostAllWithAuth(`/qrs`, user?.Personal?.uid, {"Img":r,"Huella":serial[serial.length - 1],"Bodegas":user.bodegas[0].id},);
                if(_Qrs.ok){
                    const newBolsa = await PostAllWithAuth(`/bolsas`, user?.Personal?.uid,{"Qr":_Qrs.resPost.data.id,"PuntosBodega":user.PuntosBodega[0].id})
                    if( newBolsa.ok){
                        let bolsas = user.bolsas
                        bolsas.push(newBolsa.resPost.data)
                        dispatch(bolsasAction(bolsas))
                        Swal.fire({ position: 'center', icon: 'success', title: `Un nuevo marcador se ha generado`,showConfirmButton: false, timer: 1500 })
                      }else{ Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No ha sido posible generar el marcador.', showConfirmButton: false, timer: 1500})}
                }else{ Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No ha sido posible generar el marcador.', showConfirmButton: false, timer: 1500})}
            })
        }
      })
  }

  const eliminateMarker = async (e)=>{
 
    await Swal.fire({
      title: '¿Estas seguro?',
      text:"Se perderán todos los registros de este marcador.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'

    }).then(async(result) => {
      if (result.isConfirmed) {
          const DeleteQr = await DeleteAllWithAuth(`/qrs/${e.Qr.id}`,user?.Personal?.uid)
          if(DeleteQr.ok){
                const DeleteBag = await DeleteAllWithAuth(`/bolsas/${e.id}`,user?.Personal?.uid)
                if(DeleteBag.ok){
                  const updateBags = user.bolsas.filter(i => i.id !== e.id)
                  
                  dispatch(bolsasAction(updateBags))
                  Swal.fire({ position: 'center', icon: 'success', title: `El marcador se ha eliminado.`,showConfirmButton: false, timer: 1500 })
                }else{ Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No ha sido posible eliminar el marcador.', showConfirmButton: false, timer: 1500})}
          }else{ Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No ha sido posible eliminar el marcador.', showConfirmButton: false, timer: 1500})}
      }
    })
  }

  console.log(user.bolsas);
  return (
    <div className='d-flex flex-column'>
        <h1 style={{ textAlign: "center" }}>Marcadores</h1>

        <div className='d-flex justify-content-end mb-4'>
            <Button variant="contained" startIcon={<AddCircleIcon />} 
              style={{ cursor: "pointer" ,marginBottom:10,marginTop:-10, backgroundColor:user.colors[2],borderRadius:40 }} onClick={GenerateMarker}>
                Generar marcador
            </Button>
            
        </div>

      <Paper sx={{ width: "85%", alignSelf: "center",borderRadius:5 }}>
          <TableContainer>

              <Table aria-label="sticky table">

                  <TableHead>
                      <TableRow hover tabIndex={-1} key={uuidv4()}>
                          <TableCell align={"center"} size='small'> Huella </TableCell>
                          <TableCell align={"center"} size='small'> Qr </TableCell>
                          <TableCell align={"center"} size='small'> Acciones </TableCell>
                      </TableRow>
                  </TableHead>

                  <TableBody>
                      {user.bolsas && user.bolsas.map((e) => {
                        return(
                          <TableRow hover tabIndex={-1} key={uuidv4()}>

                            <TableCell key={uuidv4()} align={"center"} size='small'> {e?.Qr?.Huella} </TableCell>
                            <TableCell key={uuidv4()} align={"center"} size='small'>  
                                <img src={e?.Qr?.Img} alt={e?.Qr?.Huella} width="50px" onClick={()=>{setQr(e.Qr); setStatus(true)}} /> 
                            </TableCell>
                            <TableCell key={uuidv4()} align={"center"} size='small' style={{ position: "relative"}} onMouseLeave={()=>setMenuOpen(false)}>

                                  <IconButton aria-label="more" id="custom-button" style={{ position: "relative", width: 50, height: 50 }} 
                                              onClick={() => setMenuOpen((menuOpen === false || menuOpen !==e.id) ? e.id : false)} >
                                      <MoreVertIcon />
                                  </IconButton>

                                  {menuOpen == e.id && (
                                      <div style={{ position: "absolute",top:20, right: 20, backgroundColor: "#fff",  boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",zIndex: 2000,
                                                    width: "17ch",borderRadius: 5, }} >
                                          {hasPermission(user?.Personal?.Permisos,'Eliminar bolsa') && 
                                              <MenuItem onClick={() => { setMenuOpen(false); eliminateMarker(e)  }}> Eliminar </MenuItem>
                                          }
                                      </div>
                                  )}

                            </TableCell>

                          </TableRow>
                          )
                      })}
                  </TableBody>

              </Table>

          </TableContainer>

          <TablePagination
              rowsPerPageOptions={[10, 25, 100]} component="div" count={user.bolsas?user.bolsas.length:0} rowsPerPage={rowsPerPage}
              page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
      <QrsModal status={status} setStatus={setStatus} qr={qr} />
    </div>
  );
}



export default Bolsas
