import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { PostAllWithAuth, PutAllWithAuth, UseGetWithAuth2 } from '../../../service/base2';
import { useSelector } from 'react-redux';
import { useForm } from '../../../hooks/useForm';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';

export const NewEmpresaGeneradora = ({ setType, BodegaById = [], setEmpresaGeneradoraEdit = [], EmpresaGeneradoraEdit = [] }) => {
    const { empresas } = useSelector(state => state.empresa)
    const user = useSelector(state => state.user)
    let dataColor = empresas[0]?.typeUser === 'EMPRESA' ? empresas[0]?.empresas?.data?.Color :  user?.bodegas[0]?.Color 

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const [puntos, setPuntos] = useState([])
    const [idPuntoGenerador, setIdPuntoGenerador] = useState([]);

    const objetoRecibidoByparams = {};
    for (let [key, value] of params.entries()) {
        objetoRecibidoByparams[key] = value;
    }
   
    const { NombreContacto, Direccion, CargoContacto, Telefono, Email, NombreEmpresa, Bodega, Empresa, Generador, PuntoGenerador = [], onInputChange, formState }
        = useForm({
            Empresa: empresas[0]?.empresas?.data.id,
            Bodega: objetoRecibidoByparams?.Bodega ? objetoRecibidoByparams?.Bodega : objetoRecibidoByparams?.id,
            PuntoGenerador: objetoRecibidoByparams?.Bodega ? [objetoRecibidoByparams?.PuntoGenerador ]: idPuntoGenerador,
            NombreContacto: objetoRecibidoByparams?.Bodega ? objetoRecibidoByparams?.NombreContacto : '',
            Direccion: objetoRecibidoByparams?.Bodega ? objetoRecibidoByparams?.Direccion : '',
            CargoContacto: objetoRecibidoByparams?.Bodega ? objetoRecibidoByparams?.CargoContacto : '',
            Telefono: objetoRecibidoByparams?.Bodega ? objetoRecibidoByparams?.Telefono : '',
            Email: objetoRecibidoByparams?.Bodega ? objetoRecibidoByparams?.Email : '',
            NombreEmpresa: objetoRecibidoByparams?.Bodega ? objetoRecibidoByparams?.NombreEmpresa : '',
        });

    const getPuntoGenerador = async () => {
        const { resGet, ok } = await UseGetWithAuth2(`/puntos-generadores/?_where[PuntosBodega.Bodega]=${objetoRecibidoByparams?.Bodega ? objetoRecibidoByparams?.Bodega : objetoRecibidoByparams?.id}`, empresas[0].jwToken);
        if (ok) return setPuntos(resGet);
    };

    const handleChangeSelect = (event) => {
        setIdPuntoGenerador(event.target.value);
    };
    const onNavigateBack = () => {
        navigate(-1)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data;
        if (idPuntoGenerador.length > 0) {
            data = { ...formState, PuntoGenerador: [idPuntoGenerador] };
        } else {
            data = { ...formState }
        };

        const { resPost, ok } = await PostAllWithAuth(`/empresas-generadoras`, empresas[0].jwToken, data);
        if (ok) {
            Swal.fire({
                position: 'center', icon: 'success', title: `La empresa  ha sido agregada`,
                showConfirmButton: false,
                timer: 2500
            })
            onNavigateBack();
        } else {
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                showConfirmButton: false,
                timer: 1500
            })
        }
    };

    const EditEmpresaGeneradora = async (e) => {
        e.preventDefault()
        let data;
        if (idPuntoGenerador.length > 0) {
            data = { ...formState, PuntoGenerador: [idPuntoGenerador] };
        } else {
            data = { ...formState }
        };
        const { resPut, ok } = await PutAllWithAuth(`/empresas-generadoras/${objetoRecibidoByparams?.id}`, empresas[0].jwToken, data);
        if (ok) {
            Swal.fire({
                position: 'center', icon: 'success', title: `La empresa ha sido Actializado`,
                showConfirmButton: false,
                timer: 3000
            })
            onNavigateBack();
        } else {
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                showConfirmButton: false,
                timer: 1500
            })
        }
    };


    useEffect(() => {
        getPuntoGenerador()
    }, [])

    return (
        <>
            <nav className='d-flex gap-3 justify-content-between p-2 rounded mb-2 '
                style={empresas ? { backgroundColor: `#${dataColor}`, fontWeight: "bold" } : { color: "#666", fontWeight: "bold" }}>
                <div className='ml-5'>
                    <h5 style={{ color: "white" }}>{objetoRecibidoByparams?.Bodega ? 'Actualizar datos' : 'Nueva Empresa Generadora'}</h5>
                </div>
            </nav>

            <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ cursor: "pointer", backgroundColor: `#${dataColor}` }}
                onClick={onNavigateBack}
            >
                Atras
            </Button>

            <main className='mt-5 card'>
                <Form className='w-100 row m-4'

                    onSubmit={objetoRecibidoByparams?.Bodega ? EditEmpresaGeneradora : handleSubmit}
                >
                    <div className="row">
                        <div className="col-6 mb-3 ">
                            <TextField
                                label="Nombre de contacto"
                                type="text"
                                placeholder="Nombre de contacto"
                                fullWidth
                                name="NombreContacto"
                                value={NombreContacto}
                                onChange={onInputChange}
                            />
                        </div>

                        <div className="col-6 mb-3 " >
                            <TextField
                                label="Dirección "
                                type="text"
                                placeholder="Dirección "
                                fullWidth
                                name="Direccion"
                                value={Direccion}
                                onChange={onInputChange}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 mb-3 ">
                            <TextField
                                label="Cargo de contacto"
                                type="text"
                                placeholder="Cargo de contacto"
                                fullWidth
                                // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                                name="CargoContacto"
                                value={CargoContacto}
                                onChange={onInputChange}
                            />
                        </div>

                        <div className="col-6 mb-3 " >
                            <TextField
                                label="Teléfono"
                                type="text"
                                placeholder="Teléfono"
                                fullWidth
                                name="Telefono"
                                value={Telefono}
                                onChange={onInputChange}
                            />
                        </div>
                    </div>



                    <div className="row">

                        <div className="col-4 mb-3 " >
                            <TextField
                                label="Email"
                                type="email"
                                placeholder="Email"
                                fullWidth
                                // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                                name="Email"
                                value={Email}
                                onChange={onInputChange}
                            />
                        </div>

                        <div className="col-4 mb-3 " >
                            <TextField
                                label="Nombre de empresa"
                                type="text"
                                placeholder="Nombre de empresa"
                                fullWidth
                                name="NombreEmpresa"
                                value={NombreEmpresa}
                                onChange={onInputChange}
                            />
                        </div>



                        <div className="col-4 mb-3 " >
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="label-sede">Punto generador</InputLabel>
                                    <Select
                                        labelId="label-sede"
                                        id="demo-simple-select"
                                        value={objetoRecibidoByparams?.Bodega ? PuntoGenerador : idPuntoGenerador}
                                        label="Punto generador"
                                        onChange={handleChangeSelect}
                                    >
                                        {puntos?.map((punto) => (
                                            <MenuItem key={punto?.id} value={punto?.id}>{punto?.Nombre}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>

                    </div>

                    <div>
                        <button
                            className='btn btn-primary w-25'
                        // style={{ backgroundColor: state.empresa.Color }}
                        >
                            {objetoRecibidoByparams?.Bodega ? 'Actualizar' : 'Guardar'}

                        </button>
                    </div>
                </Form>
            </main>
        </>
    )
}
