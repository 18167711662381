import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import 'react-circular-progressbar/dist/styles.css';
import "./stylesMain.css"
import { FormDates, calcularDistancia, filtrarPorFecha } from '../../../helpers/Utils';
import {MyLineChart, MyPieChart} from '../../../helpers/Charts';
import "../../Solicitudes/Solicitudes.css"
import { Link } from 'react-router-dom';
const MainDash = ({data}) => {
    const { empresas } = useSelector(state => state.empresa)
    const state = useSelector(state => state.user)
    const [dates, setDates] = useState({})

    const [historialRecogida, setHistorialRecogida] = useState([])
    const [valueHistorialRecogida, setvalueHistorialRecogida] = useState([])
    const [sum, setSum] = useState(0); // Estado para almacenar la suma
    const [coordenadas, setCoordenadas] = useState([]); // Estado para almacenar las coordenadas
    const solicitudes =  state?.PuntosGeneradores?.filter(e=>e.Llenado==true && e.asignado==false)||[]
    
    const getHistorialRecogida =  () => {

        const filter = filtrarPorFecha(state?.reportes?.Recogidas, 'esta semana') 
        setHistorialRecogida(filter)
    };

    useEffect(() => {
        let filter = []
        if(data.type == "personalizado"){
             filter = filtrarPorFecha(state?.reportes?.Recogidas,data.type ,data.fecha1,data.fecha2) 
             let dat = {
                inicio: data.fecha1,
                fin: data.fecha2
            }
        setDates(dat)
        }else{
            filter = filtrarPorFecha(state?.reportes?.Recogidas, data.type?data.type:'Todo') 
            let dat = FormDates(data.type?data.type:'Todo')
             setDates(dat)
        }
        
        setHistorialRecogida(filter)
    }, [data])

    
      function calcularFrecuenciaVisitas(dataArray) {
        const frequencyMap = {};
        let data = {
            labels: [],
            values: [],
        }
      
        // Contar la frecuencia de cada punto generador
        dataArray.forEach(item => {
          const generatorName = (item.PuntoGenerador &&item.PuntoGenerador.Nombre)?item.PuntoGenerador.Nombre:"Generadores eliminados";
          if (frequencyMap[generatorName]) {
            frequencyMap[generatorName]++;
            let index = data.labels.indexOf(generatorName)
            data.values[index] = frequencyMap[generatorName]+1
          } else {
            frequencyMap[generatorName] = 1;
            data.labels.push(generatorName)
            data.values.push(frequencyMap[generatorName])
          }
        });
      
        // Construir el objeto de salida
        const result = {
          labels: Object.keys(frequencyMap),
          values: Object.values(frequencyMap),
        };
      
        return data;
      }
      
      function calculateRecoveredMaterials(dataArray) {
        const materialMap = {};
        let data = {
            labels: [],
            values: [],
        }
      
        // Sumar los valores de cada material
        dataArray.forEach(item => {
            if(item.Informe){
               
            item.Informe.forEach(materialInfo => {
                const materialName = materialInfo.material?materialInfo.material:materialInfo;
                const value = Number(materialInfo.value?materialInfo.value:1);
                if (materialMap[materialName]) {
                materialMap[materialName] += value;
                let index = data.labels.indexOf(materialName )
                    data.values[index] = materialMap[materialName ]+1
                } else {
                materialMap[materialName] = value;
                data.labels.push(materialName)
                data.values.push(materialMap[materialName])
                }
            });
            }
        });
      
        // Construir el objeto de salida
        const result = {
          labels: Object.keys(materialMap),
          value: Object.values(materialMap),
        };
      
        return data;
      }
      
      function getMaterialsByBodega(reports) {
        const data = {
          labels: [],
          values: []
        };
      
        const materialsByBodega = {};
      
        // Recorre los informes y organiza los datos para cada material
        reports?.forEach(report => {
          if(!report.Informe) return
          report.Informe.forEach(informe => {
            const material = informe.material;
            const value = parseFloat(informe.value) / 1000; // Convierte de kilogramos a toneladas
      
            if (materialsByBodega[material]) {
              materialsByBodega[material].value += value;
            } else {
              materialsByBodega[material] = {
                value: value,
                name: (report.PuntoGenerador && report.PuntoGenerador.Nombre)?report.PuntoGenerador.Nombre:"Generadores eliminados"
              };
            }
          });
        });
      
        // Convierte el objeto en los dos arrays que necesitas
        for (const [material, info] of Object.entries(materialsByBodega)) {
          data.labels.push(material + ' - ' + info.name);
          data.values.push(info.value);
        }
      
        return data;
      }
      
      
      function calcularToneladas(materiales, informes) {
        let data = {
          labels: [],
          value: []
        };
      
        materiales.forEach(material => {
          
                const materialId = material.Material._id;
              informes.forEach(informe => {
                informe.Informe.forEach(detalle => {
                  if (materialId === detalle.material) {
                    let valor = detalle.value;
                    if (detalle.Unidad_de_Peso === "kilogramo") {
                      valor /= 1000; 
                    }
                    data.labels.push(informe.Bodega.Nombre + ' - ' + detalle.material);
                    data.value.push(valor);
                  }
                });
              });
        });
      
        return data;
      }
      
      const distanciaTotal = historialRecogida.map(i=>i.PuntoGenerador).reduce((acumulador, actual, index, array) => {
        if (index < array.length - 1) {
            const distancia = calcularDistancia(
              (actual && actual.Latitud !== undefined) ? actual.Latitud : 0,
              (actual && actual.Longitud !== undefined) ? actual.Longitud : 0,
              (array[index + 1] && array[index + 1].Latitud !== undefined) ? array[index + 1].Latitud : 0,
              (array[index + 1] && array[index + 1].Longitud !== undefined) ? array[index + 1].Longitud : 0
            );
            return acumulador + distancia;
        }
        return acumulador;
      }, 0);
      
    return (
        <div className='w-100'>
            
            {solicitudes.length>0&&
              <Link to="/solicitudes" className="d-flex g-5 mt-4 py-2" style={{textDecoration:"none",borderRadius:5,boxShadow:"2px 2px 5px #d7d7d7, -8px -8px 15px #e9e9e9",backgroundColor:"linear-gradient(145deg, #f0f0f0, #cacaca)",width:320}}>
                  <div className="caneca" style={{marginTop:10,marginLeft:40}}>
                      <div className="caneca-lid" style={{backgroundColor:"whitesmoke"}}>
                          <div className="caneca-handle" style={{backgroundColor:"lightgrey"}}></div>
                      </div>
                      <div className="caneca-body">
                          <div className="caneca-fill" style={{height: '100%',backgroundColor:"grey"}}></div>
                      </div>
                  </div>
                  <div className='mx-5 mt-2'>
                      <h5 style={{fontWeight:"bolder",color:"black"}}>Hay {solicitudes.length} solicitudes</h5>
                      <p style={{fontWeight:"bolder",color:"red",textUnderlineOffset:"none"}}> Prioridad alta</p>
                  </div>
              </Link>
            }
            <div className='d-flex justify-content-between p-5 pb-1'>
                <h4>Historial de visitas</h4>
                <div>
                    {dates.inicio == undefined||dates.inicio == "Invalid Date" ?
                    <p> <b> Informe historico </b> </p>
                   :
                    <p> <b>De </b> {new Date(dates.inicio).toLocaleDateString()} <b> A </b> {new Date(dates.fin).toLocaleDateString()} </p>
                    }
                </div>
            </div>

           <div className='my-4 mx-3'>
            <h5>Distancia total recorrida: {distanciaTotal.toFixed(2)} km</h5>
           </div>

            <div className="row mt-5">
                <div className={historialRecogida.length>0 &&!historialRecogida[0]?.Informe?"col-12 col-md-12 col-lg-12 ":"col-12 col-md-6 col-lg-6 "}>
                { (historialRecogida.length>0)&&<MyPieChart data={calcularFrecuenciaVisitas(historialRecogida)} colors={state.colors} title={"Frecuencia de visita"} />}
                </div>
                <div className="col-12 col-md-6 col-lg-6 ">
                { (historialRecogida.length>0&&historialRecogida[0]?.Informe )&&<MyPieChart data={calculateRecoveredMaterials(historialRecogida)} colors={state.colors} title={"Materiales recolectados KG"} />}
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12  col-md-12 col-lg-12 ">
                { (historialRecogida.length>0&&historialRecogida[0]?.Informe) &&<MyPieChart  data={getMaterialsByBodega(historialRecogida)} colors={state.colors} title={"Toneladas por gestor"} />}
                </div>
                {/* <div className="col-12 col-md-6 col-lg-6 ">
                { historialRecogida.length>0 &&<MyPieChart data={calculateRecoveredMaterials(historialRecogida)} colors={state.colors} title={"Materiales recolectador"} />}
                </div> */}
            </div>
            
        </div>
               
    )
}

export default MainDash