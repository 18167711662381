import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from '../../hooks/useForm'
import { useSelector } from 'react-redux'
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import code from '../../helpers/Codephones.json'
import { separarCodigoYTelefono } from '../../helpers/Utils';

const NewEmpresaGene = ({idSede,handleChangeSelect,setNewEmpre,setForm,responsable,obj,newEmpre,Code,setCode}) => {
    const user = useSelector(state=>state.user),
        [newEmpresa,setNewEmpresa]=useState(newEmpre)
        

    let {formState,onInputChange,onResetForm,setFormState}=useForm({
        NombreContacto:responsable?responsable.NombreContacto:"",
        CargoContacto:responsable?responsable.CargoContacto:"",
        Email:responsable?responsable.Email:"",
        Direccion:responsable?responsable.Direccion:"",
        Telefono: responsable?separarCodigoYTelefono(responsable.Telefono).error?"":separarCodigoYTelefono(responsable.Telefono).numero||"":"",
        NombreEmpresa: responsable?responsable.NombreEmpresa||"":""
    })

    const handlechange =(e)=>{
        console.log('====================================');
        console.log("e==> form e",e.target);
        console.log('====================================');
        onInputChange(e)
        setForm(formState)
    }
    const handleEmailChange=(e)=>{
      
        setFormState({...formState,Email:e.target.value})
        setForm(formState)
    }
    useEffect(()=>{},[responsable])

    const addEmpresa=()=>{
        setNewEmpre(!newEmpre)
        setNewEmpresa(!newEmpresa)
    }
  return (
    <div className="d-flex flex-column w-100">
        {responsable=== false&& !obj &&
            <div className="d-flex justify-content-end mb-3">
                <Button variant="contained"  startIcon={<ReplyAllIcon/>} style={{ cursor: "pointer", backgroundColor: user.colors[2] }} 
                    onClick={()=>{setNewEmpre(false)}}>
                Cancelar
                </Button>
            </div>
        }
        {/* {responsable=== undefined && obj !== false &&
            <div className="d-flex justify-content-start mb-3">
                <Button variant="contained"  startIcon={!newEmpresa?<PersonAddIcon/>:<PersonAddDisabledIcon/>} style={{ cursor: "pointer", backgroundColor: user.colors[2] }} 
                    onClick={addEmpresa}>
                    {!newEmpresa?"Crear responsable":"Sin responsabLe"}
                </Button>
        </div>
        }
         {responsable !== false &&responsable !== undefined && obj !== false &&
            <div className="d-flex justify-content-start mb-3">
                <Button variant="contained"  startIcon={!newEmpresa?<PersonAddIcon/>:<PersonAddDisabledIcon/>} style={{ cursor: "pointer", backgroundColor: user.colors[2] }} 
                    onClick={addEmpresa}>
                    {!newEmpresa?"Editar responsable":"Cancelar edición"}
                </Button>
        </div>
        } */}
        {(newEmpresa || responsable) &&
            <>
                <div className="d-flex  gap-1">
                        <div className="col-6 mb-2">
                            <TextField
                                label="Nombre del contacto "
                                type="text"
                                placeholder="Nombre de contacto"
                                fullWidth
                                required
                                name="NombreContacto"
                                value={formState.NombreContacto}
                                onChange={handlechange}
                                className='mb-2'
                            />
                            <TextField
                                label="Cargo del contacto "
                                type="text"
                                required
                                placeholder="Cargo del contacto"
                                fullWidth
                                name="CargoContacto"
                                value={formState.CargoContacto}
                                onChange={handlechange}
                            />
                        </div>

                        <div className="col-6 mb-2 ">
                            <TextField
                                label="Correo"
                                type="email"
                                required
                                placeholder="Correo"
                                fullWidth
                                name="Email"
                                value={formState.Email}
                                onChange={handleEmailChange}
                                className='mb-2'
                            />
                            <TextField
                                label="Dirección"
                                type="text"
                                required
                                placeholder="Direccion"
                                fullWidth
                                name="Direccion"
                                value={formState.Direccion}
                                onChange={handlechange}
                            />
                        </div>
                        
                </div>
                <div className="d-flex  gap-1">
                    <TextField
                        label="Nombre de la entidad"
                        type="text"
                        placeholder="Nombre de la entidad"
                        fullWidth
                        name="NombreEmpresa"
                        value={formState.NombreEmpresa}
                        onChange={handlechange}
                        className='mb-2'
                    />
                    <div className='d-flex gap-1' style={{width:"100%"}}>
                        <Autocomplete
                            id="combo-box-demo"
                            aria-required={true}
                            options={code.paises.map((option) => option.codigo)}
                            onChange={(e,newValue)=>setCode(newValue)}
                            style={{ width: 120, }}
                            renderInput={(params) => (
                            <TextField {...params} label="Prefijo" variant="outlined" />
                            )}
                            defaultValue={Code}
                        />
                        <TextField
                            
                            label="Telefono"
                            type="number"
                            placeholder={formState.Telefono||"Teléfono"}
                            fullWidth
                            defaultValue={formState.Telefono||""}
                            name="Telefono"
                            value={formState.Telefono}
                            onChange={handlechange}
                        />
                    </div>
                </div>
                
                {/* <div className="mt-2 w-100 mb-4">    
                    <Box  >
                    <FormControl fullWidth>
                        <InputLabel id="label-sede">Gestor</InputLabel>
                        <Select
                        labelId="label-sede"
                        id="demo-simple-select"
                        fullWidth
                        value={idSede?idSede:user?.PuntosBodega[0]?.Nombre}
                        label="Gestor"
                        // onChange={(e)=>handle(e)}
                        
                        >
                        {user?.PuntosBodega&&user?.PuntosBodega?.map((punto,i )=> 
                            <MenuItem key={i} value={punto.Nombre} onClick={handleChangeSelect}>{punto.Nombre}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                    </Box>
                </div> */}
             </>
        }

        <Divider sx={{height:2, background:"#E3E3E3",marginBottom:5}}/>
    </div>
  )
}

export default NewEmpresaGene