import React, { useEffect, useState } from 'react'
import Mancha from '../../../../../assets/blob-haikei.png';
import { UseGetWithAuth2 } from '../../../../../service/base2';
import { useDispatch, useSelector } from 'react-redux';
import { PqrsAction } from '../../../../../service/redux/action/DataActions';
import { stylesBt1, stylesBt2 } from '../../../../dashboard/MainDash/TypesTables/IngresosPlanta/Styles';
import TablasActivas from './components/TablasActivas';
import TablasCerradas from './components/TablasCerradas';
const emptyPlaces = require('../../../../../assets/emptyplaces.png')

const PQRS = ({sede}) => {

    const user = useSelector(state=>state.user)
    const [pqrs,setPqrs]=useState([])
    const [type,setType]=useState("Activas")
    
    const dispatch = useDispatch()

    const gettingData = async ()=>{
      let data = user.bodegas.map((e) => {
        let text = ""
        if (e.id != undefined) { text += "Bodega_in=" + e.id + "&" }
        return text.toString()
      })
      const { ok, resGet }  = await UseGetWithAuth2("/pqrs?"+data.join(''),user?.Personal?.uid)
      if(ok){
        let Pqr = resGet.filter((e)=>e.Bodega.id==sede.Bodega)
        setPqrs(Pqr)
        dispatch(PqrsAction(resGet))
      }
    }

    useEffect(()=>{
      if(!user.Pqrs){
        gettingData()
      }else{
        console.log('==>',sede);
        console.log(user.Pqrs);
        
        let Pqr = user.Pqrs.filter((e)=>e.Bodega.id==sede.Bodega)
        console.log('=>',Pqr);
        setPqrs(Pqr)
      }
    },[])

  return (
    <div>
       {pqrs.length>0?
        <div className='d-flex flex-column gap-3 justify-content-center p-2  mb-2 align-items-center'>

          <div className="d-flex justify-content-center gap-5 mt-3" style={{width:"100%"}}>
              <div className='d-flex justify-content-center gap-3 w-50' onClick={()=>setType("Activas")} style={type=="Activas"?stylesBt1(user.colors[8]):stylesBt2}>
                      <p style={{width:"100%"}}>Activas</p>
                  </div>

                  <div className='d-flex justify-content-center gap-3 w-50' onClick={()=>setType("cerradas")} style={type=="cerradas"?stylesBt1(user.colors[8]):stylesBt2}>
                      <p style={{width:"100%"}}>Cerradas</p>
                  </div>
              </div>
              {type=="Activas"&&<TablasActivas pqrs={pqrs.filter((e)=>e.Activo==true)}/>}
              {type=="cerradas"&&<TablasCerradas pqrs={pqrs.filter((e)=>!e.Activo)}/>}
          </div>

        :
        <div className='d-flex flex-column gap-3 justify-content-center p-2  mb-2 align-items-center'>
          
            <h3>No hay PQRS en este momento.</h3>
            <div style={{ backgroundImage: `url(${Mancha})`,width: 500, height: 500, display:"flex",alignItems:"center",justifyContent:"center",
                backgroundSize: 'cover',backgroundPosition: 'center',marginTop:-60 }}>
                <img src={emptyPlaces} alt="emptyPlaces" style={{width:160,height:160,}}/>
            </div>

        </div>
        }

    </div>
  )
}

export default PQRS