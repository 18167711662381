import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, IconButton, MenuItem } from '@mui/material';
import { DeleteAllWithAuth, UseGetWithAuth2 } from '../../../service/base2';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Swal from 'sweetalert2';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { hasPermission } from '../../../helpers/Utils';
import { materialesAction } from '../../../service/redux/action/DataActions';
import { EliminateMat_sede, eliminateMat_Empresa } from './FunctionsCrud';
const { v4: uuidv4 } = require('uuid');

const Materiales = ({ setView, setParams,setMaterialEdit ,setValue,setLoading}) => {
  const { empresas } = useSelector(state => state.empresa)
  const user = useSelector(state => state.user)
  let id_empresa = user?.typeUser === 'EMPRESA' ? empresas[0]?.empresas?.data?.id : user?.bodegas[0]?.Empresa?.id;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [calculadoraMateriales, setCalculadoraMateriales] = useState(user?.materiales)
  const [menuOpen, setMenuOpen] = React.useState(false);
  const dispatch =useDispatch()
  const getCalculadoraMateriales = async () => {
    const { ok, resGet } = await UseGetWithAuth2(`/calculadora-materiales/?_where[Empresa]=${id_empresa}`, user?.Personal?.uid)
    if (ok) return setCalculadoraMateriales(resGet)
  } 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (e) => {
    setParams(e)
    setView("reportes")
  };
  
  const eliminarMaterial = async (e) => {
    await Swal.fire({
        title: '¿Estás seguro de eliminar este elemento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
    }).then(async(result) => {
        if (result.isConfirmed) {
          setLoading(true)
          if(user?.typeUser === "EMPRESA" ){
            
              eliminateMat_Empresa(user,dispatch,e,setCalculadoraMateriales,setLoading)
                // const t = await DeleteAllWithAuth(`calculadora-materiales/${e.id}`, user?.Personal?.uid);
                // console.log('====================================');
                // console.log(t.ok);
                // console.log('====================================');
                // if (t.ok) {
                //     if(hasPermission(empresas[0]?.empresas?.Servicios, "Compra y/o venta")){
                //         const w = await DeleteAllWithAuth(`precios-materiales/${e.idPrecio}`, user?.Personal?.uid);
                //           dispatch(materialesAction(user?.materiales.filter(u=>u.id!=e.id)))
                //           setCalculadoraMateriales(user?.materiales.filter(u=>u.id!=e.id))
                //           Swal.fire({
                //               position: 'center', icon: 'success', title: `El material ha sido eliminado`,
                //               showConfirmButton: false,
                //               timer: 2500
                //           })
                //     }else{
                //         dispatch(materialesAction(user?.materiales.filter(u=>u.id!=e.id)))
                //         setCalculadoraMateriales(user?.materiales.filter(u=>u.id!=e.id))
                //         Swal.fire({
                //           position: 'center', icon: 'success', title: `El material ha sido eliminado`,
                //           showConfirmButton: false,
                //           timer: 2500
                //         })
                        
                //     }
                // }else{
                //     Swal.fire({
                //       position: 'center', icon: 'error', title: `El material no ha sido eliminado`,
                //       showConfirmButton: false,
                //       timer: 2500
                //     })
                // }
            }else{
              console.log("ENTRO a eliminar esta monda");
              EliminateMat_sede(user,dispatch,e,setCalculadoraMateriales,setLoading)
            }
        }
    }).then(() => {
      // getCalculadoraMateriales()
    })
};


  return (
    <div className='d-flex flex-column'>
      <Paper sx={{ width: "100%", alignSelf: "center",borderRadius:5 }}>
          <TableContainer >

              <Table aria-label="sticky table">
                  <TableHead>
                      <TableRow hover tabIndex={-1} key={uuidv4()}>

                          <TableCell align={"center"} size='small'> <b>Nombre</b> </TableCell>
                          <TableCell align={"center"} size='small'> <b>Unidad de peso</b> </TableCell>
                          {((user?.typeUser === "EMPRESA" && hasPermission(user?.typeUser === "EMPRESA" &&empresas[0]?.empresas?.Servicios, "Compra y/o venta"))|| (hasPermission(user?.typeUser == "PUNTOBODEGA"&& user?.bodegas[0].PermisosBodega, "Compra y/o venta"))) && (
                                <>
                                  <TableCell align={"center"} size='small'> <b>Precio</b> </TableCell>
                                  <TableCell align={"center"} size='small'> <b>Unidad de precio</b> </TableCell>
                                  <TableCell align={"center"} size='small'> <b>Moneda</b> </TableCell>
                                </>
                          )}
                          <TableCell align={"center"} size='small'> <b>Acciones</b> </TableCell>
                          
                      </TableRow>
                  </TableHead>

                  <TableBody>
                      {calculadoraMateriales && calculadoraMateriales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(e => (
                        <TableRow hover tabIndex={-1} key={uuidv4()} >

                            <TableCell  align={"center"} size='small'> {e.Material.Material}</TableCell>
                            <TableCell  align={"center"} size='small'> {e.Unidad_de_Peso}</TableCell>
                            {
                              (user?.typeUser === "EMPRESA" && hasPermission(empresas[0]?.empresas?.Servicios, "Compra y/o venta")) && (
                                <>
                                  <TableCell align={"center"} size='small'>  $ {e.Precio} </TableCell>
                                  <TableCell align={"center"} size='small'> {e.Unidad_de_Precio} </TableCell>
                                  <TableCell align={"center"} size='small'> {e.Moneda} </TableCell>
                                </>
                              )
                            }
                            <TableCell key={uuidv4()} align={"center"} size='small'  style={{ position: "relative"}} onMouseLeave={()=>setMenuOpen(false)}>
                                <IconButton aria-label="more" id="custom-button" style={{ position: "relative", width: 50, height: 50 }} onClick={() => setMenuOpen((menuOpen === false || menuOpen !==e.id) ? e.id : false)} >
                                    <MoreVertIcon />
                                </IconButton>
                                                      
                                {menuOpen == e.id && (
                                    <div style={{ position: "absolute",top:20, right: 20,backgroundColor: "#fff",boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)", zIndex: 2000,width: "17ch", borderRadius: 5}} >
                                        {hasPermission(user?.Personal?.Permisos,'Modificar material') && 
                                            <MenuItem onClick={() => { setMenuOpen(false); setMaterialEdit(e); setValue("3") }}> Editar </MenuItem>
                                        }
                                        {hasPermission(user?.Personal?.Permisos,'Eliminar material') && 
                                            <MenuItem onClick={() => { setMenuOpen(false);eliminarMaterial(e)}}> Eliminar </MenuItem>
                                        }
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
              </Table>

          </TableContainer>
          
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]} component="div" count={calculadoraMateriales? calculadoraMateriales.length:0} rowsPerPage={rowsPerPage}
            page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </div>
  );
}

export default Materiales
