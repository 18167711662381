import React, { useEffect } from 'react'
import { Paper, Tooltip } from '@mui/material'
import './Styles.css';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useState } from 'react';
import ToRenderDash from '../ToRender/ToRenderDash';

import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FilterDate from './FilterDate';



const ViewDashboard = ({privada}) => {
  const { empresas, loading } = useSelector(state => state.empresa)
  const user = useSelector(state => state.user)
  const [view, setView] = useState("Graficas"),
        [selectDate, setSelectDate] = useState(false),
        [filter, setFilter] = useState(false),
        [data, setData] = useState([]),
        [open, setOpen] = useState(false);


  const handleClose = () => {
    setOpen(false);
  };


  // useEffect(() => {}, [user])
  return (
    <div style={{ position: "relative" }}>
      <FilterDate filter={filter} selectDate={selectDate} setData={setData} setFilter={setFilter} setOpen={setOpen} reports={user.reportes} setSelectDate={setSelectDate} />
      
      {/* ----------------------- Barra Navegacion fnciones ----------------------- */}
      <Paper elevation={2} className="navDash text-white" style={{ backgroundColor:user?.colors?user?.colors[1]:"lightgrey",borderRadius:20 }}>
          <h3> Dashboard </h3>
          <div className='NavIcons' >
              <Tooltip title="Tablas">
                <BackupTableIcon onClick={() => setView("Tablas")}
                  style={view === "Tablas"  ?  { color: "#fff" }:{ color: '#666' }} />
              </Tooltip>

              <Tooltip title="Graficas" >
                <EqualizerIcon onClick={() => setView("Graficas")}
                  style={view === "Graficas" ?  { color: "#fff" }:{ color: '#666' }}/>
              </Tooltip>

              <Tooltip title="Fechas">
                <CalendarMonthIcon onClick={() => setFilter(true)} style={{ color: '#666' }}/>
              </Tooltip>
          </div>

      </Paper>

      <ToRenderDash view={view} data={data} />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">
              {"Campos vacios"}
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ingresa el rango de fechas a filtrar
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cerrar</Button>
          </DialogActions>
      </Dialog>
    </div>
  )
}

export default ViewDashboard

