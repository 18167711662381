import { Avatar,  Button,  Divider,  Grid,  Paper,  TableCell, TableRow, styled } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getAll } from '../../service/base/base'
import { SplitingInitialsName } from '../../helpers/Utils'
import { Link, useParams } from 'react-router-dom'
import Stack from '@mui/material/Stack';
import PersonIcon from '@mui/icons-material/Person';
import WidthWideIcon from '@mui/icons-material/WidthWide';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export const ItemsPersonalEmpresa = () => {
    const { empresas } = useSelector(state => state.empresa)
    const { user } = useSelector(state => state)
    const [usuarios, setUsuarios] = useState()
    const {id} =useParams()

    const getPuntoBodegaById = async () => {
        const res = await getAll(`/personals/${id}`, user?.Personal?.uid);
        setUsuarios(res.data)

    };
    
    React.useEffect(() => {
        getPuntoBodegaById()
    }, [])

    return (
        <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
            <div style={{display:"flex",justifyContent:"space-between",width:"99%"}}  >
                <div style={{display:"flex",alignItems:"center"}}  >
                    <PersonIcon style={{textAlign:"left",marginTop:-10,marginRight:10, color:user.colors[2],fontSize:50}} />
                    <h3 style={{textAlign:"right"}}>{usuarios?.user?.username}</h3>
                </div>
                <Link className="navbar-brand" to="/personal">
                    
                    <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ backgroundColor: `#${user?.Personal?.Empresa?.Color}`, cursor: "pointer" }}
                   
                >
                    Volver a Personal
                </Button>
                </Link>
            </div>
            <Grid container spacing={2} style={{alignItems:"center",padding:10,background:user.colors[8],margin:7,width:"98%",
                    borderRadius:5,alignSelf:"center",boxShadow:`inset 2px 2px 5px ${user.colors[8]}`}}>

                <Grid item xs={2} md={6}>
                        <Stack direction="row" justifyContent={"center"} >
                            <Avatar sx={{ bgcolor: `#${user?.Personal?.Empresa?.Color}}` }} style={{width:125,height:125,fontSize:45}}>
                                {SplitingInitialsName(usuarios?.user?.username)}
                            </Avatar>
                        </Stack>
                <Divider style={{height:20, background:user.colors[9],marginTop:10}} variant='fullWidth'/>
                </Grid>
                <Grid item xs={10} md={6} >
                        
                    <div  className='m-2 'style={{display:"flex",flexDirection:"column",minHeight:270,justifyContent:"center",alignItems:"center"}}>
                        <h3 className='mb-5'>Información personal</h3>
                        
                        <div style={{display:"flex",alignItems:"center"}}  >
                            <WidthWideIcon style={{textAlign:"left",marginTop:-15,marginRight:10,color:user.colors[2]}}/>
                            <p style={{textAlign:"right"}}>Documento: {usuarios?.Cedula}</p>
                        </div>
                        <div style={{display:"flex",alignItems:"center"}}  >
                            <LocalPhoneIcon style={{textAlign:"left",marginTop:-15,marginRight:10,color:user.colors[2]}}/>
                            <p style={{textAlign:"right"}}>Teléfono: {usuarios?.Telefono}</p>
                        </div>
                        <div style={{display:"flex",alignItems:"center"}}  >
                            <AlternateEmailIcon style={{textAlign:"left",marginTop:-15,marginRight:10,color:user.colors[2]}}/>
                            <p style={{textAlign:"right"}}>Correo: {usuarios?.user?.email}</p>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={12} md={12} >
                    <Item   className='m-2 ' style={{minHeight:270}}>
                    <h3 className='mb-5'>Permisos</h3>
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{padding:20,marginLeft:5}}>
                            {usuarios&&usuarios.Permisos.map((p, index) => (
                                <Grid xs={6} sm={6} md={4} key={index}>
                                    <div style={{display:"flex"}}  >
                                     <RadioButtonCheckedIcon style={{fontSize:10,color:user.colors[5],marginTop:5,marginLeft:2}}/>
                                    <p style={{textAlign:"left",fontSize:15,width:"100%"}}> {p.Permiso}</p>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Item>
                </Grid>
                
            </Grid>
        </div>
    )
}
