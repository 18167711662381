


export const typesEmpresa = {
    getEmpresa: "[empresa]getEmpresa",
    addEmpresaSuccess: "[empresa]addEmpresaSuccess",
    addEmpresaFailure: "[empresa]addEmpresaFailure",
    addEmpresaStarted: "[empresa]addEmpresaStarted",
    deleteEmpresa: "[empresa]deleteEmpresa",

}