import React, { useState } from "react";
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import { useSelector } from "react-redux";
import "../Styles.css"
import { PostAllWithAuth, PutAllWithAuth, UseGetWithAuth2 } from "../../../service/base2";
import Swal from "sweetalert2";
import { Alert } from "@mui/material";
import { fileUploadQRUsuario, generarQREnBlob } from "../../../helpers/FileUpload";
import { generadoresAction } from "../../../service/redux/action/DataActions";

const Fade = React.forwardRef(function Fade(props, ref) {
    const {
      children,
      in: open,
      onClick,
      onEnter,
      onExited,
      ownerState,
      ...other
    } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter(null, true);
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited(null, true);
        }
      },
    });
  
    return (
      <animated.div ref={ref} style={style} {...other}>
        {React.cloneElement(children, { onClick })}
      </animated.div>
    );
  });
  
  Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
  };
  
  const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "60%",
      bgcolor: 'background.paper',
      border: '2px solid #999',
      borderRadius:5,
      boxShadow: 24,
      p: 4,
      zIndex:200
  };

export default function LlamadorModal({status,setStatus,gene,setLoading}) {

    const user = useSelector(state => state.user)
    const [email,setEmail]=useState("")
    const [Generator,setGenerator]=useState("")
    const [confirmSending,setConfirmSending]=useState(false)
    
    
    const SearchGenerator = async (event) => {
        event.preventDefault()
        if(email.length>8){
            const mate = await UseGetWithAuth2("/generadores?_where[Usuario.email]="+email.toLowerCase().trim(), user?.Personal?.uid)
            if(mate.ok){
                if(mate.resGet.length>0){
                    setGenerator(mate.resGet[0])
                    setConfirmSending(true)
                }else{
                    alert('Por favor, verifica que la dirección de correo esté escrita correctamente.')
                    
                }
            }else{alert('No se pudo obtener la información solicitada.')}
            

        }else{alert('Campo de Correo Vacío')}
            
    };

    const onChangeInput=(e)=>{
            setEmail(e.target.value)
    }

    const Confirm = async ()=>{
        setLoading(true)
        setStatus(false)
        setConfirmSending(false)
        generarQREnBlob(Generator.id).then(async(blob) => {
            const r = await fileUploadQRUsuario(blob);

            const _Qrs = await PostAllWithAuth(`/qrs`, user?.Personal?.uid, {"Img":r,"Huella":Generator.id,"Generador":Generator.id},);
            if(_Qrs.ok){
                
                let data={
                    ManejaEca:true,
                    Qr:_Qrs.resPost.data.id,
                    PuntoGenerador: gene.id,
                }
                const { resPut, ok } = await PutAllWithAuth(`/generadores/${Generator?.id}`, user?.Personal?.uid, data);
                
                if(ok){
                   let filtered = user.PuntosGeneradores.filter(e=>e.id!=gene.id)
                        let g = gene
                            g.Llamadores.push(resPut)
                            filtered.push(g)
                            generadoresAction(filtered)
                            
                            setLoading(false)
                            Swal.fire({ position: 'center', icon: 'success', title: `La relación se ha generado exitosamente`,showConfirmButton: false, timer: 1500 })
                }else{alert('No se pudo obtener la información solicitada.');setLoading(false);setStatus(true)}
            }else{alert('No se pudo obtener la información solicitada.');setLoading(false);setStatus(true)}
        })
    }

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={status}
        onClose={()=>setStatus(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={status}>
          <Box sx={style}>
            {confirmSending?
                <div style={{padding:10,
                backgroundColor:user.colors[8]+"a3",borderRadius:10}}>
                    <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-evenly",alignItems:"center",padding:10,
                                    backgroundColor:user.colors[8]+"f7",borderRadius:5,marginBottom:20}}>

                        <div style={{width:"30%"}}>
                            <img src={Generator.Imagen} alt={Generator.Usuario.username} style={{width:150,height:150, borderRadius:"100%"}}/>
                        </div>

                        <div>
                            <p>Nombre: {Generator.Usuario.username}</p>
                            <p>Correo: {Generator.Usuario.email}</p>
                            <p>Teléfono: {Generator.Telefono||"Sin datos"}</p>
                        </div>
                    </div>
                    <Button variant="contained"  onClick={Confirm}
                        style={{backgroundColor:user.colors[2],width:"40%",display:"flex",margin:"auto"}}>
                        Confirmar generador
                    </Button>
                </div>
            :<div className='d-flex justify-content-center flex-column'>
                <h3 className="mb-5">Asociación de llamador </h3>
                    <p style={{fontSize:18,fontWeight:400}}>
                        Para facilitar a los generadores realizar llamados de recolección de forma rápida, es necesario vincular sus cuentas con la sede.
                    </p>

                    <p style={{fontSize:18,fontWeight:600}}>
                        Por favor, ingresa el correo electrónico que el generador utiliza para acceder a la aplicación. 
                    </p>
                    <form onSubmit={SearchGenerator}>
                        <input className="formEmail" placeholder="email" type="email" onChange={onChangeInput}
                            style={{border:"none",borderBottom:` 2px solid ${user.colors[2]}`,height:30,marginTop:20,marginBottom:"5%",width:"95%"}}
                        />

                        <Button variant="contained"  type="submit"
                            style={{backgroundColor:user.colors[2],width:"40%",display:"flex",margin:"auto"}}>
                            {"Buscar"}
                        </Button>
                    </form>
            </div>
            }
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}