import React from 'react'
import EnRuta from './typos/EnRuta';
import { stylesBt1, stylesBt2 } from '../IngresosPlanta/Styles';
import { Tooltip } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useSelector } from 'react-redux';
import { procesarHistorialCompras } from './Function/function';
import { formatDate } from '../../../../../helpers/Utils';
import { generateExcel } from '../../../../../helpers/Pdfs/GenerateExcel';
import Swal from 'sweetalert2';

const MaterialesVentas = ({historialRecogida,historialIngreso,dates}) => {
    const [type, setType] = React.useState('En Ruta');
    const { user } = useSelector(state => state)

    const Excel =(i)=>{
        
        const  dataTosend = historialRecogida.length>0? procesarHistorialCompras(type=="En Ruta"?historialRecogida:historialIngreso,user):[]
           
        if(dataTosend.length>0){
            let title = !dates||dates.inicio == undefined ?"Informe historico":`Informe periodo de  ${formatDate(new Date(dates.inicio).toLocaleString())} a ${formatDate(new Date(dates.fin).toLocaleString())}`
            let gestor = i==1?"Ingresos de rutas":"Recepción en planta"
            generateExcel(dataTosend,title,gestor)
        }else{
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'No hay datos para generar archivo.',
                showConfirmButton: false,
                timer: 1500
              })
        }
      }

  return (
    <div>
      <div className="d-flex justify-content-center gap-5">
            <div className='d-flex justify-content-center gap-3 w-50' onClick={()=>setType("En Ruta")} style={type=="En Ruta"?stylesBt1(user.colors[8]):stylesBt2}>
                <p style={{width:"95%"}}> Ingresos rutas</p>
                <div style={{width:"5%"}} >
                    <Tooltip title="Descargar excel" >
                        <CloudDownloadIcon style={{ color: type=="En Ruta"?user.colors[2]:'#666',marginTop:-2,cursor:"pointer" }} onClick={()=>{Excel(1)}}/>
                    </Tooltip>
                </div>
            </div>

            <div className='d-flex justify-content-center gap-3 w-50' onClick={()=>setType("En Sede")} style={type=="En Sede"?stylesBt1(user.colors[8]):stylesBt2}>
                <p style={{width:"95%"}}>Recepción en planta</p>
                <div style={{width:"5%"}} >
                    <Tooltip title="Descargar excel" >
                        <CloudDownloadIcon style={{ color: type=="En Sede"?user.colors[2]:'#666',marginTop:-2,cursor:"pointer" }} onClick={()=>{Excel(2)}}/>
                    </Tooltip>
                </div>
            </div>
        </div>
      <div>
        {type === 'En Ruta' && <EnRuta historialRecogida={historialRecogida} dates={dates} />}
        {type === 'En Sede'&& <EnRuta historialRecogida={historialIngreso} dates={dates} />}
      </div>
    </div>
  )
}

export default MaterialesVentas