import React, { useEffect, useState } from 'react';
import './styles.css'; 
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { generateResPQRS } from '../../../../../../../helpers/Pdfs/generatePdf';
import { UseGetWithAuth2 } from '../../../../../../../service/base2';
import { sendPdf } from '../../../../../../../helpers/FileUpload';

const ModalTextarea = ({ isOpen, onClose, Gene,Cerrar }) => {
    console.log('====================================');
    console.log(Gene);
    console.log('====================================');
    const user = useSelector(state=>state.user)
    const [generadorEmail, setGeneradorEmail] = useState(false)
    let nombreEmpr = user?.typeUser === 'EMPRESA'?  user.Personal.Empresa.Nombre :user?.bodegas[0]?.Empresa.Nombre
    const [text, setText] = useState({
        paragraph1: "",
        paragraph2: "",
        email: ""
    })
    

    const getGeneradorEmail = async () => {
        if(isOpen) return await UseGetWithAuth2("/users/"+Gene.Generador.Usuario, user?.Personal?.uid).then((res)=>{
                    if(res.ok){
                        console.log(res);
                        setGeneradorEmail(res.resGet.email)
                        
                    }
                })
    }

    useEffect(() => {getGeneradorEmail()}, [isOpen])

    const handleTextChange = (e) => {
        setText({...text, [e.target.name]: e.target.value})
    }

    const handleSend = async () => {
        if(text.paragraph1=="") return Swal.fire({icon: 'error', title: 'Oops...', text: 'Debes llenar al menos el primer párrafo'})
        if(nombreEmpr=="Vestolit"){
            const generate = await generateResPQRS(text.paragraph1,text.paragraph2.length>0?text.paragraph2:null,text.email?text.email:null)
            await sendPdf(generate,generadorEmail,'Respuesta PQR','Respuesta PQR',user?.Personal?.uid).then((res)=>{
                console.log('====================================');
                console.log(res);
                onClose()
                Cerrar(Gene.id)
            }).catch((e)=>{
                console.log(e);
            })
                
          }
        console.log(text)
        onClose()
    }
    if (!isOpen) return null;





    return (
      <div className="modal-backdrop" style={{position: "fixed",
        top: 0,left: 0,
        width: "100%", height: "100%",
        backgroundColor: "#000000c3",display: "flex",justifyContent: "center",alignItems: "center", zIndex: 99}} >

        <div  style={
                    {backgroundColor: "#fff",padding: 20, width: "70%", 
                    zIndex: 100,display: "flex", flexDirection: "column", 
                    justifyContent: "space-between",borderRadius: 5,
                    boxShadow: "0 2px 8px rgba(22, 22, 22, 0.26)"}
                    } 
                onMouseLeave={onClose}
        >
            <h2 style={
                        {color:user.colors[2], textAlign:"center", 
                        fontSize:30, fontWeight:"bold", 
                        marginBottom:20}
                    }
            >
                Envío de Respuesta PQRS
            </h2>
        

            <p style={
                {color:user.colors[2], textAlign:"left", 
                fontSize:12, fontWeight:"bold",marginBottom:0,paddingLeft:5}
            }>
                Párrafo 1.
            </p>

            <textarea placeholder="Escribe algo..." 
                name='paragraph1' onChange={handleTextChange}
                style={
                        {border: "none", backgroundColor:user.colors[8]+"81", 
                        borderRadius:10, marginBottom:10,padding:5}
                      }
            />

            <p style={
                {color:user.colors[3], textAlign:"left", 
                fontSize:12, fontWeight:"bold",marginBottom:0,
                paddingLeft:5}
            } >
                Párrafo 2.
            </p>

            <textarea placeholder="Escribe algo..." 
                name='paragraph2' onChange={handleTextChange}
                style={
                        {border: "none", backgroundColor:user.colors[8]+"81", 
                        borderRadius:10, marginBottom:30,padding:5}
                    }
            />

            <p>Si esperas recibir una respuesta directa, por favor introduce el correo electrónico a continuación.</p>

            <input type="email" placeholder="Email de respuesta (opcional)" 
                    name='email' onChange={handleTextChange}
                    style={
                        {border: "none", backgroundColor:user.colors[8]+"41", 
                        borderRadius:10, padding:5, minHeight:35, marginBottom:30
                        }}
            />

            <button onClick={handleSend} 
                    style={
                            {borderRadius:10, width:"70%",alignSelf:"center", 
                            border:"none", backgroundColor:user.colors[1], 
                            color:"white", padding:10, fontWeight:"bold"}
                           }
            >
                Enviar
            </button>
        </div>
      </div>
    );
}

export default ModalTextarea