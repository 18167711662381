import { jsPDF } from "jspdf";
import 'jspdf-autotable';
const Baxter = require('../../assets/Pdfs/Vestolit/Baxter.png')
const Hilo = require('../../assets/Pdfs/Vestolit/Hilo.png')
const Vita = require('../../assets/Pdfs/Vestolit/Vita.png')
const Vinilo = require('../../assets/Pdfs/Vestolit/ViniloMov.png')
const Firma = require('../../assets/Pdfs/Vestolit/FJ.png')
const Vestolit = require('../../assets/Pdfs/Vestolit/Vestolit.png')
const Pavco = require('../../assets/Pdfs/Vestolit/Pavco.png')
const Kopelle = require('../../assets/Pdfs/Vestolit/Kopelle.png')
const W2W = require('../../assets/Pdfs/Vestolit/w2w.png')
const recy = require('../../assets/Pdfs/Vestolit/recycle.png')

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};


export const generatePDF = async (nombre, nombreEmpres) => {
  let texto1 = `Desde Vinilo en Movimiento le damos una cordial bienvenida a ${nombreEmpres} al primer Programa de cierre de ciclo del Vinilo rígido y flexible del sector de la construcción, médico y textil; liderado por Vestolit en alianza con sus clientes como Baxter, Pavco, Grupo Kopelle, entre otros, y apoyado por W2W. ¡Estamos muy emocionados por su vinculación a esta red que transforma el mundo a través de la Economía Circular y la sustentabilidad!`
  let texto2 = `Con esta vinculación se apoya el crecimiento y mejoramiento de la calidad de vida de los recicladores en el país, gracias al fortalecimiento técnico y financiero, y al apalancamiento en la obtención de equipos y capital de trabajo que brinda el Programa para robustecer la cadena de aprovechamiento del Vinilo. Adicionalmente, ${nombreEmpres} percibirá beneficios asociados al cumplimiento de metas de sostenibilidad internas, reducción de costos en la gestión de residuos, disminución de la huella de carbono y fortalecimiento en la trazabilidad de los residuos a través de una aplicación desarrollada a medida para la operación del Programa`
  let texto3 = `Vinilo en Movimiento agradece a ${nombreEmpres} su participación y su compromiso con la Sostenibilidad y la Circularidad, siendo líderes de la transición hacía la economía circular desde su sector.`

  const pdf = new jsPDF('portrait', 'mm', 'a4');

  const pageHeight = pdf.internal.pageSize.getHeight();
  const pageWidth = pdf.internal.pageSize.getWidth();
  const footerHeight = 40;

  pdf.addImage(Hilo, 'PNG', 10, 8, 37, 18);
  pdf.addImage(Vita, 'PNG', 150, 8, 25, 21);
  pdf.addImage(Vinilo, 'PNG', 180, 8, 20, 20);
  pdf.setFontSize(10);
  pdf.text("Contacto: 3204881774 - gestionviniloenmovimiento@gmail.com", 108, 37)
  pdf.text("https://www.vestolit.com/vinyl-in-motion/", 146, 43)
  pdf.line(0, 38, 220, 39);
  pdf.setFontSize(14);
  pdf.text("Cordial Saludo,", 15, 66);
  pdf.setFontSize(14);
  pdf.text(nombre, 16, 76);
  pdf.setFontSize(12);
  let line1 = pdf.splitTextToSize(texto1, 175)
  let line2 = pdf.splitTextToSize(texto2, 175)
  let line3 = pdf.splitTextToSize(texto3, 175)
  pdf.text(line1, 15, 96);
  pdf.text(line2, 15, 130);
  pdf.text(line3, 15, 176);
  pdf.addImage(Firma, 'PNG', 17, 200, 30, 20);
  pdf.line(15, 215, 50, 215);
  pdf.setFontSize(12);
  pdf.text('Javier Pérez', 17, 225);
  pdf.setFontSize(10);
  pdf.text('Líder Vinilo en movimiento', 17, 230);
  pdf.setFillColor("#95D3DB");
  pdf.rect(0, pageHeight - footerHeight, pageWidth, footerHeight, 'F');
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(13);
  pdf.text('Una iniciativa de:', 4, pageHeight - 30);
  pdf.text('En alianza con:', 70, pageHeight - 30);
  pdf.text('Con el apoyo de:', 167, pageHeight - 30);
  pdf.addImage(Vestolit, 'PNG', 1, pageHeight - 22, 60, 17);
  pdf.addImage(Baxter, 'PNG', 69, pageHeight - 17.5, 25, 7);
  pdf.addImage(Pavco, 'PNG', 99, pageHeight - 17.5, 25, 7);
  pdf.addImage(Kopelle, 'PNG', 129, pageHeight - 18, 26, 9);
  pdf.addImage(W2W, 'PNG', 172, pageHeight - 20, 26, 10);


  const blob = pdf.output('blob');  // Obtiene el blob del PDF
  const base64String = await blobToBase64(blob);  // Convierte el blob a base64
  return base64String;  // Retorna el string base64
};

export const GeneratePDFResponsable = async (title, headers, data) => {
  const pdf = new jsPDF('portrait', 'mm', 'a4');

  const pageHeight = pdf.internal.pageSize.getHeight();
  const pageWidth = pdf.internal.pageSize.getWidth();
  const footerHeight = 40;

  pdf.addImage(Hilo, 'PNG', 10, 8, 33, 18);
  pdf.setFontSize(10);
  pdf.text("Contacto: 319 4987086 - hola@hilo.cool", 145, 37)
  pdf.text("https://hilo.cool", 185, 43)
  pdf.line(0, 38, 220, 39);
  pdf.setFontSize(18);
  pdf.setTextColor("#626E2A")
  pdf.text(title, 15, 75)

  pdf.setTextColor(150)
  pdf.autoTable({
    startY: 85,
    head: headers,
    body: data,
    theme: 'striped',
    styles: { fontSize: 8 },
    headStyles: { fillColor: "#626E2A" },
  });

  pdf.setFontSize(10);
  pdf.text("Reduce", 15, pageHeight - 17)
  pdf.addImage(recy, 'PNG', 30, pageHeight - 21, 6, 6);
  pdf.text("Reuse", 95, pageHeight - 17)
  pdf.addImage(recy, 'PNG', 110, pageHeight - 21, 6, 6);
  pdf.text("Recycle", 175, pageHeight - 17)
  pdf.addImage(recy, 'PNG', 190, pageHeight - 21, 6, 6);

  //   let blob = pdf.output('datauristring')
  pdf.save("Informe.pdf");
  //   return blob
  const blob = pdf.output('blob');  // Obtiene el blob del PDF
  const base64String = await blobToBase64(blob);  // Convierte el blob a base64
  return base64String;  // Retorna el string base64
}

export const GeneratePDFResponsableVestolit = async (title, headers, data, generador) => {
  let texto1 = `Vinilo en Movimiento agradece a ${generador} su participación durante este mes en la entrega de PVC aprovechable, su compromiso con la Sostenibilidad y la Circularidad le permite ser lider de la transición hacía la Economía Circular desde su sector. A continuación, se detallan las cantidades recolectadas por el gestor.`
  let texto2 = `Al entregar el material al programa, está apoyando el crecimiento y mejoramiento de la calidad de vida de los recicladores en el país, debido al fortalecimiento técnico y financiero, y al apalancamiento en la obtención de equipos y capital de trabajo que brinda el programa para robustecer la cadena de aprovechamiento del Vinilo. Además, ${generador} está reduciendo sus costos en la gestión de residuos, disminución de su huella de carbono y fortalecimiendo la trazabilidad de los residuos.`
  const pdf = new jsPDF('portrait', 'mm', 'a4');

  const pageHeight = pdf.internal.pageSize.getHeight();
  const pageWidth = pdf.internal.pageSize.getWidth();
  const footerHeight = 40;

  pdf.addImage(Hilo, 'PNG', 10, 8, 33, 18);
  pdf.setFontSize(10);
  pdf.text("Contacto: 319 4987086 - hola@hilo.cool", 145, 37)
  pdf.text("https://hilo.cool", 185, 43)
  pdf.line(0, 38, 220, 39);
  pdf.setFontSize(18);
  pdf.setTextColor("#626E2A")
  pdf.text(title, 15, 90)

  pdf.setTextColor(150)
  pdf.setFontSize(12);
  let line1 = pdf.splitTextToSize(texto1, 175)
  pdf.text(line1, 15, 66);
  pdf.setFontSize(18);
  pdf.autoTable({
    startY: 105,
    head: headers,
    body: data,
    theme: 'striped',
    styles: { fontSize: 8 },
    headStyles: { fillColor: "#626E2A" },
  });
  pdf.setFontSize(12);
  let line2 = pdf.splitTextToSize(texto2, 175)
  pdf.text(line2, 15, pageHeight - 72);
  pdf.setFontSize(10);
  pdf.text("Reduce", 15, pageHeight - 17)
  pdf.addImage(recy, 'PNG', 30, pageHeight - 21, 6, 6);
  pdf.text("Reuse", 95, pageHeight - 17)
  pdf.addImage(recy, 'PNG', 110, pageHeight - 21, 6, 6);
  pdf.text("Recycle", 175, pageHeight - 17)
  pdf.addImage(recy, 'PNG', 190, pageHeight - 21, 6, 6);
  pdf.setFillColor("#95D3DB");
  pdf.rect(0, pageHeight - footerHeight, pageWidth, footerHeight, 'F');
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(13);
  pdf.text('Una iniciativa de:', 4, pageHeight - 30);
  pdf.text('En alianza con:', 70, pageHeight - 30);
  pdf.text('Con el apoyo de:', 167, pageHeight - 30);
  pdf.addImage(Vestolit, 'PNG', 1, pageHeight - 22, 60, 17);
  pdf.addImage(Baxter, 'PNG', 69, pageHeight - 17.5, 25, 7);
  pdf.addImage(Pavco, 'PNG', 99, pageHeight - 17.5, 25, 7);
  pdf.addImage(Kopelle, 'PNG', 129, pageHeight - 18, 26, 9);
  pdf.addImage(W2W, 'PNG', 172, pageHeight - 20, 26, 10);

  //   let blob = pdf.output('datauristring')
  pdf.save("Informe.pdf");
  //   return blob
  const blob = pdf.output('blob');  // Obtiene el blob del PDF
  const base64String = await blobToBase64(blob);  // Convierte el blob a base64
  return base64String;  // Retorna el string base64
}

export const generateResPQRS = async (P1, P2, email) => {
  const pdf = new jsPDF('portrait', 'mm', 'a4');

  const pageHeight = pdf.internal.pageSize.getHeight();
  const pageWidth = pdf.internal.pageSize.getWidth();
  const footerHeight = 40;

  pdf.addImage(Hilo, 'PNG', 10, 8, 37, 18);
  pdf.addImage(Vita, 'PNG', 150, 8, 25, 21);
  pdf.addImage(Vinilo, 'PNG', 180, 8, 20, 20);
  pdf.setFontSize(10);
  pdf.text("Contacto: 3204881774 - gestionviniloenmovimiento@gmail.com", 108, 37)
  pdf.text("https://www.vestolit.com/vinyl-in-motion/", 146, 43)
  pdf.line(0, 38, 220, 39);
  pdf.setFontSize(14);
  pdf.text("Cordial Saludo,", 15, 66);
  pdf.setFontSize(12);
  let line1 = pdf.splitTextToSize(P1, 175)
  let line2 = pdf.splitTextToSize(P2, 175)
  pdf.text(line1, 15, 96);
  P2 && pdf.text(line2, 15, 130);
  email && pdf.text("Si deseas proporcionar más detalles o tienes preguntas, envía tu mensaje a ", 15, pageHeight - 55);
  pdf.setTextColor("#95D3DB");
  email && pdf.text(email, 15, pageHeight - 50);
  pdf.setFillColor("#95D3DB");
  pdf.rect(0, pageHeight - footerHeight, pageWidth, footerHeight, 'F');
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(13);
  pdf.text('Una iniciativa de:', 4, pageHeight - 30);
  pdf.text('En alianza con:', 70, pageHeight - 30);
  pdf.text('Con el apoyo de:', 167, pageHeight - 30);
  pdf.addImage(Vestolit, 'PNG', 1, pageHeight - 22, 60, 17);
  pdf.addImage(Baxter, 'PNG', 69, pageHeight - 17.5, 25, 7);
  pdf.addImage(Pavco, 'PNG', 99, pageHeight - 17.5, 25, 7);
  pdf.addImage(Kopelle, 'PNG', 129, pageHeight - 18, 26, 9);
  pdf.addImage(W2W, 'PNG', 172, pageHeight - 20, 26, 10);


  const blob = pdf.output('blob');  // Obtiene el blob del PDF
  const base64String = await blobToBase64(blob);  // Convierte el blob a base64
  return base64String;  // Retorna el string base64
};



