import { typesEmpresa } from "../types/typesEmpresa";

const initialState = {
    loading: false,
    empresa: [],
    error: null
};

const empresaReducers = (state = initialState, action) => {
    switch (action.type) {
        case typesEmpresa.getEmpresa:
            return {
                ...state,
                loading: false,
                error: null,
                empresa: [...state.empresa, action.payload]
            }
        case typesEmpresa.addEmpresaStarted:
            return {
                ...state,
                loading: true
            };
        case typesEmpresa.addEmpresaSuccess:
            return {
                ...state,
                loading: false,
                error: null,
                empresas: [...state.empresa, action.payload]
            };
        case typesEmpresa.addEmpresaFailure:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        default:
            return state;
    }
};


export default empresaReducers;