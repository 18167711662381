import { Badge, ListItemButton, ListItemText } from '@mui/material'
import AirlineSeatReclineNormalIcon from '@mui/icons-material/TransferWithinAStation';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

export const ListItemButtonType = ({ type, types, data, open, icon }) => {
  const user = useSelector(state => state.user)
  const dataUser = useSelector(state => state)
  let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color

  useEffect(() => {}, [type,types])

  return (
    // validador.verRutasCocaCola ?
    //   <ListItemButton csx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }} style={{backgroundColor:type === types ?"#"+dataColor:"white" }}>
    //     <div style={type === types ? { color: 'white', marginRight: "5%"} : { color: "#777", marginRight: "5%" }}>
    //       {types === 'Rutas' ?
    //         <>
    //           {icon}
    //           <Badge badgeContent={3} color="error" style={{ display: open ? 'none' : '' }}>
    //           </Badge>
    //         </>
    //         : icon
    //       }
    //     </div>
    //     <ListItemText primary={
    //       types === 'Rutas' ?
    //         <Badge badgeContent={3} color="error">
    //           {types}  <NotificationsActiveIcon className='mx-2' />
    //         </Badge>
    //         : types
    //     } sx={{ opacity: open ? 1 : 0 }} />
    //   </ListItemButton>
    //   :
      <ListItemButton csx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }} style={{backgroundColor:type === types ?dataColor:"white" }}>
        <div style={type === types ? { color: 'white', marginRight: "5%"} : { color: "#777", marginRight: "5%" }}>
            {icon}
        </div>
        <ListItemText primary={types} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>

  )
}
