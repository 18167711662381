import { Backdrop, Button, CircularProgress, FormControl, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ModalDetailPuntoGenerador } from '../modals/ModalDetailPuntoGenerador';
import { DeleteAllWithAuth, UseGetWithAuth2 } from '../../../../service/base2';
import Swal from 'sweetalert2';
import { getAll } from '../../../../service/base/base';
import { checkingBodegas, currentBodegas } from '../../redux/action/actionBodegas';
import { ModalDetailPuntoBodega } from '../modals/ModalDetailPuntoBodega';
import { ViewSelectMaterialBodega } from '../ViewSelectMaterialBodega';
import { Link, useNavigate } from 'react-router-dom';
import { currentUser } from '../../../../service/redux/action/LoginAction';
import { bodegaAction, puntosBodegasAction } from '../../../../service/redux/action/DataActions';
const { v4: uuidv4 } = require('uuid');

export const TablaBodega = ({ setType, setBodegaById, setBodegaEdit }) => {
    // const { bodegas, loading } = useSelector(state => state.bodegas)
    const { empresas, } = useSelector(state => state.empresa)
    const { bodegas, colors } = useSelector(state => state.user)
    const { user, } = useSelector(state => state)
    const navigate = useNavigate();
    

    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [menuOpen, setMenuOpen] = React.useState(false);
    // const [bodegaById, setBodegaById] = React.useState([]);
    let id_empresa = user?.typeUser === 'EMPRESA' ? user?.Personal?.Empresas?.id : empresas[0]?.empresas?.data[0]?.id;
    let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa.Color : user?.bodegas[0]?.Color

    const [reload, setReload] = useState(true)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const hasPermission = (permisoRequerido = ['no hay permisos']) => {
        const userPermissions = permisoRequerido
        return user.Personal.Permisos.some(permission => userPermissions.includes(permission.Permiso));

    };

    const eliminarBodega = async (e) => {
        setMenuOpen(false)
        console.log("bodega",e);
        await Swal.fire({
            title: '¿Estás seguro?',
            text: `Se perderan todos los datos asociados \n\n ¡No podrás revertir esta acción!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: user?.colors[2],
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then(async(result) => {
            if (result.isConfirmed) {
                // Funcion para elimianr 
                try {
                //===================================================================================================
                    const { ok: deleteBodegaOk }  = await DeleteAllWithAuth(`bodegases/${e.id}`, user?.Personal?.uid)

                    if(deleteBodegaOk){
                        const bodegas_filter = user?.bodegas.filter(i=>i.id!==e.id)
                        if(e.PuntosBodegas&&e.PuntosBodegas.length>0){
                            const filterSedes = e.PuntosBodegas.map(e=>e.id)

                            const filterPersonal = user?.PuntosBodega.map(e=> {
                                if(filterSedes.some(i=>i==e.id)){return e.empleados.map(i=>i.id)}
                                else{return false}
                            }).flat().filter(e=>e!==false)

                            const filterUser = user?.PuntosBodega.map(e=> {
                                if(filterSedes.some(i=>i==e.id)){return e.empleados.map(i=>i.user.id) }
                                else{ return false}
                            }).flat().filter(e=>e!==false)
                            
                            if(filterSedes.length>0){
                                const deleteSedes = filterSedes.map(id => DeleteAllWithAuth(`/puntos-bodegas/${id}`, user?.Personal?.uid));
                                const deleteResults = await Promise.all(deleteSedes);
                                const allDeletesSuccessful = deleteResults.every(result => result.ok);
                            //     const {ok, resPut } = await DeleteAllWithAuth('/puntos-bodegas?_where[id_in]='+filterSedes.join(','), user?.Personal?.uid)
                                const sede_filter = user?.PuntosBodega.filter(i=>!filterSedes.some(e=>e==i.id))
                                if(allDeletesSuccessful){
                                        if(filterPersonal.length>0){
                                            const deletePersonal = filterPersonal.map(id => DeleteAllWithAuth(`/personals?/${id}`, user?.Personal?.uid));
                                            const deleteResultsPersonal = await Promise.all(deletePersonal);
                                            const allDeletesSuccessfulPersonal = deleteResultsPersonal.every(result => result.ok);

                                            const deleteUser = filterUser.map(id => DeleteAllWithAuth(`/users?/${id}`, user?.Personal?.uid));
                                            const deleteResultsUser = await Promise.all(deleteUser);
                                            const allDeletesSuccessfulUser = deleteResultsUser.every(result => result.ok);
                            //             const y = await DeleteAllWithAuth('/personals?_where[id_in]='+filterPersonal.join(','), user?.Personal?.uid)
                            //             const u = await DeleteAllWithAuth('/users?_where[id_in]='+filterUser.join(','), user?.Personal?.uid)
                                            if( allDeletesSuccessfulPersonal && allDeletesSuccessfulUser){
                                                dispatch(puntosBodegasAction(sede_filter));Swal.fire('Eliminado!','La bodega ha sido eliminada.','success')
                                            }else{ Swal.fire('Eliminado!','La bodega ha sido eliminada.','success');dispatch(bodegaAction(bodegas_filter))}
                                        }
                                     Swal.fire('Eliminado!','La bodega ha sido eliminada.','success');dispatch(bodegaAction(bodegas_filter))

                                }else{Swal.fire('Eliminado!','La bodega ha sido eliminada.','success');dispatch(bodegaAction(bodegas_filter))}
                                
                            }else{ dispatch(bodegaAction(bodegas_filter));Swal.fire('Eliminado!','La bodega ha sido eliminada.','success')}
                            
                        }else{dispatch(bodegaAction(bodegas_filter)); Swal.fire('Eliminado!','La bodega ha sido eliminada.','success')}

                    }else{Swal.fire('...Ups, algo salio mal!','La bodega no ha sido eliminada.','error')}
                } catch (error) {
                    console.error("Error al eliminar bodega:", error);
                    Swal.fire('Error', 'Hubo un error al intentar eliminar la bodega.', 'error');
                }
            }
            //===================================================================================================
        })
    };
    
    return (
        <>
            
            <main>
                <nav className='d-flex gap-3 justify-content-between p-2  mb-2 '
                    style={{ backgroundColor:colors[1],borderRadius:20 }}
                >
                    <div className='ml-5'>
                        <h5 style={{ color: "white",marginLeft:10 }} className='mt-1'>Gestores</h5>
                    </div>
                </nav>
                                      
                {hasPermission('Agregar gestores') &&
                    <Link className="navbar-brand"  to="/nueva-bodega" >
                        <Button variant="contained" endIcon={<AddCircleIcon />}
                            style={{ backgroundColor: user.colors[2], position: "absolute", top: 150, right: 25, cursor: "pointer",borderRadius:40  }}>
                            Nuevo gestor
                        </Button>
                    </Link>
                }

                <Paper sx={{ width: '100%', marginTop: 10 }}>
                    <TableContainer sx={{ }}>
                        <Table  aria-label="sticky table">

                            <TableHead >
                                <TableRow hover tabIndex={-1} key={uuidv4()} >
                                    <TableCell align={"center"} size='small'> <b>Logo</b> </TableCell>
                                    <TableCell align={"center"} size='small'><b>Nombre gestor</b></TableCell>
                                    <TableCell align={"center"} size='small'> <b>Direccion</b> </TableCell>
                                    <TableCell align={"center"} size='small'> <b># Puntos generadores</b> </TableCell>
                                    <TableCell align={"center"} size='small'> <b># Sedes gestores</b> </TableCell>
                                    <TableCell align={"center"} size='small'> <b>Acciones</b></TableCell>
                                </TableRow>
                            </TableHead>
                            
                            <TableBody>
                                {empresas[0]?.idBodega.length === 0 ?
                                    bodegas && bodegas?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                                        <TableRow hover tabIndex={-1} key={uuidv4()} >
                                            <TableCell key={uuidv4()} align={"center"} style={{cursor:"pointer"}}>
                                                <Link className="navbar-brand" to={`/bodega/${e?.id}`}>
                                                    <img src={e?.Logo} style={{ width: 40, height: 40, borderRadius: 100 }} alt={'profile imagen'} />
                                                </Link>
                                            </TableCell>

                                            <TableCell key={uuidv4()} align={"center"}>{e.Nombre}</TableCell>
                                            <TableCell key={uuidv4()} align={"center"} > {e.Direccion} </TableCell>

                                            <TableCell key={uuidv4()} align={"center"}>
                                                <span className='mx-1' style={{ cursor: 'pointer', color: user.colors[4], textDecoration: 'underline', fontWeight: 'bold' }}>
                                                    <ModalDetailPuntoGenerador e={e} />
                                                </span>
                                            </TableCell>


                                            <TableCell key={uuidv4()} align={"center"}>
                                                <span className='mx-1' style={{ cursor: 'pointer', color: user.colors[4], textDecoration: 'underline' }} >
                                                    <ModalDetailPuntoBodega e={e} />
                                                </span>
                                            </TableCell>

                                            <TableCell align={"center"} size='small'  style={{ position: "relative"}} onMouseLeave={()=>setMenuOpen(false)}>
                                        
                                    
                                                <IconButton
                                                    aria-label="more"
                                                    id="custom-button"
                                                    style={{ position: "relative", width: 50, height: 50 }}
                                                    onClick={() => setMenuOpen((menuOpen === false || menuOpen !==e.id) ? e.id : false)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                
                                                {menuOpen == e.id && (
                                                    <div 
                                                        style={{
                                                        position: "absolute", top:20, right: 20,
                                                        backgroundColor: "#fff",boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                        zIndex: 2000,width: "17ch",borderRadius: 5
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => { setMenuOpen(e.id); navigate(`/bodega/${e.id}`) }}>
                                                            Detalles
                                                        </MenuItem>
                                                        {hasPermission('Modificar gestores') && 
                                                                <MenuItem onClick={() => { setMenuOpen(e.id);  navigate({ pathname: `/actualizar-bodega`, search: `?${new URLSearchParams(e).toString()}` })}}>
                                                                    Editar
                                                                </MenuItem>
                                                        }
                                                        {hasPermission('Eliminar gestores') && 
                                                            <MenuItem onClick={() => { setMenuOpen(e.id); eliminarBodega(e)   }}>
                                                                Eliminar
                                                            </MenuItem>
                                                        }
                                                    </div>
                                                    
                                                    )
                                                    }
                                            

                                            </TableCell>
                                        </TableRow>
                                    ))
                                    :
                                    <TableRow hover tabIndex={-1} key={uuidv4()} >
                                        <TableCell key={uuidv4()} align={"center"}>
                                            <Link className="navbar-brand" to={`/bodega/${bodegas?.id}`}>
                                                <img src={bodegas?.Logo} style={{ width: 40, height: 40, borderRadius: 100 }} alt={'profile imagen'} />
                                            </Link>
                                        </TableCell>


                                        <TableCell key={uuidv4()} align={"center"}>
                                            <Link className="navbar-brand" to={`/bodega/${bodegas?.id}`}>
                                                {bodegas?.Nombre}
                                            </Link>
                                        </TableCell>

                                        <TableCell key={uuidv4()} align={"center"}>
                                            <Link className="navbar-brand" to={`/bodega/${bodegas?.id}`}>
                                                {bodegas?.Direccion}
                                            </Link>
                                        </TableCell>

                                        

                                        <TableCell key={uuidv4()} align={"center"}>
                                            <span className='mx-1 ' style={{ cursor: 'pointer', color: bodegas?.Color, textDecoration: 'underline' }}>
                                                {bodegas?.Personal ? bodegas?.Personal?.length : '0'}
                                            </span>
                                        </TableCell>

                                        
                                        <TableCell align={"center"}>
                                            {bodegas !== undefined && <ViewSelectMaterialBodega query={'Bodegas'} id={bodegas?.id} />}
                                        </TableCell>
                                        
                                    </TableRow>

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={bodegas?.length ? bodegas.length : 1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </main>
            {/* } */}
        </>

    )
}
