import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import './StylesPermisos.css'
import { actualizarPermisos } from "../private/bodegas/components/Detalles/functionsPerisos";

const CustomCheckbox = ({ isChecked, onCheck }) => {
  const user = useSelector((state) => state.user);
  return (
    <div
      className={`my-checkbox ${isChecked ? 'checked' : ''}`}
      onClick={onCheck}
      style={isChecked ? { background: user.colors[4] } : {}}
    >
      <p style={{ marginTop: -2, marginLeft: 3 }}> {isChecked ? '✓' : ''}</p>
    </div>
  );
};

const filterPermissionsApp = (array, user, idBodega) => {
  const bodegas = user.bodegas.find(e => e.id == idBodega)
  let per = array

  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Comprar")) {
    per = per.filter(e => e.Permiso !== "Comprar")
  }
  if (!bodegas.Empresa.Servicios.some(e => e == "657a1862997b320014a3bcd3")) {
    per = per.filter(e => e.Permiso !== "Usa carnet")
  }

  return per
}

const filterPermissionsPG = (array, user, idBodega) => {
  const bodegas = user.bodegas.find(e => e.id == idBodega)
  let per = array

  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Comprar")) {
    per = per.filter(e => e.Permiso !== "Modificar precios")
  }
  // if(!bodegas.PermisosBodegas.some(e=>e.Permiso=="Crear campañas")){
  //   per = per.filter(e=>e.Permiso !=="Modificar precios")
  // }
  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Agregar generadores")) {
    per = per.filter(e => e.Permiso !== "Agregar generadores")
  }
  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Agregar materiales")) {
    per = per.filter(e => e.Permiso !== "Agregar material")
  }
  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Agregar personal")) {
    per = per.filter(e => e.Permiso !== "Agregar Personal")
  }
  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Eliminar materiales")) {
    per = per.filter(e => e.Permiso !== "Eliminar material")
  }
  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Eliminar personal")) {
    per = per.filter(e => e.Permiso !== "Eliminar personal")
  }
  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Modificar generadores")) {
    per = per.filter(e => e.Permiso !== "Modificar generadores")
  }
  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Modificar personal")) {
    per = per.filter(e => e.Permiso !== "Modificar personal")
  }
  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Modificar precios")) {
    per = per.filter(e => e.Permiso !== "Modificar precios")
  }
  if (!bodegas.PermisosBodegas.some(e => e.Permiso == "Manejar bolsa")) {
    per = per.filter(e => e.Permiso !== "Crear bolsa")
    per = per.filter(e => e.Permiso !== "Eliminar bolsa")
  }
  // if(!user.bodegas[0].Empresa.Servicios.some(e=>e =="657a1862997b320014a3bcd3")){
  //   per = per.filter(e=>e.Permiso !=="Leer Qr")
  // }

  return per
}

const filterPermissionsPE = (array, user) => {

  let per = array

  if (!user.Personal.Empresa.Servicios.some(e => e == "65246345637f1900140aa59c")) {
    per = per.filter(e => e.Permiso !== "Modificar precios")
  }
  // CAMPAÑAS PEDIENTES

  return per
}

const PermissionsCheck = ({ permisos, tipo, onChange, selectedPermsInitial, idBodega }) => {
  const [selectedPerms, setSelectedPerms] = useState([]);

  const user = useSelector((state) => state.user);
  let permiso = []
  let title

  switch (tipo) {
    case "PermisoApp":
      title = "Permisos para logístico aplicativo";
      permiso = permisos.filter(permiso => permiso[tipo])
      permiso = filterPermissionsApp(permiso, user, idBodega)
      break;
    case "AdministradorGestor":
      title = "Permisos para personal gestor";
      permiso = permisos.filter(permiso => permiso[tipo])
      break;
    case "AdministradorPuntoG":
      title = "Permisos para personal punto gestor";
      permiso = permisos.filter(permiso => permiso[tipo])
      permiso = filterPermissionsPG(permiso, user, idBodega)
      break;
    case "AdministradorEmpresa":
      title = "Permisos para personal master";
      permiso = permisos.filter(permiso => permiso[tipo])
      permiso = filterPermissionsPE(permiso, user)
      break;
    default:
      title = tipo;
      permiso = permisos
      break;
  }





  useEffect(() => {
    // Enviar la lista de permisos seleccionados al componente padre
    onChange(actualizarPermisos(selectedPerms));

  }, [selectedPerms]);

  const handleCheckboxChange = (permId, isChecked) => {
    if (isChecked) {
      setSelectedPerms([...selectedPerms, permId]);

    } else {
      setSelectedPerms(selectedPerms.filter(id => id !== permId));
    }
    onChange(selectedPerms);
  };

  useEffect(() => { }, [tipo, handleCheckboxChange]);
  useEffect(() => {
    if (selectedPermsInitial) {
      let permisos = selectedPermsInitial.map((permiso) => permiso.id);
      setSelectedPerms(permisos);
    }
  }, [selectedPermsInitial]);
  return (
    <div>
      <h4>{title}</h4>
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 12, sm: 12, md: 12 }} style={{ padding: 20, marginLeft: 5 }}>
        {permiso
          .map(permiso => (
            <Grid xs={12} sm={6} md={6} key={permiso.id} >
              <div className="d-flex mt-2">
                {/*   <label>
                <input
                    type="checkbox"
                    checked={selectedPerms.includes(permiso.id)}
                    onChange={(e) => handleCheckboxChange(permiso.id, e.target.checked)}
                    style={{marginRight:5,backgroundColor:selectedPerms.includes(permiso.id)?'blue':'white'}}

                    
                />
                
                </label> */}
                <CustomCheckbox
                  isChecked={selectedPerms.includes(permiso.id)}
                  onCheck={() => handleCheckboxChange(permiso.id, !selectedPerms.includes(permiso.id))}
                />
                <span style={{ fontSize: 14 }}>{permiso.Permiso}</span>
              </div>

            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default PermissionsCheck;
