import {
    Routes, Route,
    Navigate
} from 'react-router-dom';
import Login from '../../piblic/Login/Login';

export const Inicio = () => {
    return ( 
    <>
        <Routes>
            <Route path="/singin" element={<Login />} />
            <Route path='*' element={<Navigate to="/login/singin" />} />
        </Routes> 
    </>
    )
}