import { createStore,combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducerUsers from "../reducers/reducerUser";
import empresaReducers from "../reducers/empresaReducers";
import bodegasReducers from "../../../private/bodegas/redux/reducers/bodegasReducers";

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    user:reducerUsers,
    empresa: empresaReducers,
    bodegas: bodegasReducers,
})
 
export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(thunk)
    )
)