import { Avatar, Paper, Stack, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { SplitingInitialsName } from '../../../../helpers/Utils';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { DeleteAllWithAuth } from '../../../../service/base2';
import { Link } from 'react-router-dom';

const { v4: uuidv4 } = require('uuid');

export const TablaPuntosGeneradores = ({ reload, setReload, puntosGeneradores, setType, bodegaById, setPuntoGeneradorEdit }) => {
    const { empresas, } = useSelector(state => state.empresa)
    const user = useSelector(state => state.user)
    let dataColor = empresas[0]?.typeUser === 'EMPRESA' ? user?.Personal?.Empresa?.Color : user?.Personal?.Empresa?.Color
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deletePuntoGenerador = async (id) => {
        await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esta acción!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteAllWithAuth(`/puntos-generadores/${id}`, user?.Personal?.uid)
                Swal.fire(
                    'Eliminado!',
                    'El punto generador ha sido eliminado.',
                    'success'
                )
            }
        })
        // await setReload(!reload);
    }
    
    return (
        <>
            <Link className="navbar-brand" to={{ pathname: `/nuevo-punto-generador`, search: `?${new URLSearchParams(bodegaById).toString()}` }} >
                <Button variant="contained" style={{ backgroundColor: `#${dataColor}`, position: "absolute", top: 200, right: 25, cursor: "pointer", color: 'white' }}>
                    Agregar punto<PersonAddAlt1Icon className='mx-1 text-white' />
                </Button>
            </Link>

            <Paper sx={{ width: '100%', marginTop: 6.5 }}>
                <TableContainer sx={{ minHeight: 440, maxHeight: 840 }}>
                    <Table  aria-label="sticky table">
                        <TableHead >
                            <TableRow hover tabIndex={-1} key={uuidv4()} >

                                <TableCell align={"center"}>
                                    <b>Avatar</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Nombre</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Direccion</b>
                                </TableCell>

                                <TableCell align={"center"}>
                                    <b>Acciones</b>
                                </TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {puntosGeneradores && puntosGeneradores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                                <TableRow hover tabIndex={-1} key={uuidv4()} >
                                    <TableCell key={uuidv4()} align={"center"}>

                                        <Stack direction="row" justifyContent={"center"}>
                                            <Avatar sx={{ bgcolor: `#${dataColor}` }}>{SplitingInitialsName(e.Nombre)}</Avatar>
                                        </Stack>

                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.Nombre}
                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        {e.Direccion}
                                    </TableCell>

                                    <TableCell key={uuidv4()} align={"center"}>
                                        <div className='d-flex gap-3 justify-content-center'>
                                            <Tooltip title="Modificar">
                                                <Link className="navbar-brand" to={{ pathname: `/nuevo-punto-generador`, search: `?${new URLSearchParams({ id: e.id, Nombre: e.Nombre, Direccion: e.Direccion, PuntosBodega: e.PuntosBodega, bodegaById: bodegaById.id }).toString()}` }} >
                                                    <EditTwoToneIcon color='primary' />
                                                </Link>
                                            </Tooltip>

                                            <Tooltip disabled title="Eliminar">
                                                <DeleteTwoToneIcon color='error'
                                                    onClick={() => deletePuntoGenerador(e.id)}
                                                />
                                            </Tooltip>
                                        </div>
                                    </TableCell>

                                </TableRow>

                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={puntosGeneradores && puntosGeneradores.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    )
}
