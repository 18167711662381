import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { formatCurrency, getAbbreviation, formatDate } from '../../../../../../helpers/Utils';
import Swal from 'sweetalert2';
import { generateExcel } from '../../../../../../helpers/Pdfs/GenerateExcel';
import { useSelector } from 'react-redux';
import { procesarHistorialCompras } from '../Function/function';
const { v4: uuidv4 } = require('uuid');

const EnRuta = ({historialRecogida,dates}) => {
    const { user } = useSelector(state => state)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    

    const  dataTosend = historialRecogida.length>0? procesarHistorialCompras(historialRecogida,user):[]

    
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // const Excel =()=>{
    //     if(dataTosend.length>0){
    //         console.log("dates",dates);
    //         let title = dates == {}||dates.inicio == undefined ?"Informe historico":`Informe periodo de  ${formatDate(new Date(dates.inicio).toLocaleString())} a ${formatDate(new Date(dates.fin).toLocaleString())}`
    //         let gestor = "Materiales comprados"
    //         generateExcel(dataTosend,title,gestor)
    //     }else{
    //         Swal.fire({
    //             position: 'center', icon: 'error', title: 'Oops...', text: 'No hay datos para generar archivo.',
    //             showConfirmButton: false,
    //             timer: 1500
    //           })
    //     }
    // }
    
  return (
    <div style={{marginTop:20}}>
        {/* <div style={{position:"absolute",top:22, right:50,zIndex:20}} >
            <Tooltip title="Descargar excel">
                <CloudDownloadIcon style={{ color: '#666',cursor:"pointer" }} onClick={Excel}/>
            </Tooltip>
        </div> */}
        <TableContainer sx={{  maxHeight: 840, }}>
            <Table aria-label="sticky table">
                <TableHead style={{zIndex:-10}}>
                    <TableRow hover tabIndex={-10} key={uuidv4()} >

                        <TableCell align={"center"} size='small'> <b>Material</b> </TableCell>
                        {user.typeUser=="EMPRESA"&&
                            <TableCell align={"center"} size='small'> <b>Gestor</b> </TableCell>
                        }
                        <TableCell align={"center"} size='small'> <b>Estado del material</b> </TableCell>
                        <TableCell align={"center"} size='small'> <b>Inverción</b> </TableCell>
                        <TableCell align={"center"} size='small'> <b>Peso</b> </TableCell>

                    </TableRow>
                </TableHead>

                <TableBody>
                    {dataTosend.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                        <TableRow hover tabIndex={-1} key={uuidv4()} >
                            <TableCell key={uuidv4()} align={"center"} size='small'> {e.Material||"Sin material"} </TableCell>
                            {user.typeUser=="EMPRESA"&&
                                <TableCell key={uuidv4()} align={"center"} size='small'> {e.Locacion||"-"} </TableCell>
                            }
                            <TableCell key={uuidv4()} align={"center"} size='small'> {e.MediaCalificacion||"-"}</TableCell>
                            <TableCell key={uuidv4()} align={"center"} size='small'> {e.TotalInvertido||"0"} </TableCell>
                            <TableCell key={uuidv4()} align={"center"} size='small'>{e.TotalRecuperadoPeso||"0"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={dataTosend.length>0 ? dataTosend.length:0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  )
}

export default EnRuta