import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import { UseGetWithAuth2 } from '../../../service/base2';
import { useSelector } from 'react-redux';


export const ViewSelectMaterialBodega = ({ id,query}) => {
    const { empresas, } = useSelector(state => state.empresa);

    const [personName, setPersonName] = useState([]);
    const [materialeBodega, setMaterialeBodega] = useState([])

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setPersonName(typeof value === 'string' ? value.split(',') : value,);
    };

    const getMaterialesBodega = async () => {
        const { resGet } = await UseGetWithAuth2(`/calculadora-materiales/?_where[${query}]=${id}`, empresas[0].jwToken)
        
        setMaterialeBodega(resGet)
    }

    useEffect(() => {
        getMaterialesBodega()
    }, [])

    return (
        <FormControl sx={{ m: 1, width: 120, mt: 3 }}>
            <Select
                multiple
                displayEmpty
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <em>Materiales</em>;
                    }

                    return selected.join(', ');
                }}
                // MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem disabled value="">
                    <em>{materialeBodega?.length > 0 ? 'Materiales:' : 'No tiene Materiales'}</em>
                </MenuItem>
                {materialeBodega.map((name) => (
                    <MenuItem disabled
                        key={name.id}
                        value={name.Material.Material}
                    // style={getStyles(name, personName, theme)}
                    >
                        {name.Material.Material}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

    )
}
