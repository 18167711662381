import Swal from "sweetalert2";
import { PostAllWithAuth, PutAllWithAuth } from "../../../service/base2";
import { empresasGeneradoresAction, generadoresAction } from "../../../service/redux/action/DataActions";
import { generatePDF } from "../../../helpers/Pdfs/generatePdf";
import { sendPdf } from "../../../helpers/FileUpload";


// actualiza datos de la empres
export const UpdatingEmpresa = async (formi, user, objetoRecibidoByparams, dispatch, ResponsableEnParams, idSede, setLoading) => {

    formi.PuntosBodega = user?.PuntosBodega?.find(b => b.Nombre == idSede).id;
    formi.PuntoGenerador = user.EmpresasGeneradoras.find(e => e.id == ResponsableEnParams.id).PuntoGenerador || []
    formi.PuntoGenerador = formi.PuntoGenerador.length > 0 ? formi.PuntoGenerador.map(e => e.id) : []

    const newEmpresa = await PutAllWithAuth(`/empresas-generadoras/${ResponsableEnParams.id}`, user?.Personal?.uid, formi);

    if (newEmpresa.ok) {
        setLoading(false)
        const empre = user?.EmpresasGeneradoras.filter(i => i.id != ResponsableEnParams.id)
        empre.push(newEmpresa.resPut)
        dispatch(empresasGeneradoresAction(empre))
        Swal.fire({
            position: 'center', icon: 'success', title: 'Datos guardados', text: 'Los datos han sido guardados con éxito',
            showConfirmButton: false,
            timer: 1500
        })
    } else {
        setLoading(false)
        Swal.fire({
            position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
            showConfirmButton: false,
            timer: 1500
        })
    }


}

export const CreatingNewempresa = async (formi, user, objetoRecibidoByparams, dispatch, formState, idSede, setLoading) => {
    formi.Empresa = user?.typeUser === 'EMPRESA' ? user.Personal.Empresa.id : user?.bodegas[0]?.Empresa.id
    formi.Bodega = user?.PuntosBodega?.find(b => b.Nombre == idSede).Bodega.id
    formi.PuntoGenerador = [objetoRecibidoByparams.id]
    console.log("formi", formi);

    const newEmpre = await PostAllWithAuth(`/empresas-generadoras`, user?.Personal?.uid, formi);

    if (newEmpre.ok) {
        setLoading(false)
        const empre = user?.EmpresasGeneradoras
        empre.push(newEmpre.resPost.data)
        dispatch(empresasGeneradoresAction(empre))

        // =====================================================> Captura el nombre de la empresa a la que corresponde <===============================================================
        let nombreEmpr = user?.typeUser === 'EMPRESA' ? user.Personal.Empresa.Nombre : user?.bodegas[0]?.Empresa.Nombre
        // =====================================================> Correo electronico de bienvenida solo para la empresa VESTOLIT <=====================================================
        if (nombreEmpr == "Vestolit") {
            const generate = await generatePDF(formi.NombreContacto, formState.Nombre)
            await sendPdf(generate, formi.Email, 'Cordial bienvenida', 'Cordial bienvenida', user?.Personal?.uid)
        }
        Swal.fire({
            position: 'center', icon: 'success', title: 'Datos guardados', text: 'Los datos han sido guardados con éxito',
            showConfirmButton: false,
            timer: 1500
        })
    } else {
        setLoading(false)
        Swal.fire({
            position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
            showConfirmButton: false,
            timer: 1500
        })
    }
}

export const updatingPoint = async (formState, objetoRecibidoByparams, user, dispatch, idSede, permisosSeleccionados, setLoading) => {
    formState.PuntosBodega = user?.PuntosBodega?.find(b => b.Nombre == idSede).id;
    formState.Permisos = permisosSeleccionados
    const { resPut, ok } = await PutAllWithAuth(`/puntos-generadores/${objetoRecibidoByparams.id}`, user?.Personal?.uid, formState);
    if (ok) {
        const filter = user?.PuntosGeneradores.filter(i => i.id !== objetoRecibidoByparams.id)
        filter.push(resPut)
        dispatch(generadoresAction(filter))
        setLoading(false)
        Swal.fire({
            position: 'center', icon: 'success', title: `El punto ${formState.Nombre} ha sido Actializado`,
            showConfirmButton: false,
            timer: 1500
        })
    } else {
        setLoading(false)
        Swal.fire({
            position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
            showConfirmButton: false,
            timer: 1500
        })
    }
}

export const addpointToCompany = async (objetoRecibidoByparams, user, dispatch, EmpresaGeneradora, setLoading) => {
    console.log("EmpresaGeneradora", EmpresaGeneradora);
    let PuntoGenerador = user.EmpresasGeneradoras.find(h => h.id == EmpresaGeneradora).PuntoGenerador || []
    PuntoGenerador = PuntoGenerador.length > 0 ? PuntoGenerador.map(e => e.id) : []
    console.log("PuntoGenerador", PuntoGenerador);
    PuntoGenerador.push(objetoRecibidoByparams.id)
    console.log("PuntoGenerador", PuntoGenerador);
    console.log(objetoRecibidoByparams.id);

    let newEmpresa = await PutAllWithAuth(`/empresas-generadoras/${EmpresaGeneradora}`, user?.Personal?.uid, { "PuntoGenerador": PuntoGenerador });
    newEmpresa = await newEmpresa
    console.log("newEmpresa", newEmpresa.ok);
    if (newEmpresa.ok) {
        const empre = user?.EmpresasGeneradoras.filter(i => i.id != EmpresaGeneradora)
        empre.push(newEmpresa.resPut)
        dispatch(empresasGeneradoresAction(empre))
        setLoading(false)
        Swal.fire({
            position: 'center', icon: 'success', title: `El punto ${objetoRecibidoByparams.Nombre} ha sido asignado a la empresa`,
            showConfirmButton: false,
            timer: 1500
        })
    } else {
        setLoading(false)
        Swal.fire({
            position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
            showConfirmButton: false,
            timer: 1500
        })
    }
}