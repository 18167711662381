import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FormDates, convertWeight, filtrarPorFecha, getAbbreviation,formatDate } from '../../../helpers/Utils';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { generateExcel } from '../../../helpers/Pdfs/GenerateExcel'; 
import { GeneratePDFResponsable, GeneratePDFResponsableVestolit } from '../../../helpers/Pdfs/generatePdf';
import FilterDate from '../../dashboard/ViewDashboard/FilterDate';
import Swal from 'sweetalert2';
import { sendPdf } from '../../../helpers/FileUpload';
const { v4: uuidv4 } = require('uuid');

const Tablas = ({data}) => {
    const { empresas } = useSelector(state => state.empresa),
    user = useSelector(state => state.user),
    dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color,
    Informes = user?.reportes?.Recogidas.filter(e=>e.PuntoGenerador&&e.PuntoGenerador.id==data._id),
    Empleados =user?.PuntosBodega?.find(i=> i._id&&data.PuntosBodega&& i._id ==data.PuntosBodega.id)?user?.PuntosBodega?.find(i=>  i._id&&data.PuntosBodega&& i._id ==data.PuntosBodega.id).empleados:{},
    [settingInform,SetSettingInform]=useState(Informes),
    [value1, setValue1] = useState(),
    [value2, setValue2] = useState(),
    [dateRange,setDateRange]=useState(false),
    [page, setPage] = useState(0),
    [rowsPerPage, setRowsPerPage] = useState(10),
    [selectDate, setSelectDate] = useState(false),
    [filter, setFilter] = useState(false),
    state = useSelector(state => state.user),
    [open, setOpen] = useState(false),
    [dates, setDates] = useState({}),

    functionSum = (info) => {
        let sum = 0;
        let unidad_de_peso = 'kilogramo'
        info.forEach(element => {
            console.log("info",info);
           let num = convertWeight(Number(element.value), element.Unidad_de_Peso,unidad_de_peso)
              sum += Number(num)
             
        });
        return sum + " " + getAbbreviation(unidad_de_peso)
    },

    dataTosend = settingInform.length>0? settingInform.map(e=>{
        console.log(e);
        return {
            Fecha:new Date(e.Fecha).toLocaleDateString(),
            Personal:Empleados.find(i=> i.id ==e.Personal.id).user.username,
            Gestor:e.PuntoBodega.Nombre,
            Ruta:e.Ruta.id,
            Total_recuperado:e.PesoCarga?e.PesoCarga:"No hay datos"
        }
    }):[]
    
console.log('====================================');
console.log(data);
console.log('====================================');
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
 /// Errror aquiiiiiii
    const sendigPDf=async()=>{

        if(dataTosend.length>0){
            const headers = [["Fecha", "Personal", "Gestor", "Ruta", "Total Recuperado"]];
            const transformedData = dataTosend.map(entry => [
                entry.Fecha,
                entry.Personal,
                entry.Gestor,
                entry.Ruta,
                entry.Total_recuperado,
            ]);
          const responsable = user?.EmpresasGeneradoras.find(e=> e.PuntoGenerador &&e.PuntoGenerador.find(i=> i.id&&i.id ==Informes[0].PuntoGenerador.id)) 
          if(user.typeUser="Empresa"&&user.Personal.Empresa.Nombre =="Vestolit"){
            const generate = await GeneratePDFResponsableVestolit("Informe historico",headers,transformedData,data.Nombre)
            if(responsable !== undefined){
            const se = await sendPdf(generate,responsable?.Email,"Informe historico","Informe historico",user?.Personal?.uid) 
                
                Swal.fire({
                    position: 'center', icon: 'success', title: 'El informe se ha enviado correctamente',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
          }else{
            const generate = await GeneratePDFResponsable("Informe historico",headers,transformedData)
            if(responsable !== undefined){
            const se = await sendPdf(generate,responsable?.Email,"Informe historico","Informe historico",user?.Personal?.uid) 
                
                Swal.fire({
                    position: 'center', icon: 'success', title: 'El informe se ha enviado correctamente',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
        //    
        //     
        }else{
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'No hay datos para generar archivo.',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }
    

   console.log(data);

    useEffect(() => {
        if(!dateRange){
          setDateRange("Todo")
        }
        
    }, [])

    
    const setData=(data)=>{
        let filter = []
        if(data.type == "personalizado"){
            filter = filtrarPorFecha(Informes,data.type ,data.fecha1,data.fecha2) 
            let dat = {
               inicio: data.fecha1,
               fin: data.fecha2
           }
       setDates(dat)
       }else{
           filter = filtrarPorFecha(Informes, data.type?data.type:"Todo") 
           let dat = FormDates(data.type?data.type:"Todo")
            setDates(dat)
       }
       SetSettingInform(filter)

    },

    Excel =()=>{
        if(dataTosend.length>0){
    console.log("dates",dates);
            let title = dates == {}||dates.inicio == undefined ?"Informe historico":`Informe periodo de  ${formatDate(new Date(dates.inicio).toLocaleString())} a ${formatDate(new Date(dates.fin).toLocaleString())}`
            let gestor = "Gestor: "+(user?.bodegas.find(e=> e._id ==data.Bodega) !=undefined? user?.bodegas.find(e=> e._id ==data.Bodega).Nombre:data.PuntosBodega.Nombre)
            generateExcel(dataTosend,title,gestor)
        }else{
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'No hay datos para generar archivo.',
                showConfirmButton: false,
                timer: 1500
              })
        }
    }
    console.log("dates",dates);
    console.log("data",data);
    console.log("user",user);
  return (
    <div style={{ position: "relative" }}>
        <div className='d-flex justify-content-between mt-3'>
            <h3 style={{color:user?.colors[1],fontWeight:"bold"}}>Historial de recolección:</h3>
            <div className='d-flex p-2' style={{backgroundColor:dataColor+"3e",borderRadius:5}}>
                <Tooltip title="Descargar excel" onClick={Excel}>
                    <CloudDownloadIcon style={{ color: user.colors[1],marginRight:5,cursor:"pointer" }} />
                </Tooltip>
                {data.Permisos.some((permiso) => permiso.Permiso === "Recibe informes")&&
                <Tooltip title="Enviar a entidad responsable" onClick={()=>sendigPDf()} >
                    <AttachEmailIcon style={{ color: user.colors[1],marginRight:5,cursor:"pointer",marginTop:1 }} />
                </Tooltip>}
                <Tooltip title="Filtrar por fecha" onClick={() => setFilter(true)}>
                    <CalendarMonthIcon style={{ color: user.colors[1],marginRight:5,cursor:"pointer" }} />
                </Tooltip>
            </div>
        </div>
        <FilterDate filter={filter} selectDate={selectDate} setData={setData} setFilter={setFilter} setOpen={setOpen} reports={settingInform} setSelectDate={setSelectDate} />

        {settingInform.length>0?
        <Paper sx={{ width: '100%', marginTop:1 }}>
            <TableContainer sx={{  maxHeight: 840, }}>
                <Table >
                    <TableHead style={{zIndex:-10}}>
                        <TableRow hover tabIndex={-10} key={uuidv4()} >

                            <TableCell align={"center"}>
                                <b>Fecha</b>
                            </TableCell>

                            <TableCell align={"center"}>
                                <b>Personal</b>
                            </TableCell>

                            <TableCell align={"center"}>
                                <b>Gestor</b>
                            </TableCell>

                            <TableCell align={"center"}>
                                <b>Ruta</b>
                            </TableCell>

                            <TableCell align={"center"}>
                                <b>Total recuperado</b>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {settingInform.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                            <TableRow hover tabIndex={-1} key={uuidv4()} >
                                
                                <TableCell key={uuidv4()} align={"center"}>
                                    {new Date(e.Fecha).toLocaleDateString()}
                                </TableCell>

                                <TableCell key={uuidv4()} align={"center"}>
                                    {Empleados.find(i=> i.id ==e.Personal.id).user.username}
                                </TableCell>

                                <TableCell key={uuidv4()} align={"center"}>
                                    {e.PuntoBodega.Nombre}
                                </TableCell>

                                <TableCell key={uuidv4()} align={"center"}>
                                    {e.Ruta.id}
                                </TableCell>

                                <TableCell key={uuidv4()} align={"center"}>
                                    {e.PesoCarga?e.PesoCarga:"No hay datos"}
                                </TableCell>

                                

                            </TableRow>

                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={settingInform && settingInform.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>:
            <div className='d-flex justify-content-center w-100 mt-5 '>
                <PendingActionsIcon sx={{height:70}} style={{ color: '#666',marginRight:4,cursor:"pointer",marginTop:-16 }} />
                <h3>Aún no se han generado informes para la locación</h3>
            </div>
        }
         <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Campos vacios"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ingresa el rango de fechas a filtrar
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpen(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Tablas