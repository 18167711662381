import { Spinner } from "react-bootstrap";
import useDimensions from "../hooks/useDimensions";
import { useSelector } from "react-redux";

function FormLoadings() {
    const { height, width } = useDimensions();
    const user = useSelector(state => state.user);
    let dataColor = user?.typeUser === 'EMPRESA' ? "#"+user?.Personal?.Empresa?.Color : user?.bodegas[0]?.Color;
    const modalStyle = {
        position: 'fixed', 
        top: 0,
        left: 0,
        height: height, // Ocupa toda la altura
        backgroundColor: 'rgba(50, 50, 60, 0.7)', // Un gris azulado claro con transparencia
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000, // Asegura que el modal esté sobre otros elementos
      };

    return (
        <div className="d-flex justify-content-center align-items-center w-100 " style={modalStyle}>
            <Spinner animation="border" role="status" color={dataColor} variant={'light'}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
  }
  
  export default FormLoadings;