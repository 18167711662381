import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { FormDates, calcularDistancia,  filtrarPorFecha, hasPermission } from '../../../helpers/Utils';
import { Paper,Accordion,AccordionSummary,AccordionDetails,Typography, Tooltip, Box, Select, InputLabel, FormControl, MenuItem, OutlinedInput } from '@mui/material';
 import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HistorialVisitas from './TypesTables/HistorialDeVisitas/HistorialVisitas';
import MaterialesVentas from './TypesTables/Ventas/MaterialesVentas';
import IngresosPlanta from './TypesTables/IngresosPlanta/IngresosPlanta';
import HistSeparacion from './TypesTables/Separacion/HistSeparacion';
import Kilometros from './TypesTables/Kiloetros';
const { v4: uuidv4 } = require('uuid');

const Tables = ({data}) => {

    const state = useSelector(state => state.user)
    const [historialRecogida, setHistorialRecogida] = useState([])
    const [historialIngreso, setHistorialIngreso] = useState([])
    const [historialSeparacion, setHistorialSeparacion] = useState([])
    const { empresas } = useSelector(state => state.empresa)
    const [dates, setDates] = useState({})
    const [Moneda, setMoneda] = useState("64c85ab91dbf8c00144b45e4")

    useEffect(() => {
        let filter = []
        let filter2 = []
        let filter3 = []
        if(data.type == "personalizado"){
             filter = filtrarPorFecha(state?.reportes?.Recogidas,data.type ,data.fecha1,data.fecha2) 
             filter2 = filtrarPorFecha(state?.reportes?.IngresoBodega,data.type ,data.fecha1,data.fecha2)
             filter3 = filtrarPorFecha(state?.reportes?.Separacion,data.type ,data.fecha1,data.fecha2)
                let dat = {
                    inicio: data.fecha1,
                    fin: data.fecha2
                }
            setDates(dat)
        }else{
            filter = filtrarPorFecha(state?.reportes?.Recogidas, data.type?data.type:'Todo') 
            filter2 = filtrarPorFecha(state?.reportes?.IngresoBodega, data.type?data.type:'Todo')
            filter3 = filtrarPorFecha(state?.reportes?.Separacion, data.type?data.type:'Todo')
            let dat = FormDates(data.type?data.type:'Todo')
             setDates(dat)
        }
        
        
        if(state?.typeUser === 'EMPRESA' && state?.Personal?.Empresa?.Servicios.some(e=>e=="65246345637f1900140aa59c")){
            if(Moneda == "Sin moneda"){
                filter = filter.filter(e=>e.PuntoBodega && !e.PuntoBodega.Moneda)
                filter2 = filter2.filter(e=>e.PuntoBodega && !e.PuntoBodega.Moneda)
                filter3 = filter3.filter(e=>e.PuntoBodega && !e.Bodega.Moneda)
            }else{
            filter = filter.filter(e=>e.PuntoBodega&&e.PuntoBodega.Moneda == Moneda )
            filter2 = filter2.filter(e=>e.PuntoBodega&&e.PuntoBodega.Moneda == Moneda)
            filter3 = filter3.filter(e=>e.PuntoBodega&&e.PuntoBodega.Moneda == Moneda)
            }
        }
        setHistorialRecogida(filter)
        setHistorialIngreso(filter2)
        setHistorialSeparacion(filter3)
    }, [data])

    console.log("historialRecogida==>",historialRecogida,"Historial de ingreso", historialIngreso, "historialSeparacion",historialSeparacion);
   
    const distanciaTotal = historialRecogida.map(i=>i.PuntoGenerador).reduce((acumulador, actual, index, array) => {
        if (index < array.length - 1) {
            const distancia = calcularDistancia(
                (actual && actual.Latitud !== undefined) ? actual.Latitud : 0,
                (actual && actual.Longitud !== undefined) ? actual.Longitud : 0,
                (array[index + 1] && array[index + 1].Latitud !== undefined) ? array[index + 1].Latitud : 0,
                (array[index + 1] && array[index + 1].Longitud !== undefined) ? array[index + 1].Longitud : 0
            );
            return acumulador + distancia;
        }
        return acumulador;
      }, 0);

   const onInputChange = (t) => {
    console.log("t",t.target.value);
    let filter = []
    let filter2 = []
    let filter3 = []
        if(data.type == "personalizado"){
            filter = filtrarPorFecha(state?.reportes?.Recogidas,data.type ,data.fecha1,data.fecha2) 
            filter2 = filtrarPorFecha(state?.reportes?.IngresoBodega,data.type ,data.fecha1,data.fecha2)
            filter3 = filtrarPorFecha(state?.reportes?.Separacion,data.type ,data.fecha1,data.fecha2)
            let dat = {
                inicio: data.fecha1,
                fin: data.fecha2
            }
            setDates(dat)
        }else{
            filter = filtrarPorFecha(state?.reportes?.Recogidas, data.type?data.type:'Todo') 
            filter2 = filtrarPorFecha(state?.reportes?.IngresoBodega, data.type?data.type:'Todo')
            filter3 = filtrarPorFecha(state?.reportes?.Separacion, data.type?data.type:'Todo')
            let dat = FormDates(data.type?data.type:'Todo')
                setDates(dat)
        }
        console.log("t",t.target.value,filter[0].PuntoBodega.Moneda);
        if(state?.typeUser === 'EMPRESA' && state?.Personal?.Empresa?.Servicios.some(e=>e=="65246345637f1900140aa59c")){
            if(t.target.value == "Sin moneda"){
                filter = filter.filter(e=>e.PuntoBodega && !e.PuntoBodega.Moneda)
                filter2 = filter2.filter(e=>e.PuntoBodega && !e.PuntoBodega.Moneda)
                filter3 = filter3.filter(e=>e.PuntoBodega && !e.PuntoBodega.Moneda)
            }else {
                filter = filter.filter(e=>e.PuntoBodega && e.PuntoBodega.Moneda === t.target.value)
                filter2 = filter2.filter(e=>e.PuntoBodega && e.PuntoBodega.Moneda === t.target.value)
                filter3 = filter3.filter(e=>e.PuntoBodega && e.PuntoBodega.Moneda === t.target.value)
            }
        }
        setHistorialRecogida(filter)
        setHistorialIngreso(filter2)
        setHistorialSeparacion(filter3)
    }
    useEffect(() => {}, [historialIngreso, historialRecogida, historialSeparacion, Moneda])
  return (
    <div>

        <div className='d-flex justify-content-between p-5 pb-3'>
            {/* <div>
                <p> <b>De </b> {new Date(dates.inicio).toLocaleDateString()} <b> A </b> {new Date(dates.fin).toLocaleDateString()} </p>
            </div> */}
            {dates.inicio == undefined||dates.inicio == "Invalid Date" ?
                <p> <b> Informe historico </b> </p>
            :
                <p> <b>De </b> {new Date(dates.inicio).toLocaleDateString()} <b> A </b> {new Date(dates.fin).toLocaleDateString()} </p>
            }
        </div>
        
        <div className='d-flex justify-content-between px-3 pb-3'>
        {  state?.typeUser === 'EMPRESA' && state?.Personal?.Empresa?.Servicios.some(e=>e=="65246345637f1900140aa59c")&& 
            <div className='d-flex justify-content-between gap-3'>
                <p>Filtro por moneda:</p>
                <Box sx={{ minWidth: 120, maxWidth: 600, height:30 }}>
                    <FormControl fullWidth style={{height:30}}>
                        <InputLabel id="label-punto">Moneda</InputLabel>
                        <Select
                            displayEmpty labelId="label-punto" required
                            value={Moneda=== "64c85ab91dbf8c00144b45e4"?"COP":Moneda=="64c85ad21dbf8c00144b45e5"?"MXN":Moneda}
                            onChange={(eve)=>{onInputChange(eve);setMoneda(eve.target.value)}}
                            input={<OutlinedInput label='Monedas' />} renderValue={(selected) => { return selected; }}
                            inputProps={{ 'aria-label': 'Without label' }} style={{height:30}}
                        >
                            <MenuItem value={"Sin moneda"} >
                                <em>sin moneda</em>
                            </MenuItem>

                            {/* <MenuItem value={"TODO"} >
                                <em>Todo</em>
                            </MenuItem> */}
                           
                            {state?.Personal?.Empresa?.Monedas.map((e) => (
                                <MenuItem value={e} >
                                    <em>{e=="64c85ab91dbf8c00144b45e4"?"COP":e=="64c85ad21dbf8c00144b45e5"?"MXN":"PEN"}</em>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </div>
        }
            <div className='m-2 mx-3'>
                <h4>Distancia total recorrida: {distanciaTotal.toFixed(2)} km</h4>
            </div>
        </div>

        <Box sx={{ width: '100%', marginTop:1, padding:2 }}>
            <div>
                <Accordion defaultExpanded={true} style={{position:"relative",borderRadius:10}} >
                    

                    <AccordionSummary  expandIcon={<ExpandMoreIcon />} 
                    aria-controls="panel1a-content" id="panel1a-header" >
                        <h4 style={{color:state?.colors[1]}}>Historial de visitas</h4>
                    </AccordionSummary>

                    <AccordionDetails >
                        <HistorialVisitas historialRecogida={historialRecogida} dates={dates} />
                    </AccordionDetails>

                </Accordion>

                {((state?.typeUser =="EMPRESA" && hasPermission(empresas.length>0&&empresas[0]?.empresas?.Servicios,"Compra y/o venta"))||(state?.typeUser =="PUNTOBODEGA" && hasPermission(state?.bodegas[0]?.PermisosBodegas,"Comprar")) )&&
                    <Accordion  style={{position:"relative",borderRadius:10}}>

                        <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content" id="panel2a-header" >
                            <h4 style={{color:state?.colors[1]}}>Historial de compras</h4>
                        </AccordionSummary>

                        <AccordionDetails>
                            <MaterialesVentas historialRecogida={historialRecogida} historialIngreso={historialIngreso} dates={dates}/>
                        </AccordionDetails>
                        
                    </Accordion>
                }

                <Accordion  style={{position:"relative",borderRadius:10}}>

                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content" id="panel2a-header" >
                        <h4 style={{color:state?.colors[1]}}>Historial de Ingreso</h4>
                    </AccordionSummary>

                    <AccordionDetails>
                        <IngresosPlanta historialRecogida={historialRecogida} Ingreso={historialIngreso}/>
                    </AccordionDetails>

                </Accordion>

                <Accordion  style={{position:"relative",borderRadius:10}}>

                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content" id="panel2a-header" >
                        <h4 style={{color:state?.colors[1]}}>Historial de Separación</h4>
                    </AccordionSummary>

                    <AccordionDetails>
                        <HistSeparacion splitter={historialSeparacion}/>
                    </AccordionDetails>

                </Accordion>
                {state.typeUser =="EMPRESA"&&
                    <Accordion  style={{position:"relative",borderRadius:10}}>

                        <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content" id="panel2a-header" >
                            <h4 style={{color:state?.colors[1]}}>Kilometros recorridos</h4>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Kilometros historial={historialRecogida}/>
                        </AccordionDetails>

                    </Accordion>
                }
            </div>
        </Box>

    </div>
  )
}

export default Tables