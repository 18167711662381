import React, { useEffect } from 'react'
import { useForm } from '../../hooks/useForm';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, Box, Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Form } from 'react-bootstrap';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Swal from 'sweetalert2';
import { PostAllWithAuth, PutAllWithAuth, UseGetWithAuth2 } from '../../service/base2';
import { fileUploadPersonal } from '../../helpers/FileUpload';
import { RegisterUser } from '../bodegas/components/RegisterUser';
import { encontrarValores, encontrarValoresName, separarCodigoYTelefono } from '../../helpers/Utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import PermissionsCheck from '../../hooks/PermissionsCheck';
import FormLoadings from '../../helpers/FormLoadings';
import code from '../../helpers/Codephones.json';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,

        },
    },
};

export const NewPersonalEmpresa = () => {
    const { empresas } = useSelector(state => state.empresa);
    const user = useSelector(state => state.user);
    const location = useLocation();
    const navigate = useNavigate();
    const objetoRecibidoByparams = useParams()
    const [loading, setLoading] = React.useState(false);
    const [file, setFile] = useState([]);
    const [newUser, setNewUser] = useState([])
    const [personalById, setPersonalById] = useState({})
    const [permisosSeleccionados, setPermisosSeleccionados] = useState([]);
    const [Code,setCode]=useState(objetoRecibidoByparams.id? !separarCodigoYTelefono(personalById.Telefono).error?separarCodigoYTelefono(personalById.Telefono).codigo: "+57": "+57")
    const handlePermisosChange = (selected) => {
        setPermisosSeleccionados(selected);
    };

    let { Telefono=objetoRecibidoByparams.id ? separarCodigoYTelefono(personalById?.Telefono).error?"":separarCodigoYTelefono(personalById?.Telefono).numero : '', Cedula,  Imagen, formState, onInputChange, onResetForm } = useForm(
        {
          
            Telefono: objetoRecibidoByparams.id  ? separarCodigoYTelefono(personalById?.Telefono).error?"":separarCodigoYTelefono(personalById?.Telefono).numero : '',
            Cedula: personalById?.Cedula ? personalById?.Cedula : '',
            Latitud: personalById.Latitud !== undefined ? personalById?.Latitud : '0',
            Longitud: personalById.Longitud !== undefined ? personalById?.Longitud : '0',
            Empresa: user?.Personal?.Empresa?.id,

        });

    const onNavigateBack = () => {
        navigate(-1)
    };
console.log("personalById",personalById);
    const getPersonalBodegaById = async () => {
        const {resGet} = await UseGetWithAuth2(`/personals/${objetoRecibidoByparams.id}`, user?.Personal?.uid);
        setPersonalById(resGet);
    };

    const handleFileChanged = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
       setLoading(true)
        
        if(!permisosSeleccionados.length >0){
            setLoading(false)
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'Por favor seleccione los permisos',
                showConfirmButton: false,
                timer: 1500
            })
        }else{
            console.log("newUser", newUser);
            if (newUser) {
                if (file?.name?.length > 0) {
                    await fileUploadPersonal(file)
                        .then(response => {
                            formState.Imagen = response
                        })
                };
                const res = await PostAllWithAuth(`/users-permissions/auth/register-with-firebase/`, user?.Personal?.uid, newUser)
                console.log("respuesta",res);
                if (res.ok) {
                    let userCurrent = await res.resPost.data.user
                    Swal.fire({
                        position: 'center', icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })

                    formState.user = userCurrent.id;
                    formState.Permisos = permisosSeleccionados;
                    formState.Empresa = empresas[0]?.empresas.id;
                    formState.Telefono = Code+formState.Telefono.toString()
                    console.log("formState", formState);
                    const { ok, resPost } = await PostAllWithAuth(`/personals/`, user?.Personal?.uid, formState);
                    let res2 = resPost.data
                    console.log("resPost", resPost);
                    if (ok) {
                        console.log(resPost);
                        setLoading(false)
                        let personals = user.empleados
                        personals.push(resPost.data);
                        user.empleados = personals;
                        onResetForm()
                        onNavigateBack()
                        Swal.fire({
                            position: 'center', icon: 'success', title: `${newUser.username} ha sido agregado`,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        onResetForm()
                        setLoading(false)
                        Swal.fire({
                            position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        onNavigateBack()
                    }
                } else {
                    onResetForm()
                    setLoading(false)
                    Swal.fire({
                        position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    onNavigateBack()
                }
            }
        }

    };

    const EditPersonal = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (file?.name?.length > 0) {
            await fileUploadPersonal(file)
                .then(response => {
                    formState.Imagen = response
                })
        };
        formState.Empresa = user?.Personal?.Empresa?.id;
            formState.Cedula = !formState.Cedula ? Number(personalById?.Cedula):Number(formState.Cedula)
            formState.Telefono = Code+formState.Telefono.toString() != personalById?.Telefono ?  Code+formState.Telefono.toString():personalById?.Telefono

        let data = { ...formState, Permisos: permisosSeleccionados };
        if(newUser.username !== personalById?.user?.username){
            const dato = await PutAllWithAuth(`/users/`+personalById?.user.id, user?.Personal?.uid, {"username":newUser.username})
           console.log("data", dato);
            
        }
        console.log("formState", data);
        const { ok,resPut } = await PutAllWithAuth(`/personals/${objetoRecibidoByparams?.id}`, user?.Personal?.uid, data);
        console.log("resPut", resPut);
        if (ok) {
            setLoading(false)
            Swal.fire({
                position: 'center', icon: 'success', title: `${personalById?.user?.username} ha sido Actializado`,
                showConfirmButton: false,
                timer: 1500
            })
            let personal = user.empleados.filter((e) => e.id !== objetoRecibidoByparams.id);
            personal.push(resPut);
            user.empleados = personal;
            onNavigateBack()
        } else {
            setLoading(false)
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                showConfirmButton: false,
                timer: 1500
            })
            onNavigateBack()
        }
       
        
       
    };
    
    useEffect(() => {
        if (objetoRecibidoByparams.id) {
            getPersonalBodegaById();
        }
    }, [personalById]);

    const handleSubmitUser = async () => {
        
        const { ok, resPost } = await PostAllWithAuth(`/users-permissions/auth/register-with-firebase/`, user?.Personal?.uid, newUser)
       
        if (ok) {
            Swal.fire({
                position: 'center', icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })
         
             setNewUser(resPost.data)
             
        } else {
            Swal.fire({
                position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos',
                showConfirmButton: false,
                timer: 1500
            })
        }
        
    }

    return (
        <>
            { loading&&
                <FormLoadings/>}
            <>
                <header className='mb-2'>
                    <nav className='d-flex gap-3 justify-content-between p-2  '
                        style={{ backgroundColor: `#${user?.Personal?.Empresa?.Color}`, fontWeight: "bold",borderRadius:20 }}
                    >
                        <div className='ml-5'>
                            <h5 style={{ color: "white",marginLeft:10 }}>{objetoRecibidoByparams.id ? `Actualizar datos de ${personalById?.user?.username}` : 'Agregar personal a Empresa'}</h5>
                        </div>
                    </nav>
                    <div className='d-flex justify-content-end'>
                    <Button variant="contained" startIcon={<ReplyAllIcon />} style={{ backgroundColor: `#${user?.Personal?.Empresa?.Color}`, cursor: "pointer",borderRadius:40,marginTop:15 }}
                        onClick={onNavigateBack}
                    >
                        Volver a Personal
                    </Button>
                    </div>

                </header>
                 {/* //========================= Primer bloque - credenciales usuario ========================= */}
                <RegisterUser userEdit={objetoRecibidoByparams?.id && personalById} setNewUser={setNewUser} handleSubmitUser={handleSubmitUser}/>
            
                <main style={{marginTop:-40}}>
                    <Form className='w-100 m-3' 
                        onSubmit={objetoRecibidoByparams?.id ? EditPersonal : handleSubmit}
                    >
                        <Grid container spacing={2} style={{alignItems:"center",margin:7,width:"90%",
                        borderRadius:5,alignSelf:"center"}}>

                            {/* //========================= Segundo bloque - datos personales ========================= */}
                            <Grid item xs={12} md={6} >
                                {/* //========================= Cedula ========================= */}
                                    <TextField
                                        label="Cédula"
                                        type="number"
                                        placeholder={objetoRecibidoByparams?.id ?personalById.Cedula:"Cédula"}
                                        fullWidth
                                        name="Cedula"
                                        value={Cedula}
                                        onChange={onInputChange}
                                        style={{marginTop:10}}
                                    />
                                {/* //========================= Foto ========================= */}
                                    <TextField
                                        label={<>Imagen (<span style={{ fontSize: '70%' }} className='text-warning'>Opcional</span>)</>}
                                        type="file"
                                        // placeholder="Imagen"
                                        fullWidth
                                        name="Imagen"
                                        focused
                                        value={Imagen}
                                        onChange={handleFileChanged}
                                        style={{marginTop:10}}
                                    />
                                {/* //========================= Telefono ========================= */}
                                    <div className='d-flex'>

                                        <Autocomplete
                                            id="combo-box-demo"
                                            aria-required={true}
                                            options={code.paises.map((option) => option.codigo)}
                                            onChange={(e)=>setCode(e.target.textContent)}
                                            style={{ width: 100,marginTop:10 }}
                                            renderInput={(params) => (
                                            <TextField {...params} label="Prefijo" variant="outlined" />
                                            )}
                                            defaultValue={objetoRecibidoByparams.id? !separarCodigoYTelefono(personalById.Telefono).error?separarCodigoYTelefono(personalById.Telefono).codigo:Code:Code}
                                        />
                                        <TextField
                                            label="Teléfono"
                                            type="number"
                                            placeholder={objetoRecibidoByparams?.id ?personalById.Telefono:"Teléfono"}
                                            fullWidth
                                            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            name="Telefono"
                                            value={Telefono}
                                            onChange={onInputChange}
                                            defaultValue={objetoRecibidoByparams.id? !separarCodigoYTelefono(personalById.Telefono).error?separarCodigoYTelefono(personalById.Telefono).numero:Telefono:Telefono} 
                                            style={{marginTop:10}}
                                        />

                                    </div>
                                
                            </Grid>
                            {/* //========================= Tercer bloque - Permisos ========================= */}
                            <Grid item xs={12} md={6} >
                                {objetoRecibidoByparams?.id ?
                                <PermissionsCheck onChange={handlePermisosChange} permisos={user.Permisos} tipo={"AdministradorEmpresa"} selectedPermsInitial={personalById.Permisos}/>
                                :
                                <PermissionsCheck onChange={handlePermisosChange} permisos={user.Permisos} tipo={"AdministradorEmpresa"} />
                                }
                            </Grid>
                            {/* //========================= Btn- submit ========================= */}
                            <Grid item xs={12} md={12} >
                                <Button variant='contained' type='submit'
                                    style={{ backgroundColor: user?.colors[2]}}
                                >
                                    {objetoRecibidoByparams?.id ? 'Actualizar' : 'Guardar'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </main>
            </>
        </>
    )
}
