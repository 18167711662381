import { types } from "../types/userType";


const reducerUsers = (state = {}, action) => {
  switch (action.type) {

    case types.getCurrentUser:
      return action.payload;

    case types.logout:
      return action.payload;

    case types._bodegas:
      return { ...state, bodegas: action.payload };

    case types._rutas:
      return { ...state, rutas: action.payload };

    case types._puntosBodegas:
      return { ...state, PuntosBodega: action.payload };

    case types._empresa:
      return { ...state, Empresa: action.payload };

    case types._personal:
      return { ...state, Personal: action.payload };

    case types._reportes:
      return { ...state, reportes: action.payload };

    case types._empresasGeneradores:
      return { ...state, EmpresasGeneradoras: action.payload };

    case types._generadores:
      return { ...state, PuntosGeneradores: action.payload };

    case types._materiales:
      return { ...state, materiales: action.payload };

    case types._materialesDescartables:
      return { ...state, materialesDescartables: action.payload }

    case types._materialesDescartablesPropios:
      return { ...state, PuntosBodega: [{ ...state.PuntosBodega[0], MaterialesDescartables: action.payload }] }

    case types._empleados:
      return { ...state, empleados: action.payload };

    case types._PermisosPunGeneradores:
      return { ...state, permisosPuntosGeneradores: action.payload };

    case types._PermisosGestores:
      return { ...state, permisosGestores: action.payload };

    case types._bolsas:
      return { ...state, bolsas: action.payload }
    case types._Pqrs:
      return { ...state, Pqrs: action.payload }

    default:
      return state;
  }
};

export default reducerUsers;