import React from 'react'

const Loader = () => {
    return (
        <div className="loader loader--style4" title="20">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="404px"
          height="70px"
          viewBox="0 0 24 24"
        >
          <rect x="0" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,4; 1,1"
              begin="0s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
  
          <rect x="5" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,4; 1,1"
              begin="0.2s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="10" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.4s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
  
          <rect x="15" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="20" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="25" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,4; 1,1"
              begin="0.2s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="30" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.4s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
  
          <rect x="35" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="40" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="45" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,4; 1,1"
              begin="0.2s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="50" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.4s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
  
          <rect x="55" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="60" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="65" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="-5" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,4; 1,1"
              begin="0.2s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="-10" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.4s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
  
          <rect x="-15" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="-20" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="-25" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,4; 1,1"
              begin="0.2s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="-30" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.4s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
  
          <rect x="-35" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="-40" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="-45" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,4; 1,1"
              begin="0.2s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="-50" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.4s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
  
          <rect x="-55" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="-60" y="0" width="2" height="4" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="scale"
              values="1,1; 1,3; 1,1"
              begin="0.6s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
        </svg>
        
      
      </div>
    );
  };
  
  export default Loader;
  