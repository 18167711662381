import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import useDimensions from '../../../hooks/useDimensions';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { UsePost, UsePostWithAuth } from '../../../service/base/base';
import Swal from 'sweetalert2';
import { PutAllWithAuth } from '../../../service/base2';
import axios from 'axios';
import { DescartablesPropiosAction } from '../../../service/redux/action/DataActions';

const NewDescarte = ({Descartes,setModal,setMaterialesDescartables}) => {
    
    const { height, width } = useDimensions();
    const [createNewMaterial,setCreateNewMaterial]=useState(false)
    const [NewMaterial,setNewMaterial]=useState('')
    const [idMaterial,setIdMaterial]=useState("")
    const user = useSelector(state=>state.user)
    let actDescart = user?.PuntosBodega[0].MaterialesDescartables || [];
    const y = [...Descartes]; 

const actDescartIds = new Set(actDescart.map(item => item._id));

const filteredY = y.filter(item => !actDescartIds.has(item._id));

    const [toDescart,setToDescart]=useState([new Set( actDescart.concat(Descartes).map(e=>e._id))])
   
    const handleChangeSelectMaterial = (event) => { setIdMaterial(event.target.value); };
    const handleChangeNewtMaterial = (event) => { setNewMaterial(event.target.value); };
    const dispatch = useDispatch()
    const modalStyle = {
        position: 'fixed', 
        top: 0,
        left: 0,
        height: height, // Ocupa toda la altura
        backgroundColor: 'rgba(50, 50, 60, 0.7)', // Un gris azulado claro con transparencia
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000, // Asegura que el modal esté sobre otros elementos
      };
      
console.log("Descartes",Descartes);
console.log("User",user);

const CreateDescarte =async()=>{
        if(createNewMaterial){
            if(NewMaterial.length>0){
                console.log(" NewMaterial", NewMaterial);
                const resPost  = await axios.post('https://hiloapi.herokuapp.com/materiales-descartables',{Material: NewMaterial})
                //axios.post('https://hiloapi.herokuapp.com/materiales-descartables',{Material: NewMaterial})
                // UsePostWithAuth(`/materiales-descartables`, user?.Personal?.uid,{Material: NewMaterial})
                console.log(resPost);
                if(resPost.statusText =="OK"){
                    const idMateriales = user?.PuntosBodega[0].MaterialesDescartables?user?.PuntosBodega[0].MaterialesDescartables.map(e=>e.id):[]
                    idMateriales.push(resPost.data.id)
                    let data ={MaterialesDescartables:idMateriales}
                    console.log("data",data);
                    const Put = await PutAllWithAuth(`/puntos-bodegas/${user?.PuntosBodega[0].id}`, user?.Personal?.uid,data);
                    console.log(Put);
                    if(Put.ok){
                        dispatch(DescartablesPropiosAction(Put.resPut.MaterialesDescartables))
                        Swal.fire({ position: 'center', icon: 'success', title: `El material ha sido actualizado`,showConfirmButton: false, timer: 1500 })
                        setModal(false)
                    }else{ Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos', showConfirmButton: false, timer: 1500}); setModal(false)}
                }else{ Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos', showConfirmButton: false, timer: 1500}); setModal(false)}
            }
        }else{
            const finder = Descartes.find(e=>e.id ==idMaterial)
            console.log(finder);
            console.log(idMaterial);
            if(finder){
                const idMateriales = user?.PuntosBodega[0].MaterialesDescartables?user?.PuntosBodega[0].MaterialesDescartables.map(e=>e._id):[]
                    idMateriales.push(finder._id)
                    console.log(idMateriales);
                    let data ={MaterialesDescartables:idMateriales}
                    console.log("data",data);
                    const Put = await PutAllWithAuth(`/puntos-bodegas/${user?.PuntosBodega[0].id}`, user?.Personal?.uid,data);
                    console.log(Put);
                    if(Put.ok){
                   
                        dispatch(DescartablesPropiosAction(Put.resPut.MaterialesDescartables))
                        Swal.fire({ position: 'center', icon: 'success', title: `El material ha sido actualizado`,showConfirmButton: false, timer: 1500 })
                        setModal(false)
                    }else{ Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos', showConfirmButton: false, timer: 1500}); setModal(false)}
            }else{ Swal.fire({ position: 'center', icon: 'error', title: 'Oops...', text: 'No se han guardado los datos', showConfirmButton: false, timer: 1500}); setModal(false)}
        }
}

  return (
    <div  className="d-flex justify-content-center align-items-center w-100 " style={modalStyle}>
        <div className="d-flex flex-column justify-content-center align-items-center "style={{ minWidth: "50%",height:"50%",backgroundColor: "White", padding:10,position:"relative", borderRadius:20 }}>
               
                <div className="d-flex flex-column justify-content-center align-items-center "  
                style={{ position:"absolute",top:7,right:7, backgroundColor: user.colors[2], cursor: "pointer",borderRadius:"100%",height:30,width:30,
                boxShadow: "0px 2px 3px 3px rgba(0,0,0,0.2)", }}
                    onClick={()=>setModal(false)}>
                        <HighlightOffIcon color='white' style={{color:"white"}} />
                </div>

                <Button variant="contained" startIcon={<AddCircleIcon style={{color:"#333"}} />} style={{ backgroundColor: user.colors[8], cursor: "pointer",borderRadius:10,color:"#333" }}
                    onClick={()=>{!createNewMaterial? setCreateNewMaterial(true):setCreateNewMaterial(false)}}>
                 {!createNewMaterial? "Crear Material":"Seleccionar Material"}

              </Button>
            <Box className='mt-3' sx={{ minWidth: "60%"}}>
                {!createNewMaterial?
                <div style={{padding:40,boxShadow:"none",borderRadius:50}}>
                    <h3 className='mb-5' style={{color:"#000"}}> Selecciona el material que deseas agregar </h3>
                    <FormControl fullWidth>
                        <InputLabel id="label-bodega">Material</InputLabel>
                            <Select labelId="label-bodega" id="demo-simple-select" required
                                value={idMaterial} label="Material" onChange={handleChangeSelectMaterial}>
                                {filteredY?.map((punto) => (
                                    <MenuItem key={punto?.id} value={punto?.id}>{punto?.Material} </MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                </div>
                :
                <div style={{padding:40,boxShadow:"none",borderRadius:20}}>
                    <h3 className='mb-5' style={{color:"#000"}}> Escribe el nombre del nuevo material</h3>
                    <FormControl fullWidth>
                        <TextField
                                    label="Nombre del material"
                                    type="text"
                                    placeholder="Nombre del material"
                                    fullWidth
                                    required
                                    value={NewMaterial}
                                    onChange={handleChangeNewtMaterial}
                                    className='mb-2'
                                />
                    </FormControl>
                </div>
                }
            </Box>
                <Button variant="contained"  style={{ backgroundColor: user.colors[1], cursor: "pointer",borderRadius:10, width:"95%",}}
                    onClick={()=>{CreateDescarte()}}>
                    Agregar Material
                </Button>
        </div>
    </div>
  )
}

export default NewDescarte