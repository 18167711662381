
import React, { useState } from 'react'
import { PersonalBodegas } from '../PersonalBodegas';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import Rutas from './Tables/Rutas';
import PQRS from '../tablas/PQRS/PQRS';

const TablasBodega = ({ sede }) => {
    const user = useSelector(state => state.user)
    console.log("sede en tablasBodega", sede);
    const personalsById = user?.PuntosBodega.filter(punto => punto._id === sede._id).flatMap(e => e.empleados)
    const [bodegaById, setBodegaById] = useState(user.PuntosBodega.filter(punto => punto._id === sede._id)[0])
    const [type, setType] = useState("Personal")
    const [tabActiva, setTabActiva] = useState('personal');

    const manejarClicTab = (nombreTab) => {
        setTabActiva(nombreTab);
    };


    const hasPermission = (permisoRequerido = ['no hay permisos']) => {
        const userPermissions = permisoRequerido
        return user.Personal.Permisos.some(permission => userPermissions.includes(permission.Permiso));
    };
    console.log('====================================');
    console.log("sede", sede);
    console.log('Per', personalsById);
    return (
        <div>
            {/* <Tabs
                defaultActiveKey="personal"
                className="mt-3">

            

            
            <Tab eventKey="personal" title='Personal'  onSelect={()=>{setType("Personal"); console.log('Tipo seleccionado:', type); }} 
                   >
                    <div  style={{color: type === "Personal" ? user.colors[2] : "#333"}}>
                {hasPermission('Ver personal') ?
                    <PersonalBodegas hasPermission={hasPermission} personalsById={personalsById} bodegaById={bodegaById} />:
                <p>Se requiere autorización para ver este contenido.</p>}
                </div>
            </Tab>
           

            <Tab eventKey="Rutas" title='Rutas' onSelect={() => { setType("Rutas"); console.log('Tipo seleccionado:', type); }}
                    style={{color: type === "Rutas" ? user.colors[2] : "#333"}}>
                <Rutas sede={sede}/>
            </Tab>
            {/* <Tab eventKey="PQRS" title='PQRS' disabled>
                {/* <Rutas sede={sede}/> 
            </Tab> 
            */}

            {/* <Tab eventKey="Historial materiales" title='Historial materiales' >
                <p>Historial materiales.</p>
            </Tab> 
        </Tabs> */}
            <div>
                {/* Pestañas */}
                <div style={{ display: 'flex' }}>

                    <div style={{ cursor: 'pointer', padding: '2px', borderBottom: tabActiva === 'personal' ? "2px solid " + user.colors[2] : 'none', color: tabActiva === 'personal' ? user.colors[2] : '#333', fontWeight: tabActiva === 'personal' ? 900 : 400 }}
                        onClick={() => manejarClicTab('personal')}>
                        Personal
                    </div>

                    <div
                        style={{ cursor: 'pointer', padding: '2px', marginLeft: "15px", borderBottom: tabActiva === 'rutas' ? "2px solid " + user.colors[2] : 'none', color: tabActiva === 'rutas' ? user.colors[2] : '#333', fontWeight: tabActiva === 'rutas' ? 900 : 400 }}
                        onClick={() => manejarClicTab('rutas')} >
                        Rutas
                    </div>
                    <div
                        style={{ cursor: 'pointer', padding: '2px', marginLeft: "15px", borderBottom: tabActiva === 'PQRS' ? "2px solid " + user.colors[2] : 'none', color: tabActiva === 'PQRS' ? user.colors[2] : '#333', fontWeight: tabActiva === 'PQRS' ? 900 : 400 }}
                        onClick={() => manejarClicTab('PQRS')} >
                        PQRS
                    </div>

                </div>

                {/* Contenido de las Pestañas */}
                <div style={{ padding: '1px' }}>
                    {tabActiva === 'personal' && (
                        <div>
                            {hasPermission('Ver personal') ?
                                <PersonalBodegas hasPermission={hasPermission} personalsById={personalsById} bodegaById={bodegaById} /> :
                                <p>Se requiere autorización para ver este contenido.</p>}
                        </div>
                    )}
                    {tabActiva === 'rutas' && (
                        <div>
                            {/* Contenido para la pestaña Rutas */}
                            <Rutas sede={sede} />
                        </div>
                    )}
                    {tabActiva === 'PQRS' && (
                        <div>
                            {/* Contenido para la pestaña Rutas */}
                            <PQRS sede={sede} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TablasBodega

