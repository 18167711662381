import React, { useState } from 'react'
import { Button, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { hasPermission } from '../../../../../../helpers/Utils';
import { useSelector } from 'react-redux';
import Mancha from '../../../../../../assets/blob-haikei.png';
const { v4: uuidv4 } = require('uuid');
const emptyPlaces = require('../../../../../../assets/emptyplaces.png')

const TablasCerradas = ({pqrs}) => {
    const user = useSelector(state=>state.user)
    const [menuOpen, setMenuOpen] = useState(false)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const hasPermission = (permisoRequerido = ['no hay permisos']) => {
        const userPermissions = permisoRequerido
        return user.Personal.Permisos.some(permission => userPermissions.includes(permission.Permiso));
    };

    const findUsername = (ruta) => {
        if (!ruta) return "--"; 
    
        for (const e of user?.PuntosBodega || []) {
            for (const i of e.empleados || []) {
                if (i._id === ruta) {
                    console.log('====================================');
                    console.log(i.user.username);
                    console.log('====================================');
                    return i.user.username;
                }
            }
        }
    
        return "--";
    };
    

  return (
    <main style={{ width: '100%' }}>
               
        {pqrs.length>0?
            <Paper sx={{ width: '100%', marginTop: 2 }}>
                <TableContainer sx={{ }}>
                    <Table  aria-label="sticky table">

                        <TableHead >
                            <TableRow hover tabIndex={-1} key={uuidv4()} >
                                <TableCell align={"center"} size='small' width={"13%"}> <b>Tipo</b> </TableCell>
                                <TableCell align={"center"} size='small' width={"13%"}><b>Creación</b></TableCell>
                                <TableCell align={"center"} size='small' width={"28%"}> <b>Personal</b> </TableCell>
                                <TableCell align={"center"} size='small' width={"28%"}> <b>Descripción</b> </TableCell>
                                <TableCell align={"center"} size='small' width={"13%"}> <b>Cerrada</b> </TableCell>
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            {
                                pqrs?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => (
                                    <TableRow hover tabIndex={-1} key={uuidv4()} >

                                        <TableCell key={uuidv4()} size='small' align={"center"} width={"13%"}> {e.Tipo}</TableCell>
                                        <TableCell key={uuidv4()} size='small' align={"center"} width={"13%"}> {e.Creacion}</TableCell>
                                        <TableCell key={uuidv4()} size='small' align={"center"} width={"28%"}> {findUsername(e.Personal?e.Personal.id:"-")} </TableCell>
                                        <TableCell key={uuidv4()} size='small' align={"justify"} width={"28%"}> {e.Descripcion} </TableCell>
                                        <TableCell key={uuidv4()} size='small' align={"center"} width={"13%"}> {new Date(e.updatedAt).toISOString().split('T')[0]} </TableCell>
                                    
                                    </TableRow>
                                ))
                                

                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={pqrs?.length ? pqrs.length : 1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            :
                                
            <div className='d-flex flex-column gap-3 justify-content-center p-2  mb-2 align-items-center'>
    
                <h3>No hay PQRS cerradas en este momento.</h3>
                <div style={{ backgroundImage: `url(${Mancha})`,width: 500, height: 500, display:"flex",alignItems:"center",justifyContent:"center",
                    backgroundSize: 'cover',backgroundPosition: 'center',marginTop:-60 }}>
                    <img src={emptyPlaces} alt="emptyPlaces" style={{width:160,height:160,}}/>
                </div>

            </div>
        }
    </main>
  )
}

export default TablasCerradas